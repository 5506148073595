<template>
  <v-stepper v-model="newCompanyStep">
    <v-stepper-header>
      <v-stepper-step :complete="newCompanyStep > 1" step="1">Company Name</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="newCompanyStep > 2" step="2">Invite Users</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <h3>Setup a New Company</h3>
        <v-text-field v-model="newCompany.companyName" label="Company Name"></v-text-field>
        <v-btn color="primary" @click="newCompanyStep = 2">Continue</v-btn>
      </v-stepper-content>
      <v-stepper-content step="2">
        <h1>Send Invites</h1>
        <v-flex xs12 style="margin-bottom: 2em;">
          <table>
            <tr v-if="newCompany.invites.length > 0">
              <th style="text-align: left;">Email Address</th>
              <th style="text-align: left;">Navigation Permissions</th>
              <th style="text-align: left;">Admin</th>
            </tr>

            <tr v-for="(invite, index) in newCompany.invites" :key="'invite-' + index">
              <td>
                <v-text-field v-model="invite.email" label="Email Address"></v-text-field>
              </td>
              <td>
                <v-select
                  multiple
                  :items="$store.getters.navPermissions"
                  v-model="invite.navPermissions"
                  label="UI Navigation Permissions"
                ></v-select>
              </td>
              <td>
                <v-checkbox v-model="invite.inviteIsAdmin"></v-checkbox>
              </td>
              <td>
                <v-btn @click="newCompany.invites.splice(index, 1)" icon color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <v-btn
                @click="
                  newCompany.invites.push({
                    company: '',
                    invite_sender: $store.state.userInfo._id,
                    email: '',
                    inviteIsAdmin: false,
                    navPermissions: [],
                    token: $UUID()
                  })
                "
                icon
                color="primary"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </tr>
          </table>
        </v-flex>
        <v-btn color="error" @click="newCompanyStep = 1">Back</v-btn>
        <v-btn
          color="secondary"
          style="margin: 0 1em 0 4em;"
          @click="setupNewCompanyAndSendInvites(false)"
        >Skip & Finish</v-btn>
        <v-btn
          :loading="sendingInvites"
          color="primary"
          @click="setupNewCompanyAndSendInvites(true)"
        >
          {{
            !doneSendingInvites ? "Send Invites & Finish" : "Invites Sent"
          }}
        </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  data() {
    return {
      newCompanyStep: 0,
      doneSendingInvites: false,
      sendingInvites: false,
      newCompany: {
        companyName: "",
        invites: [
          {
            invite_sender: "",
            inviteisAdmin: false,
            email: "",
            permissions: [],
            navPermissions: [],
            company: "",
            token: this.$UUID()
          }
        ]
      }
    };
  },
  methods: {
    sendInvites() {
      var that = this;
      var invitesSent = [];
      var invitesFailed = [];
      that.doneSendingInvites = false;
      that.sendingInvites = true;
      for (let x = 0; x < this.newCompany.invites.length; x++) {
        const invite = this.newCompany.invites[x];
        const inviteEmailData = {
          from: {
            name: "Cognitus CRM",
            email: "crm@cognitusconsulting.com"
          },
          sender: that.$store.state.userInfo.cognitus_email_address,
          to: that.inviteEmailAddress,
          subject: "Cognitus Surfboard - You have been invited to a company.",
          cc: that.$store.state.userInfo.cognitus_email_address,
          plain: `${that.$store.state.userInfo.cognitus_email_address} has invited you to ${that.newCompany.companyName}!\n\nhttps://surfboard.cognitusconsulting.com/company/${invite.company}/join/${invite.token}\nCompany ID: ${invite.company}\nInvite Token: ${invite.token}`,
          html: `<h3>${that.$store.state.userInfo.cognitus_email_address} has invited you to ${that.newCompany.companyName}!</h3><br/><br/><h1><a href="https://surfboard.cognitusconsulting.com/company/${invite.company}/join/${invite.token}">Join Surfboard</a></h1><br/><h4>Company ID: ${invite.company}</h4><br/><h4>Invite Token: ${invite.token}</h4>`
        };
        that.$BackendService.company.email.send(inviteEmailData).then(function(response) {
          invitesSent.push(inviteEmailData);
            that.$BackendService.company.addToHistory({
              event: "inviteEmailSend",
              status: "success",
              user_id: that.$store.state.userInfo_id,
              data: inviteEmailData
            });
        }).catch(function(error) {
          invitesFailed.push(inviteEmailData);
          that.$BackendService.company.addToHistory({
            event: "inviteEmailSend",
            status: "failed",
            user_id: that.$store.state.userInfo_id,
            data: inviteEmailData
          });
        });
      }
      that.$AlertService.newAlert({
        message: `Invites: ${invitesSent.length} Sent, ${invitesFailed.length} Failed.`,
        timeout: 5,
        show: true,
        color: "teal"
      });
      that.sendingInvites = false;
      that.doneSendingInvites = true;
    },
    setupNewCompanyAndSendInvites(sendInvites = false) {
      let that = this;
      this.setupNewCompany(sendInvites)
        .then(function(bool) {
          if (bool) {
            that.sendInvites();
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    setupNewCompany(sendInvites = false) {
      var that = this;
      return new Promise(function(resolve, reject) {
        var companyID = that.$UUID();
        if (that.newCompany.invites.length > 0) {
          for (let x = that.newCompany.invites.length - 1; x >= 0; x--) {
            if (that.newCompany.invites[x].email !== "") {
              that.newCompany.invites[x].company = companyID;
              // that.newCompany.invites[x].permissions = [].concat(that.newCompany.invites[x].navPermissions.map(navItem => navItem.value.requiredPermissions.map(p => `wavedash.${companyID}.${p}`)), that.$store.state.navigation.requiredPermissions)
              that.newCompany.invites[x].navPermissions = that.newCompany.invites[
                x
              ].navPermissions.map(
                navItem => `${companyID}.${navItem.value.navPermission}`
              );
              that.newCompany.invites[x].invite_sender =
                that.$store.state.userInfo._id;
              if (that.newCompany.invites[x].inviteIsAdmin) {
                // that.newCompany.invites[x].permissions.push(`wavedash.${companyID}.admin`);
                that.newCompany.invites[x].navPermissions.push(`${companyID}.admin`);
              }
            } else {
              that.newCompany.invites.splice(x, 1);
            }
          }
        }
        let companyData = {
          _id: that.$store.state.userADInfo.id,
          company: {
            _id: companyID,
            name: that.newCompany.companyName,
            invites: that.newCompany.invites,
            users: [that.$store.state.userADInfo.id],
            onboardings: []
          }
        };
        that.$BackendService.company
          .new(companyData)
          .then(function(company) {
            // send alert to screen
            that.$AlertService.newAlert({
              message: "Company Created!",
              timeout: 5,
              show: true,
              color: "success"
            });
            // add history to company
            that.$BackendService.company.addToHistory({
              event: "companyCreate",
              status: "success",
              user_id: that.$store.state.userInfo_id,
              data: company
            });
            // get new user info
            that.$BackendService.user
              .getInfo({
                _id: that.$store.state.userADInfo.id
              })
              .then(function(user) {
                that.$store.dispatch("SET_USER_INFO", user);
                that.$eventBus.$emit("getCompanies", {});
                that.$eventBus.$emit("getUsers", { data: {
                  company_id: company._id
                }});
                that.$eventBus.$emit("getOnboardings", { data: {
                  company_id: company._id
                }});
                that.$router.push({
                  path: `/company/${company._id}/home`
                });
                resolve(sendInvites);
              })
              .catch(function(error) {
                that.$AlertService.newAlert({
                  message: "Unable to get User Data!",
                  timeout: 5,
                  show: true,
                  color: "error"
                });
                reject(error);
              });
          })
          .catch(function(error) {
            that.$AlertService.newAlert({
              message: "Unable to create new Company!",
              timeout: 5,
              show: true,
              color: "error"
            });
            that.$BackendService.company.addToHistory({
              event: "companyCreate",
              status: "failed",
              user_id: that.$store.state.userInfo_id,
              data: companyData
            });
            reject(error);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>