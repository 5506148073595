import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Page404 from "./views/404.vue";
import UserSettings from "./views/UserSettings.vue";
import Company from "./views/Company.vue";
Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [{
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/company",
      name: "company",
      component: Company,
    },
    {
      path: "/company/:id",
      name: "company-id",
      props: true,
      component: Company,
    },
    {
      path: "/company/:id/:path",
      name: "company-id-path",
      props: true,
      component: Company,
    },
    {
      path: "/company/:id/:path/:uuid",
      name: "company-id-path-uuid",
      props: true,
      component: Company,
    },
    {
      path: "/settings",
      name: "settings",
      component: UserSettings
    },
    {
      path: "*",
      name: "404",
      component: Page404
    },
    {
      path: "/redirect/:url",
      beforeEnter(to) {
        window.open(decodeURIComponent(to.params.url));
      }
    }
  ]
});