module.exports = {
  _id: "",
  company_id: "",
  project_id: "",
  onboarding_id: "",
  sharepointInternalId: "",
  estimatedWork: "",
  estimatedWorkUnit: 'hours',
  billRateToCustomer: 0,
  pricingDateFrom: "",
  pricingDateTo: "",
  role: "",
  costRateCurrency: "USD",
  costRateOfResource: 0,
  active: false,
  sow: "",
  history: [],
  lastChange: '',
  changedBy: '',
  payment_terms: ''
}