<template>
  <v-layout
    align-center
    text-center
    justify-center
    row
    wrap
    :style="`${$store.state.userInfo !== null ? 'position: relative; left: -40px;' : ''}`"
  >
    <v-dialog v-model="enablePopupDialog">
      <v-card>
        <v-card-title>Popups Blocked</v-card-title>
        <v-card-subtitle>Please enable popups to login through SSO</v-card-subtitle>
        <v-card-text>
          <v-img :src="require('@/assets/enablePopups.png')"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="enablePopupDialog = false"
          >
            Okay
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-flex
      :xs4="$store.state.userADInfo !== undefined && $store.state.userADInfo === null"
      :xs6="$store.state.userInfo !== undefined && $store.state.userInfo !== null"
      style="margin: 10% 25%"
    >
      <!-- {{$store.state.userInfo}} -->
      <v-card
        dark
        style="background-color: rgba(0, 0,0, .97);"
        v-if="($store.state.userADInfo !== undefined && $store.state.userADInfo === null)"
      >
        <v-card-title>
          <span text-center style="width: 100%;">Single Sign On - Azure</span>
        </v-card-title>
        <v-card-actions>
          <v-btn
            v-if="silentLoginAttemptFailed"
            style="margin: 0 auto; color: #790f6e;"
            color="rgba(255, 255, 255, .95)"
            @click="loginSSO()"
          >Single Sign On</v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        dark
        style="background-color: rgba(0, 0,0, .75);"
        v-else-if="$store.state.userInfo !== undefined && $store.state.userInfo !== null"
      >
        <v-card-title>
          <span text-center style="width: 100%;">You are now logged in to Surfboard!</span>
        </v-card-title>
        <!-- {{$store.state.userADInfo}} -->
        <v-card-text>
          {{$store.state.userInfo.first_name}} {{$store.state.userInfo.last_name}}
          <span
            :style="$store.state.userInfo.is_admin ? `color: rgba(18, 224, 114, .9);` : `color: rgba(18, 224, 204, .9)`"
          >Surfboard {{$store.state.userInfo.is_admin ? 'Admin User' : 'Non-Admin User'}}</span>
          <br />
          {{$store.state.userInfo.cognitus_email_address}}
          <br v-if="$store.state.userInfo.job_title !== null" />
          <span v-if="$store.state.userInfo.job_title !== null">{{$store.state.userInfo.job_title}}</span>
          <br v-if="$store.state.userInfo.mobile_phone !== null" />
          <span
            v-if="$store.state.userInfo.mobile_phone !== null"
          >{{$store.state.userInfo.mobile_phone}}</span>
        </v-card-text>
        <v-card-actions>
          <div style="margin: 0 auto;">
            <v-btn color="error" @click="logout()">Logout</v-btn>
            <v-btn
              color="primary"
              @click="$router.push({path: '/'}); $eventBus.$emit('authDialog', false);"
            >Dashboard</v-btn>
            <v-btn
              color="#bbb"
              @click="$router.push({path: '/settings'}); $eventBus.$emit('authDialog', false);"
            >Settings</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      loginFailed: false,
      loggedin: false,
      silentLoginAttemptFailed: false,
      enablePopupDialog: false,
      reauthorizing: false
    };
  },
  watch: {
    loggedin(val) {
      if (val) {
        let that = this;
        // console.log('loggedin', that.$store.state.loginRedirect);
        this.$eventBus.$emit("authDialog", false, function() {
          if (that.$store.state.loginRedirect !== null) {
            that.$router.push(that.$store.state.loginRedirect);
            that.$store.dispatch("SET_LOGIN_REDIRECT", null);
          }
        });
      }
    }
  },
  mounted() {
    var that = this;
    this.$eventBus.$on("updated_user", function(doc) {
      if (that.userADInfo !== null) {
        if (doc._id === that.userADInfo.id) {
          that.$store.dispatch("SET_USER_INFO", doc);
        }
      }
    });
    this.$eventBus.$on("logout", function() {
      that.logout();
    });
    this.$eventBus.$on("reauth", function(callback) {
      if (!this.reauthorizing) {
        this.reauthorizing = true;
        that.$store.dispatch("SET_AD_ACCESS_TOKEN", null);
        that.trySilentLogin(true).then(() => {
          this.reauthorizing = false;
          that.$AlertService.newAlert({
            message: 'Reauthorized with SSO',
            timeout: 5,
            color: 'success',
            show: true
          })
        }).catch(error => {
          this.reauthorizing = false;
          if (error.message.includes("popup_window_error")) {
            that.enablePopupDialog = true;
          } else {
            callback(error);
          }
        })
      }
    })
    setTimeout(function() {
      that.trySilentLogin().catch(function(error) {
        if (error.message.includes("popup_window_error")) {
          that.enablePopupDialog = true;
        }
      })
    }, 1000);
  },
  computed: {
    userADInfo() {
      return this.$store.state.userADInfo;
    }
  },
  methods: {
    logout() {
      this.$AuthService.logout().then(() => {
        this.$store.dispatch("LOGOUT");
      }).catch(error => {
        console.error(error);
      })
      
    },
    getPermissions() {
      var that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.wavedash.permissions().then(function(permissions) {
          resolve(permissions);
        }).catch(function(error) {
          reject(error);
        })
      })
      
    },
    registerBackend() {
      var that = this;
      if (this.$store.state.userADInfo !== null) {
        this.$BackendService.user
          .register({
            _id: that.$store.state.userADInfo.id,
            first_name: that.$store.state.userADInfo.givenName,
            last_name: that.$store.state.userADInfo.surname,
            cognitus_email_address: that.$store.state.userADInfo.mail,
            mobile_phone: that.$store.state.userADInfo.mobilePhone,
            job_title: that.$store.state.userADInfo.jobTitle || "",
            date_time_registered: Date.now(),
            is_global_admin: false,
            companies: [],
            navPermissions: []
          })
          .then(function(response) {
            if (response.finishedRegistration || res.userCreated) {
              that.$BackendService.user
                .getInfo({
                  _id: that.$store.state.userADInfo.id
                })
                .then(function(userInfo) {
                  resolve(userInfo);
                })
                .catch(function(error) {
                  reject(error);
                });
            }
          })
          .catch(function(error) {
            reject(error);
          });
      }
    },
    loginBackend() {
      var that = this;
      return new Promise(function(resolve, reject) {
        if (that.$store.state.userADInfo !== null) {
          that.$BackendService.user
            .login({
              _id: that.$store.state.userADInfo.id
            })
            .then(function(data) {
              if (data.loggedin) {
                that.$BackendService.user
                  .getInfo({
                    _id: that.$store.state.userADInfo.id
                  })
                  .then(function(userInfo) {
                    // console.log(userInfo)
                    resolve(userInfo);
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              } else if (data.finishRegistration) {
                that.registerBackend().then(function(userInfo) {
                  resolve(userInfo);
                }).catch(function(error) {
                  reject(error);
                });
              }
            })
            .catch(function(error) {
              reject(error);
            });
        } else {
          reject(new Error("userADInfo is null"));
        }
      })
      
    },
    loginSSO() {
      var that = this;
      this.loginFailed = false;
      that.$AuthService
        .login()
        .then(function(data) {
          that.$store.dispatch("SET_AD_USER", data.account);
          that
              .trySilentLogin()
              .then(function() {
                that.loggedin = true;
                that.loginFailed = false;
              })
              .catch(function(error) {
                that.loggedin = false;
                that.loginFailed = true;
                if (error.message.includes("popup_window_error")) {
                  that.enablePopupDialog = true;
                }
              });
        })
        .catch(function(error) {
          console.error(error);
          that.loginFailed = true;
        });
    },
    trySilentLogin(noNotify = false) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$AuthService.getToken().then(function(authToken) {
          
          that.$store.dispatch("SET_AD_ACCESS_TOKEN", authToken);
          
          that.$GraphService.getUserInfo(authToken).then(function(adUserInfo) {
              
            that.$store.dispatch("SET_AD_USER_INFO", adUserInfo);
              
            that.$GraphService.getUserAvatar(authToken).then(function(adUserPhoto) {
              
              that.$store.dispatch("SET_AD_USER_PHOTO_URL", adUserPhoto);
              
              that.getPermissions().then(function(permissions) {

                that.$store.dispatch("SET_PERMISSIONS", permissions);

                that.loginBackend().then(function(userInfo) {

                  that.$store.dispatch("SET_USER_INFO", userInfo);
                  if (!noNotify)
                    that.$AlertService.newAlert({
                      message: `Logged in as ${that.$store.state.userInfo.cognitus_email_address}`,
                      show: true,
                      color: "success",
                      timeout: 5
                    });
                  that.loggedin = true;
                  that.silentLoginAttemptFailed = false;
                  resolve();
  
                }).catch(function(error) {
                  if (!noNotify)
                    that.$AlertService.newAlert({
                      message: `Failed to login in as ${that.$store.state.userADInfo.mail}`,
                      show: true,
                      color: "error",
                      timeout: 5
                    });
                  that.$store.dispatch("SET_AD_ACCESS_TOKEN", null)
                  resolve(that.trySilentLogin())
                  that.loggedin = false;
                  that.silentLoginAttemptFailed = true;
                  console.error(error.message);
                });
              }).catch(function(error) {
                if (error.message === "Request failed with status code 400") {
                  that.$store.dispatch("SET_AD_ACCESS_TOKEN", null);
                  resolve(that.trySilentLogin())
                } else {
                  reject(error)
                }
                
              })
            }).catch(function(error) {
                // console.log(error.message);
                reject(error);
            });
          }).catch(function(error) {
            // console.log(error.message);
            reject(error);
          }); 
        }).catch(function(error) {
          that.silentLoginAttemptFailed = true;
          reject(error);
        });
      })
    }
  }
};
</script>

<style lang="scss" scoped>
</style>