import store from '../store/index.js'
import {
    default as UUID
} from '../util/UUID.js'

let consoleService = {
    /**
     * @returns array of console messages
     */
    getAllConsoleMessages: function() {
        return store.state.console.messages
    },
    /**
     * 
     * @param {*} uuid 
     * @returns message from uuid
     */
    getConsoleMessageFromUUID: function(uuid) {
        return new Promise(function (resolve, reject) {
            let consoleMessage = store.state.console.messages.filter(function (message) {
                return message.id === uuid
            })[0] || null;
            if (consoleMessage === null) {
                reject(new Error("No message found."));
            }
            resolve(consoleMessage);
        })

    },
    getConsoleMessageIndexFromUUID: function(uuid) {
        return new Promise(function (resolve, reject) {
            consoleService.getConsoleMessageFromUUID(uuid).then(function (message) {
                let index = store.state.console.messages.indexOf(message);
                resolve(index);
            }).catch(function (error) {
                reject(error);
            })
        })
    },
    /**
     * 
     * @param {*} data { message, timeout, link }
     * @returns id of alert
     */
    newMessage: function(data) {
        let uuid = data.uuid || UUID();
        let currentTime = new Date();
        store.dispatch("ADD_CONSOLE_MESSAGE", {
            message: data.message,
            link: data.link,
            color: data.color,
            id: uuid,
            timestamp: `${currentTime.getUTCHours()}:${currentTime.getUTCMinutes()}`
        })
        return uuid;
    },

}
export default consoleService