var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.userInfo !== null && _vm.$store.state.userInfo.navPermissions !== undefined && _vm.$store.state.userInfo.navPermissions !== null && _vm.company !== undefined)?_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":""}},[(_vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".admin")) || _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".company.user.manage")))?_c('v-flex',{staticStyle:{"margin":"2em"},attrs:{"xs12":""}},[(_vm.computedUsers !== null)?_c('v-flex',{attrs:{"xs12":""}},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Company User Manage")]),_c('br')]):_vm._e(),(_vm.computedUsers !== undefined && _vm.computedUsers !== null)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.computedUsers,"item-key":"cognitus_email_address","multi-sort":"","calculate-widths":"","search":_vm.search,"loading":_vm.computedUsers === null,"custom-filter":function (value, search, item) {
        return search !== null && item !== null && ( (item.first_name !== null && (item.first_name.includes(search) || item.first_name.startsWith(search) || search.includes(item.first_name))) || (item.last_name !== null && (item.last_name.includes(search) || item.last_name.startsWith(search) || search.includes(item.last_name))) ||
        (item.cognitus_email_address !== null && (item.cognitus_email_address === search || item.cognitus_email_address.includes(search) || search.includes(item.cognitus_email_address) || item.cognitus_email_address.startsWith(search))));
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{staticStyle:{"margin":"0 auto"},attrs:{"icon":""},on:{"click":function($event){return _vm.$eventBus.$emit('getUsers')}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-select',{attrs:{"items":_vm.companyNavPermissions.map(function (x) { return x.text; }),"label":("Navigation Permissions " + (_vm.admin ? '(admin disabled)' : '')),"disabled":_vm.admin,"multiple":""},model:{value:(_vm.editedItem.navPermissions),callback:function ($$v) {_vm.$set(_vm.editedItem, "navPermissions", $$v)},expression:"editedItem.navPermissions"}})],1),_c('v-row',[_c('v-switch',{attrs:{"label":"Admin","success":_vm.admin},model:{value:(_vm.admin),callback:function ($$v) {_vm.admin=$$v},expression:"admin"}})],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1),_c('v-text-field',{staticStyle:{"margin":"0em 2em"},attrs:{"type":"text","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{class:("" + (item._id === _vm.user_id ? 'row-active' : ''))},[_c('td',[_vm._v(_vm._s(item.first_name))]),_c('td',[_vm._v(_vm._s(item.last_name))]),_c('td',[_vm._v(_vm._s(item.cognitus_email_address))]),_c('td',[_vm._v(_vm._s(item.mobile_phone))]),_c('td',[_vm._v(_vm._s(item.job_title))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.editItem(item); _vm.formTitle='Edit User'}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_vm._v("Edit Permissions ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_vm._v(" Remove User from "+_vm._s(_vm.company.name)+" ")])],1)])]}}],null,false,416982771),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('v-card-text',[_c('v-btn',{staticStyle:{"margin":"0 auto"},on:{"click":function($event){return _vm.$eventBus.$emit('getUsers')}}},[_vm._v("Refresh User List")])],1)],1):_vm._e()],1):_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('h1',[_vm._v("You must be logged in to view this page!")]),_c('v-btn',{on:{"click":function($event){return _vm.$eventBus.$emit('authDialog', true)}}},[_vm._v("Login")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }