<template>
  <v-layout style="margin: 1em 0;" justify-center align-top row wrap v-if="onboardingFormDataCopy !== null && onboardingFormDataCopy !== undefined">
    <v-flex xs4 style="margin-right: 1em;" v-if="onboardingFormDataCopy.contractor">
      <h3>MSA</h3>
      <v-text-field 
      v-if="onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider'"
      v-model="onboardingFormDataCopy.vendorAddress.serviceproviderName"
      label="Service Provider Name"
      ></v-text-field>
      <v-text-field
        v-model="onboardingFormDataCopy.msaDate"
        label="MSA Date (MM/DD/YYYY)"
        v-mask="'##/##/####'"
      >
        <!-- <template v-slot:prepend> 
          <v-menu
            v-model="msaDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile icon v-on="on" v-bind="attrs">
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="onboardingFormDataCopy.msaDate"
              @change="() => {
                msaDateMenu = false;
              }"
            ></v-date-picker>
          </v-menu>
        </template> -->
      </v-text-field>
      <v-text-field label="Link MSA from Sharepoint" v-model="onboardingFormDataCopy.existingMSALink"></v-text-field>
    </v-flex>
    <v-flex :xs4="onboardingFormDataCopy.contractor" :xs8="!onboardingFormDataCopy.contractor" style="margin-left: 1em;">
      <h3>Address</h3>
      <v-text-field
        v-model="onboardingFormDataCopy.vendorAddress.streetAddress"
        label="Street Address"
      ></v-text-field>
      <v-text-field
        v-model="onboardingFormDataCopy.vendorAddress.streetAddress2"
        label="Street Address 2"
      ></v-text-field>
      <v-text-field v-model="onboardingFormDataCopy.vendorAddress.city" label="City"></v-text-field>
      <v-text-field
        v-model="onboardingFormDataCopy.vendorAddress.state"
        label="State / Province / Region"
      ></v-text-field>
      <v-text-field v-model="onboardingFormDataCopy.vendorAddress.zip" label="Zip / Postal Code"></v-text-field>
      <v-autocomplete
        :items="countries"
        v-model="onboardingFormDataCopy.vendorAddress.country"
        label="Country"
      ></v-autocomplete>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: [
    "data",
    "onboardings",
    "company",
    "countries",
    "onboarding_id",
    "step"
  ],
  data() {
    return {
      msaDateMenu: false,
      onboardingFormDataCopy: null,
      isStepping: false
    };
  },
  created() {
    var that = this;
    that.onboardingFormDataCopy = JSON.parse(JSON.stringify(that.data));
    this.$eventBus.$on("nextStep", function(currentStep) {
      if (currentStep === that.step && !that.isStepping) {
        that.isStepping = true;
        that.$emit(
          "updateData",
          that.getOnboardingFormDataCopy(),
          that.step,
          true,
          function() {
            that.isStepping = false;
          }
        );
      }
    });
  },
  mounted() {
    
  },
  methods: {
    getOnboardingFormDataCopy() {
      return this.onboardingFormDataCopy;
    },
    checkStepOkay() {
      var that = this;
      var okay = true
      that.$eventBus.$emit("stepOkay", {
        step: that.step,
        valid: okay
      });
    }
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          this.onboardingFormDataCopy = JSON.parse(JSON.stringify(val));
          if (this.onboardingFormDataCopy.msaDate === undefined) {
            this.onboardingFormDataCopy.msaDate = '';
          } else if (this.onboardingFormDataCopy.msaDate.includes('-') && this.onboardingFormDataCopy.msaDate.includes(' ')) {
           let msaDateSplit = this.onboardingFormDataCopy.msaDate.split(' ')[0].split('-')
           this.onboardingFormDataCopy.msaDate = msaDateSplit[1] + '/' + msaDateSplit[2] + '/' + msaDateSplit[0]
          } else if (this.onboardingFormDataCopy.msaDate.includes('-')) {
            let msaDateSplit = this.onboardingFormDataCopy.msaDate.split('-')
            this.onboardingFormDataCopy.msaDate = msaDateSplit[1] + '/' + msaDateSplit[2] + '/' + msaDateSplit[0]
          }
          this.$forceUpdate();
        }
      }
    },
    "onboardingFormDataCopy.vendorAddress": {
      handler(val) {
        this.checkStepOkay();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
