
const isProduction = (window.location.hostname === 'surfboard.cognitusconsulting.com');

require("./pollyfills");
import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router.js";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";

import { default as chunk } from "./util/chunk.js";
Vue.prototype.$chunk = chunk;

import { default as pass } from "./util/pass.js";
Vue.prototype.$pass = pass;

import * as phonenumbers from "libphonenumber-js/max";
Vue.prototype.$phonenumbers = phonenumbers;

import countrycodes from "./util/countrycodes";
Vue.prototype.$countrycodes = countrycodes();

import Chartkick from "vue-chartkick";
import Chart from "chart.js";

Vue.use(Chartkick.use(Chart));

import VuetifyPhoneInput from "@kingscode/vuetify-phone-input";
Vue.use(VuetifyPhoneInput);

import { default as AuthService } from "@/services/auth.service";
Vue.prototype.$AuthService = AuthService;

import { default as GraphService } from "@/services/graph.service";
Vue.prototype.$GraphService = GraphService;

import { default as ConsoleService } from "@/services/console.service";
Vue.prototype.$ConsoleService = ConsoleService;

import { default as CompanyService } from "@/services/company.service";
Vue.prototype.$CompanyService = CompanyService;

import { default as UserService } from "@/services/user.service";
Vue.prototype.$UserService = UserService;

import { default as OnboardingService } from "@/services/onboarding.service";
Vue.prototype.$OnboardingService = OnboardingService;

import { default as BackendService } from "@/services/backend.service";

Vue.prototype.$BackendService = BackendService;

import { default as AlertService } from "@/services/alert.service";

Vue.prototype.$AlertService = AlertService;

import { default as ProjectRateCardService } from "@/services/projectRateCard.service";

Vue.prototype.$ProjectRateCardService = ProjectRateCardService;

import { default as ProjectService } from "@/services/project.service";

Vue.prototype.$ProjectService = ProjectService;


import { default as RunnService } from "@/services/runn.service";

Vue.prototype.$RunnService = RunnService;


import { default as UUID } from "./util/UUID.js";
Vue.prototype.$UUID = UUID;

import VueCurrencyInput from "vue-currency-input";
Vue.use(VueCurrencyInput);

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

import io from "socket.io-client";

Vue.config.productionTip = false;
Vue.prototype.$publicPath = process.env.BASE_URL;

const eventBus = new Vue({});
Vue.prototype.$eventBus = eventBus;

import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  test: "true",
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.code === 400 || error.code === 401) {
      eventBus.$emit("reauth");
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

const socket = io(isProduction ? 'https://wavedash.cognitusconsulting.com' : 'https://wavedashqa.cognitusconsulting.com');
Vue.prototype.$socket = socket;
router.beforeEach((to, from, next) => {
  if (
    store.state.userADInfo === null &&
    store.state.userInfo === null &&
    store.state.loginRedirect === null &&
    to.path !== "/"
  ) {
    store.dispatch("SET_LOGIN_REDIRECT", to);
    next({ path: "/" });
  } else {
    next();
  }
});

new Vue({
  mounted() {
    window.document.title = "Cognitus Surfboard";
    if (!isProduction) {
      window.document.title += " ( DEV )"
    } else {
      window.document.title += " ( PROD )"
    }
  },
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
