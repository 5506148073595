var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.company !== undefined && _vm.$store.state.userInfo !== null && _vm.$store.state.userInfo.navPermissions !== undefined && _vm.$store.state.userInfo.navPermissions !== null)?_c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[(_vm.onboardingHistory.length > 0 && _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".admin")) || _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".company.onboarding.history")))?_c('v-flex',{attrs:{"xs10":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","color":!_vm.refreshing ? 'primary' : 'warning'},on:{"click":function($event){return _vm.refreshOnboardingHistory()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,4246101927)},[_vm._v(" "+_vm._s(("" + (_vm.refreshing ? "Refreshing" : "Refresh")))+" ")]),_c('h1',{staticStyle:{"text-align":"center","margin":".75em 0"}},[_vm._v("Company Onboarding History")]),(_vm.refreshing)?_c('v-progress-linear',{attrs:{"color":"warning","indeterminate":""}}):_vm._e(),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-text-field',{attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_vm._l((_vm.searchedOnboardingHistory),function(onboardingHistoryItem,index){return _c('span',{key:("onboardingHistoryItem-" + index)},[_c('v-list-item',{staticClass:"history-item",attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(onboardingHistoryItem.firstName + " " + onboardingHistoryItem.lastName + " - " + onboardingHistoryItem.cognitusEmailMailbox + onboardingHistoryItem.cognitusEmailDomain)+" ")]),_c('v-list-item-subtitle',[_vm._v(" Onboarded By: "),_c('strong',[_vm._v(_vm._s(onboardingHistoryItem.requester))]),_c('br'),_vm._v("Time Onboarded: "),_c('strong',[_vm._v(" "+_vm._s(_vm.getDate(onboardingHistoryItem.date_time_onboarded))+" ")])]),_c('span',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){_vm.$route.path !==
                      ("/company/" + (_vm.company._id) + "/onboarding-change/" + (onboardingHistoryItem.onboarding_id))
                        ? _vm.$router.push({
                            path: ("/company/" + (_vm.company._id) + "/onboarding-change/" + (onboardingHistoryItem.onboarding_id))
                          })
                        : _vm.$eventBus.$emit('openOnboardingChange')}}},on),[_vm._v(" Flow Approval Status "),_c('v-icon',{class:("history-item-avatar " + (onboardingHistoryItem.flowApprovalStatus ===
                          'Approved'
                            ? 'approved'
                            : onboardingHistoryItem.flowApprovalStatus ===
                              'Denied'
                            ? 'denied'
                            : onboardingHistoryItem.flowApprovalStatus ===
                              'Requested'
                            ? 'requested'
                            : ''))},[_vm._v(" "+_vm._s(onboardingHistoryItem.flowApprovalStatus === "Approved" ? "mdi-check" : onboardingHistoryItem.flowApprovalStatus === "Denied" ? "mdi-cancel" : onboardingHistoryItem.flowApprovalStatus === "Requested" ? "mdi-help-circle" : "")+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(onboardingHistoryItem.flowApprovalStatus)+" ")])],1),(onboardingHistoryItem.flowApprovalStatus === 'Approved')?_c('span',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){_vm.$router.push({
                        path: ("/redirect/" + (encodeURIComponent(
                          ("https://cognitus.atlassian.net/browse/" + (onboardingHistoryItem.jiraIssueKey))
                        )))
                      })}}},on),[_vm._v(" Jira Issue Status "),_c('v-icon',{class:("history-item-avatar " + (onboardingHistoryItem.jiraIssueStatus === 'Done'
                            ? 'done'
                            : onboardingHistoryItem.jiraIssueStatus ===
                              'Created'
                            ? 'created'
                            : onboardingHistoryItem.jiraIssueStatus ===
                              'Requested'
                            ? 'requested'
                            : ''))},[_vm._v(" "+_vm._s(onboardingHistoryItem.jiraIssueStatus === "Done" ? "mdi-check" : onboardingHistoryItem.jiraIssueStatus === "Created" ? "mdi-progress-clock" : onboardingHistoryItem.jiraIssueStatus === "Requested" ? "mdi-help-circle" : "")+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(onboardingHistoryItem.jiraIssueStatus)+" ")])],1):_vm._e(),(_vm.getHistory(onboardingHistoryItem).length > 0)?_c('span',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('a',_vm._g({on:{"click":function($event){_vm.$route.path !==
                      ("/company/" + (_vm.company._id) + "/onboarding-history/" + (onboardingHistoryItem._id))
                        ? _vm.$router.push({
                            path: ("/company/" + (_vm.company._id) + "/onboarding-history/" + (onboardingHistoryItem._id))
                          })
                        : _vm.$router.push({
                            path: ("/company/" + (_vm.company._id) + "/onboarding-history")
                          })}}},on),[_vm._v(" History "),_c('v-icon',{staticClass:"history-item-avatar"},[_vm._v("mdi-history")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getHistory(onboardingHistoryItem).length - 1)+" Change Requests ")])],1):_vm._e(),(
                _vm.onboarding_id !== undefined &&
                  _vm.onboarding_id !== null &&
                  _vm.specificOnboarding !== undefined &&
                  _vm.specificOnboarding !== null &&
                  _vm.specificOnboarding._id === onboardingHistoryItem._id
              )?_c('v-flex',{attrs:{"xs12":""}},[_c('v-dialog',{model:{value:(_vm.showSpecificHistory),callback:function ($$v) {_vm.showSpecificHistory=$$v},expression:"showSpecificHistory"}},[(
                    _vm.specificHistoryIndex !== -1 &&
                      _vm.history[_vm.specificHistoryIndex] !== undefined
                  )?_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.history[_vm.specificHistoryIndex].cognitusEmailMailbox + _vm.history[_vm.specificHistoryIndex].cognitusEmailDomain)+" ")]),_c('v-card-text',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.syntaxHighlight(JSON.stringify(_vm.history[_vm.specificHistoryIndex], null, 4)))}})])],1):_vm._e()],1),(_vm.history.length > 0)?_c('v-list',_vm._l((_vm.history),function(specificOnboardingHistoryItem,index){return _c('v-list-item',{key:'specificOnboardingHistory-' + index,staticClass:"history-item",attrs:{"three-line":""},on:{"click":function($event){return _vm.showHistory(specificOnboardingHistoryItem, index)}}},[_c('v-list-item-content',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(" "+_vm._s(index === 0 ? _vm.history.length > 1 ? "Initial" : "Current" : index === _vm.history.length - 1 ? "Current" : ("Change Request #" + index))+" ")]),_c('v-list-item-subtitle',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.convertToDate( specificOnboardingHistoryItem.date_time_onboarded ))+" ")])]}}],null,true)},[_vm._v(" Flow: "+_vm._s(specificOnboardingHistoryItem.flowApprovalStatus)+" "),(specificOnboardingHistoryItem.flowApprovalStatus === 'Approved')?_c('span',[_c('br'),_vm._v(" Jira: "+_vm._s(specificOnboardingHistoryItem.jiraIssueStatus)+" ")]):_vm._e()])],1)],1)}),1):_c('h3',[_vm._v("No Change History")])],1):_vm._e()],1)],1),(index < _vm.onboardingHistory.length - 1)?_c('v-divider'):_vm._e()],1)})],2)],1):(!_vm.$vuetify.breakpoint.smAndDown)?_c('h1',[_vm._v("No Onboarding History To Show")]):(_vm.$vuetify.breakpoint.smAndDown)?_c('h3',[_vm._v("No Onboarding History To Show")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }