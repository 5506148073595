<template>
  <v-layout row wrap justify-center align-center>
    <v-flex xs11>
      <v-card style="margin: 1em 0 0 0">
        <v-card-title style="color: red;">404</v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="$router.push('/')">Return to Home</v-btn>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {};
</script>

<style scoped>
</style>