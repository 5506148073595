<template>
  <v-card v-if="actionRequired">
    <v-card-title>Onboarding Action Required</v-card-title>
    <v-card-text>Continue the onboarding process!</v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        @click="$router.push(`/company/${company._id}/onboarding-continue/${continueonboarding_id}`)"
      >Continue</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["company", "onboarings"],
  data() {
    return {
      onboardings: null,
      continueonboarding_id: ""
    };
  },
  computed: {
    actionRequired() {
      var that = this;
      if (
        this.onboardings !== null &&
        that.$store.state.userInfo !== null &&
        that.$store.state.userInfo.cognitus_email_address !== undefined
      ) {
        let onboarding = this.onboardings.filter(function(row) {
          return (
            row.cognitusEmailMailbox ===
            that.$store.state.userInfo.cognitus_email_address.split("@")[0]
          );
        });
        if (
          onboarding.length > 0 &&
          onboarding[0].readyForOnboardedUserInput &&
          !onboarding[0].userInputCompleted
        ) {
          that.continueonboarding_id = onboarding[0]._id;
          that.$BackendService.user.getInfo(
            {
              _id: that.$store.state.userADInfo.id
            },
            function(userInfo) {
              if (userInfo.navPermissions === undefined) {
                userInfo.navPermissions = [];
              }
              if (
                !userInfo.navPermissions.includes(
                  `${company._id}.company.onboarding.continue`
                )
              ) {
                userInfo.navPermissions.push(
                  `${company._id}.company.onboarding.continue`
                );
                that.$BackendService.user.update(
                  userInfo,
                  function(result) {},
                  function(error) {}
                );
              }
            },
            function(error) {}
          );
          return true;
        }
      }
      return false;
    }
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
</style>