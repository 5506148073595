<template>
  <v-layout row wrap justify-center align-center>
    <v-flex xs12>
      <ZCC-FormItem
        v-for="(formItem, index) in items"
        :key="'formItem-' + index + '-' + formItem.render"
        :data="formItem"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import ZCC_FormItem from "@/components/Company/Onboarding/Form/ZCC_FormItem.vue";
export default {
  props: ["items"],
  components: {
    "ZCC-FormItem": ZCC_FormItem
  }
};
</script>

<style scoped></style>
