var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.onboardingFormDataCopy !== null)?_c('v-layout',{staticStyle:{"margin":"1em 0"},attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"xs11":""}},[_c('table',{staticStyle:{"width":"100%"}},[_c('tr',[_c('td',[_c('h4',[_vm._v("Onboarding Data Overview")])]),_c('td',[_vm._v(" "+_vm._s(("" + (_vm.onboardingFormDataCopy.changeRequest ? "Change Request" : "New Onboarding")))+" ")])]),_c('br'),_vm._l((Object.keys(_vm.onboardingFormDataCopy).filter(
          function(key) {
            if (_vm.onboardingFormDataCopy.employee) {
              if (
                key === 'costRate' ||
                key === 'billRate' ||
                key === 'travelExpenses' ||
                key === 'contingentWorker' ||
                key === 'startDate' ||
                key === 'currency'
              ) {
                return false;
              }
            }
            if (_vm.onboardingFormDataCopy.contractor) {
              if (key === 'laptop' || key === 'employee') {
                return false;
              }
              if (key === 'costRate' || key === 'billRate' || key === 'currency') {
                return (_vm.onboardingFormDataCopy['contingentWorker'])
              }
              if (key === 'startDate') {
                return (_vm.onboardingFormDataCopy['travelExpenses'] || _vm.onboardingFormDataCopy['contingentWorker'])
              }
            }
            return (
              key !== 'step' &&
              key !== 'avatar' &&
              key !== 'onboarding_id' &&
              key !== 'company_id' &&
              _vm.onboardingFormDataCopy[key] !== false &&
              _vm.onboardingFormDataCopy[key] !== null &&
              _vm.onboardingFormDataCopy[key] !== '' &&
              key !== 'history' &&
              key !== 'vendorAddress' &&
              key !== 'flowApprovalStatus' &&
              key !== 'jiraIssueStatus' &&
              key !== 'jiraIssueID' &&
              key !== 'jiraIssueKey' &&
              key !== 'readyForOnboardedUserInput' &&
              key !== '_id' &&
              key !== '_rev' &&
              key !== 'changeRequest' &&
              key !== 'sentEmails' &&
              key !== 'forms' &&
              key !== 'jiraCheckboxDisabled' &&
              key !== 'hasBeenOnboarded' &&
              key !== 'requesterComment' &&
              key !== 'sapUser' &&
              key !== 'temporaryOffice365Password' &&
              key !== 'conversationId' &&
              key !== 'activityId' && 
              key !== 'socketID' && 
              key !== 'onboardedUserInputComplete' && 
              key !== 'date_time_onboarded' &&
              key !== 'changeRequestUUID' && 
              key !== 'adobeAgreements' &&
              key !== 'projectRateCards' &&
              key !== 'adobeSignedAgreementsFieldData' &&
              key !== 'adobeFieldData' &&
              key !== 'vendorAddressSupplier' &&
              key !== 'serviceProviderEmailAddress' &&
              key !== 'msaDate' &&
              key !== 'adobeSignedAgreementFieldData' &&
              (key === 'mobilePhone' ? _vm.histories[0] !== undefined && _vm.onboardingFormDataCopy[key] !== undefined ? _vm.onboardingFormDataCopy[key].e164 !== _vm.histories[0][key].e164 : true : true)
            );
          }
        )),function(key,index){return _c('tr',{key:'key-' + index},[_c('td',[_vm._v(" "+_vm._s(_vm.textValues.filter(function (i) { return i.key === key; })[0] !== undefined ? _vm.textValues.filter(function (i) { return i.key === key; })[0].value : key + ': '))]),_c('td',{on:{"click":function($event){_vm.edit[key] = true}}},[_c('strong',[(
                _vm.onboardingFormDataCopy.changeRequest && _vm.histories.length > 0 &&
                  _vm.onboardingFormDataCopy.history !== undefined &&
                  _vm.onboardingFormDataCopy.history !== null &&
                  _vm.onboardingFormDataCopy.history.length > 0 &&
                  JSON.stringify(_vm.onboardingFormDataCopy[key]) !==
                    JSON.stringify(
                      _vm.histories[0][key]
                    )
              )?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s((key === 'date_time_onboarded' && _vm.isValidDate(new Date(_vm.histories[0][key]))) ? new Date(_vm.histories[0][key]).toDateString() : key === 'mobilePhone' ? _vm.histories[0][key].e164 : key === 'otherSystem' ? _vm.histories[0][key].join(", ") : JSON.stringify(_vm.histories[0][key]).replaceAll("\"", ""))+" ")]),_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s((key === 'date_time_onboarded' && _vm.isValidDate(new Date(_vm.onboardingFormDataCopy[key]))) ? (new Date(_vm.onboardingFormDataCopy[key])).toDateString() : key === 'mobilePhone' ? _vm.onboardingFormDataCopy[key].e164 : key === 'otherSystem' ? _vm.onboardingFormDataCopy[key].join(", ") : JSON.stringify(_vm.onboardingFormDataCopy[key]).replaceAll("\"", ""))+" ")])]):_c('span',[_vm._v(_vm._s((key === 'date_time_onboarded' && _vm.isValidDate(new Date(_vm.onboardingFormDataCopy[key]))) ? (new Date(_vm.onboardingFormDataCopy[key])).toDateString() : key === 'mobilePhone' ? _vm.onboardingFormDataCopy[key].e164 : key === 'otherSystem' ? _vm.onboardingFormDataCopy[key].join(", ") : JSON.stringify(_vm.onboardingFormDataCopy[key]).replaceAll("\"", ""))+" ")])])])])}),_c('tr',[_c('td',[_vm._v("Comment:")]),_c('td',[_c('v-textarea',{staticStyle:{"border":"1px solid rgba(0, 0, 0, .03)"},attrs:{"color":"primary","autofocus":""},model:{value:(_vm.onboardingFormDataCopy.requesterComment),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "requesterComment", $$v)},expression:"onboardingFormDataCopy.requesterComment"}})],1)])],2),_c('br')])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }