import store from '../store/index.js'
import {
    default as UUID
} from '../util/UUID.js'

let alertService = {
    /**
     * @returns array of alerts
     */
    getAllAlerts: function() {
        return store.state.alerts
    },
    /**
     * 
     * @param {*} uuid 
     * @returns alert from uuid
     */
    getAlertFromUUID: function(uuid) {
        return new Promise(function (resolve, reject) {
            let alert = store.state.alerts.filter(function (alert) {
                return alert.id === uuid
            })[0] || null;
            if (alert === null) {
                reject(new Error("No alert found."));
            }
            resolve(alert);
        })

    },
    getAlertIndexFromUUID: function(uuid) {
        return new Promise(function (resolve, reject) {
            alertService.getAlertFromUUID(uuid).then(function (alert) {
                let index = store.state.alerts.indexOf(alert);
                resolve(index);
            }).catch(function (error) {
                reject(error);
            })
        })
    },
    /**
     * 
     * @param {*} data { message, timeout, link }
     * @returns id of alert
     */
    newAlert: function(data) {
        let uuid = data.uuid || UUID();
        store.dispatch("ADD_ALERT", {
            message: data.message,
            timeout: data.timeout,
            link: data.link,
            show: data.show,
            color: data.color,
            id: uuid
        })
        if (data.show) {
            setTimeout(function () {
                alertService.hideAlert(uuid)
            }, data.timeout * 2000)
        }
        return uuid;
    },

    hideAlert: function(uuid) {
        alertService.getAlertIndexFromUUID(uuid).then(function (index) {
            store.dispatch("HIDE_ALERT", index);
        }).catch(function (error) {
            console.error(error);
        })

    },

    showAlert: function(uuid) {
        alertService.getAlertIndexFromUUID(uuid).then(function (index) {
            store.dispatch("SHOW_ALERT", index);
        }).catch(function (error) {
            console.error(error);
        })
    },

    deleteAlert: function(uuid) {
        alertService.getAlertIndexFromUUID(uuid).then(function (index) {
            store.dispatch("REMOVE_ALERT", index);
        }).catch(function (error) {
            console.error(error);
        })
    }
}
export default alertService