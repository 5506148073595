var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.userInfo !== undefined && _vm.$store.state.userInfo.navPermissions !== undefined && _vm.$store.state.userInfo.navPermissions !== null && _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".admin")) || _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".company.onboarding.email.send")))?_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{staticStyle:{"margin-top":"1em"},attrs:{"xs10":""}},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Email Send")])]),_c('v-flex',{attrs:{"xs10":_vm.populatedTemplate === '',"xs5":_vm.populatedTemplate !== ''}},[_c('div',{staticStyle:{"margin":"0em"}},[_c('v-select',{attrs:{"label":"Template Selection","items":_vm.templates},model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}}),(_vm.template)?_c('v-autocomplete',{attrs:{"label":"Recipient","search-input":_vm.recipientSearch,"items":_vm.onboardingsMapped},on:{"update:searchInput":function($event){_vm.recipientSearch=$event},"update:search-input":function($event){_vm.recipientSearch=$event}},model:{value:(_vm.onboardingData),callback:function ($$v) {_vm.onboardingData=$$v},expression:"onboardingData"}}):_vm._e(),(_vm.onboardingData && _vm.template)?_c('v-select',{attrs:{"label":"Recipient Email Address","items":_vm.onboardingDataEmails},model:{value:(_vm.recipientEmail),callback:function ($$v) {_vm.recipientEmail=$$v},expression:"recipientEmail"}}):_vm._e(),_c('br'),(_vm.recipientEmail)?_c('v-text-field',{attrs:{"label":"To","disabled":"","value":_vm.recipientEmail}}):_vm._e(),(_vm.recipientEmail)?_c('v-combobox',{attrs:{"items":Array.prototype.concat.apply([], _vm.onboardings.map(function (onb) {
          return {
            text: ("" + (onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>')),
            value: onb.cognitusEmailMailbox + onb.cognitusEmailDomain
          }
        })).concat(_vm.onboardings.map(function (onb) {
          return {
            text: ("" + (onb.firstName + ' ' + onb.lastName + ' <' + onb.personalEmailAddress + '>')),
            value: onb.personalEmailAddress
          }
        })),"label":"Cc","multiple":"","small-chips":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to add a new email address ")])],1)],1)]},proxy:true}],null,false,827662678),model:{value:(_vm.ccEmail),callback:function ($$v) {_vm.ccEmail=$$v},expression:"ccEmail"}}):_vm._e(),(_vm.recipientEmail)?_c('v-text-field',{attrs:{"label":"Subject"},model:{value:(_vm.emailSubject),callback:function ($$v) {_vm.emailSubject=$$v},expression:"emailSubject"}}):_vm._e(),_c('br'),(_vm.template)?_c('v-btn',{attrs:{"loading":_vm.sendingEmail === true,"outlined":"","color":_vm.sendingEmail === true ? 'warning' : _vm.sendingEmail === 'success' ? 'success' : _vm.sendingEmail === 'error' ? 'error' : 'primary',"disabled":_vm.populatedTemplate === '' || !_vm.recipientEmail},on:{"click":function($event){return _vm.sendEmail()}}},[_vm._v(_vm._s(_vm.sendingEmail ==='success' ? 'Email Sent' : _vm.sendingEmail === 'error' ? 'Error Sending Email' : 'Send Email'))]):_vm._e()],1)]),(_vm.populatedTemplate !== '')?_c('v-flex',{staticStyle:{"margin-top":"1em"},attrs:{"xs5":""}},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Email Preview")]),_c('span',{staticStyle:{"background-color":"#ffffff"},domProps:{"innerHTML":_vm._s(_vm.populatedTemplate)}})]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }