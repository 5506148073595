import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
    key: 'vuex',
    storage: window.sessionStorage,
    reducer: (state) => {
        return {
            accessToken: state.accessToken,
            itemsPerPage: state.itemsPerPage,
            projectWorkEstimationInDays: state.projectWorkEstimationInDays
        }
    }
})

export default vuexLocal;