import Vue from "vue";
import Vuex from "vuex";
import vuexLocal from '../plugins/vuex-persist';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    projectWorkEstimationInDays: true,
    itemsPerPage: 5,
    adobe_access_point: null,
    adobe_access_token: null,
    companies: null,
    users: null,
    onboardings: null,
    projects: null,
    projectRateCards: null,
    userInfo: null,
    userAD: null,
    userADInfo: null,
    userADPhotoURL: null,
    accessToken: null,
    alerts: [],
    changeonboarding_id: null,
    loginRedirect: null,
    socketID: null,
    runn: {
      people: [],
      assignments: [],
      projects: []
    },
    console: {
      messages: [],
    },
    currencies: {
      USD: {
        symbol: "$",
        name: "US Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "USD",
        name_plural: "US dollars",
      },
      CAD: {
        symbol: "CA$",
        name: "Canadian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "CAD",
        name_plural: "Canadian dollars",
      },
      EUR: {
        symbol: "€",
        name: "Euro",
        symbol_native: "€",
        decimal_digits: 2,
        rounding: 0,
        code: "EUR",
        name_plural: "euros",
      },
      AED: {
        symbol: "AED",
        name: "United Arab Emirates Dirham",
        symbol_native: "د.إ.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "AED",
        name_plural: "UAE dirhams",
      },
      AFN: {
        symbol: "Af",
        name: "Afghan Afghani",
        symbol_native: "؋",
        decimal_digits: 0,
        rounding: 0,
        code: "AFN",
        name_plural: "Afghan Afghanis",
      },
      ALL: {
        symbol: "ALL",
        name: "Albanian Lek",
        symbol_native: "Lek",
        decimal_digits: 0,
        rounding: 0,
        code: "ALL",
        name_plural: "Albanian lekë",
      },
      AMD: {
        symbol: "AMD",
        name: "Armenian Dram",
        symbol_native: "դր.",
        decimal_digits: 0,
        rounding: 0,
        code: "AMD",
        name_plural: "Armenian drams",
      },
      ARS: {
        symbol: "AR$",
        name: "Argentine Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "ARS",
        name_plural: "Argentine pesos",
      },
      AUD: {
        symbol: "AU$",
        name: "Australian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "AUD",
        name_plural: "Australian dollars",
      },
      AZN: {
        symbol: "man.",
        name: "Azerbaijani Manat",
        symbol_native: "ман.",
        decimal_digits: 2,
        rounding: 0,
        code: "AZN",
        name_plural: "Azerbaijani manats",
      },
      BAM: {
        symbol: "KM",
        name: "Bosnia-Herzegovina Convertible Mark",
        symbol_native: "KM",
        decimal_digits: 2,
        rounding: 0,
        code: "BAM",
        name_plural: "Bosnia-Herzegovina convertible marks",
      },
      BDT: {
        symbol: "Tk",
        name: "Bangladeshi Taka",
        symbol_native: "৳",
        decimal_digits: 2,
        rounding: 0,
        code: "BDT",
        name_plural: "Bangladeshi takas",
      },
      BGN: {
        symbol: "BGN",
        name: "Bulgarian Lev",
        symbol_native: "лв.",
        decimal_digits: 2,
        rounding: 0,
        code: "BGN",
        name_plural: "Bulgarian leva",
      },
      BHD: {
        symbol: "BD",
        name: "Bahraini Dinar",
        symbol_native: "د.ب.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "BHD",
        name_plural: "Bahraini dinars",
      },
      BIF: {
        symbol: "FBu",
        name: "Burundian Franc",
        symbol_native: "FBu",
        decimal_digits: 0,
        rounding: 0,
        code: "BIF",
        name_plural: "Burundian francs",
      },
      BND: {
        symbol: "BN$",
        name: "Brunei Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "BND",
        name_plural: "Brunei dollars",
      },
      BOB: {
        symbol: "Bs",
        name: "Bolivian Boliviano",
        symbol_native: "Bs",
        decimal_digits: 2,
        rounding: 0,
        code: "BOB",
        name_plural: "Bolivian bolivianos",
      },
      BRL: {
        symbol: "R$",
        name: "Brazilian Real",
        symbol_native: "R$",
        decimal_digits: 2,
        rounding: 0,
        code: "BRL",
        name_plural: "Brazilian reals",
      },
      BWP: {
        symbol: "BWP",
        name: "Botswanan Pula",
        symbol_native: "P",
        decimal_digits: 2,
        rounding: 0,
        code: "BWP",
        name_plural: "Botswanan pulas",
      },
      BYN: {
        symbol: "Br",
        name: "Belarusian Ruble",
        symbol_native: "руб.",
        decimal_digits: 2,
        rounding: 0,
        code: "BYN",
        name_plural: "Belarusian rubles",
      },
      BZD: {
        symbol: "BZ$",
        name: "Belize Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "BZD",
        name_plural: "Belize dollars",
      },
      CDF: {
        symbol: "CDF",
        name: "Congolese Franc",
        symbol_native: "FrCD",
        decimal_digits: 2,
        rounding: 0,
        code: "CDF",
        name_plural: "Congolese francs",
      },
      CHF: {
        symbol: "CHF",
        name: "Swiss Franc",
        symbol_native: "CHF",
        decimal_digits: 2,
        rounding: 0.05,
        code: "CHF",
        name_plural: "Swiss francs",
      },
      CLP: {
        symbol: "CL$",
        name: "Chilean Peso",
        symbol_native: "$",
        decimal_digits: 0,
        rounding: 0,
        code: "CLP",
        name_plural: "Chilean pesos",
      },
      CNY: {
        symbol: "CN¥",
        name: "Chinese Yuan",
        symbol_native: "CN¥",
        decimal_digits: 2,
        rounding: 0,
        code: "CNY",
        name_plural: "Chinese yuan",
      },
      COP: {
        symbol: "CO$",
        name: "Colombian Peso",
        symbol_native: "$",
        decimal_digits: 0,
        rounding: 0,
        code: "COP",
        name_plural: "Colombian pesos",
      },
      CRC: {
        symbol: "₡",
        name: "Costa Rican Colón",
        symbol_native: "₡",
        decimal_digits: 0,
        rounding: 0,
        code: "CRC",
        name_plural: "Costa Rican colóns",
      },
      CVE: {
        symbol: "CV$",
        name: "Cape Verdean Escudo",
        symbol_native: "CV$",
        decimal_digits: 2,
        rounding: 0,
        code: "CVE",
        name_plural: "Cape Verdean escudos",
      },
      CZK: {
        symbol: "Kč",
        name: "Czech Republic Koruna",
        symbol_native: "Kč",
        decimal_digits: 2,
        rounding: 0,
        code: "CZK",
        name_plural: "Czech Republic korunas",
      },
      DJF: {
        symbol: "Fdj",
        name: "Djiboutian Franc",
        symbol_native: "Fdj",
        decimal_digits: 0,
        rounding: 0,
        code: "DJF",
        name_plural: "Djiboutian francs",
      },
      DKK: {
        symbol: "Dkr",
        name: "Danish Krone",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "DKK",
        name_plural: "Danish kroner",
      },
      DOP: {
        symbol: "RD$",
        name: "Dominican Peso",
        symbol_native: "RD$",
        decimal_digits: 2,
        rounding: 0,
        code: "DOP",
        name_plural: "Dominican pesos",
      },
      DZD: {
        symbol: "DA",
        name: "Algerian Dinar",
        symbol_native: "د.ج.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "DZD",
        name_plural: "Algerian dinars",
      },
      EEK: {
        symbol: "Ekr",
        name: "Estonian Kroon",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "EEK",
        name_plural: "Estonian kroons",
      },
      EGP: {
        symbol: "EGP",
        name: "Egyptian Pound",
        symbol_native: "ج.م.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "EGP",
        name_plural: "Egyptian pounds",
      },
      ERN: {
        symbol: "Nfk",
        name: "Eritrean Nakfa",
        symbol_native: "Nfk",
        decimal_digits: 2,
        rounding: 0,
        code: "ERN",
        name_plural: "Eritrean nakfas",
      },
      ETB: {
        symbol: "Br",
        name: "Ethiopian Birr",
        symbol_native: "Br",
        decimal_digits: 2,
        rounding: 0,
        code: "ETB",
        name_plural: "Ethiopian birrs",
      },
      GBP: {
        symbol: "£",
        name: "British Pound Sterling",
        symbol_native: "£",
        decimal_digits: 2,
        rounding: 0,
        code: "GBP",
        name_plural: "British pounds sterling",
      },
      GEL: {
        symbol: "GEL",
        name: "Georgian Lari",
        symbol_native: "GEL",
        decimal_digits: 2,
        rounding: 0,
        code: "GEL",
        name_plural: "Georgian laris",
      },
      GHS: {
        symbol: "GH₵",
        name: "Ghanaian Cedi",
        symbol_native: "GH₵",
        decimal_digits: 2,
        rounding: 0,
        code: "GHS",
        name_plural: "Ghanaian cedis",
      },
      GNF: {
        symbol: "FG",
        name: "Guinean Franc",
        symbol_native: "FG",
        decimal_digits: 0,
        rounding: 0,
        code: "GNF",
        name_plural: "Guinean francs",
      },
      GTQ: {
        symbol: "GTQ",
        name: "Guatemalan Quetzal",
        symbol_native: "Q",
        decimal_digits: 2,
        rounding: 0,
        code: "GTQ",
        name_plural: "Guatemalan quetzals",
      },
      HKD: {
        symbol: "HK$",
        name: "Hong Kong Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "HKD",
        name_plural: "Hong Kong dollars",
      },
      HNL: {
        symbol: "HNL",
        name: "Honduran Lempira",
        symbol_native: "L",
        decimal_digits: 2,
        rounding: 0,
        code: "HNL",
        name_plural: "Honduran lempiras",
      },
      HRK: {
        symbol: "kn",
        name: "Croatian Kuna",
        symbol_native: "kn",
        decimal_digits: 2,
        rounding: 0,
        code: "HRK",
        name_plural: "Croatian kunas",
      },
      HUF: {
        symbol: "Ft",
        name: "Hungarian Forint",
        symbol_native: "Ft",
        decimal_digits: 0,
        rounding: 0,
        code: "HUF",
        name_plural: "Hungarian forints",
      },
      IDR: {
        symbol: "Rp",
        name: "Indonesian Rupiah",
        symbol_native: "Rp",
        decimal_digits: 0,
        rounding: 0,
        code: "IDR",
        name_plural: "Indonesian rupiahs",
      },
      ILS: {
        symbol: "₪",
        name: "Israeli New Sheqel",
        symbol_native: "₪",
        decimal_digits: 2,
        rounding: 0,
        code: "ILS",
        name_plural: "Israeli new sheqels",
      },
      INR: {
        symbol: "Rs",
        name: "Indian Rupee",
        symbol_native: "টকা",
        decimal_digits: 2,
        rounding: 0,
        code: "INR",
        name_plural: "Indian rupees",
      },
      IQD: {
        symbol: "IQD",
        name: "Iraqi Dinar",
        symbol_native: "د.ع.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "IQD",
        name_plural: "Iraqi dinars",
      },
      IRR: {
        symbol: "IRR",
        name: "Iranian Rial",
        symbol_native: "﷼",
        decimal_digits: 0,
        rounding: 0,
        code: "IRR",
        name_plural: "Iranian rials",
      },
      ISK: {
        symbol: "Ikr",
        name: "Icelandic Króna",
        symbol_native: "kr",
        decimal_digits: 0,
        rounding: 0,
        code: "ISK",
        name_plural: "Icelandic krónur",
      },
      JMD: {
        symbol: "J$",
        name: "Jamaican Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "JMD",
        name_plural: "Jamaican dollars",
      },
      JOD: {
        symbol: "JD",
        name: "Jordanian Dinar",
        symbol_native: "د.أ.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "JOD",
        name_plural: "Jordanian dinars",
      },
      JPY: {
        symbol: "¥",
        name: "Japanese Yen",
        symbol_native: "￥",
        decimal_digits: 0,
        rounding: 0,
        code: "JPY",
        name_plural: "Japanese yen",
      },
      KES: {
        symbol: "Ksh",
        name: "Kenyan Shilling",
        symbol_native: "Ksh",
        decimal_digits: 2,
        rounding: 0,
        code: "KES",
        name_plural: "Kenyan shillings",
      },
      KHR: {
        symbol: "KHR",
        name: "Cambodian Riel",
        symbol_native: "៛",
        decimal_digits: 2,
        rounding: 0,
        code: "KHR",
        name_plural: "Cambodian riels",
      },
      KMF: {
        symbol: "CF",
        name: "Comorian Franc",
        symbol_native: "FC",
        decimal_digits: 0,
        rounding: 0,
        code: "KMF",
        name_plural: "Comorian francs",
      },
      KRW: {
        symbol: "₩",
        name: "South Korean Won",
        symbol_native: "₩",
        decimal_digits: 0,
        rounding: 0,
        code: "KRW",
        name_plural: "South Korean won",
      },
      KWD: {
        symbol: "KD",
        name: "Kuwaiti Dinar",
        symbol_native: "د.ك.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "KWD",
        name_plural: "Kuwaiti dinars",
      },
      KZT: {
        symbol: "KZT",
        name: "Kazakhstani Tenge",
        symbol_native: "тңг.",
        decimal_digits: 2,
        rounding: 0,
        code: "KZT",
        name_plural: "Kazakhstani tenges",
      },
      LBP: {
        symbol: "LB£",
        name: "Lebanese Pound",
        symbol_native: "ل.ل.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "LBP",
        name_plural: "Lebanese pounds",
      },
      LKR: {
        symbol: "SLRs",
        name: "Sri Lankan Rupee",
        symbol_native: "SL Re",
        decimal_digits: 2,
        rounding: 0,
        code: "LKR",
        name_plural: "Sri Lankan rupees",
      },
      LTL: {
        symbol: "Lt",
        name: "Lithuanian Litas",
        symbol_native: "Lt",
        decimal_digits: 2,
        rounding: 0,
        code: "LTL",
        name_plural: "Lithuanian litai",
      },
      LVL: {
        symbol: "Ls",
        name: "Latvian Lats",
        symbol_native: "Ls",
        decimal_digits: 2,
        rounding: 0,
        code: "LVL",
        name_plural: "Latvian lati",
      },
      LYD: {
        symbol: "LD",
        name: "Libyan Dinar",
        symbol_native: "د.ل.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "LYD",
        name_plural: "Libyan dinars",
      },
      MAD: {
        symbol: "MAD",
        name: "Moroccan Dirham",
        symbol_native: "د.م.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "MAD",
        name_plural: "Moroccan dirhams",
      },
      MDL: {
        symbol: "MDL",
        name: "Moldovan Leu",
        symbol_native: "MDL",
        decimal_digits: 2,
        rounding: 0,
        code: "MDL",
        name_plural: "Moldovan lei",
      },
      MGA: {
        symbol: "MGA",
        name: "Malagasy Ariary",
        symbol_native: "MGA",
        decimal_digits: 0,
        rounding: 0,
        code: "MGA",
        name_plural: "Malagasy Ariaries",
      },
      MKD: {
        symbol: "MKD",
        name: "Macedonian Denar",
        symbol_native: "MKD",
        decimal_digits: 2,
        rounding: 0,
        code: "MKD",
        name_plural: "Macedonian denari",
      },
      MMK: {
        symbol: "MMK",
        name: "Myanma Kyat",
        symbol_native: "K",
        decimal_digits: 0,
        rounding: 0,
        code: "MMK",
        name_plural: "Myanma kyats",
      },
      MOP: {
        symbol: "MOP$",
        name: "Macanese Pataca",
        symbol_native: "MOP$",
        decimal_digits: 2,
        rounding: 0,
        code: "MOP",
        name_plural: "Macanese patacas",
      },
      MUR: {
        symbol: "MURs",
        name: "Mauritian Rupee",
        symbol_native: "MURs",
        decimal_digits: 0,
        rounding: 0,
        code: "MUR",
        name_plural: "Mauritian rupees",
      },
      MXN: {
        symbol: "MX$",
        name: "Mexican Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "MXN",
        name_plural: "Mexican pesos",
      },
      MYR: {
        symbol: "RM",
        name: "Malaysian Ringgit",
        symbol_native: "RM",
        decimal_digits: 2,
        rounding: 0,
        code: "MYR",
        name_plural: "Malaysian ringgits",
      },
      MZN: {
        symbol: "MTn",
        name: "Mozambican Metical",
        symbol_native: "MTn",
        decimal_digits: 2,
        rounding: 0,
        code: "MZN",
        name_plural: "Mozambican meticals",
      },
      NAD: {
        symbol: "N$",
        name: "Namibian Dollar",
        symbol_native: "N$",
        decimal_digits: 2,
        rounding: 0,
        code: "NAD",
        name_plural: "Namibian dollars",
      },
      NGN: {
        symbol: "₦",
        name: "Nigerian Naira",
        symbol_native: "₦",
        decimal_digits: 2,
        rounding: 0,
        code: "NGN",
        name_plural: "Nigerian nairas",
      },
      NIO: {
        symbol: "C$",
        name: "Nicaraguan Córdoba",
        symbol_native: "C$",
        decimal_digits: 2,
        rounding: 0,
        code: "NIO",
        name_plural: "Nicaraguan córdobas",
      },
      NOK: {
        symbol: "Nkr",
        name: "Norwegian Krone",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "NOK",
        name_plural: "Norwegian kroner",
      },
      NPR: {
        symbol: "NPRs",
        name: "Nepalese Rupee",
        symbol_native: "नेरू",
        decimal_digits: 2,
        rounding: 0,
        code: "NPR",
        name_plural: "Nepalese rupees",
      },
      NZD: {
        symbol: "NZ$",
        name: "New Zealand Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "NZD",
        name_plural: "New Zealand dollars",
      },
      OMR: {
        symbol: "OMR",
        name: "Omani Rial",
        symbol_native: "ر.ع.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "OMR",
        name_plural: "Omani rials",
      },
      PAB: {
        symbol: "B/.",
        name: "Panamanian Balboa",
        symbol_native: "B/.",
        decimal_digits: 2,
        rounding: 0,
        code: "PAB",
        name_plural: "Panamanian balboas",
      },
      PEN: {
        symbol: "S/.",
        name: "Peruvian Nuevo Sol",
        symbol_native: "S/.",
        decimal_digits: 2,
        rounding: 0,
        code: "PEN",
        name_plural: "Peruvian nuevos soles",
      },
      PHP: {
        symbol: "₱",
        name: "Philippine Peso",
        symbol_native: "₱",
        decimal_digits: 2,
        rounding: 0,
        code: "PHP",
        name_plural: "Philippine pesos",
      },
      PKR: {
        symbol: "PKRs",
        name: "Pakistani Rupee",
        symbol_native: "₨",
        decimal_digits: 0,
        rounding: 0,
        code: "PKR",
        name_plural: "Pakistani rupees",
      },
      PLN: {
        symbol: "zł",
        name: "Polish Zloty",
        symbol_native: "zł",
        decimal_digits: 2,
        rounding: 0,
        code: "PLN",
        name_plural: "Polish zlotys",
      },
      PYG: {
        symbol: "₲",
        name: "Paraguayan Guarani",
        symbol_native: "₲",
        decimal_digits: 0,
        rounding: 0,
        code: "PYG",
        name_plural: "Paraguayan guaranis",
      },
      QAR: {
        symbol: "QR",
        name: "Qatari Rial",
        symbol_native: "ر.ق.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "QAR",
        name_plural: "Qatari rials",
      },
      RON: {
        symbol: "RON",
        name: "Romanian Leu",
        symbol_native: "RON",
        decimal_digits: 2,
        rounding: 0,
        code: "RON",
        name_plural: "Romanian lei",
      },
      RSD: {
        symbol: "din.",
        name: "Serbian Dinar",
        symbol_native: "дин.",
        decimal_digits: 0,
        rounding: 0,
        code: "RSD",
        name_plural: "Serbian dinars",
      },
      RUB: {
        symbol: "RUB",
        name: "Russian Ruble",
        symbol_native: "₽.",
        decimal_digits: 2,
        rounding: 0,
        code: "RUB",
        name_plural: "Russian rubles",
      },
      RWF: {
        symbol: "RWF",
        name: "Rwandan Franc",
        symbol_native: "FR",
        decimal_digits: 0,
        rounding: 0,
        code: "RWF",
        name_plural: "Rwandan francs",
      },
      SAR: {
        symbol: "SR",
        name: "Saudi Riyal",
        symbol_native: "ر.س.‏",
        decimal_digits: 2,
        rounding: 0,
        code: "SAR",
        name_plural: "Saudi riyals",
      },
      SDG: {
        symbol: "SDG",
        name: "Sudanese Pound",
        symbol_native: "SDG",
        decimal_digits: 2,
        rounding: 0,
        code: "SDG",
        name_plural: "Sudanese pounds",
      },
      SEK: {
        symbol: "Skr",
        name: "Swedish Krona",
        symbol_native: "kr",
        decimal_digits: 2,
        rounding: 0,
        code: "SEK",
        name_plural: "Swedish kronor",
      },
      SGD: {
        symbol: "S$",
        name: "Singapore Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "SGD",
        name_plural: "Singapore dollars",
      },
      SOS: {
        symbol: "Ssh",
        name: "Somali Shilling",
        symbol_native: "Ssh",
        decimal_digits: 0,
        rounding: 0,
        code: "SOS",
        name_plural: "Somali shillings",
      },
      SYP: {
        symbol: "SY£",
        name: "Syrian Pound",
        symbol_native: "ل.س.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "SYP",
        name_plural: "Syrian pounds",
      },
      THB: {
        symbol: "฿",
        name: "Thai Baht",
        symbol_native: "฿",
        decimal_digits: 2,
        rounding: 0,
        code: "THB",
        name_plural: "Thai baht",
      },
      TND: {
        symbol: "DT",
        name: "Tunisian Dinar",
        symbol_native: "د.ت.‏",
        decimal_digits: 3,
        rounding: 0,
        code: "TND",
        name_plural: "Tunisian dinars",
      },
      TOP: {
        symbol: "T$",
        name: "Tongan Paʻanga",
        symbol_native: "T$",
        decimal_digits: 2,
        rounding: 0,
        code: "TOP",
        name_plural: "Tongan paʻanga",
      },
      TRY: {
        symbol: "TL",
        name: "Turkish Lira",
        symbol_native: "TL",
        decimal_digits: 2,
        rounding: 0,
        code: "TRY",
        name_plural: "Turkish Lira",
      },
      TTD: {
        symbol: "TT$",
        name: "Trinidad and Tobago Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "TTD",
        name_plural: "Trinidad and Tobago dollars",
      },
      TWD: {
        symbol: "NT$",
        name: "New Taiwan Dollar",
        symbol_native: "NT$",
        decimal_digits: 2,
        rounding: 0,
        code: "TWD",
        name_plural: "New Taiwan dollars",
      },
      TZS: {
        symbol: "TSh",
        name: "Tanzanian Shilling",
        symbol_native: "TSh",
        decimal_digits: 0,
        rounding: 0,
        code: "TZS",
        name_plural: "Tanzanian shillings",
      },
      UAH: {
        symbol: "₴",
        name: "Ukrainian Hryvnia",
        symbol_native: "₴",
        decimal_digits: 2,
        rounding: 0,
        code: "UAH",
        name_plural: "Ukrainian hryvnias",
      },
      UGX: {
        symbol: "USh",
        name: "Ugandan Shilling",
        symbol_native: "USh",
        decimal_digits: 0,
        rounding: 0,
        code: "UGX",
        name_plural: "Ugandan shillings",
      },
      UYU: {
        symbol: "$U",
        name: "Uruguayan Peso",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "UYU",
        name_plural: "Uruguayan pesos",
      },
      UZS: {
        symbol: "UZS",
        name: "Uzbekistan Som",
        symbol_native: "UZS",
        decimal_digits: 0,
        rounding: 0,
        code: "UZS",
        name_plural: "Uzbekistan som",
      },
      VEF: {
        symbol: "Bs.F.",
        name: "Venezuelan Bolívar",
        symbol_native: "Bs.F.",
        decimal_digits: 2,
        rounding: 0,
        code: "VEF",
        name_plural: "Venezuelan bolívars",
      },
      VND: {
        symbol: "₫",
        name: "Vietnamese Dong",
        symbol_native: "₫",
        decimal_digits: 0,
        rounding: 0,
        code: "VND",
        name_plural: "Vietnamese dong",
      },
      XAF: {
        symbol: "FCFA",
        name: "CFA Franc BEAC",
        symbol_native: "FCFA",
        decimal_digits: 0,
        rounding: 0,
        code: "XAF",
        name_plural: "CFA francs BEAC",
      },
      XOF: {
        symbol: "CFA",
        name: "CFA Franc BCEAO",
        symbol_native: "CFA",
        decimal_digits: 0,
        rounding: 0,
        code: "XOF",
        name_plural: "CFA francs BCEAO",
      },
      YER: {
        symbol: "YR",
        name: "Yemeni Rial",
        symbol_native: "ر.ي.‏",
        decimal_digits: 0,
        rounding: 0,
        code: "YER",
        name_plural: "Yemeni rials",
      },
      ZAR: {
        symbol: "R",
        name: "South African Rand",
        symbol_native: "R",
        decimal_digits: 2,
        rounding: 0,
        code: "ZAR",
        name_plural: "South African rand",
      },
      ZMK: {
        symbol: "ZK",
        name: "Zambian Kwacha",
        symbol_native: "ZK",
        decimal_digits: 0,
        rounding: 0,
        code: "ZMK",
        name_plural: "Zambian kwachas",
      },
      ZWL: {
        symbol: "ZWL$",
        name: "Zimbabwean Dollar",
        symbol_native: "ZWL$",
        decimal_digits: 0,
        rounding: 0,
        code: "ZWL",
        name_plural: "Zimbabwean Dollar",
      },
    },
    countries: [
      {
        text: "Afghanistan",
        value: "AF",
      },
      {
        text: "Åland Islands",
        value: "AX",
      },
      {
        text: "Albania",
        value: "AL",
      },
      {
        text: "Algeria",
        value: "DZ",
      },
      {
        text: "American Samoa",
        value: "AS",
      },
      {
        text: "AndorrA",
        value: "AD",
      },
      {
        text: "Angola",
        value: "AO",
      },
      {
        text: "Anguilla",
        value: "AI",
      },
      {
        text: "Antarctica",
        value: "AQ",
      },
      {
        text: "Antigua and Barbuda",
        value: "AG",
      },
      {
        text: "Argentina",
        value: "AR",
      },
      {
        text: "Armenia",
        value: "AM",
      },
      {
        text: "Aruba",
        value: "AW",
      },
      {
        text: "Australia",
        value: "AU",
      },
      {
        text: "Austria",
        value: "AT",
      },
      {
        text: "Azerbaijan",
        value: "AZ",
      },
      {
        text: "Bahamas",
        value: "BS",
      },
      {
        text: "Bahrain",
        value: "BH",
      },
      {
        text: "Bangladesh",
        value: "BD",
      },
      {
        text: "Barbados",
        value: "BB",
      },
      {
        text: "Belarus",
        value: "BY",
      },
      {
        text: "Belgium",
        value: "BE",
      },
      {
        text: "Belize",
        value: "BZ",
      },
      {
        text: "Benin",
        value: "BJ",
      },
      {
        text: "Bermuda",
        value: "BM",
      },
      {
        text: "Bhutan",
        value: "BT",
      },
      {
        text: "Bolivia",
        value: "BO",
      },
      {
        text: "Bosnia and Herzegovina",
        value: "BA",
      },
      {
        text: "Botswana",
        value: "BW",
      },
      {
        text: "Bouvet Island",
        value: "BV",
      },
      {
        text: "Brazil",
        value: "BR",
      },
      {
        text: "British Indian Ocean Territory",
        value: "IO",
      },
      {
        text: "Brunei Darussalam",
        value: "BN",
      },
      {
        text: "Bulgaria",
        value: "BG",
      },
      {
        text: "Burkina Faso",
        value: "BF",
      },
      {
        text: "Burundi",
        value: "BI",
      },
      {
        text: "Cambodia",
        value: "KH",
      },
      {
        text: "Cameroon",
        value: "CM",
      },
      {
        text: "Canada",
        value: "CA",
      },
      {
        text: "Cape Verde",
        value: "CV",
      },
      {
        text: "Cayman Islands",
        value: "KY",
      },
      {
        text: "Central African Republic",
        value: "CF",
      },
      {
        text: "Chad",
        value: "TD",
      },
      {
        text: "Chile",
        value: "CL",
      },
      {
        text: "China",
        value: "CN",
      },
      {
        text: "Christmas Island",
        value: "CX",
      },
      {
        text: "Cocos (Keeling) Islands",
        value: "CC",
      },
      {
        text: "Colombia",
        value: "CO",
      },
      {
        text: "Comoros",
        value: "KM",
      },
      {
        text: "Congo",
        value: "CG",
      },
      {
        text: "Congo, The Democratic Republic of the",
        value: "CD",
      },
      {
        text: "Cook Islands",
        value: "CK",
      },
      {
        text: "Costa Rica",
        value: "CR",
      },
      {
        text: "Cote D'Ivoire",
        value: "CI",
      },
      {
        text: "Croatia",
        value: "HR",
      },
      {
        text: "Cuba",
        value: "CU",
      },
      {
        text: "Cyprus",
        value: "CY",
      },
      {
        text: "Czech Republic",
        value: "CZ",
      },
      {
        text: "Denmark",
        value: "DK",
      },
      {
        text: "Djibouti",
        value: "DJ",
      },
      {
        text: "Dominica",
        value: "DM",
      },
      {
        text: "Dominican Republic",
        value: "DO",
      },
      {
        text: "Ecuador",
        value: "EC",
      },
      {
        text: "Egypt",
        value: "EG",
      },
      {
        text: "El Salvador",
        value: "SV",
      },
      {
        text: "Equatorial Guinea",
        value: "GQ",
      },
      {
        text: "Eritrea",
        value: "ER",
      },
      {
        text: "Estonia",
        value: "EE",
      },
      {
        text: "Ethiopia",
        value: "ET",
      },
      {
        text: "Falkland Islands (Malvinas)",
        value: "FK",
      },
      {
        text: "Faroe Islands",
        value: "FO",
      },
      {
        text: "Fiji",
        value: "FJ",
      },
      {
        text: "Finland",
        value: "FI",
      },
      {
        text: "France",
        value: "FR",
      },
      {
        text: "French Guiana",
        value: "GF",
      },
      {
        text: "French Polynesia",
        value: "PF",
      },
      {
        text: "French Southern Territories",
        value: "TF",
      },
      {
        text: "Gabon",
        value: "GA",
      },
      {
        text: "Gambia",
        value: "GM",
      },
      {
        text: "Georgia",
        value: "GE",
      },
      {
        text: "Germany",
        value: "DE",
      },
      {
        text: "Ghana",
        value: "GH",
      },
      {
        text: "Gibraltar",
        value: "GI",
      },
      {
        text: "Greece",
        value: "GR",
      },
      {
        text: "Greenland",
        value: "GL",
      },
      {
        text: "Grenada",
        value: "GD",
      },
      {
        text: "Guadeloupe",
        value: "GP",
      },
      {
        text: "Guam",
        value: "GU",
      },
      {
        text: "Guatemala",
        value: "GT",
      },
      {
        text: "Guernsey",
        value: "GG",
      },
      {
        text: "Guinea",
        value: "GN",
      },
      {
        text: "Guinea-Bissau",
        value: "GW",
      },
      {
        text: "Guyana",
        value: "GY",
      },
      {
        text: "Haiti",
        value: "HT",
      },
      {
        text: "Heard Island and Mcdonald Islands",
        value: "HM",
      },
      {
        text: "Holy See (Vatican City State)",
        value: "VA",
      },
      {
        text: "Honduras",
        value: "HN",
      },
      {
        text: "Hong Kong",
        value: "HK",
      },
      {
        text: "Hungary",
        value: "HU",
      },
      {
        text: "Iceland",
        value: "IS",
      },
      {
        text: "India",
        value: "IN",
      },
      {
        text: "Indonesia",
        value: "ID",
      },
      {
        text: "Iran, Islamic Republic Of",
        value: "IR",
      },
      {
        text: "Iraq",
        value: "IQ",
      },
      {
        text: "Ireland",
        value: "IE",
      },
      {
        text: "Isle of Man",
        value: "IM",
      },
      {
        text: "Israel",
        value: "IL",
      },
      {
        text: "Italy",
        value: "IT",
      },
      {
        text: "Jamaica",
        value: "JM",
      },
      {
        text: "Japan",
        value: "JP",
      },
      {
        text: "Jersey",
        value: "JE",
      },
      {
        text: "Jordan",
        value: "JO",
      },
      {
        text: "Kazakhstan",
        value: "KZ",
      },
      {
        text: "Kenya",
        value: "KE",
      },
      {
        text: "Kiribati",
        value: "KI",
      },
      {
        text: "Korea, Democratic People'S Republic of",
        value: "KP",
      },
      {
        text: "Korea, Republic of",
        value: "KR",
      },
      {
        text: "Kuwait",
        value: "KW",
      },
      {
        text: "Kyrgyzstan",
        value: "KG",
      },
      {
        text: "Lao People'S Democratic Republic",
        value: "LA",
      },
      {
        text: "Latvia",
        value: "LV",
      },
      {
        text: "Lebanon",
        value: "LB",
      },
      {
        text: "Lesotho",
        value: "LS",
      },
      {
        text: "Liberia",
        value: "LR",
      },
      {
        text: "Libyan Arab Jamahiriya",
        value: "LY",
      },
      {
        text: "Liechtenstein",
        value: "LI",
      },
      {
        text: "Lithuania",
        value: "LT",
      },
      {
        text: "Luxembourg",
        value: "LU",
      },
      {
        text: "Macao",
        value: "MO",
      },
      {
        text: "Macedonia, The Former Yugoslav Republic of",
        value: "MK",
      },
      {
        text: "Madagascar",
        value: "MG",
      },
      {
        text: "Malawi",
        value: "MW",
      },
      {
        text: "Malaysia",
        value: "MY",
      },
      {
        text: "Maldives",
        value: "MV",
      },
      {
        text: "Mali",
        value: "ML",
      },
      {
        text: "Malta",
        value: "MT",
      },
      {
        text: "Marshall Islands",
        value: "MH",
      },
      {
        text: "Martinique",
        value: "MQ",
      },
      {
        text: "Mauritania",
        value: "MR",
      },
      {
        text: "Mauritius",
        value: "MU",
      },
      {
        text: "Mayotte",
        value: "YT",
      },
      {
        text: "Mexico",
        value: "MX",
      },
      {
        text: "Micronesia, Federated States of",
        value: "FM",
      },
      {
        text: "Moldova, Republic of",
        value: "MD",
      },
      {
        text: "Monaco",
        value: "MC",
      },
      {
        text: "Mongolia",
        value: "MN",
      },
      {
        text: "Montserrat",
        value: "MS",
      },
      {
        text: "Morocco",
        value: "MA",
      },
      {
        text: "Mozambique",
        value: "MZ",
      },
      {
        text: "Myanmar",
        value: "MM",
      },
      {
        text: "Namibia",
        value: "NA",
      },
      {
        text: "Nauru",
        value: "NR",
      },
      {
        text: "Nepal",
        value: "NP",
      },
      {
        text: "Netherlands",
        value: "NL",
      },
      {
        text: "Netherlands Antilles",
        value: "AN",
      },
      {
        text: "New Caledonia",
        value: "NC",
      },
      {
        text: "New Zealand",
        value: "NZ",
      },
      {
        text: "Nicaragua",
        value: "NI",
      },
      {
        text: "Niger",
        value: "NE",
      },
      {
        text: "Nigeria",
        value: "NG",
      },
      {
        text: "Niue",
        value: "NU",
      },
      {
        text: "Norfolk Island",
        value: "NF",
      },
      {
        text: "Northern Mariana Islands",
        value: "MP",
      },
      {
        text: "Norway",
        value: "NO",
      },
      {
        text: "Oman",
        value: "OM",
      },
      {
        text: "Pakistan",
        value: "PK",
      },
      {
        text: "Palau",
        value: "PW",
      },
      {
        text: "Palestinian Territory, Occupied",
        value: "PS",
      },
      {
        text: "Panama",
        value: "PA",
      },
      {
        text: "Papua New Guinea",
        value: "PG",
      },
      {
        text: "Paraguay",
        value: "PY",
      },
      {
        text: "Peru",
        value: "PE",
      },
      {
        text: "Philippines",
        value: "PH",
      },
      {
        text: "Pitcairn",
        value: "PN",
      },
      {
        text: "Poland",
        value: "PL",
      },
      {
        text: "Portugal",
        value: "PT",
      },
      {
        text: "Puerto Rico",
        value: "PR",
      },
      {
        text: "Qatar",
        value: "QA",
      },
      {
        text: "Reunion",
        value: "RE",
      },
      {
        text: "Romania",
        value: "RO",
      },
      {
        text: "Russian Federation",
        value: "RU",
      },
      {
        text: "RWANDA",
        value: "RW",
      },
      {
        text: "Saint Helena",
        value: "SH",
      },
      {
        text: "Saint Kitts and Nevis",
        value: "KN",
      },
      {
        text: "Saint Lucia",
        value: "LC",
      },
      {
        text: "Saint Pierre and Miquelon",
        value: "PM",
      },
      {
        text: "Saint Vincent and the Grenadines",
        value: "VC",
      },
      {
        text: "Samoa",
        value: "WS",
      },
      {
        text: "San Marino",
        value: "SM",
      },
      {
        text: "Sao Tome and Principe",
        value: "ST",
      },
      {
        text: "Saudi Arabia",
        value: "SA",
      },
      {
        text: "Senegal",
        value: "SN",
      },
      {
        text: "Serbia and Montenegro",
        value: "CS",
      },
      {
        text: "Seychelles",
        value: "SC",
      },
      {
        text: "Sierra Leone",
        value: "SL",
      },
      {
        text: "Singapore",
        value: "SG",
      },
      {
        text: "Slovakia",
        value: "SK",
      },
      {
        text: "Slovenia",
        value: "SI",
      },
      {
        text: "Solomon Islands",
        value: "SB",
      },
      {
        text: "Somalia",
        value: "SO",
      },
      {
        text: "South Africa",
        value: "ZA",
      },
      {
        text: "South Georgia and the South Sandwich Islands",
        value: "GS",
      },
      {
        text: "Spain",
        value: "ES",
      },
      {
        text: "Sri Lanka",
        value: "LK",
      },
      {
        text: "Sudan",
        value: "SD",
      },
      {
        text: "Suriname",
        value: "SR",
      },
      {
        text: "Svalbard and Jan Mayen",
        value: "SJ",
      },
      {
        text: "Swaziland",
        value: "SZ",
      },
      {
        text: "Sweden",
        value: "SE",
      },
      {
        text: "Switzerland",
        value: "CH",
      },
      {
        text: "Syrian Arab Republic",
        value: "SY",
      },
      {
        text: "Taiwan, Province of China",
        value: "TW",
      },
      {
        text: "Tajikistan",
        value: "TJ",
      },
      {
        text: "Tanzania, United Republic of",
        value: "TZ",
      },
      {
        text: "Thailand",
        value: "TH",
      },
      {
        text: "Timor-Leste",
        value: "TL",
      },
      {
        text: "Togo",
        value: "TG",
      },
      {
        text: "Tokelau",
        value: "TK",
      },
      {
        text: "Tonga",
        value: "TO",
      },
      {
        text: "Trinidad and Tobago",
        value: "TT",
      },
      {
        text: "Tunisia",
        value: "TN",
      },
      {
        text: "Turkey",
        value: "TR",
      },
      {
        text: "Turkmenistan",
        value: "TM",
      },
      {
        text: "Turks and Caicos Islands",
        value: "TC",
      },
      {
        text: "Tuvalu",
        value: "TV",
      },
      {
        text: "Uganda",
        value: "UG",
      },
      {
        text: "Ukraine",
        value: "UA",
      },
      {
        text: "United Arab Emirates",
        value: "AE",
      },
      {
        text: "United Kingdom",
        value: "GB",
      },
      {
        text: "United States",
        value: "US",
      },
      {
        text: "United States Minor Outlying Islands",
        value: "UM",
      },
      {
        text: "Uruguay",
        value: "UY",
      },
      {
        text: "Uzbekistan",
        value: "UZ",
      },
      {
        text: "Vanuatu",
        value: "VU",
      },
      {
        text: "Venezuela",
        value: "VE",
      },
      {
        text: "Viet Nam",
        value: "VN",
      },
      {
        text: "Virgin Islands, British",
        value: "VG",
      },
      {
        text: "Virgin Islands, U.S.",
        value: "VI",
      },
      {
        text: "Wallis and Futuna",
        value: "WF",
      },
      {
        text: "Western Sahara",
        value: "EH",
      },
      {
        text: "Yemen",
        value: "YE",
      },
      {
        text: "Zambia",
        value: "ZM",
      },
      {
        text: "Zimbabwe",
        value: "ZW",
      },
    ],
    navigation: {
      items: [
        {
          name: "Home",
          icon: "mdi-home",
          navPermission: "company.home",
          items: null,
        },
        // {
        //   name: "History",
        //   icon: "mdi-history",
        //   navPermission: "company.history",
        //   items: null
        // },
        {
          name: "Onboarding",
          icon: "mdi-briefcase-account",
          navPermission: "company.onboarding",
          required: false,
          items: null,
          // items: [
          // {
          //   name: "Form",
          //   icon: "mdi-format-annotation-plus",
          //   navPermission: "company.onboarding.form",
          //   requiredPermissions: [],

          //   items: [{
          //     name: "Builder",
          //     icon: "mdi-wrench",
          //     navPermission: "company.onboarding.form.builder",

          //     items: null
          //   }]
          // },
          // {
          //   name: "New",
          //   icon: "mdi-plus",
          //   navPermission: "company.onboarding.new",
          //   items: null,
          // },
          // {
          //   name: "Profile",
          //   icon: "mdi-account",
          //   navPermission: "company.onboarding.profile",
          //   items: null,
          // },
          // {
          //   name: "Search",
          //   icon: "mdi-magnify",
          //   navPermission: "company.onboarding.search",
          //   items: null,
          // },
          // {
          //   name: "Change",
          //   icon: "mdi-delta",
          //   navPermission: "company.onboarding.change",
          //   items: null,
          // },
          // {
          //   name: "Legacy",
          //   icon: "mdi-archive",
          //   navPermission: "company.onboarding.legacy",
          //   items: null,
          // },
          // {
          //   name: "Continue",
          //   icon: "mdi-navigation",
          //   navPermission: "company.onboarding.continue",
          //   items: null,
          // },
          // {
          //   name: "History",
          //   icon: "mdi-history",
          //   navPermission: "company.onboarding.history",
          //   items: null,
          // },
          // {
          //   name: "Settings",
          //   icon: "mdi-cog",
          //   navPermission: "company.onboarding.setting",
          //   items: null,
          // },
          // ],
        },
        {
          name: "Project",
          icon: "mdi-group",
          navPermission: "company.project",
          items: null,
        },
        {
          name: "Email",
          icon: "mdi-email",
          navPermission: "company.onboarding.email",
          items: [
            {
              name: "Composer",
              icon: "mdi-pencil",
              navPermission: "company.onboarding.email.compose",
              items: null,
            },
            // {
            //   name: "Send",
            //   icon: "mdi-send",
            //   navPermission: "company.onboarding.email.send",
            //   items: null,
            // },
          ],
        },
        {
          name: "Settings",
          icon: "mdi-cog",
          navPermission: "company.setting",
          items: null,
        },
        {
          name: "User",
          icon: "mdi-account-supervisor",
          navPermission: "company.user",
          required: false,
          items: [
            {
              name: "Manage",
              icon: "mdi-pencil",
              navPermission: "company.user.manage",
              items: null,
            },
            {
              name: "Invite",
              icon: "mdi-account-multiple-plus",
              navPermission: "company.user.invite",
              items: null,
            },
            // {
            //   name: "History",
            //   icon: "mdi-history",
            //   navPermission: "company.user.history",
            //   items: null,
            // },
            // {
            //   name: "Settings",
            //   icon: "mdi-cog",
            //   navPermission: "company.user.setting",
            //   items: null,
            // },
          ],
        },
      ],
    },
    permissions: [],
    keys: {
      production: {
        client: {
          private: `-----BEGIN PRIVATE KEY-----
          MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDYLmJzJu4eCjoP
          C5E8c4u5iz8ZJULJk4tjZvr6Wm/qOxBTRRksmGUolgbyzsv+Ijdoo9TbusAsAjyD
          i/NYpT/2kCR+v2s28I/6ABf8xim58JWbU9SgjM5xhXfRoVgqVGvDsM+3TxML0Ynk
          xYQxK9Lmx33QYcthfR1qjgrgjxOOaT6ylxLKQINCtD8ezcbZHP8Ouj5hcmhHdv5R
          lXEDF1e4r1DLErbufARFap9q4flLWQ9MruiONo91GKynNFO+UMj5WYGdcCgDNTFG
          zz7KazypcgK5ptdnBp9eCFSSgCfn1eC9zEXOPcuR5mioLAIcWB0mGuj+kYa6vyOb
          OFqOCZ3/AgMBAAECggEADnUzsl3VKP8oGN9+LBvSKWuNocHjMJYEiXZWrK2ltHWU
          xuzlHR1oDonSFyULcYiaSg5C6xsXRRo1L9sJQFF08Kl9N1jX88ms51xgPlCGc+AK
          MWQu4TnpiKATflqbCeGxf1nw2mKYdH7kCP/Isn0q0rJe0LPppTB36itrwM7w0OJL
          jVBhEsVJYe09OolLfCS/Cl9pMXbuL7AG3qbeo4AMYKgGvXPTaQqhLwdq1/7c4Tci
          6IBqeIHgbbGN7cSSzv0J8ryCJker0jHVS8SQnA9FcLAlvIE0d8p1DezaoFXPw6y8
          ooke6GJ9cWa665ggnCHCxhUY18k7vzErL//KBi2QeQKBgQDvpXD4zb24tvyUwUtu
          kCxnwAYMcSTxKzyV8lkhGVqSAVOm4mtXV/jxqcF5mJ/o3FjYMKRYZpqAI7RGEucS
          INCWNtHSDDo8ccsaIgsQHovEHX9Oa61nGqlSYnIqKTh8H2yd0J8xhRK+AZLobUBo
          6jZpYEN3gmkIQEB4ScEZn0XPpQKBgQDm7wO8O824E5hojZs5p18HYmtT8GmFcUHK
          +4ybAOKRsUKVcC88ufaH9/fFiqrptHJHImOE8emv57vqy4ajH3dh6GjpZyrvBZtq
          g6/Gq2miQIJ6j1X1LPIS2FBWJbw/HdUBUaa3VHna0GOaQs1yv6GewVoL+sf5SNp2
          rutO1ehF0wKBgQCJWt7sjCfwxljw/fU5MsNw19vfp74PyMNaAmTZlsmVo9MJdP8n
          OdIC2VR+WF9XPWxixUZ9MJnfVheOjmkIt+w2HMeYR8/dMy/BE8AZhhh/2JROI2Np
          FBv2j1x2E9g/NFeyAJAt9uLuKsLEG1Juj8eOEFG7HvEKfKE3ELbv8500zQKBgQDi
          I0vVF/rrNsUYHRLT8kHsSUQwfkvWkIGM86dMYiF76cj57VFUdgYkP34xwwh6smj1
          n4c0uG/OAW7TDVpagoW1KpERfiVcynDWbP3D3jci22jp+1KBM/IY4hpJQcqFRAFB
          uWM4Q1DQJSBj/uaQZ3csrsou0PTh96V2cvOZz34UYQKBgF92iKlYr3yk8GIBdj8S
          b0ocjoKXD6oogUwq2gP4l85QJicSOtG9bG2pdhqbmW36ueoGcfnJCX8n0MbrW9q2
          BuLjehLkQLRm4NGncevsQL0LfiNwQLTZcr6Q2eBhXTmYzlzFA4gBTWrlmBMcX970
          suWxsNV3M+mi/sZhxxyvKjhb
          -----END PRIVATE KEY-----`,
          public: `-----BEGIN PUBLIC KEY-----
          MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2C5icybuHgo6DwuRPHOL
          uYs/GSVCyZOLY2b6+lpv6jsQU0UZLJhlKJYG8s7L/iI3aKPU27rALAI8g4vzWKU/
          9pAkfr9rNvCP+gAX/MYpufCVm1PUoIzOcYV30aFYKlRrw7DPt08TC9GJ5MWEMSvS
          5sd90GHLYX0dao4K4I8Tjmk+spcSykCDQrQ/Hs3G2Rz/Dro+YXJoR3b+UZVxAxdX
          uK9QyxK27nwERWqfauH5S1kPTK7ojjaPdRispzRTvlDI+VmBnXAoAzUxRs8+yms8
          qXICuabXZwafXghUkoAn59XgvcxFzj3LkeZoqCwCHFgdJhro/pGGur8jmzhajgmd
          /wIDAQAB
          -----END PUBLIC KEY-----`,
        },
        server: {
          public: `-----BEGIN PUBLIC KEY-----
          MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA17GG+v+UIRdDU29kmrO3
          wx3/yhtKsUEA12clxKpB5H9sKv+uHfJDoPg2RnWL4wF1MPRFEBKRMgwypyYMlOfk
          4JRrPaqOmhs9UygtHtLjSGdeJ7yhfw90mio8Fs96dOs7EDCfzvm4ufGwwpTiht9t
          dilAn5RGy5Nh0unbs/aFrNdSNBGkJGYzDVzm6yxJrYGW7V8YClJesUePYHOy0565
          fJYFQXAa/VNh+8PWlcMzFN6OH3YswfzZ9TU9BbJQyBjGShuQx4V338AYFLz81CEe
          VdAVX7wDjxREmIPGnHxDnrT61TmhqPP/NukvwmQKoNP8KK9y3vgZj7C+y5Bo3Qr9
          XQIDAQAB
          -----END PUBLIC KEY-----`,
        },
      },
      development: {
        client: {
          private: `-----BEGIN PRIVATE KEY-----
            MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQC6SxmTUjjUj1r5
            0keJZOhuQRV54wMVKf0v8uYuAvvsreGiykfl50BHMijcP9GHBY5snLCz5N/VjAmG
            vti/J1JaIc0h/cNkX+s++NFVPsqK7Ey9HaPuoeDzwhkn0OPe8gaNvlF3JsFhhOqb
            hpI+AFsHfB7V1YKOrDn5WkpLrKgzDp7T2/uA/3/xaGp7jW78UOx7UNZbIs3b1LQs
            HveZ+GoAtNAlOGdHsnFK0g59edq0Vl2xzPgfVOMHZZnPw6VV9R6ghQSekBvV5Ahi
            W0TqGIfUejIb2nrCNj/kpUc431NXPG/C5hL4RniDmGYMD3nQNgCt1aqeHPtCcd6D
            Yc736v6rAgMBAAECggEAUZIAqNkfuTLsCudgG3DrtRsaZDxaZTH1gsFAuHTmCkQ4
            wCcCRFB1fLch3Q60mAJ+0l/pn1tgBwlCQpXeIhT9oimawH90xsaZIwGf50uPNDUz
            hLstxJdtAE7E5QI6RKyE4hurYBjAJAsnDuJltnBBa42VktviRsZyvBQk4+292FyX
            MV7+BbrjGYVc1SBadf6Tltmjf54OChFpHXECmCGgrP0y1YRtM3EOWsSdNenUu2gx
            T7JMMvw/OYeqaYO3NwllkfQtG8ET35jF8RFYg5u5UFrr1+z3SIRVZtCew1+IDy/L
            JCap0kpJJDllZ3qaYJQAG4C7IClgG3qQMTeSRh+vgQKBgQDf1jsTojddHREtN2TF
            i7zggdXYU/fia4+RIS+mMhH7Y8SctOcfdrjjvlW5e4BnBYJHtlTFE3wueFslaSSG
            yT5DxaMqDFInIGO8yrKDpUKN2q1qpU4M6H4sgki8pwgp1OwirgJnRHgS8Q3BOu0C
            A+0HdYOU26kmM+xt9KAOBbDucwKBgQDVD9gCBT3Q29ST4sP20XzovcT7FzY3hEIc
            amhJsBZ0w1qKxRw6r4S0xHvVKeDOWJIrm6kNY0TzvXDDLniS2zbrvVHpRhuPN2we
            r1wpfptWuAgMmBWtrTlX3sZnIpd4dFOieZMdBw6ZHTBGvriuy4O0NfU/EvzbppWb
            jy/qgOAo6QKBgQDSJBcMZLOjaEGS+LlTuM/W2i3ATXygBikfsE9CsSkuoMiZ8kA1
            ysjjjvJ+E1NF5OcdmjDVYGiqW/X+Fkrje5VIHQkE1dJex0q7mHSloQXtlUpPn+e5
            uJ+T+7B75DiGxU33dPPurVopNkOakE6oILVr1kh0/RA9PGwQAqQs5ezovwKBgQCP
            lnDl07B4PF6EF9QnV8ENfS7uZRNF0N1ZFyEXYBOSN0GiufovVrBhvvURkHqPtcM0
            nmAMxLToaug+sefSWOgncVxtkLDsENbNyzKaSFfZ1oaeLjSV6mL15A3j3b0lEYh3
            u+U4L9RXvgMV+lyueDtXTPHtwxcj1J2Iy7PoH1hyWQKBgCgUuT9unsdUxZEv1vSR
            fd5vwNdC1rdwzA4KHjVu2QCNarWEiML3yQAQTSnsfRyi6vLUL1RT8HtK588jCZYt
            QCRjNLNXg9ahjCIvDwPZRIRDuNtrT6fPDx1jjRla5aU6ebSqaj6E+NHml2Yty9yH
            +BHorbISiSp3AeEJ5ydpCRAu
            -----END PRIVATE KEY-----`,
          public: `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuksZk1I41I9a+dJHiWTo
            bkEVeeMDFSn9L/LmLgL77K3hospH5edARzIo3D/RhwWObJyws+Tf1YwJhr7YvydS
            WiHNIf3DZF/rPvjRVT7KiuxMvR2j7qHg88IZJ9Dj3vIGjb5RdybBYYTqm4aSPgBb
            B3we1dWCjqw5+VpKS6yoMw6e09v7gP9/8Whqe41u/FDse1DWWyLN29S0LB73mfhq
            ALTQJThnR7JxStIOfXnatFZdscz4H1TjB2WZz8OlVfUeoIUEnpAb1eQIYltE6hiH
            1HoyG9p6wjY/5KVHON9TVzxvwuYS+EZ4g5hmDA950DYArdWqnhz7QnHeg2HO9+r+
            qwIDAQAB
            -----END PUBLIC KEY-----`,
        },
        server: {
          public: `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAii1jNJp/hQKPwFJuQJVK
            V1NzJJZhEAzeoibxCLFK0rQJTwCk7S3z6CKE0YsHoHmwRUEiNlsRp3A7V1bjogD6
            GqA/bV3rNq9CQgQ+eKakFMB+j2E2MjCabcvp2TNbGg+OI7vUjRojIKAaBTILoDFh
            QMYZu3nx8wFQDcQGFfRnl1iLhojKSY5BGDdGYTCPUDJfI3jbrtqwRyLr53HjkR59
            HP77j5CtOxqpjWB82SNhOxiTtgrjCwNYdXk+WI854dSuELM9UI2PGHuPWErqkxbb
            C6eiVf/Fd4G0xpVmTher0hmznbYY4V5zfxY9xxxukdxl5PnlwuTJ4xl3k9IOoLpy
            XwIDAQAB
            -----END PUBLIC KEY-----`,
        }
      }
    },
    aesKey: null,
  },
  getters: {
    navPermissions: (state) => {
      let getPermissionsFromItems = function(items) {
        let x = [];
        items.forEach((item) => {
          if (item.items !== null) {
            x.push(getPermissionsFromItems(item.items));
          }
        });
        x.push(
          items.map((item) => {
            return {
              text: item.navPermission,
              value: item,
            };
          })
        );
        return [].concat.apply([], x);
      };
      return getPermissionsFromItems(
        JSON.parse(JSON.stringify(state.navigation.items))
      );
    },
  },
  mutations: {
    DELETE_RUNN_PROJECTS(state, project) {
      state.runn.projects.splice(state.runn.projects.indexOf(p => p.id === project.id), 1);
    },
    DELETE_RUNN_PEOPLE(state, person) {
      state.runn.people.splice(state.runn.people.indexOf(p => p.id === person.id), 1);
    },
    DELETE_RUNN_ASSIGNMENTS(state, assignment) {
      state.runn.assignments.splice(state.runn.assignments.indexOf(a => a.id === assignment.id), 1);
    },

    UPDATE_RUNN_PROJECTS(state, project) {
      state.runn.projects[state.runn.projects.indexOf(p => p.id === project.id)] = project;
    },
    UPDATE_RUNN_PEOPLE(state, person) {
      state.runn.people[state.runn.people.indexOf(p => p.id === person.id)] = person;
    },
    UPDATE_RUNN_ASSIGNMENTS(state, assignment) {
      state.runn.assignments[state.runn.assignments.indexOf(a => a.id === assignment.id)] = assignment;
    },

    SET_RUNN_PROJECTS(state, projects) {
      state.runn.projects = projects;
    },
    SET_RUNN_PEOPLE(state, people) {
      state.runn.people = people;
    },
    SET_RUNN_ASSIGNMENTS(state, assignments) {
      state.runn.assignments = assignments;
    },

    SET_PROJECT_WORK_ESTIMATION_IN_DAYS(state, projectWorkEstimationInDays) {
      state.projectWorkEstimationInDays = projectWorkEstimationInDays
    },
    SET_ITEMS_PER_PAGE(state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage
    },
    SET_ADOBE_ACCESS_POINT(state, accessPoint) {
      state.adobe_access_point = accessPoint
    },
    SET_ADOBE_ACCESS_TOKEN(state, accessToken) {
      state.adobe_access_token = accessToken
    },
    SET_AES_KEY(state, aesKey) {
      state.aesKey = aesKey;
    },
    SET_SOCKET_ID(state, id) {
      state.socketID = id;
    },
    LOGOUT(state) {
      state.companies = null;
      state.user = null;
      state.userInfo = null;
      state.userAD = null;
      state.userADInfo = null;
      state.userADPhotoURL = null;
      state.accessToken = null;
      state.alerts = [];
      state.changeonboarding_id = null;
      state.loginRedirect = null;
      state.permissions = [];
      state.onboardings = null;
      state.projects = null;
      state.projectRateCards = null;
      state.adobe_access_point = null;
      state.adobe_access_token = null;
    },
    SET_LOGIN_REDIRECT(state, route) {
      state.loginRedirect = route;
    },
    SET_CHANGE_ONBOARDING_ID(state, id) {
      state.changeonboarding_id = id;
    },
    REMOVE_ALERT(state, alertIndex) {
      state.alerts.splice(alertIndex, 1);
    },
    ADD_ALERT(state, alert) {
      state.alerts.push(alert);
    },
    SHOW_ALERT(state, alertIndex) {
      state.alerts[alertIndex].show = true;
    },
    HIDE_ALERT(state, alertIndex) {
      state.alerts[alertIndex].show = false;
    },
    SET_AD_ACCESS_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_AD_USER(state, user) {
      state.userAD = user;
    },
    SET_AD_USER_INFO(state, user) {
      state.userADInfo = user;
    },
    SET_AD_USER_PHOTO_URL(state, url) {
      state.userADPhotoURL = url;
    },
    SET_COMPANIES(state, companies) {
      state.companies = companies;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_ONBOARDINGS(state, onboardings) {
      if (state.onboardings === null) {
        state.onboardings = onboardings;
      } else {
        Object.keys(onboardings).forEach(key => {
          if (state.onboardings[key] !== undefined) {
            onboardings[key].forEach(onb => {
              let i = -1;
              let existingOnb = state.onboardings[key].filter((existingOnb, index) => {
                if (onb._id === existingOnb._id) {
                  i = index
                  return true;
                } else {
                  return false;
                }
              })[0]
              if (existingOnb === undefined) {
                state.onboardings[key].push(onb)
              }
            })
          } else {
            state.onboardings[key] = onboardings[key]
          }
        })
      }
      
    },
    NEW_ONBOARDING(state, onboarding) {
      let existIndex = state.onboardings[onboarding.company_id].indexOf(state.onboardings[onboarding.company_id].filter(o => o._id === onboarding._id)[0])
      if (existIndex === -1) {
        state.onboardings[onboarding.company_id].push(onboarding)
      } else {
        state.onboardings[onboarding.company_id][existIndex] = onboarding
      }
    },
    UPDATE_ONBOARDING(state, onboarding) {
      // console.log(state.onboardings, state.onboardings[onboarding.company_id])
      let existIndex = state.onboardings[onboarding.company_id].indexOf(state.onboardings[onboarding.company_id].filter(o => o._id === onboarding._id)[0])
      if (existIndex === -1) {
        state.onboardings[onboarding.company_id].push(onboarding)
      } else {
        state.onboardings[onboarding.company_id][existIndex] = onboarding
      }
    },
    DELETE_ONBOARDING(state, onboarding) {
      let existIndex = state.onboardings[onboarding.company_id].indexOf(state.onboardings[onboarding.company_id].filter(o => o._id === onboarding._id)[0])
      if (existIndex !== -1) {
        state.onboardings[onboarding.company_id].splice(existIndex, 1)
      }
    },
    SET_PROJECT_RATE_CARDS(state, projectRateCards) {
      state.projectRateCards = projectRateCards;
    },
    SET_PROJECTS(state, projects) {
      if (state.projects === null) {
        state.projects = projects;
      } else {
        projects.forEach(project => {
          let i = -1;
          let existingProject = state.projects.filter((p, index) => {
            if (p._id === project._id) {
              i = index
              return true
            } else {
              return false
            }
          })[0]
          if (existingProject === undefined) {
            state.projects.push(project)
          }
        })
      }
    },
    NEW_PROJECT(state, project) {
      let existIndex = state.projects.indexOf(state.projects.filter(p => p._id === project._id)[0])
      if (existIndex === -1) {
        state.projects.push(project)
      } else {
        state.projects[existIndex] = project
      }
    },
    UPDATE_PROJECT(state, project) {
      let existIndex = state.projects.indexOf(state.projects.filter(p => p._id === project._id)[0])
      if (existIndex === -1) {
        state.projects.push(project)
      } else {
        state.projects[existIndex] = project
      }
    },
    DELETE_PROJECT(state, project) {
      let existIndex = state.projects.indexOf(state.projects.filter(p => p._id === project._id)[0])
      // console.log(state, project, existIndex)
      if (existIndex !== -1) {
        state.projects.splice(existIndex, 1)
      }
    },
    SET_PROJECT_RATE_CARDS(state, projectRateCards) {
      if (state.projectRateCards === null) {
        state.projectRateCards = projectRateCards;
      } else {
        projectRateCards.forEach(projectRateCard => {
          let i = -1;
          let existingProjectRateCard = state.projectRateCards.filter((p, index) => {
            if (p._id === projectRateCard._id) {
              i = index
              return true
            } else {
              return false
            }
          })[0]
          if (existingProjectRateCard === undefined) {
            state.projectRateCards.push(projectRateCard)
          }
        })
      }
    },
    NEW_PROJECT_RATE_CARD(state, projectRateCard) {
      let existIndex = state.projectRateCards.indexOf(state.projectRateCards.filter(prc => prc._id === projectRateCard._id)[0])
      if (existIndex === -1) {
        state.projectRateCards.push(projectRateCard)
      } else {
        state.projectRateCards[existIndex] = projectRateCard
      }
    },
    UPDATE_PROJECT_RATE_CARD(state, projectRateCard) {
      
      let existIndex = state.projectRateCards.indexOf(state.projectRateCards.filter(prc => prc._id === projectRateCard._id)[0])
      if (existIndex === -1) {
        // console.log('update prc (vuex) 1')
        state.projectRateCards.push(projectRateCard)
      } else {
        // console.log('update prc (vuex) 2')
        state.projectRateCards[existIndex] = projectRateCard
      }
    },
    DELETE_PROJECT_RATE_CARD(state, projectRateCard) {
      let existIndex = state.projectRateCards.indexOf(state.projectRateCards.filter(prc => prc._id === projectRateCard._id)[0])
      if (existIndex !== -1) {
        state.projectRateCards.splice(existIndex, 1)
      }
    },

    ADD_CONSOLE_MESSAGE(state, message) {
      state.console.messages.push(message);
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
  },
  actions: {
    DELETE_RUNN_PROJECTS({ commit },  projects) {
      commit("DELETE_RUNN_PROJECTS", projects)
    },
    DELETE_RUNN_PEOPLE({ commit },  people) {
      commit("DELETE_RUNN_PEOPLE", people)
    },
    DELETE_RUNN_ASSIGNMENTS({ commit },  assignments) {
      commit("DELETE_RUNN_ASSIGNMENTS", assignments)
    },
    UPDATE_RUNN_PROJECTS({ commit },  projects) {
      commit("UPDATE_RUNN_PROJECTS", projects)
    },
    UPDATE_RUNN_PEOPLE({ commit },  people) {
      commit("UPDATE_RUNN_PEOPLE", people)
    },
    UPDATE_RUNN_ASSIGNMENTS({ commit },  assignments) {
      commit("UPDATE_RUNN_ASSIGNMENTS", assignments)
    },
    SET_RUNN_PROJECTS({ commit },  projects) {
      commit("SET_RUNN_PROJECTS", projects)  
    },
    SET_RUNN_PEOPLE({ commit },  people) {
      commit("SET_RUNN_PEOPLE", people)  
    },
    SET_RUNN_ASSIGNMENTS({ commit }, assignments) {
      commit("SET_RUNN_ASSIGNMENTS", assignments)
    },
    SET_PROJECT_WORK_ESTIMATION_IN_DAYS({ commit }, projectWorkEstimationInDays) {
      commit("SET_PROJECT_WORK_ESTIMATION_IN_DAYS", projectWorkEstimationInDays)
    },
    SET_ITEMS_PER_PAGE({ commit }, itemsPerPage) {
      commit("SET_ITEMS_PER_PAGE", itemsPerPage)
    },
    SET_ADOBE_ACCESS_POINT({commit}, accessPoint) {
      commit("SET_ADOBE_ACCESS_POINT", accessPoint)
    },
    SET_ADOBE_ACCESS_TOKEN({commit}, accessToken) {
      commit("SET_ADOBE_ACCESS_TOKEN", accessToken)
    },
    SET_AES_KEY({ commit }, aesKey) {
      commit("SET_AES_KEY", aesKey);
    },
    SET_PERMISSIONS({ commit }, permissions) {
      commit("SET_PERMISSIONS", permissions);
    },
    ADD_CONSOLE_MESSAGE({ commit }, message) {
      commit("ADD_CONSOLE_MESSAGE", message);
    },
    SET_SOCKET_ID({ commit }, id) {
      commit("SET_SOCKET_ID", id);
    },
    SET_COMPANIES({ commit }, companies) {
      commit("SET_COMPANIES", companies);
    },
    SET_USERS({ commit }, users) {
      commit("SET_USERS", users);
    },
    SET_ONBOARDINGS({ commit }, onboardings) {
      commit("SET_ONBOARDINGS", onboardings);
    },
    NEW_ONBOARDING({ commit }, onboarding) {
      commit("NEW_ONBOARDING", onboarding)
    },
    UPDATE_ONBOARDING({ commit }, onboarding) {
      commit("UPDATE_ONBOARDING", onboarding)
    },
    DELETE_ONBOARDING({ commit }, onboarding) {
      commit("DELETE_ONBOARDING", onboarding)
    },
    SET_PROJECTS({ commit }, projects) {
      commit("SET_PROJECTS", projects);
    },
    NEW_PROJECT({ commit }, project) {
      commit("NEW_PROJECT", project)
    },
    UPDATE_PROJECT({ commit }, project) {
      commit("UPDATE_PROJECT", project)
    },
    DELETE_PROJECT({ commit }, project) {
      commit("DELETE_PROJECT", project)
    },
    SET_PROJECT_RATE_CARDS({ commit }, projectRateCards) {
      commit("SET_PROJECT_RATE_CARDS", projectRateCards);
    },
    NEW_PROJECT_RATE_CARD({ commit }, projectRateCard) {
      commit("NEW_PROJECT_RATE_CARD", projectRateCard)
    },
    UPDATE_PROJECT_RATE_CARD({ commit }, projectRateCard) {
      commit("UPDATE_PROJECT_RATE_CARD", projectRateCard)
    },
    DELETE_PROJECT_RATE_CARD({ commit }, projectRateCard) {
      commit("DELETE_PROJECT_RATE_CARD", projectRateCard)
    },
    LOGOUT({ commit }) {
      commit("LOGOUT");
    },
    SET_LOGIN_REDIRECT({ commit }, route) {
      commit("SET_LOGIN_REDIRECT", route);
    },
    SET_CHANGE_ONBOARDING_ID({ commit }, id) {
      commit("SET_CHANGE_ONBOARDING_ID", id);
    },
    ADD_ALERT({ commit }, alert) {
      commit("ADD_ALERT", alert);
    },
    REMOVE_ALERT({ commit }, alert) {
      commit("REMOVE_ALERT", alert);
    },
    SHOW_ALERT({ commit }, alert) {
      commit("SHOW_ALERT", alert);
    },
    HIDE_ALERT({ commit }, alert) {
      commit("HIDE_ALERT", alert);
    },
    SET_AD_ACCESS_TOKEN({ commit }, accessToken) {
      commit("SET_AD_ACCESS_TOKEN", accessToken);
    },
    SAVE_PASS({ commit }, pass) {
      commit("SAVE_PASS", pass);
    },
    SET_AD_USER({ commit }, user) {
      commit("SET_AD_USER", user);
    },
    SET_USER_INFO({ commit }, userInfo) {
      commit("SET_USER_INFO", userInfo);
    },
    SET_AD_USER_INFO({ commit }, userInfo) {
      commit("SET_AD_USER_INFO", userInfo);
    },
    SET_AD_USER_PHOTO_URL({ commit }, url) {
      commit("SET_AD_USER_PHOTO_URL", url);
    },
  },
  modules: {},
});
