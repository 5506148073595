<template>
<v-layout row wrap justify-center align-center style="margin-top: 4em;">
  <v-flex xs12>
    <user-info />
  </v-flex>
</v-layout>
</template>

<script>
import UserInfo from "@/components/Settings/UserInfo.vue";

export default {
  components: {
    UserInfo
  }
};
</script>

<style scoped>
</style>