import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import minifyTheme from 'minify-css-string';
import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset'


// suppress all vuetify logs and warnings
// Vuetify.config.silent = true;

Vue.use(Vuetify);

export default new Vuetify({
  // preset,
  // theme: {
  //   options: {
  //     minifyTheme,
  //     variations: false,
  //     themeCache: {
  //       get: key => localStorage.getItem(key),
  //       set: (key, value) => localStorage.setItem(key, value),
  //     }
  //   }
  // },
  icons: {
    iconfont: 'mdi',
  },
});
