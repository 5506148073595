import * as Msal from "@azure/msal-browser";
import store from '../store';
let AuthService = {};
AuthService.applicationConfig = {
    authority: "https://login.microsoftonline.com/4f7aeefa-21ee-4bfd-91ef-f9c7f954da3d",
    client_id: '9057869f-8f28-4d25-bd53-4493e8755bea',
    scopes: ["https://graph.microsoft.com//User.Read", "https://graph.microsoft.com//User.ReadWrite.All", "https://graph.microsoft.com//Directory.ReadWrite.All"]
};
AuthService.login = function () {
    return new Promise(function(resolve, reject) {
        AuthService.app.loginPopup(AuthService.applicationConfig.scopes)
        .then(function (data) {
            resolve(data);
        }).catch(function(error) {
            reject(error);
        });
    })
}

AuthService.silentSSO = function() {
    return new Promise((resolve, reject) => {
        if (store.state.userADInfo !== null) {
            if (store.state.userADInfo.error !== undefined) {
                if(store.state.userADInfo.error.code === 'InteractionRequiredAuthError') {
                    resolve(AuthService.login())
                }
            } else {
                AuthService.app.ssoSilent({
                    scope: AuthService.applicationConfig.scopes,
                    login_hint: store.state.userADInfo.mail
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    if (error.message.includes("Silent SSO could not be completed")) {
                        resolve(AuthService.login())
                    } else {
                        reject(error);
                    }
                })
            }
        } else {
            resolve(AuthService.login())
        }
    })
}


AuthService.logout = function () {
    return new Promise((resolve, reject) => {
        AuthService.app.logoutPopup().then(resolve()).catch(error => reject(error))
    })
    
}

AuthService.getToken = function () {
    return new Promise(function(resolve, reject) {
        if (store.state.accessToken !== null) {
            resolve(store.state.accessToken)
        } else {
            AuthService.silentSSO().then((authResponse) => resolve(authResponse.accessToken)).catch(error => reject(error));
        }
    });
}

AuthService.app = new Msal.PublicClientApplication({
    auth: {
        clientId: AuthService.applicationConfig.client_id,
        authority: AuthService.applicationConfig.authority,
        redirectUri: window.location.origin
    },
    cache: {
        storeAuthStateInCookie: true,
        cacheLocation: 'localStorage',
        secureCookies: true
    }
});

export default AuthService;