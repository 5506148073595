<template>
  <v-layout row wrap justify-center align-center v-if="$store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && company !== undefined">
    <v-flex xs12>
      <h1 style="text-align: center;">Company User Invite</h1>
    </v-flex>
    <v-flex xs12 v-if="$store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.user.invite`)">
      <v-card style="margin: 1em 1em;">
        <v-card-title>Invite a new user to your {{company.name}}.</v-card-title>
        <v-card-text>
          <v-text-field v-model="inviteEmailAddress" label="Email Address"></v-text-field>
          <v-select :disabled="inviteIsAdmin" :items="companyNavPermissions" multiple v-model="invitePermissions" label="UI Navigation Permissions"></v-select>
          <v-switch v-model="inviteIsAdmin" label="Admin" :success="inviteIsAdmin"></v-switch>
          <v-btn color="success" @click="inviteNewUser()">Send Invite</v-btn>
        </v-card-text>
      </v-card>
      <v-card style="margin: 1em 1em;">
        <v-card-title>Active Invites</v-card-title>
        <v-card-text>
          <table style="width: 100%;" v-if="company.invites.length > 0">
            <tr>
              <th>Sender</th>
              <th>Recipient</th>
              <th>Token</th>
              <th>Actions</th>
            </tr>
            <tr v-for="(invite, index) in company.invites" :key="`${invite.email}-invite-${index}`">
              <td style="text-align:center; border: 1px solid black;">
                <router-link
                  :to="{path: `/company/${company._id}/user-manage/${invite.invite_sender}`}"
                >{{invite.invite_sender}}</router-link>
              </td>
              <td style="text-align:center; border: 1px solid black;">
                <a :href="`mailto:${invite.email}`">{{invite.email}}</a>
              </td>
              <td style="text-align:center; border: 1px solid black;">{{invite.token}}</td>
              <td style="text-align:center; border: 1px solid black;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="sendInvite(invite)">
                      <v-icon color="success">mdi-email</v-icon>
                    </v-btn>
                  </template>
                  <span>Resend Invite Email</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="revokeInvite(invite, index)">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Revoke Invite</span>
                </v-tooltip>
              </td>
            </tr>
          </table>
          <div v-else>
            <h3>No invites found.</h3>
          </div>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["company", "companyNavPermissions"],
  data() {
    return {
      inviteEmailAddress: "",
      invitePermissions: [],
      inviteUUID: null,
      inviteSent: false,
      inviteIsAdmin: false
    };
  },
  methods: {
    revokeInvite(invite, index) {
      var that = this;
      that.$BackendService.company
        .revokeInvite({
          company: that.company._id,
          inviteToken: invite.token
        })
        .then(function(response) {
          that.$AlertService.newAlert({
            message: "Invite Revoked!",
            timeout: 5,
            show: true,
            color: "success"
          });
          that.$BackendService.company.addToHistory({
            event: "revokeInvite",
            status: "success",
            user_id: that.$store.state.userInfo_id,
            data: invite
          });
          that.$eventBus.$emit("getCompanies", {});
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: "Invite revoke failed!",
            timeout: 5,
            show: true,
            color: "error"
          });
          that.$BackendService.company.addToHistory({
            event: "revokeInvite",
            status: "failed",
            user_id: that.$store.state.userInfo_id,
            data: invite
          });
        });
    },
    buildInviteData() {
      var that = this;
      this.inviteUUID = this.$UUID();
      let inviteData = {
        company: this.company._id,
        invite_sender: this.$store.state.userInfo._id,
        email: this.inviteEmailAddress,
        // permissions: this.inviteIsAdmin ? [`wavedash.${that.company._id}.admin`] : this.invitePermissions.map(navItem => navItem.requiredPermissions.map(p => `wavedash.${that.company._id}.`+p)).concat(that.$store.state.navigation.requiredPermissions),
        navPermissions: this.inviteIsAdmin ? [`${this.company._id}.admin`] : this.invitePermissions.map(navItem => navItem.navPermission),
        token: this.inviteUUID
      };
      return inviteData;
    },
    inviteNewUser() {
      var that = this;
      let inviteData = this.buildInviteData();
      that
        .createInvite(inviteData)
        .then(function() {
          that.sendInvite(inviteData);
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    createInvite(inviteData) {
      var that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.company
          .invite(inviteData)
          .then(function(response) {
            that.$AlertService.newAlert({
                message: "Invite Created!",
                timeout: 5,
                show: true,
                color: "success"
              });
              that.$BackendService.company.addToHistory({
                event: "inviteCreate",
                status: "success",
                user_id: that.$store.state.userInfo_id,
                data: inviteData
              });
              resolve();
          })
          .catch(function(error) {
            that.$AlertService.newAlert({
              message: "Invite Creation Failed!",
              timeout: 5,
              show: true,
              color: "error"
            });
            that.$BackendService.company.addToHistory({
              event: "inviteCreate",
              status: "failed",
              user_id: that.$store.state.userInfo_id,
              data: inviteData
            });
            reject(error);
          });
      });
    },
    sendInvite(inviteData) {
      var that = this;
      let emailData = {
        from: {
          name: "Cognitus CRM",
          email: "crm@cognitusconsulting.com"
        },
        to: `${inviteData.email}`,
        cc: `${that.$store.state.userInfo.cognitus_email_address}`,
        subject: "Cognitus Surfboard - You have been invited to a company.",
        plain: `${that.$store.state.userInfo.cognitus_email_address} has invited you to ${that.company.name} in Cognitus' Surfboard!\n\nhttps://surfboard.cognitusconsulting.com/company/${inviteData.company}/join/${inviteData.token}\nCompany ID: ${inviteData.company}\nInvite Token: ${inviteData.token}`,
        html: `<h3>${that.$store.state.userInfo.cognitus_email_address} has invited you to ${that.company.name} in Cognitus' Surfboard!</h3><br/><br/><h1><a href="https://surfboard.cognitusconsulting.com/company/${inviteData.company}/join/${inviteData.token}">Join Surfboard</a></h1><br/><h4>Company ID: ${inviteData.company}</h4><br/><h4>Invite Token: ${inviteData.token}</h4>`
      };
      that.$BackendService.company.email
        .send({company: inviteData.company, emailData})
        .then(function(response) {
          that.$AlertService.newAlert({
            message: "Invite Email Sent!",
            timeout: 5,
            show: true,
            color: "success"
          });
          that.$BackendService.company.addToHistory({
            event: "inviteEmailSend",
            status: "success",
            user_id: that.$store.state.userInfo_id,
            data: emailData
          });
          that.$eventBus.$emit("getCompanies", {});
        })
        .catch(function(error) {
          console.error(error);
          that.$AlertService.newAlert({
            message: "Invite Email Failed!",
            timeout: 5,
            show: true,
            color: "error"
          });
          that.$BackendService.company.addToHistory({
            event: "inviteEmailSend",
            status: "failed",
            user_id: that.$store.state.userInfo_id,
            data: emailData
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>