import store from '../store/index.js';
import BackendService from './backend.service.js';
let ProjectService = {
    updateProject: function(data) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.project.update(data).then(function (updatedProject) {
                  resolve(updatedProject);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    deleteProject: function(data) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.project.delete(data).then(function (deleteProject) {
                  resolve(deleteProject);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    createProject: function(data) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.project.create(data).then(function (createdProject) {
                  resolve(createdProject);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    getProjects: function ({company_id}) {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.project.getAll({
                    company_id: company_id
                }).then(function (projects) {
                    store.dispatch("SET_PROJECTS", projects.docs);
                    resolve(projects);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }

        })
    },
    getProject: function ({company_id, project_id}) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.project.get({
                  company_id: company_id,
                  project_id: project_id
              }).then(function (project) {
                  if (project !== null) {
                    store.dispatch('SET_PROJECT', project)
                  }
                  resolve(project);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }

      })
    },
    search: function ({company_id, searchQuery}) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.project.search({
                  company_id: company_id,
                  searchQuery: searchQuery
              }).then(function (project) {
                  if (project !== null) {
                    store.dispatch('SET_PROJECT', project)
                  }
                  resolve(project);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }

      })
    },
}
export default ProjectService;