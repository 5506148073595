<template>
  <v-layout justify-center align-center row wrap>
    <v-flex
      xs11
      style="margin: 0 .8em 2em .8em;"
      v-if="onboardingData !== undefined && onboardingData !== null"
    >
      <v-stepper v-model="onboardingData.step">
        <v-stepper-header>
          <v-stepper-step :complete="onboardingData.step > 1" step="1"
            >Basic User Data</v-stepper-step
          >

          <v-divider></v-divider>

          <v-stepper-step :complete="onboardingData.step > 2" step="2">
            {{
              onboardingData.employee
                ? "Employee"
                : onboardingData.contractor
                ? "Contractor"
                : "Employee or Contractor"
            }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="onboardingData.step > 3" step="3"
            >Configuration</v-stepper-step
          >
          <v-stepper-step :complete="onboardingData.step > 4" step="4"
            > {{ onboardingData.contractor ? "Vendor Address" : "Address" }}</v-stepper-step
          >
          <v-stepper-step :complete="onboardingData.step > 5" step="5">
           Confirmation
          </v-stepper-step>
          <!-- <v-stepper-step
            v-if="onboardingData.contractor"
            :complete="onboardingData.step > 6"
            step="6"
            >PDF Signing</v-stepper-step
          > -->
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <Step1
              :step="1"
              :data="onboardingData"
              :company="company"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardings="onboardings"
              @updateData="updateData"
            />
            <span>
              <v-btn
                color="primary"
                :disabled="!stepsOkay[onboardingData.step - 1]"
                @click.stop="nextStep()"
                >Continue</v-btn
              >
            </span>
            <!-- <v-btn text @click.stop="goBack()">Go Back</v-btn> -->
          </v-stepper-content>

          <v-stepper-content step="2">
            <Step2
              :step="2"
              :data="onboardingData"
              :company="company"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardings="onboardings"
              @updateData="updateData"
            />
            <span
              v-if="
                onboardingData.flowApprovalStatus === 'Approved' ||
                  onboardingData.flowApprovalStatus === 'Requested'
              "
            >
              <v-btn @click.stop="nextStep()" color="primary">Continue</v-btn>
            </span>
            <span v-else>
              <v-btn
                color="primary"
                :disabled="!stepsOkay[onboardingData.step - 1]"
                @click.stop="nextStep()"
                >Continue</v-btn
              >
            </span>
            <v-btn class="ml-3" color="error" @click.stop="goBack()"
              >Go Back</v-btn
            >
          </v-stepper-content>

          <v-stepper-content step="3">
            <Step3
              :step="3"
              :data="onboardingData"
              :company="company"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardings="onboardings"
              @updateData="updateData"
            />
            <v-btn
              color="primary"
              :disabled="!stepsOkay[onboardingData.step - 1]"
              @click.stop="nextStep()"
              >Continue</v-btn
            >

            <v-btn
              style="margin-left: 1em;"
              color="error"
              @click.stop="goBack()"
              >Go Back</v-btn
            >
          </v-stepper-content>

          <v-stepper-content step="5">
            <Step5
              :step="5"
              :data="onboardingData"
              :company="company"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardings="onboardings"
              @updateData="updateData"
            />
            <v-btn
              v-if="!$route.path.includes('onboarding-continue')"
              @click.stop="sendForApproval()"
              :color="
                startFlowApproval
                  ? 'warning'
                  : sentForApproval
                  ? 'success'
                  : notSentForApproval
                  ? 'error'
                  : 'primary'
              "
              :loading="startFlowApproval"
              :disabled="
                !stepsOkay[onboardingData.step - 1] ||
                  sentForApproval ||
                  (onboardingData.flowApprovalStatus === 'Approved' &&
                    !onboardingData.changeRequest)
              "
            >
              {{
                sentForApproval
                  ? "Approval Started"
                  : notSentForApproval
                  ? "Failed to Start Approval"
                  : onboardingData.flowApprovalStatus === "Approved" &&
                    !onboardingData.changeRequest
                  ? "Flow Already Approved"
                  : onboardingData.changeRequest
                  ? "Save and Start Change Request"
                  : "Save and Start Flow Approval"
              }}
              <v-icon v-if="sentForApproval">mdi-check</v-icon>
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="!$route.path.includes('onboarding-continue')"
                  style="margin-left: 1em;"
                  @click="save()"
                  :loading="saving"
                  :color="
                    saving
                      ? 'warning'
                      : finishedSave && saved
                      ? 'success'
                      : finishedSave && !saved
                      ? 'error'
                      : 'light-primary'
                  "
                  :disabled="
                    startFlowApproval || onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                    !$store.state.userInfo.navPermissions.includes(
                      `${company._id}.admin`
                    )
                  "
                >
                  Only save to DB
                  <v-icon v-on="on" v-if="!finishedSave">
                    {{
                      "mdi-lock" +
                      $store.state.userInfo.navPermissions.includes(
                        `${company._id}.admin`
                      )
                        ? "-open"
                        : ""
                    }}
                  </v-icon>
                  <v-icon v-else>mdi-{{ saved ? "check" : "cross" }}</v-icon>
                </v-btn>
              </template>
              Admin Permission Bypass
            </v-tooltip>
            <br />
            <br />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :disabled="
                    (onboardingData.changeRequest ||
                      onboardingData.flowApprovalStatus !== 'Approved' ||
                      onboardingData.jiraIssueStatus !== 'Done' ||
                      !onboardingData.readyForOnboardedUserInput ||
                      (onboardingData.cognitusEmailMailbox +
                        onboardingData.cognitusEmailDomain !==
                        $store.state.userInfo.cognitus_email_address &&
                        $store.state.userInfo.cognitus_email_address !==
                          onboardingData.requester)) &&
                      !$store.state.userInfo.navPermissions.includes(
                        `${company._id}.admin`
                      )
                  "
                  @click.stop="nextStep()"
                  color="primary"
                >
                  Continue
                  <v-icon
                    v-if="!$route.path.includes('onboarding-continue')"
                    v-on="on"
                  >
                    mdi-lock{{
                      $store.state.userInfo.navPermissions.includes(
                        `${company._id}.admin`
                      )
                        ? "-open"
                        : ""
                    }}
                  </v-icon>
                </v-btn>
              </template>
              Admin Permission Bypass
            </v-tooltip>

            <v-btn
              v-if="!$route.path.includes('onboarding-continue')"
              style="margin-left: 1em;"
              :color="sentForApproval ? 'warning' : 'error'"
              :disabled="
                (onboardingData.flowApprovalStatus === 'Approved' ||
                  onboardingData.flowApprovalStatus === 'Requested' ||
                  !onboardingData.changeRequest) &&
                  !$store.state.userInfo.navPermissions.includes(
                    `${company._id}.admin`
                  )
              "
              @click.stop="goBack()"
              >Go Back</v-btn
            >
            <v-btn
              v-if="sentForApproval && dialog"
              style="margin-left: 1em;"
              color="error"
              @click.stop="() => { $emit('closeDialog'); $emit('updateOnboarding'); }"
              >Close</v-btn
            >
          </v-stepper-content>

          <v-stepper-content step="4">
            <Step4
              :step="4"
              :data="onboardingData"
              :company="company"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardings="onboardings"
              @updateData="updateData"
            />

            <v-btn
              color="primary"
              :disabled="!stepsOkay[onboardingData.step - 1]"
              @click.stop="nextStep()"
              >Continue</v-btn
            >
            <!-- <v-btn
              v-else-if="onboardingData.employee"
              color="primary"
              :disabled="!stepsOkay[onboardingData.step - 1]"
              @click.stop="
                finishOnboarding();
                nextStep();
              "
              >Finish</v-btn
            > -->
            <v-btn
              style="margin-left: 1em;"
              color="error"
              :disabled="
                (onboardingData.flowApprovalStatus === 'Approved' ||
                  onboardingData.flowApprovalStatus === 'Requested') &&
                  !$store.state.userInfo.navPermissions.includes(
                    `${company._id}.admin`
                  )
              "
              @click.stop="goBack()"
              >Go Back</v-btn
            >
          </v-stepper-content>

          <!-- <v-stepper-content step="6" v-if="onboardingData.contractor">
            <Step6
              :step="6"
              :data="onboardingData"
              :company="company"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardings="onboardings"
              @updateData="updateData"
            />

            <v-btn
              color="primary"
              :disabled="!stepsOkay[onboardingData.step - 1]"
              @click.stop="
                finishOnboarding();
                nextStep();
              "
              >Finish</v-btn
            >
            <v-btn
              style="margin-left: 1em;"
              color="error"
              @click.stop="goBack()"
              >Go Back</v-btn
            >
          </v-stepper-content> -->
        </v-stepper-items>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  diff,
  addedDiff,
  deletedDiff,
  updatedDiff,
  detailedDiff,
} from "deep-object-diff";
import  * as jd  from 'json-delta'
import Step1 from "@/components/Company/Onboarding/FormSteps/Step1.vue";
import Step2 from "@/components/Company/Onboarding/FormSteps/Step2.vue";
import Step3 from "@/components/Company/Onboarding/FormSteps/Step3.vue";
import Step4 from "@/components/Company/Onboarding/FormSteps/Step4.vue";
import Step5 from "@/components/Company/Onboarding/FormSteps/Step5.vue";
import Step6 from "@/components/Company/Onboarding/FormSteps/Step6.vue";
export default {
  props: [
    "formTemplate",
    "onboardingFormData",
    "onboardings",
    "countries",
    "onboarding_id",
    "company",
    "dialog",
  ],
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    // Step6,
  },
  watch: {
    onboardingFormData(val) {
      var that = this;
      if (val !== undefined && val !== null) {
        // console.log()
        this.approvalSent = false;
        this.onboardingFormDataCopy = JSON.parse(
          JSON.stringify(that.onboardingFormData)
        );
      }
    },
  },
  created() {
    var that = this;
    this.$eventBus.$on("stepOkay", function(data) {
      that.stepsOkay[data.step - 1] = data.valid;
      that.$forceUpdate();
    });
    this.onboardingFormDataCopy = JSON.parse(
      JSON.stringify(that.onboardingFormData)
    );

    if (
      (this.onboardingFormDataCopy.onboarding_id === "" ||
        this.onboardingFormDataCopy.onboarding_id === null) &&
      this.onboarding_id !== undefined &&
      this.onboarding_id !== null &&
      this.onboarding_id !== ""
    ) {
      this.onboardingFormDataCopy.onboarding_id = this.onboarding_id;
    }
    if (
      this.onboardingFormDataCopy.requester === undefined ||
      this.onboardingFormDataCopy.requester === "" ||
      this.onboardingFormDataCopy.requester === null
    ) {
      this.onboardingFormDataCopy.requester = this.$store.state.userInfo.cognitus_email_address;
    }
    if (
      (this.onboardingFormDataCopy.company_id === "" ||
        this.onboardingFormDataCopy.company_id === null) &&
      this.company !== undefined
    ) {
      this.onboardingFormDataCopy.company_id = this.company._id;
    }
  },
  data() {
    return {
      stepsOkay: [false, false, false, false, false, false],
      onboardingFormDataCopy: null,
      approvalSent: false,
      approvalNotSent: false,
      finishedSaveTimeout: null,
      finishedSave: false,
      saved: false,
      saving: false,
      startFlowApproval: false,
    };
  },
  computed: {
    onboardingData() {
      return this.onboardingFormDataCopy;
    },
    sentForApproval() {
      return this.approvalSent;
    },
    notSentForApproval() {
      return this.approvalNotSent;
    },
  },
  methods: {
    updateData(data, step, doStep, callback) {
      if (this.onboardingData.step === step) {
        this.onboardingFormDataCopy = JSON.parse(JSON.stringify(data));
        if (doStep) this.onboardingFormDataCopy.step += 1;
        if (callback) {
          callback();
        }
        this.$forceUpdate();
      } else {
        console.log(this.onboardingData.step, step);
        console.log('wrong step');
      }
    },
    goBack() {
      this.onboardingFormDataCopy.step--;
      if (this.onboardingFormDataCopy.step <= 0) {
        this.onboardingFormDataCopy.step = 1;
        let that = this;
        this.$router.push({
          path: `/company/${that.company._id}/onboarding`,
        });
      }
    },
    nextStep() {
      this.$eventBus.$emit("nextStep", this.onboardingData.step);
      this.$forceUpdate();
    },
    finishOnboarding() {
      let that = this;
      this.$BackendService.onboarding
        .finish(that.onboardingFormDataCopy)
        .then(function(response) {
          that.$AlertService.newAlert({
            message: "Onboarding Data sent to the server.",
            timeout: 5,
            color: "success",
            show: true,
          });
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: "An error occurred.",
            timeout: 5,
            color: "error",
            show: true,
          });
          console.error(error);
        });
    },
    save() {
      let that = this;
      that.saving = true;
      that.saved = false;

      if (that.finishedSaveTimeout) {
        clearTimeout(that.finishedSaveTimeout);
      }
      this.$eventBus.$emit('dataUpdate', () => {
        let data = JSON.parse(JSON.stringify(that.onboardingFormDataCopy));
        data = that.fixData(data);
        if (data.changeRequest) {
          let diff = jd.diff(data, data.history[data.history.length - 1]);
          data.history[data.history.length - 1] = diff;
        } else {
          data.history = [];
        }
        if (data.changeRequest) {
          that.$BackendService.onboarding
          .change(data)
          .then(function(response) {
            that.$store.dispatch("UPDATE_ONBOARDING", response)
            that.$emit("updateOnboarding")
            that.finishedSave = true;
            that.finishedSaveTimeout = setTimeout(function() {
              that.finishedSave = false;
            }, 1000);
            that.saved = true;
            that.saving = false;
            setTimeout(() => {
              that.$emit('closeDialog');
            }, 1000);
          })
          .catch(function(error) {
            that.finishedSave = true;
            that.finishedSaveTimeout = setTimeout(function() {
              that.finishedSave = false;
            }, 1000);
            that.saving = false;
            that.saved = false;
            console.error(error);
          });
        } else {
          that.$BackendService.onboarding
            .new(data)
            .then(function(response) {
              that.$store.dispatch("UPDATE_ONBOARDING", response)
              that.$emit("updateOnboarding")
              that.finishedSave = true;
              that.finishedSaveTimeout = setTimeout(function() {
                that.finishedSave = false;
              }, 1000);
              that.saved = true;
              that.saving = false;
              setTimeout(() => {
                that.$emit('closeDialog');
              }, 1000);
            })
            .catch(function(error) {
              that.finishedSave = true;
              that.finishedSaveTimeout = setTimeout(function() {
                that.finishedSave = false;
              }, 1000);
              that.saving = false;
              that.saved = false;
              console.error(error);
            });
        }
      })
    },
    resetOnboardingFormDataTemplate() {
      return JSON.parse(JSON.stringify(this.formTemplate));
    },
    fixData(data) {
      // console.log(data);
      let defaultTemplate = this.resetOnboardingFormDataTemplate();
      Object.keys(defaultTemplate).forEach(function(key) {
        if (
          (data[key] === undefined || data[key] === null || data[key] === "") &&
          typeof data[key] !== typeof defaultTemplate[key]
        ) {
          data[key] = defaultTemplate[key];
        }
        if (data.history.length > 0) {
          data.history.forEach(function(history) {
            if (
              (history[key] === undefined ||
                history[key] === null ||
                history[key] === "") &&
              typeof history[key] !== typeof defaultTemplate[key] &&
              key !== "date_time_onboarded" &&
              key !== "history"
            ) {
              history[key] = defaultTemplate[key];
            }
          });
        }
      });
      return data;
    },
    changeRequestFlowApproval(d) {
      var that = this;
      let data = this.fixData(d);

      if (data.changeRequest) {
        let diff = jd.diff(data, data.history[data.history.length - 1]);
        data.history[data.history.length - 1] = diff;
      } else {
        data.history = [];
      }
      data.flowApprovalStatus = "";
      data.jiraIssueStatus = "";
      this.$BackendService.onboarding
        .change(data)
        .then(function(response) {
          that.$store.dispatch("UPDATE_ONBOARDING", response)
          that.$BackendService.flow.approval
              .new(data)
              .then(function() {
                that.$AlertService.newAlert({
                  message: `Onboarding Change - Flow Approval Request Sent - ${data.firstName +
                    " " +
                    data.lastName}`,
                  timeout: 5,
                  show: true,
                  color: "success",
                });
                that.approvalSent = true;
                that.approvalNotSent = false;
                that.startFlowApproval = false;
                setTimeout(() => {
                  that.$emit('closeDialog');
                  that.$emit('updateOnboarding');
                }, 1000);
              })
              .catch(function(error) {
                that.$AlertService.newAlert({
                  message: `Onboarding Change - Flow Approval Request Failed - ${data.firstName +
                    " " +
                    data.lastName}`,
                  timeout: 5,
                  show: true,
                  color: "error",
                });
                that.approvalSent = false;
                that.approvalNotSent = true;
                that.startFlowApproval = false;
                console.error(error);
              });
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: `Onboarding Change - Failed - ${data.firstName +
              " " +
              data.lastName}`,
            timeout: 5,
            show: true,
            color: "error",
          });
          that.approvalSent = false;
          that.approvalNotSent = true;
          that.startFlowApproval = false;
          console.error(error);
        });
    },
    flowApproval(data) {
      var that = this;
      data = this.fixData(data);
      data.flowApprovalStatus = "";
      data.jiraIssueStatus = "";
      this.$BackendService.onboarding
        .new(data)
        .then(function(response) {
          that.$store.dispatch("UPDATE_ONBOARDING", response)
          // console.log(response);
          that.$BackendService.flow.approval
            .new(data)
            .then(function(result) {
              that.$AlertService.newAlert({
                message: `Onboarding New - Flow Approval Request Sent - ${data.firstName +
                  " " +
                  data.lastName}`,
                timeout: 5,
                show: true,
                color: "success",
              });
              that.approvalSent = true;
              that.approvalNotSent = false;
              that.startFlowApproval = false;
              setTimeout(() => {
                that.$emit('closeDialog');
                that.$emit('updateOnboarding');
              }, 1000);
            })
            .catch(function(error) {
              that.$AlertService.newAlert({
                message: `Onboarding New - Flow Approval Request Failed - ${data.firstName +
                  " " +
                  data.lastName}`,
                timeout: 5,
                show: true,
                color: "error",
              });
              that.approvalSent = false;
              that.approvalNotSent = true;
              that.startFlowApproval = false;
              console.error(error);
            });
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: `Onboarding New - Failed - ${data.firstName +
              " " +
              data.lastName}`,
            timeout: 5,
            show: true,
            color: "error",
          });
          that.approvalSent = false;
          that.approvalNotSent = true;
          that.startFlowApproval = false;
          console.error(error);

        });
    },
    sendForApproval() {
      let that = this;
      if (!this.startFlowApproval) {
        if (
          !(
            this.onboardingFormDataCopy.date_time_onboarded instanceof Date &&
            !isNaN(this.onboardingFormDataCopy.date_time_onboarded)
          )
        ) {
          this.onboardingFormDataCopy.date_time_onboarded = Date.now();
        }
        this.startFlowApproval = true;
        this.approvalSent = false;
        this.approvalNotSent = false;
        this.$eventBus.$emit('dataUpdate', () => {
          if (that.onboardingFormDataCopy.date_time_onboarded) {
            if (that.onboardingFormDataCopy.changeRequest) {
              that.changeRequestFlowApproval(that.onboardingFormDataCopy);
            } else {
              that.flowApproval(that.onboardingFormDataCopy);
            }
          }
          that.$emit("updateOnboarding")
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
