<template>
  <v-layout
    row
    wrap
    justify-center
    align-center
    style="background-color: rgba(100, 200, 100, .1); margin: 10px 0;"
    v-if="itemCopy !== undefined && itemCopy !== null"
  >
    <v-flex xs11>
      <h1>Form Item Settings</h1>
      <div v-if="itemCopy.data.type === 'text'">
        Label:
        <v-text-field v-model="itemCopy.data.settings.label"></v-text-field>Default Value:
        <v-text-field v-model="itemCopy.data.settings.value"></v-text-field>
      </div>
      <div v-else-if="itemCopy.data.type === 'boolean'">
        Label:
        <v-text-field v-model="itemCopy.data.settings.label"></v-text-field>Default Value:
        <v-checkbox v-model="itemCopy.data.settings.value" :label="itemCopy.data.settings.label" />
      </div>
    </v-flex>
    <v-flex xs11>
      <v-btn
        :disabled="itemCopy.data.settings.value === item.data.settings.value && itemCopy.data.settings.label === item.data.settings.label"
        color="success"
        @click="$emit('saveModifiedChange', itemCopy)"
      >Save Changes</v-btn>
      <v-btn class="ml-3" color="error" @click="$emit('cancelChange')">Cancel</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      itemCopy: null
    };
  },
  props: ["item"],
  watch: {
    item(val) {
      // console.log(val);
      if (val !== undefined && val !== null) {
        if (this.itemCopy === null || val.id !== this.itemCopy.id) {
          this.itemCopy = JSON.parse(JSON.stringify(val));
        }
      }
    }
  },
  mounted() {
    if (this.item !== undefined && this.item !== null) {
      if (this.itemCopy === null || this.item.id !== this.itemCopy.id) {
        this.itemCopy = JSON.parse(JSON.stringify(this.item));
      }
    }
  }
};
</script>

<style scoped>
</style>