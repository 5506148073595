<template>
  <v-tooltip
    :bottom="settings.tooltip === 'bottom'"
    :top="settings.tooltip === 'top'"
    :left="settings.tooltip === 'left'"
    :right="settings.tooltip === 'right'"
  >
    <template v-slot:activator="{ on }">
      <ZCC-CheckBox
        v-on="{ on }"
        v-if="data.id === 'zcc-checkbox'"
        :settings="data.settings"
      />
      <ZCC-Choice
        v-on="{ on }"
        v-else-if="data.id === 'zcc-choice'"
        :settings="data.settings"
      />
      <ZCC-Date
        v-on="{ on }"
        v-else-if="data.id === 'zcc-date'"
        :settings="data.settings"
      />
      <ZCC-TextField
        v-on="{ on }"
        v-else-if="data.id === 'zcc-textfield'"
        :settings="data.settings"
      />
      <ZCC-Select
        v-on="{ on }"
        v-else-if="data.id === 'zcc-select'"
        :settings="data.settings"
      />
      <ZCC-TextArea
        v-on="{ on }"
        v-else-if="data.id === 'zcc-textarea'"
        :settings="data.settings"
      />
    </template>
    {{ data.title }}
  </v-tooltip>
</template>

<script>
import ZCC_Select from "@/components/Company/Onboarding/Form/Items/Select/ZCC_Select.vue";
import ZCC_Choice from "@/components/Company/Onboarding/Form/Items/Select/ZCC_Choice.vue";
import ZCC_CheckBox from "@/components/Company/Onboarding/Form/Items/Select/ZCC_CheckBox.vue";
import ZCC_TextArea from "@/components/Company/Onboarding/Form/Items/Text/ZCC_TextArea.vue";
import ZCC_TextField from "@/components/Company/Onboarding/Form/Items/Text/ZCC_TextField.vue";
import ZCC_Date from "@/components/Company/Onboarding/Form/Items/Time/ZCC_Date.vue";

export default {
  props: ["data", "settings"],
  components: {
    "ZCC-Select": ZCC_Select,
    "ZCC-TextArea": ZCC_TextArea,
    "ZCC-TextField": ZCC_TextField,
    "ZCC-Choice": ZCC_Choice,
    "ZCC-CheckBox": ZCC_CheckBox,
    "ZCC-Date": ZCC_Date
  }
};
</script>

<style scoped></style>
