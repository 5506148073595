<template>
  <v-layout
    v-if="$store.state.userADInfo !== null && $store.state.userInfo !== null"
    row
    wrap
    justify-center
    align-center
    style="margin: 2em 0;"
  >
    <v-flex xs10>
      <v-card>
        <v-card-title>
          <h1>User Info</h1>
        </v-card-title>
        <v-card-text>
          <br />
          <span v-for="(setting, index) in computedSettings" :key="'setting-'+index">
            <v-text-field
              v-model="setting.edit"
              :label="setting.key.split('_').map(x => x[0].toUpperCase() + x.substr(1)).join(' ')"
            >
              <template v-slot:append-outer>
                <v-btn
                  :disabled="setting.edit === setting.value"
                  color="error"
                  @click="cancelEdit(setting, index);"
                  style="border-radius: 0 0 0 0;"
                  icon
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
                <v-btn
                  :disabled="setting.edit === setting.value"
                  color="success"
                  @click="saveEdit(setting, index);"
                  style="border-radius: 0 1em 1em 0;"
                  icon
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <br />
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!computedSettings.some(function(setting) { return setting.edit !== setting.value})"
            color="success"
          >Save All</v-btn>
          <v-btn
            :disabled="!computedSettings.some(function(setting) { return setting.edit !== setting.value})"
            color="error"
          >Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      settings: []
    };
  },
  mounted() {
    this.generateSettings();
  },
  computed: {
    computedSettings() {
      return this.settings;
    }
  },
  methods: {
    generateSettings() {
      var that = this;
      let userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo));
      this.settings = Object.keys(userInfo)
        .map(function(key) {
          if (
            [
              "first_name",
              "last_name",
              "cognitus_email_address",
              "mobile_phone",
              "job_title"
            ].some(x => x === key)
          )
            return that.mapKeyToValueJSON(userInfo, key);
          return null;
        })
        .filter(x => x !== null);
    },
    mapKeyToValueJSON(json, key) {
      let value = json[key];

      return {
        key: key,
        value: value,
        edit: value,
        editMode: false,
        editButtonHover: false
      };
    },
    cancelEdit(setting, index) {
      this.settings[index].edit = this.settings[index].value;
    },
    saveEdit(setting, index) {
      this.settings[index].value = this.settings[index].edit;
      let updatedUserInfo = this.rebuiltUserInfo();
      let that = this;
      this.$BackendService.user
        .update(updatedUserInfo)
        .then(function() {
          that.$AlertService.newAlert({
            color: "success",
            timeout: 5,
            show: true,
            message: "Updated user info."
          });
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            color: "error",
            timeout: 4,
            show: true,
            message: "Failed to update user info."
          });
        });
    }
  }
};
</script>

<style scoped>
.editable-text {
  border: 0.15em dashed gray;
}
</style>