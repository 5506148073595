<template>
  <div></div>
</template>

<script>
export default {
  props: ["settings"]
};
</script>

<style scoped>
</style>