<template>
  <v-layout row wrap justify-center align-center v-if="$store.state.userInfo !== undefined && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && $store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.onboarding.email.compose`)" >
    <v-flex xs10>
      <h1 style="text-align: center;">Email Composer {{document !== null && document.title !== '' ? `- ${document.title}` : ''}}</h1>
    </v-flex>
    <v-flex xs10 v-if="document === null">
      <v-layout row wrap justify-center align-center v-if="templates.length > 0">
        <v-flex xs6>
          <span v-if='loadingTemplates'>Loading Templates...</span>
          <span v-else>Select a template</span>
        </v-flex>
        <v-flex :xs6="$vuetify.breakpoint.lgAndUp" :xs10="$vuetify.breakpoint.mdAndDown" ma-1 v-for="(template, index) in templates" :key="`template-${index}-${template._id}`">
          <v-card @click="documentSelector = template.value" style="cursor: pointer;">
            <v-card-title>
              <span style="text-align: center;">
                {{template.value.title}}
                </span>
            </v-card-title>
          </v-card>
        </v-flex>
        <v-flex xs6 ma-1>
          <v-card style="cursor: pointer;" color="rgba(0, 200, 0, .5)" @click="createTemplate()">
            <v-card-title>
              <v-icon>mdi-plus</v-icon> New Template
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center align-center v-else>
        <span v-if='loadingTemplates'>Loading Templates...</span>
      </v-layout>
    </v-flex>
    <br>
    <br>
    <v-flex xs10 v-if="document !== null" style="margin: 0 0 3em 0;">
      <!-- <v-select v-model="documentSelector" :items="templates" label="Template Selection"></v-select> -->
      <v-text-field label="Template Title" v-model="document.title"></v-text-field>
      <v-text-field label="Email Subject" v-model="document.subject"></v-text-field>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
      <v-btn v-on="on" class="ma-1" color="success" fab @click="saveTemplate()"><v-icon>mdi-content-save</v-icon></v-btn>

        </template>
        Save Template
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
      <v-btn v-on="on" class="ma-1" color="warning" fab @click="clearTemplate(true)"><v-icon>mdi-format-clear</v-icon></v-btn>

        </template>
        Clear Template
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
      <v-btn v-on="on" class="ma-1" color="error" fab @click="deleteTemplate()"><v-icon>mdi-delete</v-icon></v-btn>

        </template>
        Delete Template
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
      <v-btn v-on="on" class="ma-1" color="error" fab @click="closeTemplate()"><v-icon>mdi-close</v-icon></v-btn>

        </template>
        Close Template
      </v-tooltip>
      
    </v-flex>
    <v-flex xs10 v-show="document !== null">
      <div id="gjs">
        <mjml>
          <mj-body>
            <mj-section>
              <mj-column>
                <mj-text>Welcome</mj-text>
              </mj-column>
            </mj-section>
          </mj-body>
        </mjml>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import "grapesjs/dist/css/grapes.min.css";
import grapesjs from "grapesjs";
import grapesJSMJML from "grapesjs-mjml";


export default {
  props: ["company"],
  data() {
    return {
      gEditor: null,
      templates: [],
      document: null,
      documentSelector: null,
      loadingTemplates: false,
      documentTemplate: {
        title: "",
        _id: "",
        html: "",
        mjml: "",
        lastEditDateTime: "",
        composer: "",
        company: "",
        subject: ""
      }
    };
  },
  watch: {
    documentSelector(val) {
      let that = this;
      if (val !== null) {
        if (this.document !== null && val._id !== this.document._id && confirm("Save changes to current document?")) {
          Promise.all(that.saveTemplate()).then(function() {
              that.loadTemplate(val);
            });
        }else {
          that.loadTemplate(val);
        }
      }
    }
  },
  methods: {
    clearTemplate(dialog = false) {
      if(dialog) {
        if (!confirm(`Are you sure you want to clear?`)) {
          return;
        }
      }
      this.gEditor.setComponents(`<mjml>
            <mj-body>
              <mj-section>
                <mj-column>
                  <mj-text>Welcome</mj-text>
                </mj-column>
              </mj-section>
            </mj-body>
          </mjml>`);
    },
    loadTemplates() {
      let that = this;
      if (!this.loadingTemplates) {
        that.loadingTemplates = true;
        this.$BackendService.company.email.template.getAll(that.company).then(function(templates) {
          that.templates = templates.docs.map(doc => {
            return {
              text: doc.title,
              value: doc
            }
          });
          that.loadingTemplates = false;
        }).catch(function(error) {
          console.error(error);
        })
      }
      
    },
    deleteTemplate() {
      let that = this;
      if (confirm(`Are you sure you want to delete '${that.document.title}' email template?`)) {
        this.$BackendService.company.email.template.delete(that.document).then(function(deleted) {
          that.templates.splice(that.templates.indexOf(that.templates.filter(function(doc) {
            return doc._id === that.document._id
          })[0]), 1);
          that.clearEditor();
        }).catch(function(error) {
          console.error(error);
        })
      }
      
    },
    loadTemplate(document) {
      this.document = document;
      if (document.mjml !== '') {
        this.gEditor.DomComponents.getWrapper().set('content', '');
        this.gEditor.setComponents(document.mjml.trim());
      } else {
        this.clearTemplate();
      }
      this.$forceUpdate();
    },
    saveTemplate() {
      let that = this;
      let mjml = that.gEditor.getHtml();
      this.$BackendService.mjml.toHtml(mjml).then(function(res) {
        // console.log(res);
        let doc = JSON.parse(JSON.stringify(that.document || that.documentTemplate));
        if (doc.title === "") {
          doc.title = "No name -- "+that.$UUID().split('-')[0]
        }
        doc.html = res.html;
        doc.mjml = mjml;
        doc._id = doc._id !== '' ? doc._id : that.$UUID();
        doc.company = that.company._id;
        doc.composer = that.$store.state.userADInfo.id;
        doc.lastEditDateTime = new Date();
        that.document = doc;
        that.$BackendService.company.email.template.save(doc).then(function(saveRes) {
          // console.log("savedDoc: " + saveRes);
          that.$AlertService.newAlert({
            message: "Saved Email Template",
            color: "success",
            timeout: 5,
            show: true
          })
          that.loadTemplates();
        }).catch(function(err) {
          that.$AlertService.newAlert({
            message: "Failed to save Email Template",
            color: "error",
            timeout: 5,
            show: true
          })
          console.error(err);
        })
      }).catch(function(error) {
        that.$AlertService.newAlert({
            message: "Failed to convert MJML to HTML",
            color: "error",
            timeout: 5,
            show: true
          })
        console.error(error);
      })
      // console.log(mjml2html(this.gEditor.getHtml()))
      // that.$BackendService.email.template.save()
    },
    createTemplate() {
      let that = this;
      this.documentSelector = JSON.parse(JSON.stringify(that.documentTemplate))
    },
    closeTemplate() {
      if (confirm(`Lose unsaved changes?`)) {
        this.document = null
        this.documentSelector = null
      }
    },
    change() {
      let that = this;
      this.$emit('change', that.gEditor.getHtml());
    }
  },
  mounted() {
    let that = this;
    this.loadTemplates();
    this.gEditor = grapesjs.init({
      container: "#gjs",
      plugins: [grapesJSMJML],
      pluginsOpts: {
        [grapesJSMJML]: {
          /* ...options */
        }
      },
      // plugins: ['grapesjs-mjml'],
      // pluginsOpts: {
      //   ['grapesjs-mjml']: {}
      // }
    });
    this.clearTemplate();
    this.gEditor.on('change', that.change);
  }
};
</script>

<style lang="scss" scoped></style>
