<template>
  <v-flex xs11>
    <v-layout
      justify-center
      align-center
      row
      wrap
      v-if="$store.state.userInfo !== null"
    >
      <v-flex xs12>
        <v-layout justify-center align-center row wrap>
          <v-flex>
            <v-layout v-if="$store.state.userInfo.navPermissions.includes(`${company._id}.admin`)">
              <v-flex>
                <onboarding-legacy
                  v-if="path === 'legacy'"
                  :company="company"
                  :formTemplate="onboardingFormTemplateData"
                  :onboardings="onboardings"
                />
                <form-builder v-else-if="path === 'form-builder'" />
                <form-viewer v-else-if="path === 'form-viewer'" />
                <onboarding-profile
                  v-else
                  :onboardings="onboardings"
                  :onboarding_id="onboarding_id"
                  :countries="$store.state.countries"
                  :onboardingFormData="onboardingFormData"
                  :projects="projects"
                  :projectRateCards="projectRateCards"
                  :formTemplate="onboardingFormTemplateData"
                  :company="company"
                  :path="path"
                />
              </v-flex>
            </v-layout>
            <v-layout v-else-if="canContinueOnboarding">
              <onboarding-continue
                  :onboardings="onboardings"
                  :onboarding_id="onboarding_id"
                  :countries="$store.state.countries"
                  :company="company"
                />
            </v-layout>
            <v-layout v-else>
              Nothing here for you to see.
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import FormBuilder from "@/components/Company/Onboarding/Form/Builder/FormBuilder.vue";
import FormViewer from "@/components/Company/Onboarding/Form/Viewer/FormViewer.vue";

import OnboardingContinue from "@/components/Company/Onboarding/OnboardingContinue.vue";
import OnboardingHistory from "@/components/Company/Onboarding/OnboardingHistory.vue";
import OnboardingLegacy from "@/components/Company/Onboarding/OnboardingLegacy.vue";
import OnboardingProfile from "@/components/Company/Onboarding/OnboardingProfile.vue";
import { default as OnboardingTemplate } from "@/components/Company/Onboarding/OnboardingTemplate.js";

export default {
  props: ["path", "onboarding_id", "onboardings", "company", "projects", "projectRateCards"],
  components: {
    OnboardingContinue,
    OnboardingLegacy,
    OnboardingProfile,
    FormBuilder,
    FormViewer,
  },
  data() {
    return {
      onboardingFormTemplateData: JSON.parse(
        JSON.stringify(OnboardingTemplate)
      ),
      onboardingHistoryList: { data: [], uuid: "1" },
      onboardingHistoryFilters: [
        "Requested",
        "Approved",
        "Denied",
        "Terminated",
      ],
    };
  },
  watch: {
    path(val) {
      if (val !== undefined && val !== null) {
        // console.log(val)
        this.onboardingFormTemplateData = this.resetOnboardingFormDataTemplate();
        this.$forceUpdate();
      }
    },
    filteredOnboardingHistory(val) {
      this.$eventBus.$emit("filteredOnboardingHistory", val);
    },
  },
  beforeDestroy() {
    [ "jira_issue_resolved", "flow_approval_denied", "flow_approval_approved",
    "onboarding_flow_approval_requested",
    "onboarding_flow_approval_requested_failed", "onboarding_new",
    "onboarding_delete", "onboarding_update", ].forEach((listener) => {
      this.$eventBus.$off(listener)
    })
    this.$eventBus.$off("updateOnboardingFormData")
    this.$eventBus.$on("getOnboardingHistory")
  },
  mounted() {
    let that = this;
    this.$eventBus.$on("getOnboardingHistory", function() {
      that.getOnboardingHistory();
    });
    if (this.$store.state.userInfo !== null) {
      this.getOnboardingHistory();
    }
    [
      "jira_issue_resolved",
      "flow_approval_denied",
      "flow_approval_approved",
      "onboarding_flow_approval_requested",
      "onboarding_flow_approval_requested_failed",
      "onboarding_new",
      "onboarding_delete",
      "onboarding_update",
    ].forEach(function(listener) {
      that.$eventBus.$on(listener, function(doc) {
        if (doc !== undefined && doc._id !== undefined) {
          that.$store.dispatch("UPDATE_ONBOARDING", doc)
        }
        that.getOnboardingHistory();
      });
    });
    that.$eventBus.$on("updateOnboardingFormData", function(template, step) {
      that.updateOnboardingFormDataTemplate(template, step);
    });
  },
  computed: {
    canContinueOnboarding() {
      var that = this;
      if (this.onboardings !== null && this.onboardings !== undefined) {
        let canContinueOnboarding = this.onboardings.filter(function(row) {
          return (
            row.cognitusEmailMailbox === that.$store.state.userInfo.cognitus_email_address.split("@")[0]
          );
        });
        if (canContinueOnboarding.length > 0 && canContinueOnboarding[0].readyForOnboardedUserInput && !canContinueOnboarding[0].onboardedUserInputComplete) {
          return canContinueOnboarding[0]._id;
        }
      }
      return false;
    },
    onboardingFormData() {
      return this.onboardingFormTemplateData;
    },
    onboardingCardsClass() {
      switch (this.path) {
        case undefined:
        case null:
        case "":
          return "cards-main";
        case "new":
        case "change":
        case "continue":
          return "cards-main";
        default:
          return "";
      }
    },

    filteredOnboardingHistory() {
      let that = this;
      return JSON.parse(
        JSON.stringify(
          that.onboardingHistoryList.data.filter(function(item) {
            return (
              item !== null &&
              that.onboardingHistoryFilters.includes(item.flowApprovalStatus)
            );
          })
        )
      );
    },
  },
  methods: {
    resetOnboardingFormDataTemplate() {
      return JSON.parse(JSON.stringify(OnboardingTemplate));
    },
    updateOnboardingFormDataTemplate(template) {
      this.onboardingFormTemplateData = JSON.parse(JSON.stringify(template));
      this.$forceUpdate();
    },
    getOnboardingCardClass(card) {
      return this.path === card
        ? `onboarding-card-${card} active`
        : `onboarding-card-${card}`;
    },
    getOnboardingHistory() {
      var that = this;
      that.$BackendService.onboarding
        .history({
          company_id: that.company._id,
        })
        .then(function(response) {
          that.onboardingHistoryList.uuid = that.$UUID();
          that.onboardingHistoryList.data = response.docs;
          that.$forceUpdate();
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    changeOnboarding() {
      var that = this;
      this.$router.push(`/company/${that.company._id}/onboarding-change`);
    },
    newOnboarding() {
      this.onboardingFormTemplateData = this.resetOnboardingFormDataTemplate();
      var that = this;
      this.$nextTick(function() {
        that.$router.push(
          `/company/${that.company._id}/onboarding/${that.$UUID()}`
        );
      });
    },
    onboardingHistory() {
      var that = this;
      this.$router.push(`/company/${that.company._id}/onboarding-history`);
    },
    openEditor(item, index) {
      if (this.editorOpen) {
        // console.log("There is already an Editor session open.");
        return item + index;
      } else {
        this.editorOpen = true;
      }
    },
  },
};
</script>

<style></style>
