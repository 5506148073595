<template>
  <v-layout style="margin: 1em 0;" justify-center align-center row wrap v-if="onboardingFormDataCopy !== null">
    <v-flex xs11>
      <table style="width: 100%;">
        <tr>
          <td>
            <h4>Onboarding Data Overview</h4>
          </td>
          <td>
            {{
            `${
            onboardingFormDataCopy.changeRequest
            ? "Change Request"
            : "New Onboarding"
            }`
            }}
          </td>
        </tr>
        <br />
        <tr
          v-for="(key, index) in Object.keys(onboardingFormDataCopy).filter(
            function(key) {
              if (onboardingFormDataCopy.employee) {
                if (
                  key === 'costRate' ||
                  key === 'billRate' ||
                  key === 'travelExpenses' ||
                  key === 'contingentWorker' ||
                  key === 'startDate' ||
                  key === 'currency'
                ) {
                  return false;
                }
              }
              if (onboardingFormDataCopy.contractor) {
                if (key === 'laptop' || key === 'employee') {
                  return false;
                }
                if (key === 'costRate' || key === 'billRate' || key === 'currency') {
                  return (onboardingFormDataCopy['contingentWorker'])
                }
                if (key === 'startDate') {
                  return (onboardingFormDataCopy['travelExpenses'] || onboardingFormDataCopy['contingentWorker'])
                }
              }
              return (
                key !== 'step' &&
                key !== 'avatar' &&
                key !== 'onboarding_id' &&
                key !== 'company_id' &&
                onboardingFormDataCopy[key] !== false &&
                onboardingFormDataCopy[key] !== null &&
                onboardingFormDataCopy[key] !== '' &&
                key !== 'history' &&
                key !== 'vendorAddress' &&
                key !== 'flowApprovalStatus' &&
                key !== 'jiraIssueStatus' &&
                key !== 'jiraIssueID' &&
                key !== 'jiraIssueKey' &&
                key !== 'readyForOnboardedUserInput' &&
                key !== '_id' &&
                key !== '_rev' &&
                key !== 'changeRequest' &&
                key !== 'sentEmails' &&
                key !== 'forms' &&
                key !== 'jiraCheckboxDisabled' &&
                key !== 'hasBeenOnboarded' &&
                key !== 'requesterComment' &&
                key !== 'sapUser' &&
                key !== 'temporaryOffice365Password' &&
                key !== 'conversationId' &&
                key !== 'activityId' && 
                key !== 'socketID' && 
                key !== 'onboardedUserInputComplete' && 
                key !== 'date_time_onboarded' &&
                key !== 'changeRequestUUID' && 
                key !== 'adobeAgreements' &&
                key !== 'projectRateCards' &&
                key !== 'adobeSignedAgreementsFieldData' &&
                key !== 'adobeFieldData' &&
                key !== 'vendorAddressSupplier' &&
                key !== 'serviceProviderEmailAddress' &&
                key !== 'msaDate' &&
                key !== 'adobeSignedAgreementFieldData' &&
                (key === 'mobilePhone' ? histories[0] !== undefined && onboardingFormDataCopy[key] !== undefined ? onboardingFormDataCopy[key].e164 !== histories[0][key].e164 : true : true)
              );
            }
          )"
          :key="'key-' + index"
        >
          <td> {{ textValues.filter(i => i.key === key)[0] !== undefined ? textValues.filter(i => i.key === key)[0].value : key + ': '}}</td>
          <td @click="edit[key] = true">
            <strong>
              <span
                v-if="
                  onboardingFormDataCopy.changeRequest && histories.length > 0 &&
                    onboardingFormDataCopy.history !== undefined &&
                    onboardingFormDataCopy.history !== null &&
                    onboardingFormDataCopy.history.length > 0 &&
                    JSON.stringify(onboardingFormDataCopy[key]) !==
                      JSON.stringify(
                        histories[0][key]
                      )
                "
              >
                <span
                  style="color: red;"
                >{{
                  (key === 'date_time_onboarded' && isValidDate(new Date(histories[0][key]))) ? new Date(histories[0][key]).toDateString()  : 
                  key === 'mobilePhone' ? histories[0][key].e164 :
                  key === 'otherSystem' ? histories[0][key].join(", ") :
                  JSON.stringify(histories[0][key]).replaceAll("\"", "") 
                 }}&nbsp;</span>
                <span
                  style="color: green;"
                >{{ (key === 'date_time_onboarded' && isValidDate(new Date(onboardingFormDataCopy[key]))) ? (new Date(onboardingFormDataCopy[key])).toDateString() : 
                    key === 'mobilePhone' ? onboardingFormDataCopy[key].e164 :
                    key === 'otherSystem' ? onboardingFormDataCopy[key].join(", ") :
                    JSON.stringify(onboardingFormDataCopy[key]).replaceAll("\"", "") 
                  }}
                </span>
              </span>
              <span
                v-else
              >{{ (key === 'date_time_onboarded' && isValidDate(new Date(onboardingFormDataCopy[key]))) ? (new Date(onboardingFormDataCopy[key])).toDateString() :
                    key === 'mobilePhone' ? onboardingFormDataCopy[key].e164 : 
                    key === 'otherSystem' ? onboardingFormDataCopy[key].join(", ") :
                  JSON.stringify(onboardingFormDataCopy[key]).replaceAll("\"", "") 
                }}
                </span>
            </strong>
          </td>
        </tr>
        <tr>
          <td>Comment:</td>
          <td>
            <v-textarea
              style="border: 1px solid rgba(0, 0, 0, .03)"
              color="primary"
              autofocus
              v-model="onboardingFormDataCopy.requesterComment"
            ></v-textarea>
          </td>
        </tr>
      </table>
      <br />
    </v-flex>
  </v-layout>
</template>

<script>
import * as jd from 'json-delta'
export default {
  props: [
    "data",
    "onboardings",
    "company",
    "countries",
    "onboarding_id",
    "step"
  ],
  data() {
    return {
      onboardingFormDataCopy: null,
      edit: [],
      isStepping: false,
      textValues: [{
        key: 'contractor',
        value: "Contractor"
      },{
        key: 'employee',
        value: "Employee"
      },{
        key: 'firstName',
        value: "First Name"
      },{
        key: 'lastName',
        value: "Last Name"
      },{
        key: 'jobTitle',
        value: "Job Title"
      },{
        key: 'personalEmailAddress',
        value: "Personal Email Address"
      },{
        key: 'cognitusEmailMailbox',
        value: "Cognitus Mailbox"
      },{
        key: 'cognitusEmailDomain',
        value: "Cognitus Email Domain"
      },{
        key: 'contingentWorker',
        value: "Contingent Worker"
      },{
        key: 'startDate',
        value: "Start Date"
      },{
        key: 'currency',
        value: "Currency"
      },{
        key: 'costRate',
        value: "Cost Rate"
      },{
        key: 'billRate',
        value: "Bill Rate"
      },{
        key: 'costCenter',
        value: "Cost Center"
      },{
        key: 'region',
        value: "Region"
      },{
        key: 'usageLocation',
        value: "Usage Location"
      },{
        key: 'otherSystem',
        value: "System Access"
      },{
        key: 'o365license',
        value: "Office365 License"
      },{
        key: 'requester',
        value: "Requester"
      },{
        key: 'sfReportsTo',
        value: "Reports To"
      },{
        key: 'sfPayingLegalEntity',
        value: "Paying Legal Entity"
      }]
    };
  },
  created() {
    var that = this;
    this.onboardingFormDataCopy = JSON.parse(JSON.stringify(that.data));
    if (this.onboardingFormDataCopy.changeRequest) {
      this.onboardingFormDataCopy.flowApprovalStatus = null;
    }
    if (this.onboardingFormDataCopy.employee) {
      this.onboardingFormDataCopy = this.resetContractorFields(
        that.onboardingFormDataCopy
      );
    } else if (this.onboardingFormDataCopy.contractor) {
      this.onboardingFormDataCopy = this.resetEmployeeFields(
        that.onboardingFormDataCopy
      );
    }
    this.$eventBus.$on("nextStep", function(currentStep) {
      if (currentStep === that.step && !that.isStepping) {
        that.isStepping = true;
        that.$emit(
          "updateData",
          that.getOnboardingFormDataCopy(),
          that.step,
          true,
          function() {
            that.isStepping = false;
          }
        );
      }
    });
    this.$eventBus.$on("dataUpdate", function(callback) {
      console.log('dataUpdate')
      that.$emit(
        "updateData",
        that.getOnboardingFormDataCopy(),
        that.step,
        false,
        function() {
          callback();
        }
      );
    });
    that.$eventBus.$emit("stepOkay", {
      step: that.step,
      valid: true
    });
  },
  mounted() {
    this.$forceUpdate();
  },
  watch: {
    data: {
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          this.onboardingFormDataCopy = JSON.parse(JSON.stringify(val));
          this.$forceUpdate();
        }
      }
    }
  },
  computed: {
    histories() {
      let that = this;
      let histories = that.onboardingFormDataCopy.history.filter(history => Array.isArray(history));
      if (histories.length > 0) {
        histories = histories.map((diff, index) => {
          let i = histories.length-1;
          let history = JSON.parse(JSON.stringify(that.onboardingFormDataCopy));
          delete history.history;
          while(i >= index) {
            history = jd.applyDiff(history, histories[i])
            i--;
          }
          return history;
        });
        histories = histories.sort(that.sortDate);
      }
      return histories;
    }
  },
  methods: {
    sortDate(a, b) {
      return a.date_time_onboarded < b.date_time_onboarded ? 1 : -1;
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    getOnboardingFormDataCopy() {
      return this.onboardingFormDataCopy;
    },
    resetContractorFields(data) {
      data.travelExpenses = false;
      data.contingentWorker = false;
      data.currency = null;
      data.costRate = null;
      data.billRate = null;
      data.startDate = null;
      return data;
    },
    resetEmployeeFields(data) {
      data.laptop = false;
      return data;
    }
  }
};
</script>

<style lang="scss" scoped></style>
