<template>
  <v-layout row wrap justify-center align-center 
    v-if="company.settings[company.settings.indexOf(company.settings.filter(setting => setting.label === 'Project Access')[0])].value && company.settings[company.settings.indexOf(company.settings.filter(setting => setting.label === 'Project Access')[0])].value.some(entry => entry.split('@')[0] === $store.state.userADInfo.mail.split('@')[0])"
  >
    <v-flex xs11>
      <!-- <v-layout> 
        <v-flex xs6 style="margin-right: 1em;">
          <v-card :dark="hover['importFromSharepoint']" @mouseenter="hover['importFromSharepoint'] = true" @mouseleave="hover['importFromSharepoint'] = false" :style="`transition: all .3s; margin-bottom: 1em; border: ${ hover['importFromSharepoint'] ? '1.5px solid #ffd700' : '1px solid rgba(0, 0, 0, .6)'}`" @click="importFromSharepoint()">
            <v-card-title>
              Import Projects and Rate Cards from Sharepoint
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout> -->
      <v-dialog v-model="dialog.history.project.show" persistent fullscreen>
        <v-card v-if="projectHistoryItem !== null">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.history.project.hide()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ projectHistoryItem.title }} > Project History</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-layout wrap>
            <v-flex xs12 v-if="Array.isArray(projectHistoryItem.history)">
              <table style="width: 100%;">
                <thead style="background-color: rgba(0, 0, 0, .1)">
                  <tr>
                    <th v-for="key in Object.keys(projectHistoryItem).filter(key => [
                        'title',
                        'project_definition_existing_project',
                        'end_client_name',
                        'project_location',
                        'partner_or_practice',
                        'payment_terms',
                        'purchase_order_number',
                        'billing_cycle',
                        'standard_work_week',
                        'expenses',
                        'lastChange',
                        'changedBy'
                      ].includes(key))" :key="'tableHeader-'+key">
                      {{ [{
                        'title': 'Title',
                        'project_definition_existing_project': "Project Definition",
                        'end_client_name': "End Client Name",
                        'project_location': "Project Location",
                        'partner_or_practice': "Practice",
                        'payment_terms': "Payment Terms",
                        'purchase_order_number': "Purchase Order Number",
                        'billing_cycle': "Billing Cycle",
                        'standard_work_week': "Standard Work Week (Hrs)",
                        'expenses': "Expenses",
                        'lastChange': "Last Change Date",
                        'changedBy': "Changed By",
                      }][0][key] }}
                    </th>
                  </tr>
                </thead>
                <tbody style="text-align: center">
                  <tr>
                    <td v-for="key in Object.keys(projectHistoryItem).filter(key => ['title',
                        'project_definition_existing_project',
                        'end_client_name',
                        'project_location',
                        'partner_or_practice',
                        'payment_terms',
                        'purchase_order_number',
                        'billing_cycle',
                        'standard_work_week',
                        'expenses',
                        'lastChange',
                        'changedBy'].includes(key))" :key="'tableDataCurrent-'+key">
                      <span v-if="projectHistoryItem[key] !== projectHistoryItem.history[projectHistoryItem.history.length - 1][key]">
                        <span style="color: rgba(0, 200, 0, 1)">
                          <span v-if="key === 'lastChange'">
                            <strong>{{ new Date(projectHistoryItem[key]).toUTCString() }}</strong>
                          </span>
                          <span v-else>
                            <strong>{{ projectHistoryItem[key] }}</strong>
                          </span>
                        </span>
                        &nbsp;
                        <span style="color: rgba(200, 0, 0, 1)">
                          <span v-if="key === 'lastChange'">
                              <strong>{{ new Date(projectHistoryItem.history[projectHistoryItem.history.length - 1][key]).toUTCString() }}</strong>
                          </span>
                          <span v-else>
                            <strong>{{ projectHistoryItem.history[projectHistoryItem.history.length - 1][key]  }}</strong>
                          </span>
                        </span>
                      </span>
                      <span v-else>
                        <span v-if="key === 'lastChange'">
                          <strong>{{ new Date(projectHistoryItem[key]).toUTCString() }}</strong>
                        </span>
                        <span v-else>
                          <strong>{{ projectHistoryItem[key] }}</strong>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr v-for="(historyItem, index) in sortedResourceHistoryItem" :key="'projectHistoryItem'+index">
                    <td v-for="key in Object.keys(historyItem).filter(key => ['title',
                        'project_definition_existing_project',
                        'end_client_name',
                        'project_location',
                        'partner_or_practice',
                        'payment_terms',
                        'purchase_order_number',
                        'billing_cycle',
                        'standard_work_week',
                        'expenses',
                        'lastChange',
                        'changedBy'].includes(key))" :key="'tableData-'+key+'-'+index">
                      <span v-if="index+1 <= sortedResourceHistoryItem.length - 1">
                        <span v-if="sortedResourceHistoryItem[index+1][key] !== sortedResourceHistoryItem[index][key]">
                          <span style="color: rgba(0, 200, 0, 1)">
                            <span v-if="key === 'lastChange'">
                              <strong>{{ new Date(sortedResourceHistoryItem[index][key]).toUTCString() }}</strong>
                            </span>
                            <span v-else>
                              <strong>{{ sortedResourceHistoryItem[index][key]  }}</strong>
                            </span>
                          </span>
                          &nbsp;
                          <span style="color: rgba(200, 0, 0, 1)">
                            <span v-if="key === 'lastChange'">
                              <strong>{{ new Date(sortedResourceHistoryItem[index+1][key]).toUTCString() }}</strong>
                            </span>
                            <span v-else>
                              <strong>{{ sortedResourceHistoryItem[index+1][key] }}</strong>
                            </span>
                          </span>
                        </span>
                        <span v-else>
                          {{ sortedResourceHistoryItem[index][key] }}
                        </span>
                      </span>
                      <span v-else style="color: rgba(255, 141, 0, 1)">
                        <span v-if="key === 'lastChange'">
                          {{ new Date(sortedResourceHistoryItem[index][key]).toUTCString() }}
                        </span>
                        <span v-else>
                          {{ sortedResourceHistoryItem[index][key]  }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
                
              </table>
            </v-flex>
            <v-flex xs12>
              <v-btn color="error" tile text style="margin: 1em;" @click="dialog.history.project.hide()">Close</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.history.projectRateCard.show" persistent fullscreen>
        <v-card v-if="resourceHistoryItem !== null">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.history.projectRateCard.hide()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ projectTabs[tabs].title }} > {{ resourceHistoryItem.name }} > Project Rate Card History</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-layout wrap>
            <v-flex xs12 v-if="Array.isArray(resourceHistoryItem.projectRateCard.history)">
              <table style="width: 100%;">
                <thead style="background-color: rgba(0, 0, 0, .1)">
                  <tr>
                    <th v-for="key in Object.keys(resourceHistoryItem.projectRateCard).filter(key => ['estimatedWork', 'billRateToCustomer', 'pricingDateFrom', 'pricingDateTo', 'role', 'costRateCurrency', 'costRateOfResource', 'payment_terms', 'lastChange', 'sow', 'changedBy'].includes(key))" :key="'tableHeader-'+key">
                      {{ [{
                        'estimatedWork': "Estimated Work Days",
                        'billRateToCustomer': "Bill Rate to Customer",
                        'pricingDateFrom': "Pricing Date From",
                        'pricingDateTo': "Pricing Date To",
                        'role': "Role",
                        'costRateCurrency': "Cost Rate Currency",
                        'costRateOfResource': "Cost Rate of Resource",
                        'lastChange': "Last Change Date",
                        'sow': "Statement of Work ID",
                        'payment_terms': "Payment Terms",
                        'changedBy': 'Changed By'
                      }][0][key] }}
                    </th>
                  </tr>
                </thead>
                <tbody style="text-align: center">
                  <tr>
                    <td v-for="key in Object.keys(resourceHistoryItem.projectRateCard).filter(key => ['estimatedWork', 'billRateToCustomer', 'pricingDateFrom', 'pricingDateTo', 'role', 'costRateCurrency', 'costRateOfResource',  'payment_terms', 'lastChange', 'sow', 'changedBy'].includes(key))" :key="'tableDataCurrent-'+key">
                      <span v-if="resourceHistoryItem.projectRateCard[key] !== resourceHistoryItem.projectRateCard.history[resourceHistoryItem.projectRateCard.history.length - 1][key]">
                        <span style="color: rgba(0, 200, 0, 1)">
                          <span v-if="key === 'lastChange'">
                            <strong>{{ new Date(resourceHistoryItem.projectRateCard[key]).toUTCString() }}</strong>
                          </span>
                          <span v-else-if="key === 'sow'">
                            <strong>{{ resourceHistoryItem.projectRateCard[key].length > 0 ? resourceHistoryItem.projectRateCard[key].substring(0, 5) + '...' : ''  }}</strong>
                          </span>
                          <span v-else>
                            <strong>{{ resourceHistoryItem.projectRateCard[key] }}</strong>
                          </span>
                        </span>
                        &nbsp;
                        <span style="color: rgba(200, 0, 0, 1)">
                          <span v-if="key === 'lastChange'">
                              <strong>{{ new Date(resourceHistoryItem.projectRateCard.history[resourceHistoryItem.projectRateCard.history.length - 1][key]).toUTCString() }}</strong>
                          </span>
                          <span v-else-if="key === 'sow'">
                            <strong>{{ resourceHistoryItem.projectRateCard.history[resourceHistoryItem.projectRateCard.history.length - 1][key].length > 0 ? resourceHistoryItem.projectRateCard.history[resourceHistoryItem.projectRateCard.history.length - 1][key].substring(0, 5) + '...' : '' }}</strong>
                          </span>
                          <span v-else>
                            <strong>{{ resourceHistoryItem.projectRateCard.history[resourceHistoryItem.projectRateCard.history.length - 1][key]  }}</strong>
                          </span>
                        </span>
                      </span>
                      <span v-else>
                        <span v-if="key === 'lastChange'">
                          <strong>{{ new Date(resourceHistoryItem.projectRateCard[key]).toUTCString() }}</strong>
                        </span>
                        <span v-else-if="key === 'sow'">
                          <strong>{{ resourceHistoryItem.projectRateCard[key].length > 0 ? resourceHistoryItem.projectRateCard[key].substring(0, 5) + '...' : ''  }}</strong>
                        </span>
                        <span v-else>
                          <strong>{{ resourceHistoryItem.projectRateCard[key] }}</strong>
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr v-for="(historyItem, index) in sortedResourceHistoryItem" :key="'prcHistoryItem'+index">
                    <td v-for="key in Object.keys(historyItem).filter(key => ['estimatedWork', 'billRateToCustomer', 'pricingDateFrom', 'pricingDateTo', 'role', 'costRateCurrency', 'costRateOfResource', 'payment_terms', 'lastChange', 'sow', 'changedBy'].includes(key))" :key="'tableData-'+key+'-'+index">
                      <span v-if="index+1 <= sortedResourceHistoryItem.length - 1">
                        <span v-if="sortedResourceHistoryItem[index+1][key] !== sortedResourceHistoryItem[index][key]">
                          <span style="color: rgba(0, 200, 0, 1)">
                            <span v-if="key === 'lastChange'">
                              <strong>{{ new Date(sortedResourceHistoryItem[index][key]).toUTCString() }}</strong>
                            </span>
                            <span v-else>
                              <strong>{{ sortedResourceHistoryItem[index][key]  }}</strong>
                            </span>
                          </span>
                          &nbsp;
                          <span style="color: rgba(200, 0, 0, 1)">
                            <span v-if="key === 'lastChange'">
                              <strong>{{ new Date(sortedResourceHistoryItem[index+1][key]).toUTCString() }}</strong>
                            </span>
                            <span v-else>
                              <strong>{{ sortedResourceHistoryItem[index+1][key] }}</strong>
                            </span>
                          </span>
                        </span>
                        <span v-else>
                          {{ sortedResourceHistoryItem[index][key] }}
                        </span>
                      </span>
                      <span v-else style="color: rgba(255, 141, 0, 1)">
                        <span v-if="key === 'lastChange'">
                          {{ new Date(sortedResourceHistoryItem[index][key]).toUTCString() }}
                        </span>
                        <span v-else>
                          {{ sortedResourceHistoryItem[index][key]  }}
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
                
              </table>
            </v-flex>
            <v-flex xs12>
              <v-btn color="error" tile text style="margin: 1em;" @click="dialog.history.projectRateCard.hide()">Close</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.create.show" fullscreen persistent>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.create.hide()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Create New Project</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-layout justify-center align-top style="margin-top: 1em;">
            <v-flex xs3 style="margin: 1em;">
              <!-- PROJECT FIELDS -->
              <h3>Project</h3>
              <br>
              <v-text-field v-model="newProject.title" label="Project Name *"></v-text-field>
              <v-combobox v-if="onboardings !== null" multiple label="Resources" v-model="newProject.resources" :items="onboardings.map(onb => {
                return {
                  text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
                  value: onb
                }}).filter(onb => !onb.terminated)">
              </v-combobox>
              <v-text-field label="Project Number" v-model="newProject.project_definition_existing_project"></v-text-field>
              <v-text-field label="End Client Name *" v-model="newProject.end_client_name"></v-text-field>
              <v-text-field label="Location" v-model="newProject.project_location"></v-text-field>
              <v-text-field label="Client Manager Name *" v-model="newProject.client_project_manager.name"></v-text-field>
              <v-text-field 
                label="Client Manager Email *" 
                v-model="newProject.client_project_manager.email" 
                :error="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newProject.client_project_manager.email))"
                :error-messages="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newProject.client_project_manager.email)) ? ['Please enter a valid email address'] : []"
                :success="(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newProject.client_project_manager.email))"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 style="margin: 1em;">
              <!-- CUSTOMER FIELDS -->
              <h3>Bill to Customer</h3>
              <br>
              <v-text-field label="Name *" v-model="newProject.bill_to_customer.name"></v-text-field>
              <v-text-field label="Street" v-model="newProject.bill_to_customer.address.street"></v-text-field>
              <v-layout>
                <v-flex xs6 style="margin-right: 1em;"><v-text-field label="City" v-model="newProject.bill_to_customer.address.city"></v-text-field></v-flex>
                <v-flex xs3 style="margin-right: 1em;"><v-text-field label="State" v-model="newProject.bill_to_customer.address.state"></v-text-field></v-flex>
                <v-flex xs3><v-text-field label="ZIP" v-model="newProject.bill_to_customer.address.zip"></v-text-field></v-flex>
              </v-layout>
              <v-text-field label="Phone *" v-model="newProject.bill_to_customer.phone"></v-text-field>
              <v-text-field 
                label="Email Address *" 
                v-model="newProject.bill_to_customer.email_address"
                :error="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newProject.bill_to_customer.email_address))"
                :error-messages="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newProject.bill_to_customer.email_address)) ? ['Please enter a valid email address'] : []"
                :success="(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newProject.bill_to_customer.email_address))"
              ></v-text-field>
              <v-autocomplete label="Payment Terms *" v-model="newProject.payment_terms" :items="[
                { text: 'NT10', value: 'NT10' },
                { text: 'NT30', value: 'NT30' },
                { text: 'NT45', value: 'NT45' },
                { text: 'NT60', value: 'NT60' },
                { text: 'NT90', value: 'NT90' },
                { text: 'On Receipt', value: 'On Receipt' },
              ]"></v-autocomplete>
              <v-text-field label="Purchase Order Number" v-model="newProject.purchase_order_number"></v-text-field>
              <v-autocomplete label="Billing Cycle *" v-model="newProject.billing_cycle" :items="[
                { text: 'Milestone', value: 'Milestone' },
                { text: 'Monthly', value: 'Monthly' },
                { text: 'Weekly Mon-Sun', value: 'Weekly Mon-Sun' },
                { text: 'Weekly Sat-Fri', value: 'Weekly Sat-Fri' },
                { text: 'Weekly Sun-Sat', value: 'Weekly Sun-Sat' }
              ]"></v-autocomplete>
              <v-text-field type="number" label="Standard Work Week (Hrs) *" :rules="[() => (newProject.standard_work_week >= 10 && newProject.standard_work_week <= 99) || 'Must be between 10 and 99']" v-model="newProject.standard_work_week"></v-text-field>
              <v-autocomplete :items="[{ text: 'N/A', value: 'N/A'}, { text: 'Actual Expenses based on Receipt', value: 'Actual Expenses based on Receipt'}]" label="Expenses *" v-model="newProject.expenses"></v-autocomplete>
              <v-autocomplete
                v-model="newProject.bill_rate_currency"
                :items="Object.keys($store.state.currencies)"
                label="Bill Rate Currency"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs3 style="margin: 1em;">
              <!-- OWNER FIELDS -->
              <h3>{{ 'Owner' + (newProject.owners.length > 1 ? 's' : '') }}</h3>
              <br>
              <v-text-field label="Practice *" v-model="newProject.partner_or_practice"></v-text-field>
              <v-autocomplete label="Owners *" v-model="additionalProjectOwner" :items="computedAvailableNewProjectOwners" @change="() => {
                    newProject.owners.push(additionalProjectOwner)
                    additionalProjectOwner = null
                }">
              </v-autocomplete>
              <v-list>
                <v-list-item v-for="(owner, ownerIndex) in newProject.owners.filter(ow => ow !== null)" :key="'owner'+ownerIndex">
                  <v-layout>
                    <v-flex xs6 style="margin-right: 1em;">
                      <v-text-field label="Name" readonly v-model="owner.name" prepend-icon="mdi-close" @click:prepend="newProject.owners.splice(ownerIndex, 1)"></v-text-field>
                    </v-flex>
                    <v-flex xs6> 
                      <v-text-field label="Percentage Ownership" type="number" v-model="owner.percentageOfOwnership"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-list-item>
              </v-list>
              <v-autocomplete label="Sales Commission" v-model="additionalSalesCommission" :items="computedAvailableSalesCommission" @change="() => {
                    newProject.salesCommission.push(additionalSalesCommission)
                    additionalSalesCommission = null
                }">
              </v-autocomplete>
              <v-list>
                <v-list-item v-for="(seller, sellerIndex) in newProject.salesCommission" :key="'seller'+sellerIndex">
                  <v-layout>
                    <v-flex xs6 style="margin-right: 1em;">
                      <v-text-field label="Name" readonly v-model="seller.name" prepend-icon="mdi-close" @click:prepend="newProject.salesCommission.splice(sellerIndex, 1)"></v-text-field>
                    </v-flex>
                    <v-flex xs6> 
                      <v-text-field label="Percentage of Sales" type="number" v-model="seller.percentageOfSales"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs3 style="margin-top: 1em;">
              <v-btn color="success" tile text :disabled="dialog.create.buttons.create.disabled()" @click="dialog.create.buttons.create.click" :elevation="`${ dialog.create.buttons.create.hover ? 3 : 1}`" style="margin-right: 2em;">Create</v-btn>
              <v-btn color="warning" tile text @click="dialog.create.buttons.clear.click" :elevation="`${ dialog.create.buttons.clear.hover ? 3 : 1}`" style="margin-right: 2em;">Clear</v-btn>
              <v-btn color="error" tile text @click="dialog.create.buttons.cancel.click" :elevation="`${ dialog.create.buttons.cancel.hover ? 3 : 1}`">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.edit.show" fullscreen persistent>
        <v-card >
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.edit.hide()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="projectEdit !== null">Edit {{ projectEdit.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-layout v-if="projectEdit !== null" justify-center align-top style="margin-top: 1em;">
            <v-flex xs3 style="margin: 1em;">
              <!-- PROJECT FIELDS -->
              <h3>Project</h3>
              <br>
              <v-text-field v-model="projectEdit.title" label="Project Name *" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field label="Project Number" v-model="projectEdit.project_definition_existing_project" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field label="End Client Name *" v-model="projectEdit.end_client_name" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field label="Location" v-model="projectEdit.project_location" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field label="Client Manager Name *" v-model="projectEdit.client_project_manager.name" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field 
                label="Client Manager Email *" 
                v-model="projectEdit.client_project_manager.email" @change="() => {$forceUpdate();}"
                :error="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(projectEdit.client_project_manager.email))"
                :error-messages="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(projectEdit.client_project_manager.email)) ? ['Please enter a valid email address'] : []"
                :success="(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(projectEdit.client_project_manager.email))"
              ></v-text-field>
            </v-flex>
            <v-flex xs3 style="margin: 1em;">
              <!-- CUSTOMER FIELDS -->
              <h3>Bill to Customer</h3>
              <br>
              <v-text-field label="Name *" v-model="projectEdit.bill_to_customer.name" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field label="Street" v-model="projectEdit.bill_to_customer.address.street" @change="() => {$forceUpdate();}"></v-text-field>
              <v-layout>
                <v-flex xs6 style="margin-right: 1em;"><v-text-field label="City" v-model="projectEdit.bill_to_customer.address.city" @change="() => {$forceUpdate();}"></v-text-field></v-flex>
                <v-flex xs3 style="margin-right: 1em;"><v-text-field label="State" v-model="projectEdit.bill_to_customer.address.state" @change="() => {$forceUpdate();}"></v-text-field></v-flex>
                <v-flex xs3><v-text-field label="ZIP" v-model="projectEdit.bill_to_customer.address.zip" @change="() => {$forceUpdate();}"></v-text-field></v-flex>
              </v-layout>
              <v-text-field label="Phone *" v-model="projectEdit.bill_to_customer.phone" @change="() => {$forceUpdate();}"></v-text-field>
              <v-text-field 
                label="Email Address *" v-model="projectEdit.bill_to_customer.email_address" @change="() => {$forceUpdate();}"
                :error="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(projectEdit.bill_to_customer.email_address))"
                :error-messages="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(projectEdit.bill_to_customer.email_address)) ? ['Please enter a valid email address'] : []"
                :success="(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(projectEdit.bill_to_customer.email_address))"
              ></v-text-field>
              <v-autocomplete label="Payment Terms *" v-model="projectEdit.payment_terms" :items="[
                { text: 'NT10', value: 'NT10' },
                { text: 'NT30', value: 'NT30' },
                { text: 'NT45', value: 'NT45' },
                { text: 'NT60', value: 'NT60' },
                { text: 'NT90', value: 'NT90' },
                { text: 'On receipt', value: 'On receipt' },
              ]" @change="() => {$forceUpdate();}"></v-autocomplete>
              <v-text-field label="Purchase Order Number" v-model="projectEdit.purchase_order_number" @change="() => {$forceUpdate();}"></v-text-field>
              <v-autocomplete label="Billing Cycle *" v-model="projectEdit.billing_cycle" :items="[
                { text: 'Milestone', value: 'Milestone' },
                { text: 'Monthly', value: 'Monthly' },
                { text: 'Weekly Mon-Sun', value: 'Weekly Mon-Sun' },
                { text: 'Weekly Sat-Fri', value: 'Weekly Sat-Fri' },
                { text: 'Weekly Sun-Sat', value: 'Weekly Sun-Sat' }
              ]"
              @change="() => {$forceUpdate();}"
              ></v-autocomplete>
              <v-text-field 
                type="number" 
                label="Standard Work Week (Hrs) *" 
                :rules="[() => (projectEdit.standard_work_week >= 10 && projectEdit.standard_work_week <= 99) || 'Must be between 10 and 99']" 
                v-model="projectEdit.standard_work_week"
                @change="() => {$forceUpdate();}"
              >
              </v-text-field>
              <v-autocomplete :items="[{ text: 'N/A', value: 'N/A'}, { text: 'Actual Expenses based on Receipt', value: 'Actual Expenses based on Receipt'}]" label="Expenses *" v-model="projectEdit.expenses"></v-autocomplete>
              <v-autocomplete
                v-model="projectEdit.bill_rate_currency"
                :items="Object.keys($store.state.currencies)"
                label="Bill Rate Currency"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs3 style="margin: 1em;" v-if="projectEdit !== null">
              <!-- OWNER FIELDS -->
              <h3>{{ 'Owner' + (projectEdit.owners.length > 1 ? 's' : '') }}</h3>
              <br>
              <v-text-field label="Practice *" v-model="projectEdit.partner_or_practice"></v-text-field>
              <v-autocomplete label="Owners *" v-model="additionalProjectOwner" :items="computedAvailableExistingProjectOwners" @change="() => {
                    projectEdit.owners.push(additionalProjectOwner);
                    additionalProjectOwner = null;
                    $forceUpdate();
                }">
              </v-autocomplete>
              <v-list>
                <v-list-item v-for="(owner, ownerIndex) in projectEdit.owners.filter(ow => ow !== null)" :key="'owner'+ownerIndex">
                  <v-layout>
                    <v-flex xs6 style="margin-right: 1em;">
                      <v-text-field label="Name" readonly v-model="owner.name" prepend-icon="mdi-close" @click:prepend="projectEdit.owners.splice(ownerIndex, 1)"></v-text-field>
                    </v-flex>
                    <v-flex xs6> 
                      <v-text-field label="Percentage Ownership" type="number" v-model="owner.percentageOfOwnership"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-list-item>
              </v-list>
              <v-autocomplete label="Sales Commission" v-model="additionalSalesCommission" :items="computedAvailableSalesCommission" @change="() => {
                    projectEdit.salesCommission.push(additionalSalesCommission);
                    additionalSalesCommission = null;
                    $forceUpdate();
                }">
              </v-autocomplete>
              <v-list>
                <v-list-item v-for="(seller, sellerIndex) in projectEdit.salesCommission" :key="'seller'+sellerIndex">
                  <v-layout>
                    <v-flex xs6 style="margin-right: 1em;">
                      <v-text-field label="Name" readonly v-model="seller.name" prepend-icon="mdi-close" @click:prepend="projectEdit.salesCommission.splice(sellerIndex, 1)"></v-text-field>
                    </v-flex>
                    <v-flex xs6> 
                      <v-text-field label="Percentage of Sales" type="number" v-model="seller.percentageOfSales"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex xs3 style="margin-top: 1em;">
              <v-btn color="success" :disabled="dialog.edit.buttons.save.disabled()" tile text @mouseover="dialog.edit.buttons.save.hover = true" @mouseleave="dialog.edit.buttons.save.hover = false" @click="dialog.edit.buttons.save.click" :elevation="`${ dialog.edit.buttons.save.hover ? 3 : 1}`" style="margin-right: 2em;">Save</v-btn>
              <v-btn color="warning" tile text @mouseover="dialog.edit.buttons.cancel.hover = true" @mouseleave="dialog.edit.buttons.cancel.hover = false" @click="dialog.edit.buttons.cancel.click" :elevation="`${ dialog.edit.buttons.cancel.hover ? 3 : 1}`" style="margin-right: 2em;">Cancel</v-btn>
              <v-btn color="error" tile text @mouseover="dialog.edit.buttons.delete.hover = true" @mouseleave="dialog.edit.buttons.delete.hover = false" @click="dialog.edit.buttons.delete.click" :elevation="`${ dialog.edit.buttons.delete.hover ? 3 : 1}`">Delete</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.sow.show" fullscreen persistent>
        <v-card >
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialog.sow.hide()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="sowTemplate !== null">Create SoW</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-layout v-if="sowTemplate !== null" justify-center align-top style="margin-top: 1em;">
            <v-flex style="margin-right: 1em;" xs4 v-if="sowTemplate.sows.length > 0">
              <h1>SoW Status</h1>
              <v-list>
                <v-list-item @mouseenter="hoveredSoW = sowIndex" @mouseleave="hoveredSow = -1" v-for="(sow, sowIndex) in sowTemplate.sows" :key="'sow-'+sowIndex">
                  <v-list-item-content v-if="sow !== undefined && sow.name === undefined && sow.startsWith('https')">
                    <v-list-item-title>
                      <a :href="sow">Open in Sharepoint</a>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-if="sow !== undefined && sow.name !== undefined" :style="`background-color: ${sow.active ? 'rgba(0, 200, 0, .25)' : 'rgba(253, 227, 167, .25)'};`">
                    <v-list-item-title>{{ sow.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ sow.status }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="hoveredSoW === sowIndex">
                    <v-btn-toggle dense >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn text @click="cancelAgreement(sow)" v-on="on" :disabled="sow.status === 'SIGNED' || sow.status === 'CANCELLED' ">
                            <v-icon>mdi-cancel</v-icon>
                          </v-btn>
                        </template>
                        Cancel Agreement
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn text @click="deleteAgreement(sow)" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        Delete Agreement
                      </v-tooltip>
                      
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn text @click="showAgreementEvents(sow)" v-on="on">
                            <v-icon>mdi-history</v-icon>
                          </v-btn>
                        </template>
                        Agreement Events
                      </v-tooltip>
                      
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn text @click="openPDF(sow)" v-on="on">
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        Open PDF
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn text @click="openAuditTrail(sow)" v-on="on">
                            <v-icon>mdi-history</v-icon>
                          </v-btn>
                        </template>
                        Open Audit Trail
                      </v-tooltip>

                      
                    </v-btn-toggle>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <h5>Add Existing SoW via URL</h5>
                  <v-text-field label="Link SoW from Sharepoint" v-model="externalSoWLink">
                    <template v-slot:[`prepend`]>
                      <v-btn tile icon :disabled="!externalSoWLink.startsWith('https://')" @click="addAndSaveLinkedSoW(sowTemplate.projectRateCard)">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex style="flex: 0 0 auto; width: 1em;">
              <v-divider vertical></v-divider>
            </v-flex>
            
            <v-flex xs8 style="margin: 1em auto;" v-if="viewingPdf && !loadingPdf">
              <v-fade-transition group>
                <v-toolbar dense flat floating key='pdfToolbar'>
                  <v-btn text tile @click="viewingPdf = false; adobeDCView = null;"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <div key="pdfview" id="adobe-dc-view-pdf" style="width: 100%; height: 800px;"></div>
              </v-fade-transition>
            </v-flex>
            <v-flex xs8 style="margin: 1em auto;" v-else-if="viewingPdf && loadingPdf">
              <v-fade-transition>
                <v-skeleton-loader
                  key="pdfSkeletonLoader"
                  class="mx-auto"
                  max-width="300"
                  type="card"
                ></v-skeleton-loader>
              </v-fade-transition>
            </v-flex>
            <v-flex xs6 v-else-if="!viewingPdf && !loadingPdf">
              <h4>SoW Creation</h4>
              <v-text-field label="SoW Number" :prefix="(new Date()).getFullYear().toString()+'-'" :disabled="company.sowNumber !== undefined" v-model="sowTemplate.sowNumber"></v-text-field>
              <v-list>
                <v-list-item v-for="(signedMSA, index) in sowTemplate.signedMSAs" :key="'signedMSA'+index" @click="() => {
                    sowTemplate.msaDate = signedMSA.fieldData.data.filter(obj => {
                      return obj.header === 'completed'
                    })[0].data[1];
                    let splitMSADate = sowTemplate.msaDate.split('-')
                    sowTemplate.msaDate = splitMSADate[1]+'/'+splitMSADate[2].split(' ')[0]+'/'+splitMSADate[0]
                    sowTemplate.vendorName = signedMSA.fieldData.data.filter(obj => {
                      return obj.header === 'Vendor Name'
                    })[0].data[0];
                  }">
                  {{ signedMSA.signedMSA.name }} - {{ signedMSA.fieldData.data.filter(obj => {
                      return obj.header === 'completed'
                    })[0].data[1] }}
                </v-list-item>
              </v-list>
              <v-text-field 
                label="MSA Date" v-model="sowTemplate.msaDate" v-mask="'##/##/####'"
                :error="sowTemplateError['msaDate'].state"
                :error-messages="sowTemplateError['msaDate'].val"
                :hint="'MM/DD/YYYY'"
                @change="(val) => {
                  let splt = val.split('/');
                  log(val)
                  sowTemplateError['msaDate'].state = false;
                  sowTemplateError['msaDate'].val = [];
                  if (splt[0] > 12 || split[0] < 1) {
                    sowTemplateError['msaDate'].state = true;
                    sowTemplateError['msaDate'].val.push('invalid month MM/DD/YYYY')
                  }
                  if (splt[1] > 31 || split[1] < 1) {
                    sowTemplateError['msaDate'].state = true;
                    sowTemplateError['msaDate'].val.push('invalid day MM/DD/YYYY')
                  }
                }"
              
              ></v-text-field>
              <v-text-field label="Vendor Name" v-model="sowTemplate.vendorName"></v-text-field>
              <v-combobox
                v-model="sowTemplate.ccs"
                :items="Array.prototype.concat.apply([], onboardings.map(onb => {
                  return {
                    text: `${onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>'}`,
                    value: onb.cognitusEmailMailbox + onb.cognitusEmailDomain
                  }
                })).concat(onboardings.map(onb => {
                  return {
                    text: `${onb.firstName + ' ' + onb.lastName + ' <' + onb.personalEmailAddress + '>'}`,
                    value: onb.personalEmailAddress
                  }
                }))"
                label="Cc"
                multiple
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Press <kbd>enter</kbd> to add a new email address
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <v-combobox label="Cognitus Signer" v-model="sowTemplate.cognitusSigner" :items="sowTemplate.project.owners.map(o => {
                  return {
                    text: o.name + ' <' + o.email + '>',
                    value: o.email
                  }
                })">
              </v-combobox>
              <ul>
                <li>SoW: {{sowTemplate.projectRateCard.sow}}</li>
                <li>Vender Name: {{sowTemplate.vendorName}}</li>
                <li>MSA Date: {{sowTemplate.msaDate.includes(' ') ? sowTemplate.msaDate.split(" ")[0] : sowTemplate.msaDate}}</li>
                <li>Year - Sow: {{(new Date()).getFullYear() + "-" + parseInt(sowTemplate.sowNumber)}}</li>
                <li>Name: {{onboardings.filter(onb => onb._id === sowTemplate.projectRateCard.onboarding_id)[0].firstName + " " + onboardings.filter(onb => onb._id === sowTemplate.projectRateCard.onboarding_id)[0].lastName}}</li>
                <li>Cost Rate: {{sowTemplate.projectRateCard.costRateOfResource}}</li>
                <li>Pricing Date From: {{sowTemplate.projectRateCard.pricingDateFrom}}</li>
                <li>Pricing Date To: {{sowTemplate.projectRateCard.pricingDateTo}}</li>
                <li>Role: {{sowTemplate.projectRateCard.role}}</li>
                <li>End Client Name: {{sowTemplate.project.end_client_name}}</li>
                <li>Project Location: {{sowTemplate.project.project_location}}</li>
                <li>Payment Terms: {{sowTemplate.projectRateCard.payment_terms.replace("NT", "").replace("On Receipt", "0")}}</li>
                <li>Expenses: {{sowTemplate.project.expenses}}</li>
                <li>Standard Work Week: {{sowTemplate.project.standard_work_week}}</li>
                <li>Estimated Work: {{sowTemplate.projectRateCard.estimatedWorkUnit === 'days' ? parseFloat(sowTemplate.projectRateCard.estimatedWork * 8) : parseFloat(sowTemplate.projectRateCard.estimatedWork) }}</li>
              </ul>
              <v-btn :disabled="Object.keys(sowTemplateError).some(key => sowTemplateError[key].state)" :color="`${dialog.sow.buttons.create.error ? 'error' : 'success' }`" :loading="loading" tile text @click="dialog.sow.buttons.create.click" :elevation="`${ dialog.sow.buttons.create.hover ? 3 : 1 }`" style="margin-right: 2em;">
                <span v-if="!dialog.sow.buttons.create.error">Create</span>
                <v-icon v-else>mdi-close</v-icon> 
              </v-btn>
              <v-btn color="error" tile text @click="dialog.sow.buttons.cancel.click" :elevation="`${ dialog.sow.buttons.cancel.hover ? 3 : 1 }`">Cancel</v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.clone.projectRateCard.show">
        <v-card v-if="clone !== null">
          <v-card-title>Clone Project Rate Card</v-card-title>
          <v-card-subtitle>Cloning {{ clone.name }}</v-card-subtitle>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-autocomplete label="Select Resources" v-model="selectedClone" :items="onboardings.filter(onb => 
                  !clones.some(c => c !== null && c._id === onb._id) && 
                  doesNotExistInProjectResourcePool(cloneProject, onb) && 
                  !onb.terminated
                ).map(onb => {
                  return {
                    text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
                    value: JSON.parse(JSON.stringify(onb))
                  }
                })" @change="(val) => {
                  if (!clones.includes(val) && val !== null) {
                    clones.push(val);
                    $forceUpdate();
                  }
                }">
                </v-autocomplete>
              </v-list-item>
              <v-list-item v-for="(clone, index) in clones" :key="'clone-'+index">
                <v-icon color="error" @click="() => {

                  clones.splice(index, 1)

                }">mdi-close</v-icon> {{ clone.firstName + ' ' + clone.lastName }}
                <v-text-field v-model="clone.jobTitle" label="Role" style="margin-left: 1em;"></v-text-field>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="dialog.clone.projectRateCard.close()">Cancel</v-btn>
            <v-btn color="success" @click="dialog.clone.projectRateCard.save(cloneProject, clone, clones)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.clone.project.show">
        <v-card v-if="cloneProject !== null">
          <v-card-title>Clone Project</v-card-title>
          <v-card-subtitle>Cloning {{cloneProject.title}}</v-card-subtitle>
          <v-card-text>
            <v-text-field v-model="cloneProject.title" label="Project Title"></v-text-field>
            <v-checkbox v-model="cloneProjectRateCards" label="Clone Project Rate Cards"></v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="dialog.clone.project.close()">Cancel</v-btn>
            <v-btn color="success" @click="dialog.clone.project.save(cloneProject, cloneProjectRateCards)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.runn.import.show">
        <v-card>
          <v-card-title>Import Project from Runn</v-card-title>
          <v-card-text>
            <v-autocomplete label="Select Project" :items="runnProjects.filter(p => p.references['SurfboardProject'] === undefined).map( p => ({ text: p.name, value: p}) )"></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-dialog>
      
      <v-card v-if="projects !== undefined && projects !== null && Array.isArray(projects) && projects.length > 0" style="margin-bottom: 5em;">
        <!-- CREATE PROJECT BUTTON -->
        <v-btn color="rgba(0, 200, 0, .6)" @click="dialog.create.open()" @mouseenter="hover.create = true"  @mouseleave="hover.create = false" :elevation="2" icon tile :style="`transition: all .3s; position: absolute; left: ${hover.create ? '-2.75em' : '-2em' }; top: 1em;`">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- SEARCH PROJECTS BUTTON -->
        <v-btn color="primary" v-if="!showProjectsSelection" @click="showProjectsSelection = true;" @mouseenter="hover.search = true"  @mouseleave="hover.search = false" :elevation="2" icon tile :style="`transition: all .3s; position: absolute; left: ${hover.search ? '-2.75em' : '-2em' }; top: 4.5em;`">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn v-show="false" color="primary" v-if="!dialog.runn.import.show" @click="dialog.runn.import.open();" @mouseenter="dialog.runn.import.hover = true"  @mouseleave="dialog.runn.import.hover = false" :elevation="2" icon tile :style="`transition: all .3s; position: absolute; left: ${dialog.runn.import.hover ? '-2.75em' : '-2em' }; top: 8em;`">
          <v-icon>mdi-import</v-icon>
        </v-btn>
        <!-- PROJECTS SEARCH TOOLBAR -->
        <v-toolbar elevation="0" v-if="showProjectsSelection">

          <v-autocomplete autofocus flat tile label="Select Project" width="100%" v-model="projectId"
            :items="selectableProjects"></v-autocomplete>
        </v-toolbar>
        <!-- PROJECT TABS -->
        <v-tabs v-model="tabs" v-if="projectTabs.length > 0">
          <v-tab style="background-color: rgba(255, 255, 255, 1)" v-for="(project, projectTabIndex) in projectTabs" :key="'projectTab-'+projectTabIndex">
            <div style="font-size: .8em" v-if="project !== undefined" @mouseenter="project.hover = true; $forceUpdate();" @mouseleave="project.hover = false;$forceUpdate();">
              {{ project.title }} <v-progress-circular v-if="project.loading" indeterminate color="primary"></v-progress-circular>

              <v-icon @click="() => {
                projectTabs.splice(projectTabIndex, 1)
                if (projectTabs.length === 0) {
                  showProjectsSelection = true;
                  projectId = null
                }
              }" v-show="project.hover">mdi-close</v-icon>
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(project, projectIndex) in projectTabs" :key="'project-'+projectIndex">
            <div v-if="project !== undefined && project !== null && projectEdit !== undefined && project.DataTableItems !== undefined" style="margin-top: .5em; margin-bottom: .5em;">
              <!--  PROJECT DETAILS -->
              <h3>
                <span @click="project.details = !project.details; $forceUpdate();" style="cursor: pointer;">
                  <v-icon>{{ project.details ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  Project Details
                </span>
              </h3>
              <v-fade-transition>
                <v-layout row wrap v-if="project.details" style="margin-top: 1em; background-color: rgba(0, 0, 0, .01)">
                  <v-flex xs12 style="margin-top: 1em;">
                    <v-toolbar flat style="background-color: rgba(0, 0, 0, 0)">
                      <v-btn-toggle tile style="background-color: rgba(0, 0, 0, .05)">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn icon text v-on="on" tile @click="dialog.edit.open()">
                              <v-icon>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          Edit Project Details
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{on}">
                            <v-btn v-on="on" :disabled="project.history.length < 1" icon @click="dialog.history.project.open(project)"
                            >
                              <v-icon
                                color="primary"
                              >
                                mdi-history
                              </v-icon>
                            </v-btn>
                          </template>
                          Project History
                        </v-tooltip>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn icon text v-on="on" tile @click="dialog.clone.project.open(project)">
                              <v-icon>
                                mdi-content-copy
                              </v-icon>
                            </v-btn>
                          </template>
                          Clone Project
                        </v-tooltip>
                      </v-btn-toggle>
                    </v-toolbar>
                  </v-flex>
                  <v-flex xs12 style="padding: 1em;" >
                    <v-layout justify-left>
                      <v-flex xs4>
                        <h2>Project Information</h2>
                        <table style="width: 100%; text-align: left;">
                          <tr>
                            <td style="width: 50%;">
                              <h4>Title</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.title }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Project Definition</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.project_definition_existing_project }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>End Client Name</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.end_client_name }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Project Location</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.project_location }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Bill Rate Currency</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_rate_currency }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Client PM Name </h4>
                            </td>
                            <td style="width: 50%;">
                              {{ `${project.client_project_manager.name}` }} 
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Client PM Email </h4>
                            </td>
                            <td style="width: 50%;">
                              {{ `${project.client_project_manager.email}` }} 
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                      <v-flex xs4>
                        <h2>Bill To Customer</h2>
                        <table style="width: 100%; text-align: left;">
                          <tr>
                            <td style="width: 50%;">
                              <h4>Name</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.name }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Email</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.email_address }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Address</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.address.street  }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>City</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.address.city }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>State</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.address.state}}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>ZIP</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.address.zip}}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Phone</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_to_customer.phone }}
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                    </v-layout>
                    <v-layout justify-left style="margin-top: 1em;">
                      <v-flex xs4>
                        <h2>Terms</h2>
                        <table style="width: 100%; text-align: left;">
                          <tr>
                            <td style="padding-right: 1em;">
                              <h4>Bill Rate Currency</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.bill_rate_currency }}
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-right: 1em;">
                              <h4>Payment Terms</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.payment_terms }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Purchase Order Number</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.purchase_order_number }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Billing Cycle</h4>
                              
                            </td>
                            <td style="width: 50%;">
                              {{ project.billing_cycle }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Standard Work Week (Hrs)</h4>
                              
                            </td>
                            <td style="width: 50%;">
                              {{ project.standard_work_week}}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Expenses</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.expenses }}
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                      <v-flex xs4>
                        <h2>Commission Structure</h2>
                        <table style="width: 100%;">
                          <tr>
                            <td style="width: 50%;">
                              <h4>Practice</h4>
                            </td>
                            <td style="width: 50%;">
                              {{ project.partner_or_practice }}
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>Ownership</h4>
                            </td>
                            <td style="width: 50%;">
                              <ul>
                                <li v-for="(owner, index) in project.owners" :key="'projectOwner-'+index">
                                  <span v-if="owner !== null">{{ owner.name }} {{ owner.percentageOfOwnership + " %"}}</span>
                                </li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <td style="width: 50%;">
                              <h4>
                                Sales Commission
                              </h4>
                              
                            </td>
                            <td style="width: 50%;">
                              <ul v-if="project.salesCommission.length > 0">
                                <li v-for="(seller, index) in project.salesCommission" :key="'salesCommission-'+index">
                                  {{ seller.name }} {{ seller.percentageOfSales + " %"}}
                                </li>
                              </ul>
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-fade-transition>
              <!-- PROJECT RESOURCE POOL -->
              <br>
              <h3>
                <span @click="project.resourcePool = !project.resourcePool; $forceUpdate();" style="cursor: pointer;">
                  <v-icon>{{ project.resourcePool ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
                  Resource Pool
                </span>
              </h3>
              <v-fade-transition>
                <v-data-table
                  v-if="project.resourcePool"
                  :key="'projectDataTable-'+project.render"
                  :loading="project.loading"
                  fixed-header
                  height="36em"
                  :footer-props="{
                      itemsPerPageOptions:[5,10,-1]
                  }"
                  :page="project.page"
                  @update:items-per-page="(val) => {
                    itemsPerPage = val
                    $forceUpdate()
                  }"
                  @update:page="(val) => {
                    project.DataTableItems.forEach((item, index) => {
                      if (index+1 <= project.page * itemsPerPage && index+1 > (project.page-1) * itemsPerPage) {
                        $set(item, 'selected', false)
                      }
                    })
                    $set(project, 'page', val);
                    $set(project, 'selected', false)
                    $forceUpdate()
                  }"
                  :items-per-page="itemsPerPage"
                  :headers="[
                    { text: 'Name', value: 'name', width: '9.7%' },
                    { text: 'Role', value: 'projectRateCard.role', width: '9.7%' },
                    { text: 'Customer Bill Rate', value: 'projectRateCard.billRateToCustomer', width: '9.7%' }, 
                    { text: 'Cost Rate Currency', value: 'projectRateCard.costRateCurrency', width: '9.7%' }, 
                    { text: 'Resource Cost Rate', value: 'projectRateCard.costRateOfResource', width: '9.7%' }, 
                    { text: 'Start Date', value: 'projectRateCard.pricingDateFrom', width: '9.7%' }, 
                    { text: 'End Date', value: 'projectRateCard.pricingDateTo', width: '9.7%' },
                    { text: `Est. Work ${projectWorkEstimationInDays ? 'Days' : 'Hrs'}`, value: 'projectRateCard.estimatedWork', width: '9.7%' },
                    { text: 'Payment Terms', value: 'projectRateCard.payment_terms' , width: '9.7%'},
                    { text: 'Actions', value: 'actions', sortable: false , width: '5%'}
                  ]"
                  :items="project.DataTableItems || []"
                  item-key="_id"
                  calculate-widths
                  group-by="hasProjectRateCard"
                  show-select
                >

                  <!-- <template v-slot:[`item.name`]="{ item }">
                     <router-link :to="{path: `/company/${company._id}/onboarding-profile/${item.projectRateCard.onboarding_id}`}">{{ item.name }}</router-link> 
                  </template> -->
                  <template v-slot:[`group.header`]="{ group, groupBy, headers, toggle, isOpen }">
                    <td :colspan="headers.length">
                      <v-btn @click="toggle" icon><v-icon> {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon></v-btn>
                      {{ groupBy[0] === 'hasProjectRateCard' ? !group ? 'No Project Rate Card' : 'Has Project Rate Card' : groupBy[0] + ': ' + group }} 
                    </td>
                    
                  </template>
                  <template v-slot:top=" { items } ">
                    <v-toolbar flat style="">
                      <v-btn-toggle tile borderless>
                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-btn tile icon v-on="on" @click="project.editMode = !project.editMode; $forceUpdate();">
                              <v-icon :color="`${project.editMode ? 'warning' : 'black'}`">mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          Edit Mode
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-badge color="success" overlap :value="selectedResourceWithoutProjectRateCard(project).length > 0" :content="selectedResourceWithoutProjectRateCard(project).length > 0 ? selectedResourceWithoutProjectRateCard(project).length : ''">
                              <v-btn tile icon v-on="on" @click="massAddProjectRateCards(project, selectedResourceWithoutProjectRateCard(project))" :disabled="selectedResourceWithoutProjectRateCard(project).length < 1">
                                <v-icon color="success">mdi-card</v-icon>
                              </v-btn>
                            </v-badge>
                          </template>
                          Mass Add Rate Cards to Selected Resources
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-badge color="error" overlap :value="selectedResource(project).length > 0" :content="selectedResource(project).length > 0 ? selectedResource(project).length : ''">
                              <v-btn tile icon v-on="on" @click="deleteItems(project, selectedResource(project))" :disabled="selectedResource(project).length < 1">
                                <v-icon color="error">mdi-eraser</v-icon>
                              </v-btn>
                            </v-badge>
                          </template>
                          Mass Remove Selected Resources
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-badge color="primary" overlap 
                              :value="validSaveableItems(items).length >= 1" 
                              :content="validSaveableItems(items).length >= 1 ? validSaveableItems(items).length : ''"
                            >
                              <v-btn tile icon v-on="on" @click="saveItems(project, validSaveableItems(items))" :disabled="validSaveableItems(items).length < 1">
                                <v-icon color="primary">mdi-content-save</v-icon>
                              </v-btn>
                            </v-badge>
                          </template>
                          Mass Save All Edited
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{on}">
                            <v-btn 
                              v-on="on" 
                              icon 
                              @click="dialog.sow.status(project)" :disabled="items.filter(item => item.contractor && item.hasProjectRateCard).length === 0"
                              :loading="sowStatusLoading"
                            >
                              <v-icon
                                color="warning"
                              >
                                mdi-information
                              </v-icon>
                            </v-btn>
                          </template>
                          Check SoW Status
                        </v-tooltip>
                      </v-btn-toggle>
                      <v-switch style="margin-left: 1em; margin-top: 1.2em;" v-model="projectWorkEstimationInDays" :label="`${projectWorkEstimationInDays ? 'Days' : 'Hours'}`"></v-switch>
                      <v-spacer></v-spacer>
                      <v-text-field multiple label="Search Resources" v-model="project.resourceTableSearch" @change="() => {
                        $set(project, 'DataTableItems', resourceDataTableItems(project))
                      }"></v-text-field>
                    </v-toolbar>
                  </template>
                  <!-- <template v-slot:body="{ items }">
                    <tbody>
                      <tr 
                        v-for="(item) in items" 
                        :key="`table-row-${project._id}-${item._id}`"
                        :style="`background-color: ${validSaveableItem(item) ? 'rgba(0, 200, 100, .1)' : Object.keys(item.edited).some(key => item.edited[key].state) ? 'rgba(244, 208, 63, .1)' : item.selected ? 'rgba(0, 0, 200, .1)' : 'rgba(0, 0, 0, 0)'}`"
                      >

                      </tr>
                    </tbody>
                    
                  </template> -->

                  <template v-slot:[`header.data-table-select`]>
                    <v-checkbox
                      v-model="project.selected" 
                      :indeterminate="project.selected && project.DataTableItems.some(dti => !dti.selected)" 
                      @change="(val) => {
                        project.DataTableItems.filter((item, index) => {
                          if (itemsPerPage !== -1) {
                            if (index+1 <= project.page * itemsPerPage && index+1 > ((project.page - 1) * itemsPerPage))
                              return true
                            return false
                          } else {
                            return true
                          }
                        }).forEach((item, index) => {
                          $set(item, 'selected', val)
                        })
                        $forceUpdate()
                      }"
                    ></v-checkbox>
                  </template>

                  <template v-slot:item="{ item }">
                    <tr :key="`table-row-${project._id}-${item._id}`"
                        :style="`background-color: ${validSaveableItem(item) ? 'rgba(0, 200, 100, .1)' : Object.keys(item.edited).some(key => item.edited[key].state) ? 'rgba(244, 208, 63, .1)' : item.selected ? 'rgba(0, 0, 200, .1)' : 'rgba(0, 0, 0, 0)'}`"
                      >
                        <td :key="'selected-'+project._id+'-'+item._id+'-'+item.render">
                          <v-checkbox v-model="item.selected" @change="() => {
                            if (project.selected) {
                              if (!project.DataTableItems.filter((item, index) => {
                                if (itemsPerPage !== -1) {
                                  if (index+1 <= project.page * itemsPerPage && index+1 > ((project.page - 1) * itemsPerPage))
                                    return true
                                  return false
                                } else {
                                  return true
                                }
                              }).some(dti => dti.selected))
                                $set(project, 'selected', false);
                              $forceUpdate()
                            } else if (!project.DataTableItems.filter((item, index) => {
                              if (itemsPerPage !== -1) {
                                if (index+1 <= project.page * itemsPerPage && index+1 > ((project.page - 1) * itemsPerPage))
                                  return true
                                return false
                              } else {
                                return true
                              }
                            }).some(dti => !dti.selected)) {
                              $set(project, 'selected', true)
                              $forceUpdate()
                            }
                          }"></v-checkbox>
                        </td>
                        <td>
                          <router-link :to="{path: `/company/${company._id}/onboarding-profile/${item.projectRateCard.onboarding_id}`}">{{ item.name }}</router-link> 
                        </td>
                        <td >
                          <v-text-field
                          :key="'editMode-role-' + project._id + '-' + item._id+'-'+item.render"

                            :disabled="!project.editMode"
                            v-model="item.projectRateCard.role"
                            :hint="item.edited['role'].state ? 'edited' : ''"
                            persistent-hint
                            @focus="() => {
                              if (item.edited['role'].val === null) {
                                item.edited['role'].val = item.projectRateCard.role
                              }
                            }"
                            @change="(val) => { item.edited['role'] = item.edited['role'].val !== val }"
                          >
                          </v-text-field>
                        </td>
                        <td >
                          <v-text-field
                          :key="'editMode-billRateToCustomer-'+project._id+'-'+item._id+'-'+item.render"

                            @focus="() => {
                              if (item.projectRateCard.billRateToCustomer.substr(1) === '0.00') {
                                item.projectRateCard.billRateToCustomer = ''
                                $forceUpdate()
                              }
                              if (item.edited['billRateToCustomer'].val === null) {
                                item.edited['billRateToCustomer'].val = item.projectRateCard.billRateToCustomer
                              }
                            }"
                            :disabled="!project.editMode"
                            v-currency="{
                              currency: project.bill_rate_currency || 'USD',
                              locale: 'en',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              'allow-negative': false,
                              distractionFree: true,
                              min: 0,
                              max: null
                            }"
                            :hint="item.edited['billRateToCustomer'].state ? 'edited' : ''"
                            :persistent-hint="true"
                            
                            v-model="item.projectRateCard.billRateToCustomer"
                            @change="(val) => {
                                item.edited['billRateToCustomer'].state = item.edited['billRateToCustomer'].val !== val
                            }"
                          >
                          </v-text-field>
                        </td>
                        <td >
                          <v-autocomplete
                          :key="'editMode-costRateCurrency-'+project._id+'-'+item._id+'-'+item.render"
                            @focus="() => {
                              if (item.edited['costRateCurrency'].val === null) {
                                item.edited['costRateCurrency'].val = item.projectRateCard.costRateCurrency
                              }
                            }"
                            
                            :disabled="!project.editMode || !item.contractor"
                            v-model="item.projectRateCard.costRateCurrency"
                            :items="Object.keys($store.state.currencies)"
                            label="Select Currency"
                            @change="item.edited['costRateCurrency'].state = item.edited['costRateCurrency'].val !== val;"
                            :hint="item.edited['costRateCurrency'].state ? 'edited' : ''"
                            persistent-hint
                          ></v-autocomplete>
                        </td>
                        <td >
                          <v-text-field
                            :key="'editMode-costRateOfResource-'+project._id+'-'+item._id+'-'+item.render"
                            @focus="() => {
                              if (item.projectRateCard.costRateOfResource.substr(1) === '0.00') {
                                item.projectRateCard.costRateOfResource = ''
                                $forceUpdate()
                              }
                              if (item.edited['costRateOfResource'].val === null) {
                                item.edited['costRateOfResource'].val = item.projectRateCard.costRateOfResource
                              }
                            }"
                            :disabled="!project.editMode || !item.contractor"
                            v-currency="{
                              currency: item.projectRateCard.costRateCurrency || 'USD',
                              locale: 'en',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              'allow-negative': false,
                              distractionFree: true,
                              min: 0,
                              max: null
                            }"
                            :hint="item.edited['costRateOfResource'].state ? 'edited' : ''"
                            :persistent-hint="true"
                            
                            v-model="item.projectRateCard.costRateOfResource" 
                            @change="(val) => {
                              item.edited['costRateOfResource'].state = item.edited['costRateOfResource'].val !== val;
                            }"
                          >
                          </v-text-field>
                        </td>
                        <td >
                          <v-text-field 
                            :key="'editMode-pricingDateFrom-'+project._id+'-'+item._id+'-'+item.render"
                            :disabled="!project.editMode"
                            v-model="item.projectRateCard.pricingDateFrom"
                            label="Start Date (MM/DD/YYYY)"
                            :hint="item.edited['pricingDateFrom'].state ? 'edited' : ''"
                            :error="item.errors['pricingDateFrom'].state"
                            :error-messages="item.errors['pricingDateFrom'].val"
                            persistent-hint
                            @focus="() => {
                              if (item.edited['pricingDateFrom'].val === null) {
                                item.edited['pricingDateFrom'].val = item.projectRateCard.pricingDateFrom
                              }
                            }"
                            @change="(val) => {
                              changePricingDate(project, item, 'pricingDateFrom', val)
                            }"
                            v-mask="'##/##/####'"
                          >
                          </v-text-field>
                        </td>
                        <td >
                          <v-text-field :key="'editMode-pricingDateTo-'+project._id+'-'+item._id+'-'+item.render"
                            v-model="item.projectRateCard.pricingDateTo"
                            :disabled="!project.editMode"
                            label="End Date (MM/DD/YYYY)"
                            :hint="item.edited['pricingDateTo'].state ? 'edited' : ''"
                            :error="item.errors['pricingDateTo'].state"
                            :error-messages="item.errors['pricingDateTo'].val"
                            persistent-hint
                            @focus="() => {
                              if(item.edited['pricingDateTo'].val === null) {
                                item.edited['pricingDateTo'].val = item.projectRateCard.pricingDateTo
                              }
                            }"
                            @change="(val) => {
                              changePricingDate(project, item, 'pricingDateTo', val)
                            }"
                            v-mask="'##/##/####'"
                          >
                          </v-text-field>
                        </td>
                        <td >
                          <v-text-field :key="'editMode-estimatedWork-'+project._id+'-'+item._id+'-'+item.render"
                            :disabled="!project.editMode"
                            v-model="item.projectRateCard.estimatedWork"
                            :label="`Est. Work ${projectWorkEstimationInDays ? 'Days' : 'Hours'}`"
                            type="number"
                            :rules="[
                              () => item.projectRateCard.estimatedWork > -1 || 'No negative'
                            ]"
                            :hint="item.edited['estimatedWork'].state ? 'edited' : ''"
                            persistent-hint
                            @change="(val) => {
                                item.edited['estimatedWork'].state = item.edited['estimatedWork'].val !== val;
                            }"
                            @focus="() => {
                              if ( item.edited['estimatedWork'].val === null) {
                                item.edited['estimatedWork'].val = item.projectRateCard.estimatedWork
                              }
                            }"
                          >
                          </v-text-field>
                        </td>
                        
                        
                        <td >
                          <v-autocomplete label="Payment Terms"
                            :key="'editMode-paymentTerms-'+project._id+'-'+item._id+'-'+item.render"
                            :disabled="!project.editMode || !item.contractor"
                            v-model="item.projectRateCard.payment_terms" 
                            :items="paymentTermsItems"
                            :persistent-hint="true"
                            :hint="item.edited['payment_terms'].state ? 'edited' : ''"
                            @focus="() => {
                              if (item.edited['payment_terms'].val === null) {
                                item.edited['payment_terms'].val = item.projectRateCard.payment_terms
                              }
                            }"
                            @change="(val) => {
                              item.edited['payment_terms'].state = item.edited['payment_terms'].val !== val;
                              if (paymentTermsItems.map(x => x.value).indexOf(item.projectRateCard.payment_terms) !== paymentTermsItems.map(x => x.value).indexOf(project.payment_terms)) {
                                if (!item.projectRateCardPaymentTermsConfirmed) {
                                  $AlertService.newAlert({
                                    message: 'Project Payment Terms: '+project.payment_terms+'. Project Rate Card Payment Terms: '+item.projectRateCard.payment_terms,
                                    timeout: 5,
                                    show: true,
                                    color: 'warning'
                                  })
                                  item.projectRateCardPaymentTermsConfirmed = true;
                                }
                              } else {
                                item.projectRateCardPaymentTermsConfirmed = false;
                              }
                            }"
                          ></v-autocomplete>
                        </td>
                        <td :key="'actions-'+project._id+'-'+item._id+'-'+item.render">
                          <v-btn-toggle style="background-color: rgba(0, 0, 0, 0);" tile borderless>
                            <v-tooltip bottom>
                              <template v-slot:activator="{on}">
                                <v-btn v-on="on" icon small @click="dialog.clone.projectRateCard.open(project, item)" v-if="item.hasProjectRateCard">
                                  <v-icon
                                    color="rgba(100, 50, 250, 1)"
                                  >
                                    mdi-content-copy
                                  </v-icon>
                                </v-btn>
                              </template>
                              Clone
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{on}">
                                <v-btn v-on="on" v-if="item.contractor" small icon @click="dialog.sow.open(project, item)"
                                  :disabled="!item.hasProjectRateCard"
                                  :loading="sowStatusLoading && item.hasProjectRateCard"
                                >
                                  <v-icon
                                    :color="item.sowStatus === undefined ? 'primary' : item.sowStatus === 'SIGNED' ? 'success' : item.sowStatus === 'CANCELLED' ? 'deep-orange' : item.sowStatus === 'OUT_FOR_SIGNATURE' ? 'warning' : item.sowStatus === 'NONE' ? 'primary' : 'error'"
                                  >
                                    mdi-file-document-edit
                                  </v-icon>
                                </v-btn>
                              </template>
                              Statement of Work {{ item.sowStatus === undefined ? '' : '--- '+item.sowStatus  }}
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{on}">
                                <v-btn small v-on="on" :disabled="item.projectRateCard.history.length < 1" icon @click="dialog.history.projectRateCard.open(project, item)"
                                >
                                  <v-icon
                                    color="primary"
                                  >
                                    mdi-history
                                  </v-icon>
                                </v-btn>
                              </template>
                              Project Rate Card History
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{on}">
                                <v-btn small v-on="on" :disabled="!Object.keys(item.edited).some(key => item.edited[key].state)" icon @click="() => {
                                    $set(project.DataTableItems[project.DataTableItems.indexOf(project.DataTableItems.filter(i => i._id === item._id)[0])], 'projectRateCard', JSON.parse(JSON.stringify($store.state.projectRateCards.filter(prc => prc._id === item.projectRateCard._id)[0])))
                                    Object.keys(item.edited).forEach(key => {
                                      item.edited[key].state = false;
                                      item.edited[key].val = null;
                                      item.errors[key].state = false;
                                      item.errors[key].val = null;
                                    })
                                    $nextTick(() => {
                                      $forceUpdate()
                                    })
                                  }"
                                >
                                  <v-icon
                                    color="warning"
                                  >
                                    mdi-cancel
                                  </v-icon>
                                </v-btn>
                              </template>
                              Discard Changes
                            </v-tooltip>

                            
                          </v-btn-toggle>
                        </td>
                    </tr>
                  </template>

                  <template v-slot:[`body.append`]="{ headers }">
                    <tr :key="'project-additionalResource-selection-'+project.render"
                      v-if="project.additionalResource !== undefined && project.additionalResource !== null && project.additionalResource.name === '' && project.additionalResource.email === '' && project.additionalResource._id === ''">
                      <td :colspan="headers.length">
                        <v-autocomplete autofocus label="Select Resource" v-model="project.selectedAdditionalResource" @change="(selectedOnboarding) => {
                            project.additionalResource.name = selectedOnboarding.firstName + ' ' + selectedOnboarding.lastName;
                            project.additionalResource.email = selectedOnboarding.cognitusEmailMailbox + selectedOnboarding.cognitusEmailDomain;
                            project.additionalResource.jobTitle = selectedOnboarding.jobTitle;
                            project.additionalResource._id = selectedOnboarding._id;
                            timeouts[project._id][project.additionalResource._id] = {}
                            project.additionalResource.contractor = selectedOnboarding.contractor;
                            project.additionalResource.projectRateCard.role = selectedOnboarding.jobTitle;
                            project.additionalResource.isSelectable = false;
                            if (project.additionalResource.projectRateCard.role !==  '') {
                              project.additionalResource.edited['role'].val = ''
                              project.additionalResource.edited['role'].state = true;
                            }
                            project.additionalResource.projectRateCard.onboarding_id = selectedOnboarding._id;
                            project.additionalResource.projectRateCard.payment_terms = project.payment_terms;
                            project.additionalResource.edited['payment_terms'].state = true;
                            if (!project.additionalResource.contractor) {
                              project.additionalResource.projectRateCard.costRateOfResource = selectedOnboarding.costRate
                              project.additionalResource.edited['costRateOfResource'].state = true;
                              project.additionalResource.projectRateCard.costRateCurrency = selectedOnboarding.currency
                              project.additionalResource.edited['costRateCurrency'].state = true;
                            }

                            let existingPrc = projectRateCards.filter(prc => prc._id === project.additionalResource.projectRateCard.onboarding_id).sort((a, b) => new Date(b.lastChange) - new Date(a.lastChange))[0]
                            if (existingPrc !== undefined) {
                              project.additionalResource.projectRateCard.history = [];
                              let history = JSON.parse(JSON.stringify(existingPrc));
                              project.additionalResource.projectRateCard.history.concat(history.history)
                              delete history.history;
                              project.additionalResource.projectRateCard.history.push(history)
                              project.additionalResource.projectRateCard._id = existingPrc._id
                            }
                            project.additionalResource.render = $UUID().split('-')[0]
                            $forceUpdate();
                          }" :items="onboardings.filter(onb => doesNotExistInProjectResourcePool(project, onb) && !onb.terminated).map(onb => {
                          return {
                            text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
                            value: onb
                          }
                        })"></v-autocomplete>
                        
                      </td>
                    </tr>
                    <tr :key="'project-additionalResource-selected-'+project.render"
                      v-else-if="project.additionalResource !== undefined && project.additionalResource !== null && project.additionalResource.name !== '' && project.additionalResource.email !== '' && project.additionalResource._id !== ''">
                      <td>
                        <v-btn icon tile @click="project.additionalResource = null; project.selectedAdditionalResource = null; $forceUpdate();">
                          <v-icon color="error">mdi-minus</v-icon>
                        </v-btn>
                      </td>
                      <td>
                        <v-autocomplete label="Select Resource" v-model="project.selectedAdditionalResource" @change="(selectedOnboarding) => {
                            project.additionalResource.name = selectedOnboarding.firstName + ' ' + selectedOnboarding.lastName;
                            project.additionalResource.email = selectedOnboarding.cognitusEmailMailbox + selectedOnboarding.cognitusEmailDomain;
                            project.additionalResource.jobTitle = selectedOnboarding.jobTitle;
                            project.additionalResource._id = selectedOnboarding._id;
                            timeouts[project._id][project.additionalResource._id] = {}
                            project.additionalResource.contractor = selectedOnboarding.contractor;
                            project.additionalResource.projectRateCard.role = selectedOnboarding.jobTitle;
                            project.additionalResource.isSelectable = false;

                            if (project.additionalResource.projectRateCard.role !==  '') {
                              project.additionalResource.edited['role'].val = ''
                              project.additionalResource.edited['role'].state = true;
                            }
                            project.additionalResource.projectRateCard.onboarding_id = selectedOnboarding._id;
                            project.additionalResource.projectRateCard.payment_terms = project.payment_terms;
                            project.additionalResource.edited['payment_terms'].state = true;
                            if (!project.additionalResource.contractor) {
                              project.additionalResource.projectRateCard.costRateOfResource = selectedOnboarding.costRate
                              project.additionalResource.edited['costRateOfResource'].state = true;
                              project.additionalResource.projectRateCard.costRateCurrency = selectedOnboarding.currency
                              project.additionalResource.edited['costRateCurrency'].state = true;
                            }
                            let existingPrc = projectRateCards.filter(prc => prc._id === project.additionalResource.projectRateCard.onboarding_id).sort((a, b) => new Date(b.lastChange) - new Date(a.lastChange))[0]
                            if (existingPrc !== undefined) {
                              project.additionalResource.projectRateCard.history = [];
                              let history = JSON.parse(JSON.stringify(existingPrc));
                              project.additionalResource.projectRateCard.history.concat(history.history)
                              delete history.history;
                              project.additionalResource.projectRateCard.history.push(history)
                              project.additionalResource.projectRateCard._id = existingPrc._id
                            }
                            project.additionalResource.render = $UUID().split('-')[0]
                            $forceUpdate();
                          }" :items="onboardings.filter(onb => doesNotExistInProjectResourcePool(project, onb) && !onb.terminated).map(onb => {
                          return {
                            text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
                            value: onb
                          }
                        })"></v-autocomplete>
                      </td>
                      <td>
                        <v-text-field
                          :key="'additionalResource-role-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          label="Role"
                          v-model="project.additionalResource.projectRateCard.role" 
                          autofocus
                          @focus="() => {
                            if (project.additionalResource.edited['role'].val === null) {
                              project.additionalResource.edited['role'].val = project.additionalResource.projectRateCard.role
                            }
                          }"
                          @change="(val) => {
                            project.additionalResource.edited['role'].state = project.additionalResource.edited['role'].val !== val;
                          }"
                        >
                        </v-text-field>
                      </td>
                      <!-- <td>
                        <v-autocomplete
                          v-model="additionalResource.projectRateCard.bill_rate_currency"
                          :items="Object.keys($store.state.currencies)"
                          label="Select Currency"
                        ></v-autocomplete>
                      </td> -->
                      <td>
                        <v-text-field
                          :key="'additionalResource-billRateToCustomer-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          v-currency="{
                            currency: project.bill_rate_currency || 'USD',
                            locale: 'en',
                            autoDecimalMode: false,
                            decimalLength: 2,
                            'allow-negative': false,
                            distractionFree: true,
                            min: 0,
                            max: null
                          }"
                          v-model="project.additionalResource.projectRateCard.billRateToCustomer"
                          @focus="() => {
                            if (project.additionalResource.edited['billRateToCustomer'].val === null) {
                              project.additionalResource.edited['billRateToCustomer'].val = project.additionalResource.projectRateCard.billRateToCustomer
                            }
                          }"
                          @change="(val) => {
                            project.additionalResource.edited['billRateToCustomer'].state = project.additionalResource.edited['billRateToCustomer'].val !== val;
                          }"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete
                          :key="'additionalResource-costRateCurrency-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          :disabled="!project.additionalResource.contractor"
                          v-model="project.additionalResource.projectRateCard.costRateCurrency"
                          :items="Object.keys($store.state.currencies)"
                          label="Select Currency"
                          @focus="() => {
                            if (project.additionalResource.edited['costRateCurrency'].val === null) {
                              project.additionalResource.edited['costRateCurrency'].val = project.additionalResource.projectRateCard.costRateCurrency
                            }
                          }"
                          @change="(val) => {
                            project.additionalResource.edited['costRateCurrency'].state = project.additionalResource.edited['costRateCurrency'].val !== val;
                          }"
                        ></v-autocomplete>
                      </td>
                      <td>
                        <v-text-field
                          :key="'additionalResource-costRateOfResource-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          :disabled="!project.additionalResource.contractor"
                          v-currency="{
                            currency: project.additionalResource.projectRateCard.costRateCurrency || 'USD',
                            locale: 'en',
                            autoDecimalMode: false,
                            decimalLength: 2,
                            'allow-negative': false,
                            distractionFree: true,
                            min: 0,
                            max: null
                          }"
                          v-model="project.additionalResource.projectRateCard.costRateOfResource" 
                          @focus="() => {
                            if (project.additionalResource.edited['costRateOfResource'].val === null) {
                              project.additionalResource.edited['costRateOfResource'].val = project.additionalResource.projectRateCard.costRateOfResource
                            }
                          }"
                          @change="(val) => {
                            project.additionalResource.edited['costRateOfResource'].state = project.additionalResource.edited['costRateOfResource'].val !== val;
                          }"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :key="'additionalResource-pricingDateFrom-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          v-model="project.additionalResource.projectRateCard.pricingDateFrom"
                          label="Start Date"
                          :error="project.additionalResource.errors['pricingDateFrom'].state"
                          :error-messages="project.additionalResource.errors['pricingDateFrom'].val"
                          @focus="() => {
                            if (project.additionalResource.edited['pricingDateFrom'].val === null) {
                              project.additionalResource.edited['pricingDateFrom'].val = project.additionalResource.projectRateCard.pricingDateFrom
                            }
                          }"
                          @change="(val) => {
                            changePricingDate(project, project.additionalResource, 'pricingDateFrom', val)
                          }"
                          @input="() => {$forceUpdate()}"
                          v-mask="'##/##/####'"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :key="'additionalResource-pricingDateTo-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          v-model="project.additionalResource.projectRateCard.pricingDateTo"
                          label="End Date"
                          v-mask="'##/##/####'"
                          :error="project.additionalResource.errors['pricingDateTo'].state"
                          :error-messages="project.additionalResource.errors['pricingDateTo'].val"
                          @input="() => {$forceUpdate()}"
                          @focus="() => {
                            if (project.additionalResource.edited['pricingDateTo'].val === null) {
                              project.additionalResource.edited['pricingDateTo'].val = project.additionalResource.projectRateCard.pricingDateFrom
                            }
                          }"
                          @change="(val) => {
                            changePricingDate(project, project.additionalResource, 'pricingDateTo', val)
                          }"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          :key="'additionalResource-estimatedWork-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          ref="additionalestimatedWork"
                          :label="`Est. Work ${projectWorkEstimationInDays ? 'Days' : 'Hours'}`"
                          v-model="project.additionalResource.projectRateCard.estimatedWork" 
                          type="number"
                          :rules="[
                            () => !!project.additionalResource.projectRateCard.estimatedWork || 'Required',
                            () => project.additionalResource.projectRateCard.estimatedWork > -1 || 'No negative'
                          ]"
                          @focus="() => {
                            if (project.additionalResource.edited['estimatedWork'].val === null) {
                              project.additionalResource.edited['estimatedWork'].val = project.additionalResource.projectRateCard.estimatedWork
                            };
                          }"
                          @change="(val) => {
                            project.additionalResource.edited['estimatedWork'].state = val !== project.additionalResource.edited['estimatedWork'].val;
                          }"
                        >
                        </v-text-field>
                      </td>
                      <td>
                        <v-autocomplete 
                          :key="'additionalResource-payment_terms-'+project._id+'-'+project.additionalResource._id+'-'+project.additionalResource.render"
                          label="Payment Terms"
                          :disabled="!project.additionalResource.contractor"
                          v-model="project.additionalResource.projectRateCard.payment_terms" 
                          :items="paymentTermsItems.map(x => x.value)" 
                          @focus="() => {
                            if (project.additionalResource.edited['payment_terms'].val === null) {
                              project.additionalResource.edited['payment_terms'].val = project.additional.projectRateCard.payment_terms
                            }
                          }"
                          @change="(val) => {
                            project.additionalResource.edited['payment_terms'].state = val !== project.additionalResource.edited['payment_terms'].val;
                            if (paymentTermsItems.map(x => x.value).indexOf(project.additionalResource.projectRateCard.payment_terms) !== paymentTermsItems.map(x => x.value).indexOf(project.payment_terms)) {
                              if (!project.additionalResource.projectRateCardPaymentTermsConfirmed) {
                                project.additionalResource.projectRateCardPaymentTermsConfirmed = true;
                                $AlertService.newAlert({
                                  message: 'Payment Terms mismatch.....Project Payment Terms: '+project.payment_terms+'.....Project Rate Card Payment Terms: '+project.additionalResource.projectRateCard.payment_terms,
                                  timeout: 5,
                                  show: true,
                                  color: 'warning'
                                })
                              }
                            } else {
                              project.additionalResource.projectRateCardPaymentTermsConfirmed = false;
                            }
                          }"
                        >
                        </v-autocomplete>
                      </td>
                      <td>
                        <v-btn-toggle tile borderless style="background-color: rgba(0, 0, 0, 0);">
                          <v-btn :disabled="Object.keys(project.additionalResource.edited).filter(key => {
                              if (!project.additionalResource.contractor && ['costRateCurrency', 'costRateOfResource', 'payment_terms'].includes(key)) {
                                return false;
                              }
                              return true;
                            }).some(key => {
                              return !project.additionalResource.edited[key].state
                            })" tile icon @click="saveItem(project, project.additionalResource)">
                            <v-icon color="success">
                              mdi-content-save
                            </v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </td>
                    </tr>
                    <tr v-else-if="project.additionalResource === undefined || project.additionalResource === null">
                      <td :colspan="headers.length">
                        <v-card
                          @click="addSingleResource(project)" 
                          @mouseenter="hover.addResource = true" 
                          @mouseleave="hover.addResource = false" 
                          :dark="hover.addResource" 
                          flat tile 
                          :style="`transition: all .3s; height: 4.5em; cursor: ${ hover.addResource  ? 'pointer' : 'default'}`"
                        >
                          <v-card-title>
                            <v-icon color="success">mdi-plus</v-icon>Add Resource 
                          </v-card-title>
                        </v-card>
                      </td>
                      
                    </tr>
                  </template>
                </v-data-table>
              </v-fade-transition>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-flex>
  </v-layout>
  <v-layout v-else row wrap justify-center align-center>
    <v-flex xs11>
      No Access
    </v-flex>
  </v-layout>
</template>

<script>
  import { default as ProjectRateCardTemplate } from './ProjectRateCardTemplate.js';
  import { default as ProjectTemplate } from './ProjectTemplate.js';
  export default {
    props: ['path', 'company', 'project_id'],
    data() {
      return {
        adobeDCView: null,
        selectedClone: null,
        cloneProjectRateCards: true,
        cloneProject: null,
        clone: null,
        clones: [],
        hoveredSoW: -1,
        paymentTermsItems: [
          { text: 'NT10', value: 'NT10' },
          { text: 'NT30', value: 'NT30' },
          { text: 'NT45', value: 'NT45' },
          { text: 'NT60', value: 'NT60' },
          { text: 'NT90', value: 'NT90' },
          { text: 'On Receipt', value: 'On Receipt' }
        ],
        additionalResourcePricingDateFromTimeout: null,
        additionalResourcePricingDateToTimeout: null,
        timeouts: {},
        projectHistoryItem: null,
        resourceHistoryItem: null,
        externalSoWLink: '',
        loadingAgreementEvents: false,
        agreementEvents: {},
        loadingPdf: false,
        viewingPdf: false,
        sowTemplate: null,
        sowTemplateError: {
          'msaDate': { val: null, state: false}
        },
        loadingLibraryDocuments: false,
        libraryDocuments: [],
        loadingAdobeAgreements: false,
        adobeAgreements: [],
        additionalSalesCommission: null,
        additionalProjectOwner: null,
        onboardingsMappedToProjectOwners: [],
        existingProjectOwnersSelection: [],
        newProjectOwnersSelection: [],
        sowStatusLoading: false,
        loading: false,
        cards: [ {
          title: "Resource Pool",
          subtitle: "Manage the resources associated with this project",
          showText: true
        }],
        showProjectsSelection: true,
        tabs: -1,
        projectTabs: [],
        projectId: null,
        newProject: JSON.parse(JSON.stringify(ProjectTemplate)),
        projectEdit: null,
        hover: {
          importFromSharepoint: false,
          create: false,
          addResource: false,
          search: false
        },
        dialog: {
          runn: {
            import: {
              show: false,
              open: () => {
                this.dialog.runn.import.show = true;
              },
              close: () => {
                this.dialog.runn.import.show = false;
              }
            }
          },
          history: {
            project: {
              show: false,
              hover: false,
              open: (project) => {
                this.projectHistoryItem = JSON.parse(JSON.stringify(project));
                this.dialog.history.project.show = true;
              },
              hide: () => {
                this.projectHistoryItem = null;
                this.dialog.history.project.show = false;
              }
            },
            projectRateCard: {
              show: false,
              hover: false,
              open: (project, resource) => {
                this.resourceHistoryItem = JSON.parse(JSON.stringify(resource));
                this.dialog.history.projectRateCard.show = true;
              },
              hide: () => {
                this.resourceHistoryItem = null;
                this.dialog.history.projectRateCard.show = false;
              }
            }
          },
          sow: {
            status: (project) => {
              this.sowStatusLoading = true;
              this.getAgreements(true).then(() => {
                this.getLibraryDocuments(true).then(() => {
                  project.DataTableItems.forEach(dti => {

                    if (dti.hasProjectRateCard) {
                      const sow = this.adobeAgreements.filter(adobeAgreement => {
                          return adobeAgreement.name.startsWith("SoW") && (dti.projectRateCard.sow === adobeAgreement.id)
                      })
                      // console.log(sow)
                      dti.sowStatus = sow.length > 0 ? sow[0].status : 'NONE'
                      dti.render = this.$UUID().split('-')[0]
                    }

                  })
                  this.sowStatusLoading = false;
                })
              })
            },
            open: (project, resource) => {
              this.sowTemplate = {
                project: project,
                projectRateCard: resource.projectRateCard,
                legalEntityName: '',
                vendorName: '',
                msaDate: '',
                cognitusSigner: '',
                signedMSAs: [],
                sowNumber: this.company.sowNumber !== undefined && this.company.sowNumber !== null && this.company.sowNumber !== '' ? parseInt(this.company.sowNumber) + 1 : '',
                css: [],
                sows: []
              }
              this.sowTemplateError ={
                'msaDate': { val: null, state: false}
              }
              this.getAgreements(true).then(() => {
                this.getLibraryDocuments(true).then(() => {
                  
                  let onbAssociatedWithProjectRateCard = this.onboardings.filter(onb => onb._id === resource.projectRateCard.onboarding_id)[0];
                  if (onbAssociatedWithProjectRateCard !== undefined) {
                    this.getSFFoCompanies().then(companies => {
                      this.sowTemplate.legalEntityName = companies.find(c => c.payingLegalEntity === onbAssociatedWithProjectRateCard.sfPayingLegalEntity).name || "Cognitus Consulting LLC"
                      this.saveAdobeSignAgreementFieldData(onbAssociatedWithProjectRateCard, true).then((fieldData) => {

                        if (!Array.isArray(this.sowTemplate.projectRateCard.history)) {
                          this.sowTemplate.projectRateCard.history = [];
                        }
                        this.sowTemplate.sows = this.adobeAgreements.filter(adobeAgreement => {
                          return adobeAgreement.name.startsWith("SoW") && (
                            this.sowTemplate.projectRateCard.sow === adobeAgreement.id 
                            || this.sowTemplate.projectRateCard.history.some(h => h.sow === adobeAgreement.id)
                          )
                        }).map(sow => {
                          if (this.sowTemplate.projectRateCard.sow === sow.id) {
                            sow.active = true;
                          } else if (this.sowTemplate.projectRateCard.history.some(h => h.sow === sow.id)) {
                            sow.active = false;
                          }
                          return sow;
                        }).concat(this.sowTemplate.projectRateCard.history.filter(h => h.sow.startsWith('https')).map(h => h.sow))
                        this.sowTemplate.signedMSAs = this.adobeAgreements.filter(adobeAgreement => { 
                          return adobeAgreement.status === "SIGNED" && adobeAgreement.name.startsWith("MSA") && onbAssociatedWithProjectRateCard.adobeAgreements.includes(adobeAgreement.id)
                        }).map(signedMSA => {
                          return {
                            signedMSA: signedMSA,
                            fieldData: fieldData.filter(fd => fd.id === signedMSA.id)[0]
                          }
                        })
                        if (onbAssociatedWithProjectRateCard.msaDate !== undefined && onbAssociatedWithProjectRateCard.msaDate !== '') {
                          this.sowTemplate.msaDate = onbAssociatedWithProjectRateCard.msaDate
                          if (this.sowTemplate.msaDate.includes('-')) {
                            let splitMSADate = this.sowTemplate.msaDate.split('-')
                            this.sowTemplate.msaDate = splitMSADate[1]+'/'+splitMSADate[2].split(' ')[0]+'/'+splitMSADate[0]
                          }
                        }
                        if (onbAssociatedWithProjectRateCard.contractor && onbAssociatedWithProjectRateCard.contingentWorker) {
                          if (onbAssociatedWithProjectRateCard.vendorAddressSupplier === 'serviceprovider' && onbAssociatedWithProjectRateCard.vendorAddress.serviceproviderName !== '')
                            this.sowTemplate.vendorName = onbAssociatedWithProjectRateCard.vendorAddress.serviceproviderName
                          else
                            this.sowTemplate.vendorName = onbAssociatedWithProjectRateCard.firstName + " " + onbAssociatedWithProjectRateCard.lastName
                        }
                        this.dialog.sow.show = true
                      })
                    })
                  }
                })
              })
            },
            hide: () => {
              this.dialog.sow.show = false
              this.$nextTick(() => {
                this.sowTemplate = null
                this.sowTemplateError ={
                  'msaDate': { val: null, state: false}
                }
              })
            },
            show: false,
            buttons: {
              create: {
                hover: false,
                error: false,
                loading: false,
                click: () => {
                  this.dialog.sow.buttons.create.loading = true;
                  let sowLibraryDocument = null;
                  this.libraryDocuments.forEach(doc => {
                    if (doc.name === 'SoW' && doc.creatorEmail === 'adobe@cognitusconsulting.com') {
                      sowLibraryDocument = doc;
                    }
                  })
                  if (sowLibraryDocument !== null) {
                    let onbAssociatedWithProjectRateCard = this.onboardings.filter(onb => onb._id === this.sowTemplate.projectRateCard.onboarding_id)[0];
                    
                    if (onbAssociatedWithProjectRateCard !== undefined) {
                      
                        let ccs = this.sowTemplate.ccs.length > 0 ? this.sowTemplate.ccs.map(cc => {
                          if (typeof cc === 'object') {
                            cc = cc.value
                          }
                          return cc;
                        }) : [];
                        if (onbAssociatedWithProjectRateCard.vendorAddressSupplier === '' || (onbAssociatedWithProjectRateCard.vendorAddressSupplier !== 'serviceprovider' && onbAssociatedWithProjectRateCard.vendorAddressSupplier !== 'freelance')) {
                          this.$AlertService.newAlert({
                            message: 'Vendor Address Supplier in Onboarding is invalid, please update onboarding before sending SoW.',
                            timeout: 5,
                            show: true,
                            color: 'error'
                          })
                          return;
                        }
                        this.$BackendService.adobe.createAgreement({
                          libraryDocumentId: sowLibraryDocument.id,
                          agreement_name: sowLibraryDocument.name + ' for ' + onbAssociatedWithProjectRateCard.firstName + " " + onbAssociatedWithProjectRateCard.lastName,
                          signers: [
                            this.sowTemplate.cognitusSigner.value,
                            onbAssociatedWithProjectRateCard.vendorAddressSupplier === 'serviceprovider' ? onbAssociatedWithProjectRateCard.serviceProviderEmailAddress.trim() : onbAssociatedWithProjectRateCard.personalEmailAddress.trim()
                          ],
                          ccs: ccs
                        }).then(createdAgreement => {
                          this.$AlertService.newAlert({
                            message: 'Created Agreement',
                            timeout: 5,
                            show: true,
                            color: 'success'
                          })
                          this.$BackendService.company.updateSoWNumber({ company_id: this.company._id, sowNumber: this.sowTemplate.sowNumber}).then(() => {
                            this.$eventBus.$emit("getCompanies", { callback: () => {
                              let history = JSON.parse(JSON.stringify(this.sowTemplate.projectRateCard));
                              delete history.history;
                              this.sowTemplate.projectRateCard.history.push(history);
                              this.sowTemplate.projectRateCard.lastChange = (new Date());
                              this.sowTemplate.projectRateCard.sow = createdAgreement.id;
                              this.sowTemplate.projectRateCard.changedBy = this.$store.state.userADInfo.mail
                              this.$BackendService.projectRateCard.update({ projectRateCard: this.sowTemplate.projectRateCard, email: false}).then((updatedProjectRateCard) => {
                                this.$store.dispatch("UPDATE_PROJECT_RATE_CARD", updatedProjectRateCard)
                                let project = this.projectTabs.find(project => project._id === this.sowTemplate.project._id)
                                this.$set(project.DataTableItems[project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti.projectRateCard._id === this.sowTemplate.projectRateCard._id)[0])], 'projectRateCard', updatedProjectRateCard)
                                this.putAgreementFormFieldsMergeInfo(
                                  this.sowTemplate.projectRateCard.sow, 
                                  this.sowTemplate.legalEntityName,
                                  this.sowTemplate.vendorName, 
                                  this.sowTemplate.msaDate.includes(' ') ? this.sowTemplate.msaDate.split(" ")[0] : this.sowTemplate.msaDate,
                                  (new Date()).getFullYear() + "-" + parseInt(this.sowTemplate.sowNumber),
                                  onbAssociatedWithProjectRateCard.firstName + " " + onbAssociatedWithProjectRateCard.lastName,
                                  this.sowTemplate.projectRateCard.costRateOfResource,
                                  this.sowTemplate.projectRateCard.pricingDateFrom,
                                  this.sowTemplate.projectRateCard.pricingDateTo,
                                  this.sowTemplate.projectRateCard.role,
                                  this.sowTemplate.project.end_client_name,
                                  this.sowTemplate.project.project_location,
                                  this.sowTemplate.projectRateCard.payment_terms.replace("NT", "").replace("On Receipt", "0"),
                                  this.sowTemplate.project.expenses,
                                  this.sowTemplate.project.standard_work_week,
                                  this.sowTemplate.project.standard_work_week,
                                  this.sowTemplate.projectRateCard.estimatedWorkUnit === 'days' ? parseFloat(this.sowTemplate.projectRateCard.estimatedWork * 8) : parseFloat(this.sowTemplate.projectRateCard.estimatedWork)
                                  ).then((response) => {
                                    // swap to 
                                    
                                    // console.log(response);
                                    this.$nextTick(() => {
                                      setTimeout(() => {
                                        this.putAgreementState(this.sowTemplate.projectRateCard.sow, 'AUTHORING').then((response) => {
                                          setTimeout(() => {
                                            this.$nextTick(() => {
                                              this.putAgreementState(this.sowTemplate.projectRateCard.sow, 'IN_PROCESS').then((response) => {
                                                this.dialog.sow.buttons.create.loading = false;
                                                this.$nextTick(() => {
                                                  this.dialog.sow.show = false;
                                                  this.sowTemplate = null
                                                })
                                              }).catch(function(error) {
                                                this.dialog.sow.buttons.create.loading = false;
                                                this.dialog.sow.buttons.create.error = true;
                                                console.error(error);
                                              })
                                            })
                                          }, 2000)
                                        }).catch(function(error) {
                                          console.error(error);
                                          this.dialog.sow.buttons.create.loading = false;
                                          this.dialog.sow.buttons.create.error = true;
                                        })
                                      }, 2000)
                                    })
                                }).catch(function(error) {
                                  console.error(error);
                                  this.dialog.sow.buttons.create.loading = false;
                                  this.dialog.sow.buttons.create.error = true;
                                })
                              }).catch(error => {
                                console.error(error);
                                this.dialog.sow.buttons.create.loading = false;
                                this.dialog.sow.buttons.create.error = true;
                              })
                            }})
                          }).catch(error => {
                            console.error(error);
                            this.dialog.sow.buttons.create.loading = false;
                            this.dialog.sow.buttons.create.error = true;
                          })
                        }).catch(error => {
                          console.error(error);
                          this.dialog.sow.buttons.create.loading = false;
                          this.dialog.sow.buttons.create.error = true;
                        })
                    }
                    
                  } else {
                    this.$AlertService.newAlert({
                      message: "Failed to find SoW Library Document",
                      timeout: 5,
                      show: true,
                      color: 'error'
                    })
                  }
                  
                }
              },
              cancel: {
                hover: false,
                click: () => {
                  this.dialog.sow.show = false;
                  this.$nextTick(() => {
                    this.sowTemplate = null
                    this.sowTemplateError ={
                      'msaDate': { val: null, state: false}
                    }
                  })
                }
              }
            }
          },
          create: {
            open: () => {
              this.newProject = JSON.parse(JSON.stringify(ProjectTemplate));
              this.newProject.company_id = this.company._id;
              this.newProject._id = this.$UUID();
              this.$router.replace({ path: `/company/${this.company._id}/project-new/${this.newProject._id}`})
              this.dialog.create.show = true
            },
            hide: () => {
              this.dialog.create.show = false
              this.newProjectDrafts = JSON.parse(JSON.stringify(this.newProject));
              this.$router.replace({ path: `/company/${this.company._id}/project`})
            },
            show: false,
            buttons: {
              
              create: {
                disabled: () => {
                  return this.newProject === null || this.newProject.owners.length < 1 ||
                  this.newProject.title === "" ||
                  // this.newProject.owners.project_definition_existing_project: "",
                  this.newProject.end_client_name === "" ||
                  // this.newProject.project_location: "",
                  this.newProject.client_project_manager.name === "" ||
                  this.newProject.client_project_manager.email === "" ||
                  !this.newProject.client_project_manager.email.includes('@') ||
                  this.newProject.partner_or_practice === "" ||
                  this.newProject.bill_to_customer.name === "" ||
                  this.newProject.bill_to_customer.phone === "" ||
                  this.newProject.bill_to_customer.email_address === "" ||
                  !this.newProject.bill_to_customer.email_address.includes("@") ||
                  this.newProject.payment_terms === "" ||
                  // purchase_order_number: "",
                  this.newProject.billing_cycle === "" ||
                  this.newProject.standard_work_week === "" ||
                  this.newProject.expenses === ""
                },
                hover: false,
                click: () => {
                  this.newProject.resources = this.newProject.resources.map(r => r.value._id)
                  this.newProject.lastChange = new Date();
                  this.newProject.changedBy = this.$store.state.userADInfo.mail
                  this.$BackendService.project.create({ project: this.newProject, email: true}).then((createdProject) => {
                    this.$store.dispatch("NEW_PROJECT", this.newProject);
                    this.$router.replace({ path: `/company/${this.company._id}/project/${this.newProject._id}`})
                    this.$AlertService.newAlert({
                      message: 'Created project: ' + this.newProject.title,
                      color: 'success',
                      timeout: 5,
                      show: true
                    })
                    this.newProject = JSON.parse(JSON.stringify(ProjectTemplate));
                    this.dialog.create.show = false;
                    this.$forceUpdate();
                  }).catch(error => {
                    this.$AlertService.newAlert({
                      message: '',
                      color: 'error',
                      timeout: 5,
                      show: true
                    })
                  })
                }
              },
              clear: {
                hover: false,
                click: () => {
                  this.newProject = JSON.parse(JSON.stringify(ProjectTemplate))
                  this.newProject._id = this.$UUID();
                  this.newProject.company_id = this.company._id
                }
              },
              cancel: {
                hover: false,
                click: () => {
                  this.newProject = JSON.parse(JSON.stringify(ProjectTemplate));
                  this.dialog.create.show = false;
                  this.$router.replace({ path: `/company/${this.company._id}/project`})
                }
              }
            }
          },
          edit: {
            open: () => {
              this.projectEdit = JSON.parse(JSON.stringify(this.projectTabs[this.tabs]));
              Object.keys(this.projectTemplate).forEach(key => {
                if (this.projectEdit[key] === undefined) {
                  this.projectEdit[key] = JSON.parse(JSON.stringify(this.projectTemplate[key]))
                }
              })
              this.$router.replace({ path: `/company/${this.company._id}/project-edit/${this.projectEdit._id}`})
              this.dialog.edit.show = true
            },
            hide: () => {
              this.dialog.edit.show = false
              this.$router.replace({ path: `/company/${this.company._id}/project/${this.projectEdit._id}`})
              this.projectEdit = null;
            },
            show: false,
            buttons: {
              
              save: {
                disabled: () => {
                  return this.projectEdit === null || this.projectEdit.owners.length < 1 ||
                  this.projectEdit.title === "" ||
                  // this.projectEdit.owners.project_definition_existing_project: "",
                  this.projectEdit.end_client_name === "" ||
                  // this.projectEdit.project_location: "",
                  this.projectEdit.client_project_manager.name === "" ||
                  this.projectEdit.client_project_manager.email === "" ||
                  !this.projectEdit.client_project_manager.email.includes('@') ||
                  this.projectEdit.partner_or_practice === "" ||
                  this.projectEdit.bill_to_customer.name === "" ||
                  this.projectEdit.bill_to_customer.phone === "" ||
                  this.projectEdit.bill_to_customer.email_address === "" ||
                  !this.projectEdit.bill_to_customer.email_address.includes("@") ||
                  this.projectEdit.payment_terms === "" ||
                  // purchase_order_number: "",
                  this.projectEdit.billing_cycle === "" ||
                  this.projectEdit.standard_work_week === "" ||
                  this.projectEdit.expenses === ""
                },
                hover: false,
                click: () => {
                  // this.projectEdit.resources.map(r => r.value._id)
                  let history = JSON.parse(JSON.stringify(this.projects.filter(p => p._id === this.projectEdit._id)[0]));
                  delete history.history;
                  this.projectEdit.history.push(history);
                  this.projectEdit.owners = this.projectEdit.owners.filter(ow => ow !== null && ow !== undefined);
                  this.projectEdit.lastChange = new Date()
                  this.projectEdit.changedBy = this.$store.state.userADInfo.mail
                  this.$BackendService.project.update({project: this.projectEdit, email: true}).then((updatedProject) => {
                    this.$store.dispatch("NEW_PROJECT", this.projectEdit);
                    this.dialog.edit.show = false;
                    this.$router.replace({ path: `/company/${this.company._id}/project/${this.projectEdit._id}`})
                    this.$AlertService.newAlert({
                      message: 'Updated project: ' + this.projectEdit.title,
                      color: 'success',
                      timeout: 5,
                      show: true
                    })
                    this.projectEdit = null;
                    this.$forceUpdate();
                  }).catch(error => {
                    console.error(error);
                    this.$AlertService.newAlert({
                      message: 'Failed to update Project: '+this.projectEdit.title,
                      color: 'error',
                      timeout: 5,
                      show: true
                    })
                  })
                }
              },
              cancel: {
                hover: false,
                click: () => {
                  this.dialog.edit.show = false;
                  this.$router.replace({ path: `/company/${this.company._id}/project/${this.projectEdit._id}`})
                  this.projectEdit = null;
                }
              },
              delete: {
                hover: false,
                click: () => {
                  if (confirm(`Are you sure you want to DELETE project: ${this.projectEdit.title}?`)) {
                    this.$BackendService.project.delete(this.projectEdit).then(() => {
                      this.$store.dispatch("DELETE_PROJECT", this.projectEdit)
                      this.dialog.edit.show = false;
                      this.projectTabs.splice(this.tabs, 1);
                      this.tabs = this.projectTabs.length - 1;
                      if (this.tabs < 0) {
                        this.$router.replace({ path: `/company/${this.company._id}/project`})
                        this.showProjectsSelection = true;
                        this.projectId = null;
                      }
                      this.projectEdit = null;
                      this.$forceUpdate();
                    }).catch(error => {
                      console.error(error);
                    })
                  }
                }
              }
            }
          },
          clone: {
            project: {
              show: false,
              open: (project) => {
                this.cloneProject = JSON.parse(JSON.stringify(project));
                this.cloneProjectRateCards = true;
                this.dialog.clone.project.show = true;
                this.$forceUpdate();
              },
              close: () => {
                this.cloneProject = null;
                this.cloneProjectRateCards = true;
                this.dialog.clone.project.show = false;
                this.$forceUpdate();
              },
              save: (project, cloneRateCards) => {
                // Create the Project
                // Resources are attached to the Project
                // Clone Project Rate Cards associated with the Project-Resources
                project._id = this.$UUID();
                project.sharepointInternalId = '';
                project.lastChange = Date.now();
                project.changedBy = this.$store.state.userADInfo.mail;
                project.history = [];
                project.owners = project.owners.filter(ow => ow !== undefined && ow !== null);
                this.$BackendService.project.create({ project: project, email: false }).then((createdProject) => {
                  this.$store.dispatch("NEW_PROJECT", project)
                  this.$AlertService.newAlert({
                    message: "Cloned project",
                    timeout: 5,
                    show: true,
                    color: 'success'
                  });
                  let that = this;
                  function finish(project = null) {
                    // that.setupProjectFromId(createdProject._id);
                    if (project !== null) {
                      that.projectTabs.push(project)
                      that.tabs = that.projectTabs.length - 1;
                    }
                    that.dialog.clone.project.close();
                    that.$forceUpdate();
                  }
                  if (cloneRateCards) {
                    project.DataTableItems.forEach((item, index) => {
                      if (item.hasProjectRateCard) {
                        // console.log(projectRateCard);
                        item.projectRateCard.project_id = project._id;
                        item.projectRateCard._id = this.$UUID();
                        item.projectRateCard.sow = "";
                        item.projectRateCard.history = [];
                        item.projectRateCard.lastChange = Date.now();
                        delete item.projectRateCard._rev;
                        this.$BackendService.projectRateCard.create({ projectRateCard: item.projectRateCard, email: false }).then((createdProjectRateCard) => {
                          this.$store.dispatch("NEW_PROJECT_RATE_CARD", item.projectRateCard)
                          let onb = this.onboardings.filter(onb => {
                            return onb._id === item.projectRateCard.onboarding_id;
                          })[0];
                          onb.projectRateCards.push(item.projectRateCard._id);
                          this.$BackendService.onboarding.update({ 
                            _id: onb._id,
                            projectRateCards: onb.projectRateCards,
                            onboarding_id: onb._id,
                            company_id: onb.company_id
                          }).then((updatedOnb) => {
                            this.$store.dispatch("UPDATE_ONBOARDING", onb)
                            if (index === project.DataTableItems.length - 1) {
                              finish(project);
                            }
                          }).catch(error => {
                            if (index === project.DataTableItems.length - 1) {
                              finish();
                            }
                            console.error(error);
                          })
                        }).catch(error => {
                          if (index === project.DataTableItems.length - 1) {
                            finish();
                          }
                          console.error(error);
                        })
                      } else if (index === project.DataTableItems.length - 1) {
                        finish();
                      }
                    })
                  } else {
                    finish();
                  }
                }).catch(error => {
                  console.error(error);
                  this.$AlertService.newAlert({
                    message: "Failed to clone project",
                    timeout: 5,
                    show: true,
                    color: 'error'
                  })
                })
              }
            },
            projectRateCard: {
              show: false,
              open: (project, item) => {
                this.selectedClone = null;
                this.cloneProject = project;
                this.clone = item;
                this.clones = [];
                this.dialog.clone.projectRateCard.show = true;
                this.$forceUpdate();

              },
              close: () => {
                this.selectedClone = null;
                this.cloneProject = null;
                this.clone = null;
                this.clones = [];
                this.dialog.clone.projectRateCard.show = false;
                this.$forceUpdate();
              },
              save: (project, originalClone, clones) => {
                let that = this;

                function createProjectRateCardAndUpdateOnboarding(project, clone, projectRateCard, index) {
                  // console.log(project, clone, projectRateCard, index)
                  return new Promise((resolve, reject) => {
                    that.$BackendService.projectRateCard.create({ projectRateCard: projectRateCard, email: false}).then((createdProjectRateCard) => {
                      that.$store.dispatch("NEW_PROJECT_RATE_CARD", projectRateCard)
                      project.DataTableItems.push({
                        name: clone.firstName + ' ' + clone.lastName,
                        email: clone.cognitusEmailMailbox + clone.cognitusEmailDomain,
                        _id: clone._id,
                        jobTitle: projectRateCard.jobTitle || '',
                        pricingDateFromMenu: false,
                        pricingDateToMenu: false,
                        contractor: clone.contractor,
                        projectRateCardPaymentTermsConfirmed: false,
                        projectRateCard: projectRateCard,
                        hasProjectRateCard: true,
                        isSelectable: true,
                        render: that.$UUID(),
                        errors: {
                          billRateToCustomer: { state: false, val: null},
                          estimatedWork: { state: false, val: null},
                          pricingDateFrom: { state: false, val: null},
                          pricingDateTo: { state: false, val: null},
                          role: { state: false, val: null},
                          costRateCurrency: { state: false, val: null},
                          costRateOfResource: { state: false, val: null},
                          payment_terms: { state: false, val: null}
                        },
                        edited: {
                          billRateToCustomer: { state: false, val: null},
                          estimatedWork: { state: false, val: null},
                          pricingDateFrom: { state: false, val: null},
                          pricingDateTo: { state: false, val: null},
                          role: { state: false, val: null},
                          costRateCurrency: { state: false, val: null},
                          costRateOfResource: { state: false, val: null},
                          payment_terms: { state: false, val: null}
                        }
                      })
                      that.$AlertService.newAlert({
                        message: "Created Project Rate Card for " + clone.firstName + ' ' + clone.lastName,
                        timeout: 5,
                        color: 'success',
                        show: true
                      })
                      clone.projectRateCards.push(projectRateCard._id);
                      that.$BackendService.onboarding.update({ _id: clone._id, projectRateCards: clone.projectRateCards, onboarding_id: clone._id, company_id: clone.company_id }).then((updatedOnb) => {
                        that.$store.dispatch("UPDATE_ONBOARDING", clone)
                        // that.$store.dispatch("UPDATE_ONBOARDING", updatedOnb)
                        that.$AlertService.newAlert({
                          message: "Added Project Rate Card to Onboarding for " + clone.firstName + ' ' + clone.lastName,
                          timeout: 5,
                          color: 'success',
                          show: true
                        })
                        resolve(index)
                      }).catch(error => {
                        that.$AlertService.newAlert({
                          message: "Failed to add Project Rate Card to Onboarding for " + clone.firstName + ' ' + clone.lastName,
                            timeout: 5,
                            color: 'error',
                            show: true
                        });
                        console.error(error)
                        console.error("Failed to add Project Rate Card to Onboarding for " + clone.firstName + ' ' + clone.lastName)
                        reject()
                      })
                    }).catch(error => {
                      that.$AlertService.newAlert({
                        message: "Failed to create Project Rate Card for " + clone.firstName + ' ' + clone.lastName,
                        timeout: 5,
                        color: 'error',
                        show: true
                      });
                      console.error(error)
                      console.error("Failed to create Project Rate Card for " + clone.firstName + ' ' + clone.lastName)
                      reject()
                    })
                  })
                  
                }
                let done = [];
                // console.log(clones);
                for(var x = 0; x < clones.length; x++) {
                  let projectRateCard = JSON.parse(JSON.stringify(originalClone.projectRateCard));
                  delete projectRateCard._rev;
                  projectRateCard.onboarding_id = clones[x]._id;
                  projectRateCard._id = this.$UUID();
                  projectRateCard.sow = "";
                  projectRateCard.history = [];
                  projectRateCard.role = clones[x].jobTitle;
                  projectRateCard.changedBy = this.$store.state.userADInfo.mail;
                  if (!project.resources.includes(projectRateCard.onboarding_id)) {
                    project.resources.push(projectRateCard.onboarding_id);
                  }
                  done.push(false);
                  createProjectRateCardAndUpdateOnboarding(project, clones[x], projectRateCard, x).then((i) => {
                    done[i] = true;
                    // console.log(done)
                    if (!done.some(x => !x)) {
                      this.$BackendService.project.update({ project: project, email: false }).then((updatedProject) => {
                        this.$store.dispatch("UPDATE_PROJECT", updatedProject)
                        this.$AlertService.newAlert({
                          message: 'Updated Project',
                          color: 'success',
                          timeout: 5,
                          show: true
                        })
                        this.dialog.clone.projectRateCard.close();
                      }).catch(error => {
                        this.$AlertService.newAlert({
                          message: 'Failed to update Project. Operation cancelled.',
                          color: 'error',
                          timeout: 5,
                          show: true
                        });
                        this.dialog.clone.projectRateCard.close();
                      })
                    }
                  })
                }
              }
            }
          }
        },
        defaultHiddenHeaders: ['projectRateCards', 'costCenter', 'region', 'laptop', 'o365license', 'comment', 'requester', 'readyForOnboardedUserInput', 'sfPayingLegalEntity', 'usageLocation', 'requesterComment', 'startDate', 'currency', 'costRate', 'billRate', 'personalEmailAddress', 'hasBeenOnboarded', 'sapUser', 'conversationId', 'activityId', 'changeRequest', 'changeRequestUUID', 'jiraIssueStatus', 'flowApprovalStatus', 'jiraIssueKey', 'jiraIssueID', 'date_time_onboarded', 'travelExpenses', 'employee', 'contractor', 'contingentWorker', '_id', '_rev', 'forms', 'mobilePhone', 'otherSystem', 'history', 'sentEmails', 'vendorAddress', 'step', 'terminated', 'avatar', 'temporaryOffice365Password', 'sfReportsTo', 'company_id', 'onboarding_id'],
        breadcrumbs: [
          {
            href: "",
            text: ""
          }
        ]
      }
    },
    computed: {
      projectWorkEstimationInDays: {
        get() {
          return this.$store.state.projectWorkEstimationInDays
        },
        set(val) {
          this.$store.dispatch("SET_PROJECT_WORK_ESTIMATION_IN_DAYS", val)
        }
      },
      itemsPerPage: {
        get() {
          return this.$store.state.itemsPerPage
        },
        set(val) {
          this.$store.dispatch("SET_ITEMS_PER_PAGE", val)
        }
      },
      projects() {
        return this.$store.state.projects
      },
      projectRateCards() {
        return this.$store.state.projectRateCards
      },
      onboardings() {
        if (this.$store.state.onboardings !== null) {
          return this.$store.state.onboardings[this.company._id]
        } else {
          return []
        }
        
      },
      selectableProjects() {
        return this.projects.map(project => {
              return {  
                text: project.title,
                value: project._id
              }
            }).filter(p => this.projectTabs.length > 0 ? !this.projectTabs.some(pt => pt !== undefined ? pt._id === p.value : false) : true)
      },
      sortedResourceHistoryItem() {
        return this.resourceHistoryItem === null ? [] : this.resourceHistoryItem.projectRateCard.history.slice().sort((a, b) => new Date(b.lastChange) - new Date(a.lastChange));;
      },
      computedAvailableSalesCommission() {
        return this.onboardings === null ? [] : JSON.parse(JSON.stringify(this.onboardings)).map(onb => {
          return {
            text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
            value: {
              id: onb._id,
              name: onb.firstName + ' ' + onb.lastName,
              email: onb.cognitusEmailMailbox + onb.cognitusEmailDomain,
              percentageOfOwnership: 0
            }
          }
        }).filter(o => this.newProject.salesCommission.filter(ow => ow.id === o.value.id).length === 0);
      },
      computedAvailableNewProjectOwners() {
        return this.onboardings === null ? [] : this.onboardings.map(onb => {
          return {
            text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
            value: {
              id: onb._id,
              name: onb.firstName + ' ' + onb.lastName,
              email: onb.cognitusEmailMailbox + onb.cognitusEmailDomain,
              percentageOfOwnership: 0
            }
          }
        }).filter(o => this.newProject.owners.filter(ow => ow !== null).filter(ow => ow.id === o.value.id).length === 0);
      },
      computedAvailableExistingProjectOwners() {
        return this.onboardings === null ? [] : this.onboardings.map(onb => {
          return {
            text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
            value: {
              id: onb._id,
              name: onb.firstName + ' ' + onb.lastName,
              email: onb.cognitusEmailMailbox + onb.cognitusEmailDomain,
              percentageOfOwnership: 0
            }
          }
        }).filter(o => this.projectTabs[this.tabs] !== undefined ? this.projectTabs[this.tabs].owners.filter(ow => ow !== null).filter(ow => ow.id === o.value.id).length === 0 : true);
      },
      projectTemplate() {
        return JSON.parse(JSON.stringify(ProjectTemplate));
      },
      projectRateCardTemplate() {
        return JSON.parse(JSON.stringify(ProjectRateCardTemplate));
      },
      hiddenHeaders() {
        return this.defaultHiddenHeaders;
      },
      runnPeople() {
        return this.$store.state.runn.people;
      },
      runnProjects() {
        return this.$store.state.runn.projects;
      },
      runnAssignments() {
        return this.$store.state.runn.assignments;
      }
    },
    beforeDestroy() {
      
    },
    mounted() {

      if (this.path !== undefined) {
        if (this.path === 'new') {
          this.dialog.create.open();
        }
      }
      if (this.project_id !== undefined) {
        this.projectId = this.project_id
      }
      this.getRunnProjects()
      this.getRunnAssignments()
      this.getRunnPeople()
    },  
    watch: {
      projectWorkEstimationInDays(val, oldVal) {
        this.projectTabs.forEach(project => {
          if(project.DataTableItems.length > 0) {
            if (!val && oldVal) {
              project.DataTableItems.forEach(dti => {
                dti.projectRateCard.estimatedWork = parseFloat(8 * dti.projectRateCard.estimatedWork)
                dti.projectRateCard.estimatedWorkUnit = 'hours'
              })
            } else if (val && !oldVal) {
              project.DataTableItems.forEach(dti => {
                dti.projectRateCard.estimatedWork = parseFloat(dti.projectRateCard.estimatedWork / 8)
                dti.projectRateCard.estimatedWorkUnit = 'days'
              })
            }
          }
        })
      },
      projectTabs: {
        handler(val, oldVal) {
          let that = this;
          // loop of the active project tabs
          if (val !== undefined) {
            // console.log('projectTabs')
            val.forEach((tab, index) => {
              // for each project make sure there is data for the table
              if (tab.DataTableItems === undefined || tab.DataTableItems === null) {
                // show that the tab is loading data
                tab.loading = true;
                // get each project rate card associated with the project
                let prcAssociatedWithProject = this.projectRateCards.filter(prc => this.projectTabs[index].resources.includes(prc.onboarding_id) && prc.project_id === this.projectTabs[index]._id)
                // get the full onboarding data associated with each resource of the project, and get each project rate card associated with each onboarding
                // afterwards generate the table data for the project using the latest data
                Promise.all(this.projectTabs[index].resources.map((resource) => {
                  return new Promise((resolve, reject) => {
                    that.$BackendService.onboarding.get({ _id: resource, company_id: that.company._id}).then(onb => {
                      this.$store.dispatch("UPDATE_ONBOARDING", onb)
                      resolve(Promise.all(prcAssociatedWithProject.filter(prc => resource === prc.onboarding_id).map(prc => {
                        return new Promise((resolve, reject) => {
                          that.$BackendService.projectRateCard.get({ _id: prc._id }).then(projectRateCard => {
                            that.$store.dispatch("UPDATE_PROJECT_RATE_CARD", projectRateCard)
                            resolve();
                          }).catch(error => {
                            console.error(error);
                            reject();
                          })
                        })
                      })));
                    }).catch(error => {
                      console.error(error);
                      reject();
                    })
                  })
                })).then(() => {
                  this.$set(this.projectTabs[index], 'DataTableItems', this.resourceDataTableItems(this.projectTabs[index]))
                  this.$nextTick(() => {
                    tab.loading = false;
                    this.$forceUpdate();
                  })
                })
              } else if (tab.additionalResource !== null){
                if (tab.additionalResource.projectRateCard.estimatedWork === '' || 
                  tab.additionalResource.projectRateCard.estimatedWork < 0 ||
                  tab.additionalResource.projectRateCard.pricingDateFrom  === '' ||
                  tab.additionalResource.projectRateCard.pricingDateTo === '' ||
                  tab.additionalResource.projectRateCard.costRateCurrency === '' ||
                  tab.additionalResource.projectRateCard.costRateOfResource === ''
                ) {
                  tab.additionalResource.isValid = false
                } else {
                  tab.additionalResource.isValid = true
                }
                this.$forceUpdate();
              }
            })
          }
        }
      },
      projectId(val) {
        // console.log('projectId')
        if (val && this.path !== 'new') {
          if (this.projectTabs.length === 0 || !this.projectTabs.some(p => p._id === val)) {
            if (this.projects === null) {
              let that = this;
              // console.log(val)
              function untilNotNull (val) {
                if (that.projects !== null) {
                  that.setupProjectFromId(val);
                } else {
                  setTimeout(() => {
                    untilNotNull(val);
                  }, 2000)
                }
              }
              untilNotNull(val);
            } else {
              this.setupProjectFromId(val);
            }
          } else if (this.projectTabs.some(p => p._id === val)) {
            let projectTab = this.projectTabs.filter(p => p._id === val)[0];
            projectTab.resourcePool = true;
            this.tabs = this.projectTabs.indexOf(projectTab);
          }
          
        }
      },
      project_id(val) {
        if (val !== undefined) {
          if (!this.projectTabs.some(p => p._id === val) && this.path !== 'new') {
            this.$BackendService.project.get({_id: val, company_id: this.company._id}).then(p => {
              this.$store.dispatch("UPDATE_PROJECT", p);
              this.projectTabs.push(p)
            })
          } else {
            this.tabs = this.projectTabs.indexOf(this.projectTabs.filter(pt => pt._id === val)[0])
            this.$forceUpdate();
          }
        }
      },
      tabs(val) {
        if (val >= 0) {
          this.$router.replace(`/company/${this.company._id}/project/${this.projectTabs[val]._id}`)
          this.$forceUpdate();
        }
      }
    },
    methods: {
      getSFFoCompanies() {
        let that = this;
        return new Promise(function(resolve, reject) {
          that.$BackendService.sap.sf({
            requestURL: "https://api4.successfactors.com/odata/v2/FOCompany?$select=externalCode,name_defaultValue"
          }).then(function(response) {
            resolve(response.d.results.map(x => (
              { 
                payingLegalEntity: x.externalCode, 
                name: x.name_defaultValue
              }
            )));
          }).catch(function(error) {
            reject(error);
          })
        })
      },
      getRunnProjects() {
        this.$RunnService.getRunnProjects({ company_id: this.company_id }).then(projects => {
          this.$store.dispatch("SET_RUNN_PROJECTS", projects)
        }).catch(error => {
          console.error(error);
        })
      },
      getRunnPeople() {
        this.$RunnService.getRunnPeople({ company_id: this.company_id }).then(people => {
          this.$store.dispatch("SET_RUNN_PEOPLE", people)
        }).catch(error => {
          console.error(error);
        })
      },
      getRunnAssignments() {
        this.$RunnService.getRunnAssignments({ company_id: this.company_id }).then(assignments => {
          this.$store.dispatch("SET_RUNN_ASSIGNMENTS", assignments)
        }).catch(error => {
          console.error(error);
        })
      },
      validSaveableItem(item) {
        if (Object.keys(item.edited).some(key => item.edited[key].state) && !Object.keys(item.errors).some(key => item.errors[key].state)) {
          let valid = true;
          Object.keys(item.edited).filter(key => {
            if (!item.contractor && ['costRateCurrency', 'costRateOfResource', 'payment_terms'].includes(key)) {
              return false
            }
            return true
          }).forEach((key) => {
            if (item.projectRateCard[key] === '') {
              valid = false;
            }
          })
          return valid;
        } else {
          return false;
        }
      },
      validSaveableItems(items) {
        return items.filter(item => this.validSaveableItem(item))
      },
      selectedResource(project) {
        if (project.DataTableItems !== null)
          return project.DataTableItems.filter(dti => dti.selected)
        return []
      },
      changePricingDate(project, item, field, val) {
        item.edited[field].state = item.edited[field].val !== val;
        if (this.timeouts[project._id][item._id][field]) {
          clearTimeout(this.timeouts[project._id][item._id][field]);
        }
        this.timeouts[project._id][item._id][field] = setTimeout(() => {

          let fromDate = item.projectRateCard.pricingDateFrom.split('/');
          let toDate = item.projectRateCard.pricingDateTo.split('/');

          item.errors['pricingDateFrom'].state = false;
          item.errors['pricingDateFrom'].val = [];

          item.errors['pricingDateTo'].state = false;
          item.errors['pricingDateTo'].val = [];

          // check from date months
          if (parseInt(fromDate[0]) > 12 || parseInt(fromDate[0]) < 1) {
            item.errors['pricingDateFrom'].state = true;
            item.errors['pricingDateFrom'].val.push('invalid month')
          }

          // check from date days
          if (parseInt(fromDate[1]) > 31 || parseInt(fromDate[1]) < 1) {
            item.errors['pricingDateFrom'].state = true;
            item.errors['pricingDateFrom'].val.push('invalid day');
          }

          // check to date months
          if (parseInt(toDate[0]) > 12 || parseInt(toDate[0]) < 1) {
            item.errors['pricingDateTo'].state = true;
            item.errors['pricingDateTo'].val.push('invalid month');
          }

          // check to date days
          if (parseInt(toDate[1]) > 31 || parseInt(toDate[1]) < 1) {
            item.errors['pricingDateTo'].state = true;
            item.errors['pricingDateTo'].val.push('invalid day');
          }

          
          // check if year from is greater than year to 
          if (parseInt(fromDate[2]) > parseInt(toDate[2])) {
            item.errors['pricingDateFrom'].state = true;
            item.errors['pricingDateFrom'].val.push('from date year > to date year');
            item.errors['pricingDateTo'].state = true;
            item.errors['pricingDateTo'].val.push('from date year > to date year');
          // check if month from is greater than month to when years are equal
          } else if (parseInt(fromDate[2]) === parseInt(toDate[2]) && parseInt(fromDate[0]) > parseInt(toDate[0])) {
            item.errors['pricingDateFrom'].state = true;
            item.errors['pricingDateFrom'].val.push('from date month > to date month');
            item.errors['pricingDateTo'].state = true;
            item.errors['pricingDateTo'].val.push('from date month > to date month');
          // check if date from is greater than date to 
          } else if (parseInt(fromDate[2]) === parseInt(toDate[2]) && parseInt(fromDate[0]) === parseInt(toDate[0]) && parseInt(fromDate[1]) > parseInt(toDate[1])) {
            item.errors['pricingDateFrom'].state = true;
            item.errors['pricingDateFrom'].val.push('from date day > to date day');
            item.errors['pricingDateTo'].state = true;
            item.errors['pricingDateTo'].val.push('from date day > to date day');
          }

          if (item.errors['pricingDateTo'].state || item.errors['pricingDateFrom'].state) {
            return;
          }

          let workDays = this.calcWorkDays(item.projectRateCard.pricingDateFrom, item.projectRateCard.pricingDateTo)
          if (workDays > -1) {
            if (this.projectWorkEstimationInDays) {
              item.projectRateCard.estimatedWork = workDays
              item.projectRateCard.estimatedWorkUnit = 'days'
            } else {
              item.projectRateCard.estimatedWork = parseFloat(workDays * 8)
              item.projectRateCard.estimatedWorkUnit = 'hours'
            }
            item.edited['estimatedWork'].state = true
            this.$forceUpdate()
          }
          
        }, 500)
      },
      confirmProxy(val) {
        return confirm(val);
      },
      log(...args) {
        console.log(args);
      },
      setupProjectFromId(val) {
        this.$BackendService.project.get({_id: val, company_id: this.company._id}).then(p => {
          this.$store.dispatch("UPDATE_PROJECT", p)
          let projectTab = p;
          projectTab.DataTableItems = null;
          projectTab.selectedAdditionalResource = null
          projectTab.additionalResource = null
          projectTab.editMode = false
          // projectTab.selectedResource = []
          projectTab.resourceTableSearch = this.$route.query.q || ""
          projectTab.resourcesToAdd = null
          projectTab.hoveredResourceIndex = -1
          projectTab.cardHover = -1;
          projectTab.hover = false;
          projectTab.details = false;
          projectTab.resourcePool = true;
          projectTab.render = this.$UUID().split('-')[0]
          projectTab.page = 1
          this.timeouts[val] = {}
          this.projectTabs.push(projectTab);
          this.tabs = this.projectTabs.length - 1;
          this.showProjectsSelection = false; 
          this.hover.search = false;
          this.$forceUpdate();
        })
        
      },
      addAndSaveLinkedSoW(projectRateCard) {
        let history = JSON.parse(JSON.stringify(projectRateCard));
        delete history.history;
        projectRateCard.history.push(history);
        projectRateCard.sow = this.externalSoWLink;
        projectRateCard.lastChange = (new Date());
        projectRateCard.changedBy = this.$store.state.userADInfo.mail
        this.$BackendService.projectRateCard.update({ projectRateCard: projectRateCard, email: false}).then((updatedPRC) => {
          projectRateCard = {...projectRateCard, ...updatedPRC}
          this.$store.dispatch("UPDATE_PROJECT_RATE_CARD", updatedPRC)
          this.$AlertService.newAlert({
            message: "Saved SoW Link to " + onbAssociated.firstName + " " + onbAssociated.lastName,
            timeout: 5,
            show: true,
            color: 'success'
          })
          let project = this.projectTabs[this.tab]
          that.$set(project.DataTableItems[project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti.projectRateCard._id === projectRateCard._id)[0])], 'projectRateCard', projectRateCard)
        }).catch(error => {
          console.error(error);
        })
      },
      cancelAgreement(agreement) {
        let that = this;
        if(confirm("Are you sure you want to CANCEL this agreement?")) {
          that.$BackendService.adobe.cancelAgreement({
            agreementId: agreement.id
          }).then(function(response) {
            that.$AlertService.newAlert({
              message: "Cancelled Adobe agreement.",
              color: "success",
              timeout: 5,
              show: true,
            });
            that.getAgreements(true);
            that.$nextTick(() => {
              that.$forceUpdate();
            })
          }).catch(function(error) {
            that.$AlertService.newAlert({
              message: "Failed to cancel Adobe agreement.",
              color: "error",
              timeout: 5,
              show: true,
            });
            console.error(error);
          })
        }
      },

      deleteAgreement(agreement) {
        let that = this;
        if(confirm("Are you sure you want to DELETE this agreement?")) {
          that.$BackendService.adobe.deleteAgreement({
            agreementId: agreement.id
          }).then(function(response) {
            that.$AlertService.newAlert({
              message: "Deleted Adobe agreement.",
              color: "success",
              timeout: 5,
              show: true,
            });
            that.getAgreements(true);
            that.$nextTick(() => {
              that.$forceUpdate();
            })
          }).catch(function(error) {
            that.$AlertService.newAlert({
              message: "Failed to delete Adobe agreement.",
              color: "error",
              timeout: 5,
              show: true,
            });
            console.error(error);
          })
        }
      },

      getAgreementEvents(agreement) {
        let that = this;
        return new Promise(function(resolve, reject) {
          that.$BackendService.adobe.getAgreementEvents({
            agreementId: agreement.id
          }).then(function(response) {
            that.$AlertService.newAlert({
              message: "Retrieved Adobe agreement events.",
              color: "success",
              timeout: 5,
              show: true,
            });
            resolve(response);
          }).catch(function(error) {
            that.$AlertService.newAlert({
              message: "Failed to get Adobe agreement events.",
              color: "error",
              timeout: 5,
              show: true,
            });
            reject(error);
          })
        })
        
      },

      showAgreementEvents(agreement) {
        let that = this;
        that.loadingAgreementEvents = true;
        that.getAgreementEvents(agreement).then(function(response) {
          that.agreementEvents[agreement.id] = response.events;
          that.loadingAgreementEvents = false;
        }).catch(function(error) {
          console.error(error);
        })
      },

      openAuditTrail(agreement) {
        let that = this;
        that.loadingPdf = true;
        that.viewingPdf = true;
        that.$BackendService.adobe.getAgreementAuditTrail({
          agreementId: agreement.id
        }).then(function(doc) {
          // base64 string
          var base64str = doc;

          // decode base64 string, remove space for IE compatibility
          var binary = atob(base64str.replace(/\s/g, ''));
          var len = binary.length;
          var buffer = new ArrayBuffer(len);
          var view = new Uint8Array(buffer);
          for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
          }
          that.loadingPdf = false;
          that.$nextTick(() => {
            if (that.adobeDCView === null)
              that.adobeDCView = new AdobeDC.View({clientId: 'bfbe3eab74f143e8a1cbd75cda264eeb', divId: "adobe-dc-view-pdf"});
            that.adobeDCView.previewFile(
            {
                content:   {location: {url: URL.createObjectURL(new Blob([view], { type: 'application/pdf'})) }},
                metaData: { fileName: 'Adobe Sign Document Audit Trial'}
            });
          })
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to retrieved the Audit Trail PDF.",
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        })
      },
      openPDF(agreement) {
        let that = this;
        that.loadingPdf = true;
        that.viewingPdf = true;
        that.$BackendService.adobe.getAgreementCombinedDocument({
          agreementId: agreement.id
        }).then(function(doc) {
          // base64 string
          var base64str = doc;

          // decode base64 string, remove space for IE compatibility
          var binary = atob(base64str.replace(/\s/g, ''));
          var len = binary.length;
          var buffer = new ArrayBuffer(len);
          var view = new Uint8Array(buffer);
          for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
          }
          // that.pdfToView = URL.createObjectURL(new Blob([view], { type: 'application/pdf'}));
          that.loadingPdf = false;
          that.$nextTick(() => {
            if (that.adobeDCView === null)
              that.adobeDCView = new AdobeDC.View({clientId: 'bfbe3eab74f143e8a1cbd75cda264eeb', divId: "adobe-dc-view-pdf"});
            that.adobeDCView.previewFile(
            {
                content:   {location: {url: URL.createObjectURL(new Blob([view], { type: 'application/pdf'})) }},
                metaData: { fileName: 'Adobe Sign Document'}
            });
          })
          
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to retrieved the PDF.",
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        })
      },
      saveAdobeSignAgreementFieldData(onb, noNotify = false) {
        return new Promise((resolve, reject) => {
          let that = this;
          let promises = [];
          if (onb.adobeSignedAgreementsFieldData === undefined) {
            onb.adobeSignedAgreementsFieldData = [];
          } else if (onb.adobeSignedAgreementsFieldData !== undefined && onb.adobeSignedAgreementsFieldData.length > 0) {
            let sorted = onb.adobeSignedAgreementsFieldData.slice().sort((a, b) => new Date(b.data.filter(d => d.header === 'completed')[0].data[0]) - new Date(a.data.filter(d => d.header === 'completed')[0].data[0]))
            let latest = sorted[0];
            if (onb.msaDate !== latest.data.filter(d => d.header === 'completed')[0].data[1]) {
              let effectiveDate = latest.data.filter(d => d.header === 'Effective Date')[0]
              if (effectiveDate !== undefined) {
                onb.msaDate = latest.data.filter(d => d.header === 'Effective Date')[0].data[0] // this is the effective date of the msa (added later)
              } else {
                onb.msaDate = latest.data.filter(d => d.header === 'completed')[0].data[1] // this is the signed date of the msa
              }
              onb.vendorAddress.streetAddress = latest.data.filter(d => d.header === 'Vendor Street Address')[0].data[0]
              onb.vendorAddress.state = latest.data.filter(d => d.header === 'Vendor State')[0].data[0]
              onb.vendorAddress.city = latest.data.filter(d => d.header === 'Vendor City')[0].data[0]
              onb.vendorAddress.zip = latest.data.filter(d => d.header === 'Vendor ZIP' || d.header === 'Custom Field 3')[0].data[0]
            }
          }
          if (onb.adobeAgreements === undefined) {
            onb.adobeAgreements = [];
          }
          this.adobeAgreements.filter(agreement => onb.adobeAgreements.some(agr => agr === agreement.id)).forEach(targetAgreement => {
            // check if there is a signed agreement which hasnt had it's field data retrieved and stored 
            if (targetAgreement.status === 'SIGNED' && !onb.adobeSignedAgreementsFieldData.some(signedAgreementFieldData => signedAgreementFieldData.id === targetAgreement.id )) {
              promises.push((new Promise((resolve, reject) => {
                that.$BackendService.adobe.getAgreementFieldData({agreement_id: targetAgreement.id}).then(fieldData => {
                  resolve(fieldData)
                }).catch(error => {
                  reject(error);
                })
              })).then(fieldData => {
                onb.adobeSignedAgreementsFieldData.push({
                  id: targetAgreement.id,
                  name: targetAgreement.name,
                  data: fieldData
                })
              }).catch(error => {
                console.error(error);
              }))
            }
          })
          Promise.all(promises).then(() => {
            onb.adobeSignedAgreementsFieldData = onb.adobeSignedAgreementsFieldData.filter((signedFieldData, index) => {
              return !onb.adobeSignedAgreementsFieldData.some((fieldData, checkIndex) => {
                fieldData.id === signedFieldData.id && checkIndex !== index
              })
            })
            that.$BackendService.onboarding.update({ _id: onb._id, adobeSignedAgreementsFieldData: onb.adobeSignedAgreementsFieldData, msaDate: onb.msaData, vendorAddress: onb.vendorAddress, onboarding_id: onb._id, company_id: onb.company_id }).then((updatedOnb) => {
              that.$store.dispatch("UPDATE_ONBOARDING", updatedOnb)
              if (!noNotify)
              that.$AlertService.newAlert({
                message: `Saved Signed Adobe Agreement Field Data for ${onb.cognitusEmailMailbox}.`,
                color: "success",
                timeout: 5,
                show: true,
              })
              resolve(onb.adobeSignedAgreementsFieldData)
            }).catch(error => {
              if (!noNotify)
              that.$AlertService.newAlert({
                message: `Failed to save Signed Adobe Agreement Field Data for ${onb.cognitusEmailMailbox}.`,
                color: "error",
                timeout: 5,
                show: true,
              })
              reject(error);
            })
          })
        })
        
      },
      putAgreementFormFieldsMergeInfo(
          agreementId, 
          legalEntityName,
          vendorName, 
          msaDate, 
          sowNumber, 
          consultantName, 
          payRate, 
          startDate, 
          endDate, 
          services, 
          clientName, 
          projectLocation, 
          paymentTerms, 
          expenses, 
          standardWorkWeek, 
          overtimeHours, 
          notToExceed
        ) {
        let that = this;
        return new Promise(function(resolve, reject) {
          that.$BackendService.adobe.putAgreementFormFieldsMergeInfo({
            fieldMergeInfos: [
            {
              fieldName: 'Legal Entity Name',
              defaultValue: legalEntityName
            },
            {
              fieldName: 'Vendor Name',
              defaultValue: vendorName
            }, 
            {
              fieldName: 'MSA Date',
              defaultValue: msaDate
            },
            {
              fieldName: 'SoW Number',
              defaultValue: sowNumber
            },
            {
              fieldName: 'Consultant Name',
              defaultValue: consultantName
            },
            {
              fieldName: 'Pay Rate',
              defaultValue: payRate
            },
            {
              fieldName: 'Start Date',
              defaultValue: startDate
            },
            {
              fieldName: 'End Date',
              defaultValue: endDate
            },
            {
              fieldName: 'Services',
              defaultValue: services
            },
            {
              fieldName: 'Client Name',
              defaultValue: clientName
            },
            {
              fieldName: 'Project Location',
              defaultValue: projectLocation
            },
            {
              fieldName: 'Payment Terms',
              defaultValue: paymentTerms
            },
            {
              fieldName: 'Expenses',
              defaultValue: expenses
            },
            {
              fieldName: 'Standard Work Week',
              defaultValue: standardWorkWeek
            },
            {
              fieldName: 'Overtime Hours',
              defaultValue: overtimeHours
            },
            {
              fieldName: 'Not to Exceed',
              defaultValue: notToExceed
            }],
            agreement_id: agreementId
          }).then(function(response) {
            that.$AlertService.newAlert({
              message: "Updated agreement form fields.",
              color: "success",
              timeout: 5,
              show: true,
            });
            resolve(response);
          }).catch(function(error) {
            that.$AlertService.newAlert({
              message: "Failed to update agreement form fields.",
              color: "error",
              timeout: 5,
              show: true,
            });
            reject(error);
          })
        }) 
      },
      putAgreementState(agreementId, state) {
        let that = this;
        return new Promise(function(resolve, reject) {
          that.$BackendService.adobe.putAgreementState({
            state: state,
            agreement_id: agreementId
          }).then(function(response) {
            that.$AlertService.newAlert({
              message: "Updated agreement state to " + state + ".",
              color: "success",
              timeout: 5,
              show: true,
            });
            resolve(response)
          }).catch(function(error) {
            that.$AlertService.newAlert({
              message: "Failed to update agreement state.",
              color: "error",
              timeout: 5,
              show: true,
            });
            reject(error);
          })
        })
      },
      getAgreements(noNotify = false) {
        let that = this;
        return new Promise((resolve, reject) => {
          that.loadingAdobeAgreements = true;
          this.$BackendService.adobe.getAgreements({}).then(function(response) {
            that.adobeAgreements = response.userAgreementList;
            that.loadingAdobeAgreements = false;
            if (!noNotify)
              that.$AlertService.newAlert({
                message: `Retrieved ${that.adobeAgreements.length} agreements.`,
                color: "success",
                timeout: 5,
                show: true,
              });
            resolve()
          }).catch(function(error) {
            if (!noNotify)
              that.$AlertService.newAlert({
                message: `Failed to retrieve agreements.`,
                color: "error",
                timeout: 5,
                show: true,
              });
            reject(error)
          })
        })
        
      },
      getLibraryDocuments(noNotify = false) {
        let that = this;
        return new Promise((resolve, reject) => {
          that.loadingLibraryDocuments = true;
          this.$BackendService.adobe.getLibraryDocuments({}).then(function(response) {
            that.libraryDocuments = response.libraryDocumentList;
            that.loadingLibraryDocuments = false;
            if (!noNotify)
              that.$AlertService.newAlert({
                message: `Retrieved ${that.libraryDocuments.length} library documents.`,
                color: "success",
                timeout: 5,
                show: true,
              });
            resolve()
          }).catch(function(error) {
            if (!noNotify)
              that.$AlertService.newAlert({
                message: `Failed to retrieve library documents.`,
                color: "error",
                timeout: 5,
                show: true,
            });
            reject(error);
          })
        })
      },
      selectedResourceWithoutProjectRateCard(project) {
        return this.selectedResource(project).filter(r => !r.hasProjectRateCard);
      },
      selectedResourceWithProjectRateCard(project) {
        return this.selectedResource(project).filter(r => r.hasProjectRateCard);
      },
      addSingleResource(project) {
        project.additionalResource = {
          name: "",
          email: "",
          _id: "",
          jobTitle: "",
          pricingDateFromMenu: false,
          pricingDateToMenu: false,
          contractor: false,
          projectRateCardPaymentTermsConfirmed: false,
          hover: false,
          render: this.$UUID().split('-')[0],
          errors: {
            estimatedWork: { state: false, val: null},
            pricingDateFrom: { state: false, val: null},
            pricingDateTo: { state: false, val: null},
            role: { state: false, val: null},
            costRateCurrency: { state: false, val: null},
            costRateOfResource: { state: false, val: null},
            billRateToCustomer: { state: false, val: null},
            payment_terms: { state: false, val: null}
          },
          edited: {
            estimatedWork: { state: false, val: null},
            pricingDateFrom: { state: false, val: null},
            pricingDateTo: { state: false, val: null},
            role: { state: false, val: null},
            costRateCurrency: { state: false, val: null},
            costRateOfResource: { state: false, val: null},
            billRateToCustomer: { state: false, val: null},
            payment_terms: { state: false, val: null}
          }
        }
        // console.log(this.projectRateCardTemplate);
        project.additionalResource.projectRateCard = JSON.parse(JSON.stringify(this.projectRateCardTemplate));
        project.additionalResource.projectRateCard.project_id = project._id;
        project.additionalResource.projectRateCard.company_id = this.company._id;
        project.additionalResource.projectRateCard._id = this.$UUID();
        project.additionalResource.projectRateCard.role = ""
        project.additionalResource.projectRateCard.payment_terms = project.payment_terms;
        project.additionalResource.edited['payment_terms'].state = true;
        project.additionalResource.projectRateCard.costRateCurrency = project.costRateCurrency || 'USD';
        project.additionalResource.edited['costRateCurrency'].state = true;
        project.render = this.$UUID().split('-')[0]
        // this.additionalResource.projectRateCard.onboarding_id = resource._id;
        // this.additionalResource.projectRateCard.role = resource.jobTitle;
      },
      resourceDataTableItems(project) {
        let val = {}
        let resources = []
        resources = project.resources.map(resource => {
          let onbAssociatedWithResource = this.onboardings.filter(onb => onb._id === resource)[0]
          resource = {
            _id: resource,
            name: onbAssociatedWithResource.firstName + ' ' + onbAssociatedWithResource.lastName,
            email: onbAssociatedWithResource.cognitusEmailMailbox + onbAssociatedWithResource.cognitusEmailDomain,
            jobTitle: onbAssociatedWithResource.jobTitle || '',
            pricingDateFromMenu: false,
            pricingDateToMenu: false,
            contractor: onbAssociatedWithResource.contractor,
            projectRateCardPaymentTermsConfirmed: false,
            hover: false,
            render: this.$UUID().split('-')[0],
            errors: {
              estimatedWork: { state: false, val: null},
              pricingDateFrom: { state: false, val: null},
              pricingDateTo: { state: false, val: null},
              role: { state: false, val: null},
              costRateCurrency: { state: false, val: null},
              costRateOfResource: { state: false, val: null},
              billRateToCustomer: { state: false, val: null},
              payment_terms: { state: false, val: null}
            },
            edited: {
              billRateToCustomer: { state: false, val: null},
              estimatedWork: { state: false, val: null},
              pricingDateFrom: { state: false, val: null},
              pricingDateTo: { state: false, val: null},
              role: { state: false, val: null},
              costRateCurrency: { state: false, val: null},
              costRateOfResource: { state: false, val: null},
              payment_terms: { state: false, val: null}
            }
          }
          let isActive = false;
          let existingPRCs = this.projectRateCards.filter(prc => project.resources.includes(prc.onboarding_id) && prc.project_id === project._id && prc.onboarding_id === onbAssociatedWithResource._id)
          // console.log(existingPRCs)
          let projectRateCard;
          let isTemplate = false;
          if (existingPRCs.length === 0) {
            projectRateCard = JSON.parse(JSON.stringify(this.projectRateCardTemplate))
            this.$set(projectRateCard, 'project_id', project._id)
            this.$set(projectRateCard, 'company_id', this.company._id)
            this.$set(projectRateCard, '_id', this.$UUID())
            this.$set(projectRateCard, 'onboarding_id', resource._id)
            this.$set(projectRateCard, 'role', resource.jobTitle)
            this.$set(projectRateCard, 'payment_terms', project.payment_terms)
            if (!resource.contractor) {
              this.$set(projectRateCard, 'costRateOfResource', onbAssociatedWithResource.costRate)
              this.$set(projectRateCard, 'costRateCurrency', onbAssociatedWithResource.currency)
            }
            isTemplate = true;
          } else if (existingPRCs.length === 1) {
            if (existingPRCs[0].pricingDateFrom.includes('-')) {
              let fromSplit = existingPRCs[0].pricingDateFrom.split('-');
              existingPRCs[0].pricingDateFrom = fromSplit[1] + '/' + fromSplit[2] + '/' + fromSplit[0]
            }
            if (existingPRCs[0].pricingDateTo.includes('-')) {
              let toSplit = existingPRCs[0].pricingDateTo.split('-')
              existingPRCs[0].pricingDateTo = toSplit[1] + '/' + toSplit[2] + '/' + toSplit[0]
            }
            projectRateCard = JSON.parse(JSON.stringify(existingPRCs[0]));
            if (projectRateCard.active) {
              isActive = true;
            }
            if (projectRateCard.payment_terms === '') {
              projectRateCard.payment_terms = project.payment_terms;
            }
          } else {
            projectRateCard = JSON.parse(JSON.stringify(existingPRCs.sort((a, b) => new Date(b.lastChange) - new Date(a.lastChange) )[0]));
            if (projectRateCard.pricingDateFrom.includes('-')) {
              let fromSplit = projectRateCard.pricingDateFrom.split('-');
              projectRateCard.pricingDateFrom = fromSplit[1] + '/' + fromSplit[2] + '/' + fromSplit[0]
            }
            if (projectRateCard.pricingDateTo.includes('-')) {
              let toSplit = projectRateCard.pricingDateTo.split('-')
              projectRateCard.pricingDateTo = toSplit[1] + '/' + toSplit[2] + '/' + toSplit[0]
            }
            if (projectRateCard.active) {
              isActive = true;
            }
            if (projectRateCard.payment_terms === '') {
              projectRateCard.payment_terms = project.payment_terms;
            }
          }
          val = {
            ...resource, hasProjectRateCard: !isTemplate && isActive, projectRateCard: projectRateCard
          }
          // console.log(val.projectRateCard.estimatedWork, val.projectRateCard.estimatedWorkUnit, this.projectWorkEstimationInDays)
          // display in hours
          if (!this.projectWorkEstimationInDays) {
            // unit was saved in days
            if (val.projectRateCard.estimatedWorkUnit === 'days') {
              val.projectRateCard.estimatedWork = parseFloat(val.projectRateCard.estimatedWork * 8)
            }
          // display in days
          } else {
            // unit was saved in hours
            if (val.projectRateCard.estimatedWorkUnit === 'hours') {
              val.projectRateCard.estimatedWork = parseFloat(val.projectRateCard.estimatedWork / 8)
            }
          }
          return val
        }).filter(resource => {
          // console.log(resource)
          if (resource === undefined) {
            return false
          }
          // console.log(resource, project.resourceTableSearch);
          if (project.resourceTableSearch.length > 0) {
            return [resource._id, resource.name, resource.jobTitle, resource.email].filter(v => v !== '').some(v => {
              let match = v.includes(project.resourceTableSearch) || 
              project.resourceTableSearch.includes(v) || 
              project.resourceTableSearch.split(" ").some(splitV => 
                splitV.includes(v) || v.includes(splitV)
              )
              // console.log(v, match);
              return match;
            })
          } else {
            return true
          }
        })
        resources.forEach(r => { this.timeouts[project._id][r._id] = {} })
        // console.log(resources)
        return resources;
      },
      saveItems(project, items) {
        // console.log('saving '+items.length+ ' items');
        this.loading = false;
        // check if project needs to have a resource added
        let history = JSON.parse(JSON.stringify(project));
        delete history.history;
        project.history.push(history);
        project.lastChange = new Date();
        project.changedBy = this.$store.state.userADInfo.mail;
        let p = {
          bill_to_customer: project.bill_to_customer,
          bill_rate_currency: project.bill_rate_currency,
          billing_cycle: project.billing_cycle,
          changeBy: project.changedBy,
          company_id: project.company_id,
          client_project_manager: project.client_project_manager,
          lastChange: project.lastChange,
          end_client_name: project.end_client_name,
          expenses: project.expenses,
          history: project.history,
          owners: project.owners,
          partner_or_practice: project.partner_or_practice,
          payment_terms: project.payment_terms,
          project_definition_existing_project: project.project_definition_existing_project,
          project_location: project.project_location,
          purchase_order_number: project.purchase_order_number,
          resources: project.resources,
          salesCommission: project.salesCommission,
          standard_work_week: project.standard_work_week,
          title: project.title,
          _id: project._id
        }
        this.loading = true;
        this.$BackendService.project.update({ project: p, email: false }).then((savedProject) => {
          this.$store.dispatch("UPDATE_PROJECT", p)
          this.loading = false;
        }).catch(error => {
          console.error(error);
          this.loading = false;
        })
        items.forEach(item => {
          // find onboarding associated with item
          let onb = this.onboardings.filter(onb => onb._id === item._id)[0];
          // onboarding doesn't include the projectRateCard
          if (!onb.projectRateCards.includes(item.projectRateCard._id)) {
            // add projectRateCard id to onboarding
            onb.projectRateCards.push(item.projectRateCard._id)
            // add onboarding update to array of promises
            this.loading = true;
            this.$BackendService.onboarding.update({ _id: onb._id, projectRateCards: onb.projectRateCards, onboarding_id: onb._id, company_id: onb.company_id }).then((savedOnb) => {
              this.$store.dispatch("UPDATE_ONBOARDING", onb)
              this.$forceUpdate();
            }).catch(error => {
              console.error(error);
              console.error("failed to update onboarding: " + onb._id)
              this.loading = false;
              this.$forceUpdate();
            })
          }
          if (item.selected) {
            item.selected = false
          }
          // item had a valid projectRateCard already
          if (item.hasProjectRateCard) {
            //  check if data changed
            let projectRateCardOldData = this.projectRateCards.filter(prc => prc._id === item.projectRateCard._id)[0]
            if (Object.keys(projectRateCardOldData).some(key => projectRateCardOldData[key] !== item.projectRateCard[key])) {
              // if data changed push to history
              let history = JSON.parse(JSON.stringify(projectRateCardOldData))
              delete history.history;
              if (!Array.isArray(item.projectRateCard.history)) {
                item.projectRateCard.history = [];
              }
              item.projectRateCard.history.push(history);
              // change sow to blank
              item.projectRateCard.sow = '';
            }
          }
          // if a projectRateCard is being saved it has to be valid (active)
          item.projectRateCard.active = true;
          
          // set the last change date to now
          item.projectRateCard.lastChange = (new Date());
          // update the person who changed it
          item.projectRateCard.changedBy = this.$store.state.userADInfo.mail
          delete item.projectRateCard._rev
          // add the create/update the projectRateCard call to array of promises
          this.loading = true;
          this.$BackendService.projectRateCard[!this.projectRateCards.some(prc => prc._id === item.projectRateCard._id) ? 'create' : 'update']({ projectRateCard: item.projectRateCard, email: true}).then((updatedProjectRateCard) => {
            item.hasProjectRateCard = true;
            item.errors =  {
              estimatedWork: { state: false, val: null},
              pricingDateFrom: { state: false, val: null},
              pricingDateTo: { state: false, val: null},
              role: { state: false, val: null},
              costRateCurrency: { state: false, val: null},
              costRateOfResource: { state: false, val: null},
              billRateToCustomer: { state: false, val: null},
              payment_terms: { state: false, val: null}
            };
            item.edited = {
              billRateToCustomer: { state: false, val: null},
              estimatedWork: { state: false, val: null},
              pricingDateFrom: { state: false, val: null},
              pricingDateTo: { state: false, val: null},
              role: { state: false, val: null},
              costRateCurrency: { state: false, val: null},
              costRateOfResource: { state: false, val: null},
              payment_terms: { state: false, val: null}
            };
            item.render = this.$UUID().split('-')[0];
            this.$set(project.DataTableItems, project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti.projectRateCard._id === item.projectRateCard._id)[0]), item)
            this.$store.dispatch("UPDATE_PROJECT_RATE_CARD", item.projectRateCard)
            this.$nextTick(() => {
              this.$forceUpdate();
            })
            this.loading = false;
          }).catch(error => {
            console.error(error);
            console.error("failed to update projectRateCard: " + prc._id)
            this.$forceUpdate();
            this.loading = false;
          })
          // update the item edited state
        })
      },
      saveItem(project, item) {
        let itemClone = JSON.parse(JSON.stringify(item));
        //  add resource to project
        if (!project.resources.includes(itemClone._id)) {
          let history = JSON.parse(JSON.stringify(project));
          delete history.history;
          project.history.push(history);
          project.resources.push(itemClone._id)
          project.lastChange = new Date()
          project.changedBy = this.$store.state.userADInfo.mail
          let p = {
            bill_to_customer: project.bill_to_customer,
            bill_rate_currency: project.bill_rate_currency,
            billing_cycle: project.billing_cycle,
            changeBy: project.changedBy,
            company_id: project.company_id,
            client_project_manager: project.client_project_manager,
            lastChange: project.lastChange,
            end_client_name: project.end_client_name,
            expenses: project.expenses,
            history: project.history,
            owners: project.owners,
            partner_or_practice: project.partner_or_practice,
            payment_terms: project.payment_terms,
            project_definition_existing_project: project.project_definition_existing_project,
            project_location: project.project_location,
            purchase_order_number: project.purchase_order_number,
            resources: project.resources,
            salesCommission: project.salesCommission,
            standard_work_week: project.standard_work_week,
            title: project.title,
            _id: project._id
          }
          this.$BackendService.project.update({ project: p, email: false}).then((updatedProject) => {
            this.$store.dispatch("UPDATE_PROJECT", updatedProject)
            // console.log(updatedProject.resources.includes(itemClone._id))
            this.$AlertService.newAlert({
                message: `Saved ${itemClone.name}'s as a resource of ${project.title}.`,
                timeout: 5,
                color: 'success',
                show: true
              })
            if (itemClone.hasProjectRateCard) {
              let projectRateCardOldData = this.projectRateCards.filter(prc => prc._id === itemClone.projectRateCard._id)[0]
              if (Object.keys(projectRateCardOldData).some(key => projectRateCardOldData[key] !== itemClone.projectRateCard[key])) {
                let history = JSON.parse(JSON.stringify(projectRateCardOldData))
                delete history.history;
                if (!Array.isArray(itemClone.projectRateCard.history)) {
                  itemClone.projectRateCard.history = [];
                }
                itemClone.projectRateCard.history.push(history);
                // change sow to blank
                itemClone.projectRateCard.sow = '';
              }
            }
            itemClone.projectRateCard.active = true;
            itemClone.projectRateCard.lastChange = (new Date());
            itemClone.projectRateCard.changedBy = this.$store.state.userADInfo.mail
            delete itemClone.projectRateCard._rev
            //  add project rate card
            this.$BackendService.projectRateCard[!this.projectRateCards.some(prc => prc._id === itemClone.projectRateCard._id && prc.active) ? 'create' : 'update']({ projectRateCard: itemClone.projectRateCard, email: true }).then((updatedProjectRateCard) => {
              itemClone.hasProjectRateCard = true
              itemClone.errors = {
                estimatedWork: { state: false, val: null},
                pricingDateFrom: { state: false, val: null},
                pricingDateTo: { state: false, val: null},
                role: { state: false, val: null},
                costRateCurrency: { state: false, val: null},
                costRateOfResource: { state: false, val: null},
                billRateToCustomer: { state: false, val: null},
                payment_terms: { state: false, val: null}
              };
              itemClone.edited = {
                  billRateToCustomer: { state: false, val: null},
                  estimatedWork: { state: false, val: null},
                  pricingDateFrom: { state: false, val: null},
                  pricingDateTo: { state: false, val: null},
                  role: { state: false, val: null},
                  costRateCurrency: { state: false, val: null},
                  costRateOfResource: { state: false, val: null},
                  payment_terms: { state: false, val: null}
                };
              itemClone.render = this.$UUID().split('-')[0];
              let indexOfItem = project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti.projectRateCard._id === itemClone.projectRateCard._id)[0]);
              if(indexOfItem === -1) {
                project.DataTableItems.push(JSON.parse(JSON.stringify(itemClone)))
                indexOfItem = project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti.projectRateCard._id === itemClone.projectRateCard._id)[0]);
                project.additionalResource = null;
                project.selectedAdditionalResource = null;
                project.render = this.$UUID().split('-')[0];
                itemClone = project.DataTableItems[indexOfItem]
                this.$forceUpdate();
              } else {
                this.$set(project.DataTableItems, indexOfItem, itemClone)
              }
              this.$store.dispatch("UPDATE_PROJECT_RATE_CARD", itemClone.projectRateCard)
              this.$AlertService.newAlert({
                message: `Saved ${itemClone.name}'s Project Rate Card to DB.`,
                timeout: 5,
                color: 'success',
                show: true
              })
              // add project rate card to resource
              this.$BackendService.onboarding.get({ _id: itemClone._id, company_id: this.company._id}).then(onb => {
                if (!onb.projectRateCards.includes(itemClone.projectRateCard._id)) {
                  onb.projectRateCards.push(itemClone.projectRateCard._id)
                  this.$BackendService.onboarding.update({ _id: onb._id, projectRateCards: onb.projectRateCards, onboarding_id: onb._id, company_id: onb.company_id }).then((savedOnb) => {
                    this.$store.dispatch("UPDATE_ONBOARDING", onb)
                    this.$AlertService.newAlert({
                      message: `Saved Project Rate Card to ${itemClone.name}.`,
                      timeout: 5,
                      color: 'success',
                      show: true
                    })
                    this.$forceUpdate();
                  }).catch(error => {
                    console.error(error);
                    this.$AlertService.newAlert({
                      message: `Failed to save Project Rate Card to ${itemClone.name}.`,
                      timeout: 5,
                      color: 'error',
                      show: true
                    })
                    this.$forceUpdate();
                  })
                }
              })
            }).catch(error => {
              console.error(error);
              this.$AlertService.newAlert({
                message: `Failed to save ${itemClone.name}'s Project Rate Card to DB.`,
                timeout: 5,
                color: 'error',
                show: true
              })
              this.$forceUpdate();
            })
          }).catch(error => {
            console.error(error);
            this.$AlertService.newAlert({
              message: `Failed to save ${itemClone.name}'s as a resource of ${project.title}.`,
              timeout: 5,
              color: 'error',
              show: true
            })
            this.$forceUpdate();
          })
        }
      },
      deleteItems(project, items) {
        let itemsWithoutProjectRateCard = items.filter(item => !item.hasProjectRateCard);
        let itemsWithProjectRateCard = items.filter(item => item.hasProjectRateCard)
        let that = this;
        if(confirm(`Are you sure you want to delete?`)) {
          if (itemsWithoutProjectRateCard.length > 0) {
            // console.log('removing items without projectRateCard from project and table')
            // console.log('project resources: ', project.resources)
            for(var x = 0; x < itemsWithoutProjectRateCard.length; x++) {
              // console.log("item without prc: ", itemsWithoutProjectRateCard[x]._id)
              project.resources.splice(project.resources.indexOf(itemsWithoutProjectRateCard[x]._id), 1);
              project.DataTableItems.splice(project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti._id === itemsWithoutProjectRateCard[x]._id)[0]), 1)
            }
            if (project.selected) {
              if (project.DataTableItems.length > 0) {
                if (!project.DataTableItems.some(dti => dti.selected)) {
                  that.$set(project, 'selected', false)
                }
              } else {
                that.$set(project, 'selected', false)
              }
            }
            // console.log('project resources: ', project.resources)
            // console.log('update project history, lastChange, changedBy')
            let history = JSON.parse(JSON.stringify(project));
            delete history.history;
            project.history.push(history);
            project.lastChange = new Date()
            project.changedBy = this.$store.state.userADInfo.mail
            let p = {
              bill_to_customer: project.bill_to_customer,
              bill_rate_currency: project.bill_rate_currency,
              billing_cycle: project.billing_cycle,
              changeBy: project.changedBy,
              company_id: project.company_id,
              client_project_manager: project.client_project_manager,
              lastChange: project.lastChange,
              end_client_name: project.end_client_name,
              expenses: project.expenses,
              history: project.history,
              owners: project.owners,
              partner_or_practice: project.partner_or_practice,
              payment_terms: project.payment_terms,
              project_definition_existing_project: project.project_definition_existing_project,
              project_location: project.project_location,
              purchase_order_number: project.purchase_order_number,
              resources: project.resources,
              salesCommission: project.salesCommission,
              standard_work_week: project.standard_work_week,
              title: project.title,
              _id: project._id
            }
            // console.log("update project in backend")
            this.$BackendService.project.update({ project: p, email: false}).then((updatedProject) => {
              // console.log('update project in store')
              this.$store.dispatch("UPDATE_PROJECT", p)
              if (itemsWithProjectRateCard.length > 0) {
                // console.log('update items with projectRateCard')

                for(var x = 0; x < itemsWithProjectRateCard.length; x++) {
                  const item = itemsWithProjectRateCard[x];
                  let history = JSON.parse(JSON.stringify(item.projectRateCard));
                  delete history.history;
                  if (!Array.isArray(item.projectRateCard.history)) {
                    item.projectRateCard.history = [];
                  }
                  item.projectRateCard.history.push(history);
                  item.projectRateCard.active = false;
                  item.projectRateCard.sow = '';
                  item.projectRateCard.estimatedWork = ""
                  item.projectRateCard.billRateToCustomer = ""
                  item.projectRateCard.pricingDateFrom = ""
                  item.projectRateCard.pricingDateTo = ""
                  item.projectRateCard.role = ""
                  item.projectRateCard.costRateOfResource = ""
                  item.projectRateCard.lastChange = (new Date());
                  item.projectRateCard.payment_terms = ""
                  item.projectRateCard.changedBy = this.$store.state.userADInfo.mail
                  delete item.projectRateCard._rev
                  
                  that.$BackendService.projectRateCard.update({ projectRateCard: item.projectRateCard, email: false}).then((updatedProjectRateCard) => {
                    that.$store.dispatch("UPDATE_PROJECT_RATE_CARD", item.projectRateCard)
                    item.hasProjectRateCard = false;
                    item.render = that.$UUID().split('-')[0];
                    item.selected = false;
                    if (project.selected) {
                      if (project.DataTableItems.length > 0) {
                        if (!project.DataTableItems.some(dti => dti.selected)) {
                          that.$set(project, 'selected', false)
                        }
                      } else {
                        that.$set(project, 'selected', false)
                      }
                    }
                    that.$set(project.DataTableItems, project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti._id === item._id)[0]), item)
                    that.$forceUpdate();
                  }).catch(error => {
                    console.error(error);
                    console.error('Failed to delte projectRateCard: ' + item.projectRateCard._id);
                  })
                }
              } else {
                that.$forceUpdate();
              }
            }).catch(error => {
              console.error(error);
              console.error("Failed to update Project: " + project._id)
            })
          } else if (itemsWithProjectRateCard.length > 0) {
            // console.log('update items with projectRateCard')
            for(var x = 0; x < itemsWithProjectRateCard.length; x++) {
              let item = itemsWithProjectRateCard[x];
              // console.log(item);
              let history = JSON.parse(JSON.stringify(item.projectRateCard));
              delete history.history;
              if (!Array.isArray(item.projectRateCard.history)) {
                item.projectRateCard.history = [];
              }
              item.projectRateCard.history.push(history);
              item.projectRateCard.active = false;
              item.projectRateCard.sow = '';
              item.projectRateCard.estimatedWork = ""
              item.projectRateCard.billRateToCustomer = ""
              item.projectRateCard.pricingDateFrom = ""
              item.projectRateCard.pricingDateTo = ""
              item.projectRateCard.role = ""
              item.projectRateCard.costRateOfResource = ""
              item.projectRateCard.lastChange = (new Date());
              item.projectRateCard.payment_terms = ""
              item.projectRateCard.changedBy = this.$store.state.userADInfo.mail
              delete item.projectRateCard._rev
              
              that.$BackendService.projectRateCard.update({ projectRateCard: item.projectRateCard, email: false}).then((updatedProjectRateCard) => {
                that.$store.dispatch("UPDATE_PROJECT_RATE_CARD", item.projectRateCard)
                item.hasProjectRateCard = false;
                item.render = that.$UUID().split('-')[0];
                item.selected = false;
                if (project.selected) {
                  if (project.DataTableItems.length > 0) {
                    if (!project.DataTableItems.some(dti => dti.selected)) {
                      that.$set(project, 'selected', false)
                    }
                  } else {
                    that.$set(project, 'selected', false)
                  }
                }
                that.$set(project.DataTableItems, project.DataTableItems.indexOf(project.DataTableItems.filter(dti => dti._id === item._id)[0]), item)
                that.$forceUpdate();
              }).catch(error => {
                console.error(error);
                console.error('Failed to delte projectRateCard: ' + item.projectRateCard._id);
              })
            }
          }
        }    
      },
      save() {

      },
      close() {

      },
      open() {

      },
      cancel() {

      },
      noExistingProjectRateCard(project, onb) {
        if (onb.projectRateCards === undefined) {
          onb.projectRateCards = []
        }
        return !onb.projectRateCards.some(projectRateCard => projectRateCard.project_id === project._id)
      },
      doesNotExistInProjectResourcePool(project, onb) {
        return !this.alreadyExistInProjectResourcePool(project, onb);
      },
      alreadyExistInProjectResourcePool(project, onb) {
        return project.resources.some(resource => {
          return onb._id === resource
        });
      },
      getObject(string) {

        let splt = string.split('.');
        let d = this;
        splt.forEach(s => {
          d = d[s];
        })
        return d;
      },
      importFromSharepoint() {
        let that = this;
        
        that.$BackendService.flow.sharepoint.getProjects({
          company_id: that.company._id
        }).then(projects => {
          that.$store.dispatch("SET_PROJECTS", projects);
          // console.log(that.$store.state.projects);
          that.$BackendService.flow.sharepoint.getProjectRateCards({
              company_id: that.company._id
          }).then(projectRateCards => {
            that.$store.dispatch("SET_PROJECT_RATE_CARDS", projectRateCards);
            // console.log(that.$store.state.projectRateCards);
          }).catch(error => console.error(error))
        }).catch(error => console.error(error))
      },
      calcWorkDays(from, to) {  
        // clone date to avoid messing up original date and time
        let fromSplit = from.split('/');
        let fromFormatted = fromSplit[2] + '-' + fromSplit[0] + '-' + fromSplit[1]

        let toSplit = to.split('/');
        let toFormatted = toSplit[2] + '-' + toSplit[0] + '-' + toSplit[1]

        // console.log(fromFormatted, toFormatted);

        var frD=new Date(fromFormatted),
            toD=new Date(toFormatted),
            numOfWorkingDays=1;
        
        // reset time portion
        frD.setHours(0,0,0,0);
        toD.setHours(0,0,0,0);
        
        while(frD<toD){
          frD.setDate(frD.getDate()+1);
          var day=frD.getDay();
          if(day!=0&&day!=6){numOfWorkingDays++;}
        }
        return numOfWorkingDays;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>