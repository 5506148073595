import store from '../store/index.js';
import BackendService from './backend.service.js';
let CompanyService = {
    getCompanies: function () {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.company.getFromUser({
                    _id: store.state.userADInfo.id,
                }).then(function (companies) {
                    store.dispatch("SET_COMPANIES", companies);
                    resolve(companies);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }

        })
    }
}
export default CompanyService;