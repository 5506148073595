<template>
  <v-layout
    row
    wrap
    justify-center
    align-center
    v-if="$store.state.userInfo !== null && $store.state.companies !== null"
  >
    <v-flex xs10 mb-2 v-if="$store.state.companies.length === 0">
      <h1 style="text-align: center;">Welcome to Cognitus Surfboard</h1>
      <br />
      <h2
        style="text-align: center;"
      >Looks like you don't have any companies associated with your user...</h2>
      <br />
      <company-join />
      <br />
      <h1 style="text-align: center; color: black;">~ OR ~</h1>
      <br />
      <company-create />
      <br />
    </v-flex>
    <v-layout row wrap justify-center align-center v-else>
      <v-flex xs12>
        <br />
        <h3 style="text-align: center;">Companies</h3>
        <br />
      </v-flex>
      <v-flex :xs3="$vuetify.breakpoint.mdAndUp" :xs11="$vuetify.breakpoint.smAndDown" ma-1 v-for="(company, index) in $store.state.companies" :key="'company-'+index">
        <v-card hover @click="$router.push({path: `/company/${company._id}/home`})">
          <v-img v-if="company.logo !== undefined" :src="company.logo.url"></v-img>
          <v-card-text>
            <h3 style="text-align: center;">{{company.name}}</h3>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import CompanyCreate from "@/components/Company/CompanyCreate.vue";
import CompanyJoin from "@/components/Company/CompanyJoin.vue";
export default {
  components: { CompanyCreate, CompanyJoin },
  data() {
    return {
      completedIcon: "mdi-checkmark"
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>
</style>