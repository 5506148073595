<template>
  <v-layout
    justify-center
    align-center
    row
    wrap
    v-if="company !== undefined && $store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null"
  >
    <v-flex
      xs10
      v-if="onboardingHistory.length > 0 && $store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.onboarding.history`)"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            :color="!refreshing ? 'primary' : 'warning'"
            @click="refreshOnboardingHistory()"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        {{ `${refreshing ? "Refreshing" : "Refresh"}` }}
      </v-tooltip>
      <h1 style="text-align: center; margin: .75em 0;">Company Onboarding History</h1>
      <v-progress-linear color="warning" indeterminate v-if="refreshing"></v-progress-linear>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field label="Search" v-model="search"></v-text-field>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <span
          v-for="(onboardingHistoryItem, index) in searchedOnboardingHistory"
          :key="`onboardingHistoryItem-${index}`"
        >
          <v-list-item class="history-item" three-line>
            <v-list-item-content>
              <v-list-item-title>
                {{
                onboardingHistoryItem.firstName +
                " " +
                onboardingHistoryItem.lastName +
                " - " +
                onboardingHistoryItem.cognitusEmailMailbox +
                onboardingHistoryItem.cognitusEmailDomain
                }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Onboarded By:
                <strong>{{ onboardingHistoryItem.requester }}</strong>
                <br />Time Onboarded:
                <strong>
                  {{
                  getDate(onboardingHistoryItem.date_time_onboarded)
                  }}
                </strong>
              </v-list-item-subtitle>
              <span>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <a
                      v-on="on"
                      @click="
                        $route.path !==
                        `/company/${company._id}/onboarding-change/${onboardingHistoryItem.onboarding_id}`
                          ? $router.push({
                              path: `/company/${company._id}/onboarding-change/${onboardingHistoryItem.onboarding_id}`
                            })
                          : $eventBus.$emit('openOnboardingChange')
                      "
                    >
                      Flow Approval Status
                      <v-icon
                        :class="
                          `history-item-avatar ${
                            onboardingHistoryItem.flowApprovalStatus ===
                            'Approved'
                              ? 'approved'
                              : onboardingHistoryItem.flowApprovalStatus ===
                                'Denied'
                              ? 'denied'
                              : onboardingHistoryItem.flowApprovalStatus ===
                                'Requested'
                              ? 'requested'
                              : ''
                          }`
                        "
                      >
                        {{
                        onboardingHistoryItem.flowApprovalStatus ===
                        "Approved"
                        ? "mdi-check"
                        : onboardingHistoryItem.flowApprovalStatus ===
                        "Denied"
                        ? "mdi-cancel"
                        : onboardingHistoryItem.flowApprovalStatus ===
                        "Requested"
                        ? "mdi-help-circle"
                        : ""
                        }}
                      </v-icon>
                    </a>
                  </template>
                  {{ onboardingHistoryItem.flowApprovalStatus }}
                </v-tooltip>
              </span>
              <span v-if="onboardingHistoryItem.flowApprovalStatus === 'Approved'">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <a
                      v-on="on"
                      @click="
                        $router.push({
                          path: `/redirect/${encodeURIComponent(
                            `https://cognitus.atlassian.net/browse/${onboardingHistoryItem.jiraIssueKey}`
                          )}`
                        })
                      "
                    >
                      Jira Issue Status
                      <v-icon
                        :class="
                          `history-item-avatar ${
                            onboardingHistoryItem.jiraIssueStatus === 'Done'
                              ? 'done'
                              : onboardingHistoryItem.jiraIssueStatus ===
                                'Created'
                              ? 'created'
                              : onboardingHistoryItem.jiraIssueStatus ===
                                'Requested'
                              ? 'requested'
                              : ''
                          }`
                        "
                      >
                        {{
                        onboardingHistoryItem.jiraIssueStatus === "Done"
                        ? "mdi-check"
                        : onboardingHistoryItem.jiraIssueStatus ===
                        "Created"
                        ? "mdi-progress-clock"
                        : onboardingHistoryItem.jiraIssueStatus ===
                        "Requested"
                        ? "mdi-help-circle"
                        : ""
                        }}
                      </v-icon>
                    </a>
                  </template>
                  {{ onboardingHistoryItem.jiraIssueStatus }}
                </v-tooltip>
              </span>
              <span v-if="getHistory(onboardingHistoryItem).length > 0">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <a
                      v-on="on"
                      @click="
                        $route.path !==
                        `/company/${company._id}/onboarding-history/${onboardingHistoryItem._id}`
                          ? $router.push({
                              path: `/company/${company._id}/onboarding-history/${onboardingHistoryItem._id}`
                            })
                          : $router.push({
                              path: `/company/${company._id}/onboarding-history`
                            })
                      "
                    >
                      History
                      <v-icon class="history-item-avatar">mdi-history</v-icon>
                    </a>
                  </template>
                  {{ getHistory(onboardingHistoryItem).length - 1 }} Change
                  Requests
                </v-tooltip>
              </span>
              <v-flex
                xs12
                v-if="
                  onboarding_id !== undefined &&
                    onboarding_id !== null &&
                    specificOnboarding !== undefined &&
                    specificOnboarding !== null &&
                    specificOnboarding._id === onboardingHistoryItem._id
                "
              >
                <v-dialog v-model="showSpecificHistory">
                  <v-card
                    v-if="
                      specificHistoryIndex !== -1 &&
                        history[specificHistoryIndex] !== undefined
                    "
                  >
                    <v-card-title>
                      {{
                      history[specificHistoryIndex].cognitusEmailMailbox +
                      history[specificHistoryIndex].cognitusEmailDomain
                      }}
                    </v-card-title>
                    <v-card-text>
                      <span
                        v-html="syntaxHighlight(JSON.stringify(history[specificHistoryIndex], null, 4))"
                      ></span>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-list v-if="history.length > 0">
                  <v-list-item
                    three-line
                    class="history-item"
                    v-for="(specificOnboardingHistoryItem, index) in history"
                    :key="'specificOnboardingHistory-' + index"
                    @click="showHistory(specificOnboardingHistoryItem, index)"
                  >
                    <v-list-item-content>
                      <v-tooltip right>
                        <template v-slot:activator="{on}">
                          <v-list-item-title v-on="on">
                            {{
                            index === 0
                            ? history.length > 1
                            ? `Initial`
                            : `Current`
                            : index === history.length - 1
                            ? `Current`
                            : `Change Request #${index}`
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-on="on">
                            {{
                            convertToDate(
                            specificOnboardingHistoryItem.date_time_onboarded
                            )
                            }}
                          </v-list-item-subtitle>
                        </template>
                        Flow: {{specificOnboardingHistoryItem.flowApprovalStatus}}
                        <span
                          v-if="specificOnboardingHistoryItem.flowApprovalStatus === 'Approved'"
                        >
                          <br />
                          Jira: {{specificOnboardingHistoryItem.jiraIssueStatus}}
                        </span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <h3 v-else>No Change History</h3>
              </v-flex>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < onboardingHistory.length - 1"></v-divider>
        </span>
      </v-list>
    </v-flex>
    <h1 v-else-if="!$vuetify.breakpoint.smAndDown">No Onboarding History To Show</h1>
    <h3 v-else-if="$vuetify.breakpoint.smAndDown">No Onboarding History To Show</h3>
  </v-layout>
</template>

<script>
export default {
  props: ["filteredOnboardingHistory", "company", "onboarding_id"],
  data() {
    return {
      specificOnboarding: null,
      showSpecificHistory: false,
      specificHistoryIndex: -1,
      refreshing: false,
      onboardingHistory: [],
      search: ""
    };
  },
  computed: {
    history() {
      return this.specificOnboarding !== null &&
        this.specificOnboarding.history !== undefined
        ? this.specificOnboarding.history.concat([this.specificOnboarding])
        : this.specificOnboarding !== null
        ? [this.specificOnboarding]
        : [];
    },
    searchedOnboardingHistory() {
      let that = this;
      // console.log(this.search);
      return this.search !== "" && this.search.length >= 2
        ? this.onboardingHistory.filter(x => {
            return Object.keys(x).some(y => {
              return (
                JSON.stringify(x[y]).includes(that.search) ||
                that.search.includes(JSON.stringify(x[y]))
              );
            });
          })
        : this.onboardingHistory;
    }
  },
  methods: {
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return (
        '<pre style="outline: 1px solid #ccc; padding: 5px; margin: 5px;">' +
        json.replace(
          /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
          function(match) {
            var cls = "number";
            if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                cls = "key";
              } else {
                cls = "string";
              }
            } else if (/true|false/.test(match)) {
              cls = "boolean";
            } else if (/null/.test(match)) {
              cls = "null";
            }
            return '<span class="json-' + cls + '">' + match + "</span>";
          }
        ) +
        "</pre>"
      );
    },
    getDate(dTime) {
      return new Date(dTime).toDateString();
    },
    refreshOnboardingHistory() {
      this.$eventBus.$emit("getOnboardingHistory", { data: {
                  company_id: that.company._id
                }});
      this.refreshing = true;
    },
    getHistory(item) {
      return item.history !== undefined ? item.history.concat(item) : [item];
    },
    convertToDate(d) {
      let date = new Date(d);
      return date.toString();
    },
    showHistory(item, index) {
      this.showSpecificHistory = true;
      this.specificHistoryIndex = index;
    },
    sortDate(a, b) {
      return a.date_time_onboarded < b.date_time_onboarded ? 1 : -1;
    }
  },
  watch: {
    filteredOnboardingHistory(val) {
      let that = this;
      if (val !== undefined && val !== null) {
        this.onboardingHistory = JSON.parse(JSON.stringify(val))
          .sort(this.sortDate)
          .filter(x => {
            return Object.keys(x).some(y => {
              return x[y].includes(that.search) || that.search.includes(x[y]);
            });
          });
      }
      if (this.refreshing) {
        this.refreshing = false;
        this.$AlertService.newAlert({
          message: "Retrieved Latest Onboarding History",
          timeout: 5,
          show: true,
          color: "success"
        });
      }
    },
    onboarding_id(val) {
      let that = this;
      if (val !== undefined && val !== null) {
        this.specificOnboarding = this.onboardingHistory.filter(function(
          onboarding
        ) {
          return onboarding._id === that.onboarding_id;
        })[0];
      } else {
        this.specificOnboarding = null;
      }
    }
  },
  mounted() {
    var that = this;
    if (this.filteredOnboardingHistory !== undefined) {
      this.onboardingHistory = JSON.parse(
        JSON.stringify(that.filteredOnboardingHistory)
      ).sort(this.sortDate);
    }
    if (
      this.onboarding_id !== undefined &&
      this.onboarding_id !== null &&
      this.onboardingHistory.some(function(onboarding) {
        return onboarding._id === that.onboarding_id;
      })
    ) {
      this.specificOnboarding = this.onboardingHistory.filter(function(
        onboarding
      ) {
        return onboarding._id === that.onboarding_id;
      })[0];
    }
    that.$eventBus.$on("forceUpdate", function() {
      that.$forceUpdate();
    });
  }
};
</script>

<style>
.history-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.history-item:hover a {
  background-color: rgba(0, 0, 0, 0.1);
}
.history-item:hover a:hover {
  background-color: rgba(0, 0, 255, 0.1);
}
.history-item-avatar {
  font-size: 2.5em;
}
.history-item-avatar.approved,
.history-item-avatar.done {
  color: rgb(53, 117, 53);
}
.history-item-avatar.requested,
.history-item-avatar.created {
  color: rgb(53, 78, 117);
}
.history-item-avatar.denied {
  color: rgb(131, 56, 56);
}
.json-string {
  color: green !important;
}
.json-number {
  color: darkorange !important;
}
.json-boolean {
  color: blue !important;
}
.json-null {
  color: magenta !important;
}
.json-key {
  color: red !important;
}
</style>
