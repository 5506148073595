import store from '../store';
function encryptAES(data) {
    return new Promise(function(resolve, reject) {
        if (store.state.aesKey !== null && window.aesWrapper !== undefined) {
            decryptRSA(store.state.aesKey).then(function (aesKey) {
                // console.log(aesKey)
                window.aesWrapper.encryptMessage(aesKey, JSON.stringify(data)).then(function (encryptedData) {
                    // console.log(encryptedData)
                    resolve(encryptedData);
                }).catch(function(error) {
                    reject(error);
                })
            }).catch(function(error) {
                reject(error);
            })
        } else {
            console.log(store.state.aesKey, window.aesWrapper);
            reject(new Error("Unable to encrypt, aeskey is null."));
        }
    })
}
function decryptAES(data) {
    return new Promise(function(resolve, reject) {
        if (store.state.aesKey !== null && window.aesWrapper !== undefined) {
            // console.log('decrypt')
            decryptRSA(store.state.aesKey).then(function(aesKey) {
                window.aesWrapper.decryptMessage(aesKey, data).then(function(decryptedData) {
                    resolve(JSON.parse(decryptedData));
                }).catch(function(error) {
                    reject(error);
                })
            }).catch(function(error) {
                reject(error);
            })
        } else {
            console.log(store.state.aesKey);
            reject(new Error("Unable to decrypt, aeskey is null."));
        }
    })
}
function encryptRSA(data) {
        return new Promise(function(resolve, reject) {
            if (store.state.keys !== null && window.rsaWrapper !== undefined) {
                window.rsaWrapper.publicEncrypt(store.state.keys[window.location.hostname === 'surfboard.cognitusconsulting.com' ? 'production' : 'development'].server.public, JSON.stringify(data)).then(function(encryptedData) {
                    resolve(encryptedData);
                }).catch(function(error) {
                    reject(error);
                })
            } else {
                reject(new Error("Unable to encrypt, keys are null."));
            }
        })
    }
function decryptRSA(data) {
        return new Promise(function(resolve, reject) {
            if (store.state.keys !== null && window.rsaWrapper !== undefined) {
                window.rsaWrapper.privateDecrypt(store.state.keys[window.location.hostname === 'surfboard.cognitusconsulting.com' ? 'production' : 'development'].client.private, data).then(function (decryptedData) {
                    // console.log(decryptedData)
                    resolve(decryptedData);
                }).catch(function(error) {
                    reject(error);
                })
            } else {
                reject(new Error("Unable to decrypt, keys are null."));
            }
        })
    }
export default {
    encryptAES,
    decryptAES,
    encryptRSA,
    decryptRSA
};
