<template>
  <v-layout
    row
    wrap
    justify-center
    align-center
    v-if="onboardings !== null && $store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && company !== undefined"
  >
    <v-flex
      xs10
      mt-5
      v-if="
      ($store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.onboarding.continue`)) &&
        onboarding_id !== undefined &&
          onboarding_id !== null &&
          document !== null
      "
    >
      <h1 style="text-align: center;">Company Onboarding Continue</h1>
      <br />
      <onboarding-form
        :onboarding_id="onboarding_id"
        :countries="countries"
        :onboardingFormData="document"
        :onboardings="onboardings"
        :company="company"
      />
    </v-flex>
    <h1 v-else-if="!$vuetify.breakpoint.smAndDown">No Onboarding To Continue</h1>
    <h3 v-else-if="$vuetify.breakpoint.smAndDown">No Onboarding To Continue</h3>
  </v-layout>
</template>

<script>
import OnboardingForm from "@/components/Company/Onboarding/OnboardingForm.vue";
export default {
  props: [
    "onboarding_id",
    "countries",
    "onboardingFormData",
    "onboardings",
    "company"
  ],
  components: {
    OnboardingForm
  },
  mounted() {
    var that = this;
    that.getDocument();
    that.$eventBus.$on("forceUpdate", function() {
      that.getDocument();
      that.$forceUpdate();
    });
  },
  methods: {
    getDocument() {
      var that = this;
      if (
        this.onboardings !== undefined &&
        this.onboardings !== null &&
        this.onboardings.length > 0
      ) {
        if (this.onboarding_id !== undefined) {
          this.document =
            this.onboardings.filter(function(row) {
              return row._id === that.onboarding_id;
            })[0] || null;
          this.document.changeRequest = false;
          // add values to document.forms.required
        } else {
          var onboarding = this.onboardings.filter(function(row) {
            return (
              row.cognitusEmailMailbox ===
              that.$store.state.userInfo.cognitus_email_address.split("@")[0]
            );
          });
          if (onboarding.length > 0) {
            onboarding[0].step = onboarding[0].step+1;
            this.$router.push({
              path: `/company/${that.company._id}/onboarding-continue/${onboarding[0]._id}`
            });
          }
        }
      }
    }
  },
  watch: {
    onboarding_id(val) {
      var that = this;
      if (
        val !== undefined &&
        this.onboardings !== undefined &&
        this.onboardings !== null &&
        this.onboardings.length > 0
      ) {
        that.getDocument();
      }
    },
    onboardings(val) {
      var that = this;
      if (val.length > 0 && this.document === null) {
        that.getDocument();
      }
    }
  },
  data() {
    return {
      document: null
    };
  }
};
</script>

<style lang="scss" scoped></style>
