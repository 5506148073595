<template>
  <v-layout style="margin: 1em 0;" justify-center align-center row wrap v-if="onboardingFormDataCopy !== null">
    <v-flex xs10 v-show="onboardingFormDataCopy.employee">
      <!-- FSS CHECKBOX -->
      <!-- <v-switch
        :success="onboardingFormDataCopy.fssCheckbox === true"
        v-model="onboardingFormDataCopy.fssCheckbox"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="FSS"
      ></v-switch>-->
      <!-- FSP CHECKBOX -->
      <!-- <v-switch
        :success="onboardingFormDataCopy.fspSuccessFactorsCheckbox === true"
        disabled
        v-model="onboardingFormDataCopy.fspSuccessFactorsCheckbox"
        label="FSP/SuccessFactor"
      ></v-switch>-->
      <!-- CURRENCY -->
      <v-autocomplete
        v-model="onboardingFormDataCopy.currency"
        :items="Object.keys($store.state.currencies)"
        label="Currency"
        key="currency"
      ></v-autocomplete>
      <!-- BILL RATE -->
      <v-text-field
        v-currency="{
          currency: onboardingFormDataCopy.currency || 'USD',
          locale: 'en',
          autoDecimalMode: false,
          decimalLength: undefined,
          'allow-negative': false,
          distractionFree: true,
          min: null,
          max: null
        }"
        v-model="onboardingFormDataCopy.billRate"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Bill Rate (required)"
      ></v-text-field>
      <!-- COST RATE -->
      <v-text-field
        v-currency="{
          currency: onboardingFormDataCopy.currency || 'USD',
          locale: 'en',
          autoDecimalMode: false,
          decimalLength: undefined,
          'allow-negative': false,
          distractionFree: true,
          min: null,
          max: null
        }"
        v-model="onboardingFormDataCopy.costRate"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Cost Rate"
      ></v-text-field>
      <!-- LAPTOP -->
      <v-switch
        :success="onboardingFormDataCopy.laptop === true"
        v-model="onboardingFormDataCopy.laptop"
        label="Laptop"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-switch>
      <!-- JIRA CHEKBOX -->
      <!-- <v-switch
        :success="onboardingFormDataCopy.jiraCheckbox === true"
        v-model="onboardingFormDataCopy.jiraCheckbox"
        label="JIRA"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-switch>-->
      <!-- OTHER SYSTEM -->
      <v-autocomplete
        key="otherSystems"
        cache-items
        chips
        multiple
        v-model="onboardingFormDataCopy.otherSystem"
        :items="otherSystemItems"
        label="Other Systems"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            :close="(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? onboardingFormDataCopy.travelExpenses ? false : onboardingFormDataCopy.contingentWorker ? false : true : true"
            @click="data.select"
            @click:close="remove(data.item)"
            :color="(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? onboardingFormDataCopy.travelExpenses ? 'success' : onboardingFormDataCopy.contingentWorker ? 'success' : 'primary' : 'primary'"
          >
            <!-- <v-avatar left>
              <v-img :src="data.item"></v-img>
            </v-avatar>-->
            {{ (data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? onboardingFormDataCopy.travelExpenses ? data.item.text + ' (required)' : onboardingFormDataCopy.contingentWorker ? data.item.text + ' (required)' : data.item.text : data.item.text }}
          </v-chip>
        </template>
      </v-autocomplete>
      <!-- START DATE -->
      <v-menu
        key="contingent-startDate"
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="onboardingFormDataCopy.startDate"
            :label="'Start Date (required)'"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="onboardingFormDataCopy.startDate"
          @change="menu1 = false"
          :disabled="
            !onboardingFormDataCopy.changeRequest &&
              (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                onboardingFormDataCopy.flowApprovalStatus === 'Approved')
          "
        ></v-date-picker>
      </v-menu>
      <!-- SuccessFactors REPORTS TO -->
      <v-autocomplete 
        :color="retrievingSFData ? 'warning' : 'primary'" 
        :loading="retrievingSFData" 
        v-if="onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors')" 
        :label="`Reports To (required) ${retrievingSFData ? '(loading data)' : ''}`" 
        :items="sfPositions.filter(position => !position.hidden)" 
        v-model="onboardingFormDataCopy.sfReportsTo"
      ></v-autocomplete>
      <!-- SuccessFactors Paying Legal Entity -->
      <v-autocomplete 
        :color="retrievingSFData ? 'warning' : 'primary'" 
        :loading="retrievingSFData" 
        v-if="onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors')" 
        :label="`Paying Legal Entity (required) ${retrievingSFData ? '(loading data)' : ''}`" 
        :items="sfPayingLegalEntities" 
        v-model="onboardingFormDataCopy.sfPayingLegalEntity"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-autocomplete>
      <!-- COST CENTER -->
      <v-autocomplete
        v-model="onboardingFormDataCopy.costCenter"
        :items="sfCostCenters.filter(costCenter => !costCenter.hidden)"
        label="Cost Center (required)"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-autocomplete>
      <!-- Office 365 Licenses -->
      <v-autocomplete
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Office 365 License"
        v-model="onboardingFormDataCopy.o365license"
        :items="company.settings[
          company.settings.indexOf(
            company.settings.filter(
              setting => setting.label === 'Office 365 License'
            )[0]
          )
        ].items"
      ></v-autocomplete>
    </v-flex>

    <!--
      CONTRACTOR
    -->
    <v-flex xs10 v-show="onboardingFormDataCopy.contractor">
      <!-- JIRA CHECKBOX -->
      <!-- <v-switch
        :key="'contractor-jira'"
        :success="onboardingFormDataCopy.jiraCheckbox"
        v-model="onboardingFormDataCopy.jiraCheckbox"
        :disabled="
          onboardingFormDataCopy.jiraCheckboxDisabled ||
            (!onboardingFormDataCopy.changeRequest &&
              (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                onboardingFormDataCopy.flowApprovalStatus === 'Approved'))
        "
        :label="
          onboardingFormDataCopy.jiraCheckboxDisabled
            ? `JIRA (required by ${
                onboardingFormDataCopy.travelExpenses &&
                onboardingFormDataCopy.contingentWorker
                  ? 'contingent worker and travel expenses'
                  : onboardingFormDataCopy.travelExpenses &&
                    !onboardingFormDataCopy.contingentWorker
                  ? 'travel expenses'
                  : !onboardingFormDataCopy.travelExpenses &&
                    onboardingFormDataCopy.contingentWorker
                  ? 'contingent worker'
                  : ''
              })`
            : 'JIRA (optional)'
        "
      ></v-switch>-->
      <!-- FSP CHECKBOX -->
      <!-- <v-switch 
        :key="'contractor-fsp'"
        :success="onboardingFormDataCopy.fspSuccessFactorsCheckbox === true" 
        :label="`FSP/SuccessFactors (required by ` + (onboardingFormDataCopy.contingentWorker || onboardingFormDataCopy.travelExpenses ?  onboardingFormDataCopy.contingentWorker ? onboardingFormDataCopy.travelExpenses ? ' contingent worker and travel expenses)' : ' contingent worker)' : onboardingFormDataCopy.travelExpenses ? ' travel expenses)' : '' : '')" 
        v-model="onboardingFormDataCopy.fspSuccessFactorsCheckbox" 
        disabled 
        v-if="onboardingFormDataCopy.contingentWorker || onboardingFormDataCopy.travelExpenses"
      ></v-switch>-->
      <!-- TRAVEL EXPENSES -->
      <v-switch
        :key="'contractor-travel'"
        :success="onboardingFormDataCopy.travelExpenses === true"
        v-model="onboardingFormDataCopy.travelExpenses"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Travel Expenses (optional)"
      ></v-switch>
      <!-- CONTINGENT WORKER -->
      <v-switch
        :key="'contractor-contingent'"
        :success="onboardingFormDataCopy.contingentWorker === true"
        v-model="onboardingFormDataCopy.contingentWorker"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Contingent Worker (optional)"
      ></v-switch>
      <!-- FREELANCE CONTINGENT WORKER -->
      <v-layout v-if="onboardingFormDataCopy.contingentWorker" style="margin-bottom: 1em;">
        <v-flex xs5 mr-4>
          <v-card hover @click="
            onboardingFormDataCopy.vendorAddressSupplier = 'freelance'; $forceUpdate();
          "
          :outlined="onboardingFormDataCopy.vendorAddressSupplier === 'freelance'"
          :color="onboardingFormDataCopy.vendorAddressSupplier === 'freelance' ? 'rgba(200, 200, 200, .1)' : ''"
          :style="
            onboardingFormDataCopy.vendorAddressSupplier === 'freelance' ? 'color: rgba(0, 100, 0, 1);' : 'color: black'
          "
          :disabled="
            !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
          ">
            <v-card-title>Freelance</v-card-title>
            <v-card-subtitle>
              Vendor Address is provided by the
              <strong>freelance contingent worker</strong>.
            </v-card-subtitle>
            <v-card-text></v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 mr-4>
          <v-card hover @click="
            onboardingFormDataCopy.vendorAddressSupplier = 'serviceprovider'; $forceUpdate();
          "
          :outlined="onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider'"
          :color="onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider' ? 'rgba(200, 200, 200, .1)' : ''"
          :style="
            onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider' ? 'color: rgba(0, 100, 0, 1);' : 'color: black'
          "
          :disabled="
            !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
          ">
            <v-card-title>Service Provider</v-card-title>
            <v-card-subtitle>
              Vendor Address is provided by a
              <strong>service provider</strong>.
            </v-card-subtitle>
            <v-card-text></v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-text-field 
        v-if="onboardingFormDataCopy.contingentWorker && onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider'"
        v-model="onboardingFormDataCopy.serviceProviderEmailAddress"
        :error="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(onboardingFormDataCopy.serviceProviderEmailAddress))"
        :success="(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(onboardingFormDataCopy.serviceProviderEmailAddress))"
        :erorr-messages="!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(onboardingFormDataCopy.serviceProviderEmailAddress)) ? ['Please enter a valid email address'] : []"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Service Provider Email Address (required)"
      >

      </v-text-field>
      <!-- CURRENCY -->
      <v-autocomplete
        key="currency"
        v-model="onboardingFormDataCopy.currency"
        v-if="onboardingFormDataCopy.contingentWorker"
        :items="Object.keys($store.state.currencies)"
        label="Currency"
      ></v-autocomplete>
      <!-- BILL RATE -->
      <v-text-field
        v-currency="{
          currency: onboardingFormDataCopy.currency || 'USD',
          locale: 'en',
          autoDecimalMode: false,
          decimalLength: undefined,
          'allow-negative': false,
          distractionFree: true,
          min: null,
          max: null
        }"
        v-if="onboardingFormDataCopy.contingentWorker"
        v-model="onboardingFormDataCopy.billRate"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Bill Rate"
      ></v-text-field>
      <!-- COST RATE -->
      <v-text-field
        v-currency="{
          currency: onboardingFormDataCopy.currency || 'USD',
          locale: 'en',
          autoDecimalMode: false,
          decimalLength: undefined,
          'allow-negative': false,
          distractionFree: true,
          min: null,
          max: null
        }"
        v-if="onboardingFormDataCopy.contingentWorker"
        v-model="onboardingFormDataCopy.costRate"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Cost Rate"
      ></v-text-field>
      <!-- START DATE -->
      <v-menu
        key="startDate"
        v-if="
          onboardingFormDataCopy.travelExpenses || onboardingFormDataCopy.contingentWorker
        "
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="onboardingFormDataCopy.startDate"
            :label="onboardingFormDataCopy.travelExpenses &&
            !onboardingFormDataCopy.contingentWorker ? `Travel Start Date (required)` : !onboardingFormDataCopy.travelExpenses &&
            onboardingFormDataCopy.contingentWorker ? `Working Start Date (required)` : onboardingFormDataCopy.travelExpenses &&
            onboardingFormDataCopy.contingentWorker ? `Work and Travel Start Date (required)` : ``"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="onboardingFormDataCopy.startDate"
          @change="menu = false"
          :disabled="
            !onboardingFormDataCopy.changeRequest &&
              (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                onboardingFormDataCopy.flowApprovalStatus === 'Approved')
          "
        ></v-date-picker>
      </v-menu>
      <!-- OTHER SYSTEM -->
      <v-autocomplete
        key="otherSystems"
        cache-items
        chips
        multiple
        v-model="onboardingFormDataCopy.otherSystem"
        :items="otherSystemItems"
        label="Other Systems"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      >
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            :close="(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? onboardingFormDataCopy.travelExpenses ? false : onboardingFormDataCopy.contingentWorker ? false : true : true"
            @click="data.select"
            @click:close="remove(data.item)"
            :color="(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? onboardingFormDataCopy.travelExpenses ? 'success' : onboardingFormDataCopy.contingentWorker ? 'success' : 'primary' : 'primary'"
          >
            <!-- <v-avatar left>
              <v-img :src="data.item"></v-img>
            </v-avatar>-->
            {{ (data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? onboardingFormDataCopy.travelExpenses ? data.item.text + ' (required)' : onboardingFormDataCopy.contingentWorker ? data.item.text + ' (required)' : data.item.text : data.item.text }}
          </v-chip>
        </template>
      </v-autocomplete>
      <!-- SuccessFactors REPORTS TO -->
      <v-autocomplete
        key="reportsTo"
        :color="retrievingSFData ? 'warning' : 'primary'" 
        :loading="retrievingSFData" 
        v-if="onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors')" 
        :label="`Reports To (required) ${retrievingSFData ? '(loading data)' : ''}`" 
        :items="sfPositions.filter(position => !position.hidden)" 
        v-model="onboardingFormDataCopy.sfReportsTo"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-autocomplete>

      <!-- SuccessFactors Paying Legal Entity -->
      <v-autocomplete 
        key="payingLegalEntity"
        :color="retrievingSFData ? 'warning' : 'primary'" 
        :loading="retrievingSFData" 
        v-if="onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors')" 
        :label="`Paying Legal Entity (required) ${retrievingSFData ? '(loading data)' : ''}`" 
        :items="sfPayingLegalEntities" 
        v-model="onboardingFormDataCopy.sfPayingLegalEntity"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-autocomplete>
      
      <!-- COST CENTER -->
      <v-autocomplete
        key="costCenter"
        v-if="onboardingFormDataCopy.contingentWorker"
        :items="sfCostCenters.filter(costCenter => !costCenter.hidden)"
        v-model="onboardingFormDataCopy.costCenter"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Cost Center (required)"
      ></v-autocomplete>
      <!-- <v-select v-if="onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors')" :items="sfCandidates"></v-select> -->
      <!-- Office 365 License -->
      <v-autocomplete
        key="o365License"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
            (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        label="Office 365 License"
        v-model="onboardingFormDataCopy.o365license"
        :items="company.settings[
          company.settings.indexOf(
            company.settings.filter(
              setting => setting.label === 'Office 365 License'
            )[0]
          )
        ].items"
      ></v-autocomplete>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: [
    "data",
    "onboardings",
    "company",
    "countries",
    "onboarding_id",
    "step"
  ],
  data() {
    return {
      menu1: false,
      menu: false,
      onboardingFormDataCopy: null,
      vendorAddressSupplier: null,
      isStepping: false,
      sfPositions: [],
      sfEmpJobs: [],
      sfUsers: [],
      sfCostCenters: [],
      sfPayingLegalEntities: [],
      sfFOCompanies: [],
      retrievingSFData: false
    };
  },
  created() {
    var that = this;
    this.onboardingFormDataCopy = JSON.parse(JSON.stringify(that.data));
    if (this.onboardingFormDataCopy.otherSystem.includes("")) {
      this.onboardingFormDataCopy.otherSystem.splice(
        this.onboardingFormDataCopy.otherSystem.indexOf(""),
        1
      );
    }
    this.fixSystemsList();
    this.contingentTravelExpensesUpdate(
      that.onboardingFormDataCopy.contingentWorker,
      that.onboardingFormDataCopy.travelExpenses
    );
    this.$eventBus.$on("nextStep", function(currentStep) {
      if (currentStep === that.step && !that.isStepping) {
        that.isStepping = true;
        that.$emit(
          "updateData",
          that.getOnboardingFormDataCopy(),
          that.step,
          true,
          function() {
            that.isStepping = false;
          }
        );
      }
    });
    that.$eventBus.$emit("stepOkay", {
      step: that.step,
      valid: true
    });
  },
  mounted() {
    this.$forceUpdate();
    let that = this;

    that.loadSFData();

    if (
          this.onboardingFormDataCopy.currency === "" ||
          this.onboardingFormDataCopy.currency === null
        )
      this.onboardingFormDataCopy.currency = "USD";
  },
  watch: {
    "onboardingFormDataCopy": {
      handler: function(val) {
        var that = this;
        if (val !== undefined) {
          let valid = true;
          if (val.otherSystem.includes("FSP/SuccessFactors")) {
            if (that.onboardingFormDataCopy.sfPayingLegalEntity === undefined || 
                that.onboardingFormDataCopy.sfPayingLegalEntity === '' ||  
                that.onboardingFormDataCopy.sfReportsTo === undefined ||  
                that.onboardingFormDataCopy.sfReportsTo === '') {
                  valid = false;
            }
            if (that.onboardingFormDataCopy.employee) {
              if (that.onboardingFormDataCopy.costCenter === undefined || that.onboardingFormDataCopy.costCenter === null || that.onboardingFormDataCopy.costCenter === '') {
                  valid = false;
              }
              if (that.onboardingFormDataCopy.startDate === undefined || that.onboardingFormDataCopy.startDate === null || that.onboardingFormDataCopy.startDate === '') {
                  valid = false;
              }
            } else if (that.onboardingFormDataCopy.contingentWorker || that.onboardingFormDataCopy.travelExpenses) {
                if (that.onboardingFormDataCopy.startDate === undefined || that.onboardingFormDataCopy.startDate === null || that.onboardingFormDataCopy.startDate === '') {
                  valid = false;
                }
                if (that.onboardingFormDataCopy.sfReportsTo === undefined || that.onboardingFormDataCopy.sfReportsTo === null || that.onboardingFormDataCopy.sfReportsTo === '') {
                  valid = false;
                }
                if (that.onboardingFormDataCopy.sfPayingLegalEntity === undefined || that.onboardingFormDataCopy.sfPayingLegalEntity === null || that.onboardingFormDataCopy.sfPayingLegalEntity === '') {
                  valid = false;
                }
                if (that.onboardingFormDataCopy.contingentWorker) {
                  if (that.onboardingFormDataCopy.costCenter === undefined || that.onboardingFormDataCopy.costCenter === null || that.onboardingFormDataCopy.costCenter === '') {
                    valid = false;
                  }
                  if (that.onboardingFormDataCopy.vendorAddressSupplier === undefined || that.onboardingFormDataCopy.vendorAddressSupplier === null || that.onboardingFormDataCopy.vendorAddressSupplier === '') {
                    valid = false;
                  } else if (that.onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider' && (that.onboardingFormDataCopy.serviceProviderEmailAddress === undefined || that.onboardingFormDataCopy.serviceProviderEmailAddress === null || that.onboardingFormDataCopy.serviceProviderEmailAddress === '')) {
                    valid = false;
                  }
                  if (that.onboardingFormDataCopy.billRate === undefined || that.onboardingFormDataCopy.billRate === null || that.onboardingFormDataCopy.billRate === '' || that.onboardingFormDataCopy.billRate === 0) {
                    valid = false;
                  }
                  if ((that.onboardingFormDataCopy.costRate === undefined || that.onboardingFormDataCopy.costRate === null || that.onboardingFormDataCopy.costRate === '' || that.onboardingFormDataCopy.costRate === 0) && that.onboardingFormDataCopy.contractor) {
                    valid = false;
                  }
                }
                
                
                
            }
          }
          that.$eventBus.$emit("stepOkay", {
            step: that.step,
            valid: valid
          });
        }
      },
      deep: true
    },
    "onboardingFormDataCopy.sfPayingLegalEntity"(val) {
      let that = this;
      if (val !== '') {
        let costCentersUnderPayingLegalEntity = that.sfCostCenters.filter(costCenter => costCenter.payingLegalEntity === val).map(costCenter => costCenter.costCenter);
        that.sfCostCenters.map(costCenter => {
          if (costCenter.payingLegalEntity === val) {
            costCenter.hidden = false;
          } else {
            costCenter.hidden = true;
          }
        });
      }
    },
    data: {
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          this.onboardingFormDataCopy = JSON.parse(JSON.stringify(val));
          this.$forceUpdate();
        }
      }
    },
    "onboardingFormDataCopy.costRate"(val) {
      if (val === "") {
        this.onboardingFormDataCopy.costRate = 0.0;
      }
    },
    "onboardingFormDataCopy.billRate"(val) {
      if (val === "") {
        this.onboardingFormDataCopy.billRate = 0.0;
      }
    },
    "onboardingFormDataCopy.travelExpenses"(val) {
      this.travelExpensesUpdate(val);
      this.$forceUpdate();
    },
    "onboardingFormDataCopy.contingentWorker"(val) {
      this.contingentWorkerUpdate(val);
      this.$forceUpdate();
    },
    "onboardingFormDataCopy.step"(val) {
      if (val === this.step) {
        this.fixSystemsList();
        this.$forceUpdate();
      }
    }
  },
  computed: {
    otherSystemItems() {
      let that = this;
      let companySettingsDefault =
        that.company.settings[
          that.company.settings.indexOf(
            that.company.settings.filter(function(setting) {
              return setting.label === "Other Systems";
            })[0]
          )
        ].items;
      return companySettingsDefault;
    }
  },
  methods: {
    loadSFData() {
      let that = this;
      that.retrievingSFData = true;
      Promise.all([that.loadPayingLegalEntities(), that.loadReportsTo()]).then(() => {
        that.retrievingSFData = false;
      }).catch(error => {
        console.error(error);
      })
    },
    loadPayingLegalEntities() {
      let that = this;
      return new Promise((resolve, reject) => {
        that.getSFFoCompanies().then(companies => {
          that.sfFOCompanies = companies;
          that.getSFCostCenters().then(function(costCenters) {
            that.sfCostCenters = costCenters.filter(costCenter => costCenter !== undefined).sort((a, b) => a.payingLegalEntity - b.payingLegalEntity);
            that.sfPayingLegalEntities = that.sfCostCenters.map(costCenter => {
                let name = that.sfFOCompanies.filter(c => c.payingLegalEntity === costCenter.payingLegalEntity)[0].name;
                return { text: costCenter.payingLegalEntity + ' - ' + name, value: costCenter.payingLegalEntity }
              }
            );
            resolve();
          }).catch(function(error) {
            console.error(error);
            reject(error);
          });
        }).catch(function(error) {
          console.error(error);
          reject(error);
        });
      })
    },
    loadReportsTo() {
      let that = this;
      return new Promise((resolve, reject) => {
        that.getSFUsers().then(users => {
          that.sfUsers = users;
          that.getSFEmpJobs().then(empJobs => {
            that.sfEmpJobs = empJobs;
            that.getSFPositions().then(positions => {
              Promise.allSettled(positions.map(async position => {
                let userByPositionAndEmpJob;
                let empJobsFilteredByPosition = that.sfEmpJobs.filter(eJob => {
                  return eJob.position === position.position
                });
                
                if (empJobsFilteredByPosition.length > 0) {
                  userByPositionAndEmpJob = that.sfUsers.filter(u => {
                    return empJobsFilteredByPosition.some(empJob => empJob.userId === u.userId) && u.isActive
                  });
                  if (userByPositionAndEmpJob.length > 0) {
                    position.text = position.jobTitle + ' - ' + userByPositionAndEmpJob[0].name + ' - ' + position.costCenter + ' - ' + position.position,
                    position.value = position.jobTitle + ' - ' + userByPositionAndEmpJob[0].name + ' - ' + position.costCenter + ' - ' + position.position,
                    position.hidden = false;
                    return position;
                  }
                }
              })).then((mappedPositions) => {
                that.sfPositions = mappedPositions.map(x => x.value !== undefined ? x.value : x).filter(position => position.value !== undefined);
                resolve();
              });
            }).catch(function(error) {
              console.error(error);
              reject(error);
            });
          }).catch(function(error) {
            console.error(error);
            reject(error);
          });
        }).catch(function(error) {
          console.error(error);
          reject(error);
        });
      })
     
    },
    getSFFoCompanies(companies = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/FOCompany?$select=externalCode,name_defaultValue"
        }).then(function(response) {
          companies = companies.concat(response.d.results.map(x => (
            { 
              payingLegalEntity: x.externalCode, 
              name: x.name_defaultValue
            }
          )))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFFoCompanies(companies, response.d['__next']));
          } else {
            resolve(companies)
          }
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFCostCenters(costCenters = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/FOCostCenter?$select=externalCode,name_defaultValue,cust_company"
        }).then(function(response) {
          costCenters = costCenters.concat(response.d.results.map(x => (
            { 
              costCenter: x.externalCode, 
              payingLegalEntity: x.cust_company, 
              name: x.name_defaultValue, 
              text: x.externalCode + ' - ' + x.name_defaultValue, 
              value: x.externalCode + ' - ' + x.name_defaultValue,
              hidden: false
            }
          )))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFCostCenters(costCenters, response.d['__next']));
          } else {
            resolve(costCenters)
          }
          resolve();
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFUsers(users = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/User?$select=userId,defaultFullName,empInfo,status"
        }).then(function(response) {
          users = users.concat(response.d.results.map(x => ({ userId: x.userId, name: x.defaultFullName, isActive: x.status === "t" }) ))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFUsers(users, response.d['__next']));
          } else {
            console.log("found " + users.length + " users from SFSF");
            resolve(users)
          }
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFEmpJobs(empJobs = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/EmpJob?$select=userId,position,emplStatusNav"
        }).then(function(response) {
          empJobs = empJobs.concat(response.d.results.map(x => ({ userId: x.userId, position: x.position, emplStatusNav: x.emplStatusNav }) ))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFEmpJobs(empJobs, response.d['__next']));
          } else {
            console.log("found " + empJobs.length + " empJobs from SFSF");
            resolve(empJobs);
          }
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFEmpInfo(user) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: `https://api4.successfactors.com/odata/v2/EmpEmployment(personIdExternal='${user.userId}',userId='${user.userId}')/jobInfoNav?$select=emplStatus`
        }).then(function(response) {
          resolve(response.d.emplStatus !== "23967")
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFPositions(positions = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/Position?$select=code,jobTitle,costCenter"
        }).then(function(response) {
          positions = positions.concat(response.d.results.map(x => ({ position: x.code, jobTitle: x.jobTitle, costCenter: x.costCenter})))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFPositions(positions, response.d['__next']));
          } else {
            resolve(positions)
          }
        }).catch(function(error) {
          reject(error);
        })
      })
      
    },
    fixSystemsList() {
      let that = this;
      let newSystemsList = [];
      this.onboardingFormDataCopy.otherSystem.forEach(function(i) {
        if (
          that.otherSystemItems.some(function(system) {
            return (
              system.text === i ||
              system.value === i ||
              (i.text !== undefined && system.text === i.text)
            );
          })
        ) {
          if (i.text === undefined) {
            let x = "";
            if (
              i === "FSD" ||
              i === "FSS" ||
              i === "FSP" ||
              i === "FSQ" ||
              i === "HSS" ||
              i === "ECC"
            ) {
              x = "SAP-" + i;
            } else {
              x = i;
            }
            newSystemsList.push(x);
          } else {
            newSystemsList.push(i);
          }
        } else {
          if (i === "FSS - Formosa S/4HANA Sandbox") {
            newSystemsList.push("SAP-FAS");
          } else if (i === "FSP - Formosa S/4HANA Production") {
            newSystemsList.push("FSP/SuccessFactors");
          } else if (i === "FSD - Formosa S/4HANA Development") {
            newSystemsList.push("SAP-FSD");
          } else if (i === "FSQ - Formosa S/4HANA Quality") {
            newSystemsList.push("SAP-FSQ");
          } else if (i === "ECC IDES EHP7") {
            newSystemsList.push("SAP-ECC");
          } else if (i === "HSS - Huxelrube S/4HANA Sandbox") {
            newSystemsList.push("SAP-HSS");
          }
        }
      });
      if (
        this.onboardingFormDataCopy.contingentWorker ||
        this.onboardingFormDataCopy.travelExpenses
      ) {
        if (!this.onboardingFormDataCopy.otherSystem.some(x => x === "JIRA")) {
          newSystemsList.push("JIRA");
        }
        if (
          !this.onboardingFormDataCopy.otherSystem.some(
            x => x === "FSP/SuccessFactors"
          )
        ) {
          newSystemsList.push("FSP/SuccessFactors");
        }
      }
      this.onboardingFormDataCopy.otherSystem = JSON.parse(
        JSON.stringify(newSystemsList)
      );
      this.$forceUpdate();
    },
    remove(item) {
      if (item.value === undefined || item.text === undefined) {
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf(item),
          1
        );
      }
      const index = this.onboardingFormDataCopy.otherSystem.indexOf(item.value);
      if (index >= 0) this.onboardingFormDataCopy.otherSystem.splice(index, 1);
      this.$forceUpdate();
    },
    contingentTravelExpensesUpdate(contingent, travel) {
      if (contingent || travel) {
        // this.onboardingFormDataCopy.jiraCheckbox = true;
        // this.onboardingFormDataCopy.jiraCheckboxDisabled = true;
        // this.onboardingFormDataCopy.fspSuccessFactorsCheckbox = true;
        if (!this.onboardingFormDataCopy.otherSystem.some(x => x === "JIRA")) {
          this.onboardingFormDataCopy.otherSystem.push("JIRA");
        }
        if (
          !this.onboardingFormDataCopy.otherSystem.some(
            x => x === "FSP/SuccessFactors"
          )
        ) {
          this.onboardingFormDataCopy.otherSystem.push("FSP/SuccessFactors");
        }
      }
      if (contingent) {
        if (
          this.onboardingFormDataCopy.currency === "" ||
          this.onboardingFormDataCopy.currency === null
        )
          this.onboardingFormDataCopy.currency = "USD";
        this.onboardingFormDataCopy.contingentWorker = true;
      } else {
        this.onboardingFormDataCopy.contingentWorker = false;
      }
      if (!travel) {
        this.onboardingFormDataCopy.travelExpenses = false;
      } else {
        this.onboardingFormDataCopy.travelExpenses = true;
      }
      if (!contingent && !travel) {
        // this.onboardingFormDataCopy.jiraCheckboxDisabled = false;
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf("JIRA"),
          1
        );
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf("FSP/SuccessFactors"),
          1
        );
      }
      this.$forceUpdate();
    },
    travelExpensesUpdate(val) {
      if (val) {
        if (!this.onboardingFormDataCopy.otherSystem.some(x => x === "JIRA")) {
          this.onboardingFormDataCopy.otherSystem.push("JIRA");
        }
        if (
          !this.onboardingFormDataCopy.otherSystem.some(
            x => x === "FSP/SuccessFactors"
          )
        ) {
          this.onboardingFormDataCopy.otherSystem.push("FSP/SuccessFactors");
        }
        // this.onboardingFormDataCopy.jiraCheckbox = true;
        // this.onboardingFormDataCopy.jiraCheckboxDisabled = true;
        // this.onboardingFormDataCopy.fspSuccessFactorsCheckbox = true;
      } else if (!this.onboardingFormDataCopy.contingentWorker) {
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf("JIRA"),
          1
        );
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf("FSP/SuccessFactors"),
          1
        );
        // this.onboardingFormDataCopy.jiraCheckboxDisabled = false;
      }
      this.$forceUpdate();
    },
    contingentWorkerUpdate(val) {
      if (val) {
        if (!this.onboardingFormDataCopy.otherSystem.some(x => x === "JIRA")) {
          this.onboardingFormDataCopy.otherSystem.push("JIRA");
        }
        if (
          !this.onboardingFormDataCopy.otherSystem.some(
            x => x === "FSP/SuccessFactors"
          )
        ) {
          this.onboardingFormDataCopy.otherSystem.push("FSP/SuccessFactors");
        }
        // this.onboardingFormDataCopy.jiraCheckbox = true;
        // this.onboardingFormDataCopy.jiraCheckboxDisabled = true;
        // this.onboardingFormDataCopy.fspSuccessFactorsCheckbox = true;
        this.onboardingFormDataCopy.currency = "USD";
      } else if (!this.onboardingFormDataCopy.travelExpenses) {
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf("JIRA"),
          1
        );
        this.onboardingFormDataCopy.otherSystem.splice(
          this.onboardingFormDataCopy.otherSystem.indexOf("FSP/SuccessFactors"),
          1
        );
        // this.onboardingFormDataCopy.jiraCheckboxDisabled = false;
      }
      this.$forceUpdate();
    },
    getOnboardingFormDataCopy() {
      return this.onboardingFormDataCopy;
    }
  }
};
</script>

<style lang="scss" scoped></style>
