<template>
  <span>
    <!-- LICENSE REQUEST DIALOG -->
    <v-dialog v-model="dialog['license']" fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('license')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>License Request</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap justify-center align-center>
            <v-flex xs11 style="margin: 0em 1em;" v-if="licenseRequestItem !== undefined && licenseRequestItem !== null">
              <br/>
              <v-select :items="company.settings[
                  company.settings.indexOf(
                    company.settings.filter(
                      setting => setting.label === 'Office 365 License'
                    )[0]
                  )
                ].items" v-model="selectedLicense"
              >
              </v-select>
              <v-btn color="primary" @click="requestLicense()">Request License</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- SAP DIALOG -->
    <v-dialog v-model="dialog['sap']" fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('sap')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>SAP</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap justify-center align-center>
            <v-flex xs11 style="margin: 0em 1em;" v-if="userSAPItem !== undefined && userSAPItem !== null">
              <br/>
              <v-autocomplete :items="userSAPItem.otherSystem.filter(sys => sys.includes('SAP-') || sys.includes('FSP/SuccessFactors')).map(sys => sys.replace('SAP-', '').replace('FSP/SuccessFactors', 'FSP'))" multiple v-model="selectedSAPSystems" label="Select SAP Systems"></v-autocomplete> 
              <v-btn color="primary" @click="createUserSAP()">Create Users</v-btn>
              <v-list v-if="createdUserInSAPSystems.concat(failedToCreateUserInSAPSystem).length > 0">
                <v-list-item v-for="(createdSys, createdIndex) in createdUserInSAPSystems" :key="'created-sys-'+createdIndex">
                  {{ createdSys.system }} <v-icon color="success">mdi-check</v-icon><br/>
                  {{ createdSys.result }}
                </v-list-item>
                <v-list-item v-for="(failedSys, failedIndex) in failedToCreateUserInSAPSystem" :key="'failed-sys-'+failedIndex">
                  {{ failedSys.system }} <v-icon color="error">mdi-error</v-icon><br/>
                  {{ failedSys.reason }}
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- NEW DIALOG -->
    <v-dialog v-model="dialog['new']" fullscreen>
      <v-card v-if="newOnboardingTemplate !== null">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('new')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>New</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <OnboardingForm
          style="margin-top: 2em"
          :onboarding_id="newOnboardingTemplate.onboarding_id"
          :countries="countries"
          :onboardingFormData="newOnboardingTemplate"
          :onboardings="onboardings"
          :company="company"
          :dialog="dialog['new']"
          :formTemplate="formTemplate"
          @closeDialog="close('new')"
          @updateOnboarding="updateOnboardingFromStore()"
        />
      </v-card>
      
    </v-dialog>
    <!-- EMAIL DIALOG -->
    <v-dialog v-model="dialog['email']" fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('email')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Email</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <email-send
          v-if="emailOnboardingTarget !== null && computedOnboarding !== null &&
                emailOnboardingTarget._id === computedOnboarding._id"
          @finished="emailFinished()"
          :dialog="true"
          @closeDialog="close('email')"
          @updateOnboarding="updateOnboardingFromStore()"
          style="background-color: white;"
          :company="company"
          :onboardings="onboardings"
          :onboarding="emailOnboardingTarget"
        />
      </v-card>
      
    </v-dialog>
    <!-- CHANGE DIALOG -->
    <v-dialog v-model="dialog['change']" fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('change')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Change</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <OnboardingForm
              v-if="editedItemComputed !== null && computedOnboarding !== null && editedItemComputed._id === computedOnboarding._id"
              style="margin-top: 2em"
              :onboarding_id="onboarding_id"
              :countries="countries"
              :onboardingFormData="editedItemComputed"
              :onboardings="onboardings"
              :formTemplate="formTemplate"
              :company="company"
              :dialog="dialog['change']"
              @closeDialog="close('change')"
              @updateOnboarding="updateOnboardingFromStore()"
            />
      </v-card>
      
    </v-dialog>
    <!-- HISTORY DIALOG -->
    <v-dialog v-model="dialog['history']" fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('history')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>History</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-layout row wrap justify-center align-center>
          <!-- <v-flex xs11>
            <br />
            <h3 @click="hideCurrentHistory = !hideCurrentHistory" style="cursor: pointer; padding: 12px 1em;">Current</h3>
            <br />
            <span v-if="!hideCurrentHistory" v-html="syntaxHighlight(JSON.stringify(onboarding, null, 4))"></span>
          </v-flex> -->
          <v-flex xs11 v-if="historyItemView !== null && historyItemView !== undefined && computedOnboarding !== null &&
                  historyItemView._id === computedOnboarding._id && histories.length > 0">
            <v-timeline dense>
              <v-timeline-item  
                :key="'history-item-' + historyIndex"
                v-for="(history, historyIndex) in histories"
              >
                <v-card tile @hover="historyHoverIndex = historyIndex" @click="toggleHistory(historyIndex)">
                  <v-card-title>
                    {{ (new Date(history.date_time_onboarded)).toString() }}<br/>
                    {{ Object.keys(historyItemView).filter(key => historyFilter(key, historyIndex)).length }} Changes<br/>

                  </v-card-title>
                  <v-card-subtitle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="toggleHistoryCode(historyIndex)"><v-icon>mdi-code-json</v-icon></v-btn>
                      </template>
                      Show Code
                    </v-tooltip>
                  </v-card-subtitle>
                  <v-card-text v-if="showHistoryDetails[historyIndex] && !showHistoryCode[historyIndex]">
                    <table>
                      <tr>
                        <td><strong>key</strong></td>
                        <td><strong><span style="color: red;" v-if="historyIndex+1 < histories.length">old value</span>&nbsp;<span style="color: green;">new value</span></strong></td>
                      </tr>
                      <tr :key="'historyLineItem' + keyIndex" v-for="(key, keyIndex) in Object.keys(historyItemView).filter(key => historyFilter(key, historyIndex))">
                        <td>{{key + ": " }}</td>
                        <td>
                          <span style="color: red;" v-if="historyIndex+1 < histories.length">{{ histories[historyIndex+1][key] }}</span>&nbsp;<span style="color: green;">{{ histories[historyIndex][key]  }}</span></td>
                      </tr>
                    </table>
                  </v-card-text>
                  <v-card-text v-else-if="showHistoryCode[historyIndex]">
                    <span v-html="syntaxHighlight(JSON.stringify(histories[historyIndex], null, 4))"></span>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-flex>
          <v-flex v-else xs11>
            No Changes In History...
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- ADOBE DIALOG -->
    <v-dialog v-model="dialog['adobe']" fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close('adobe')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Adobe Sign</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-layout row wrap justify-left align-center v-if="adobeSignTarget !== null" style="margin-top: 1em;">
          <v-flex :xs7="!loadingPdf && !viewingPdf" :xs3="viewingPdf || loadingPdf" style="margin: 1em auto;" v-if="!creatingNewAgreement">
            <v-fade-transition group>
              <v-card key="activeAdobeAgreements" v-if="!loadingAdobeAgreements && adobeAgreements !== undefined && adobeAgreements !== null && adobeSignAgreements.length > 0">
                
                <v-toolbar flat dense>

                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on: menu, attrs }">
                      <v-tooltip left >
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn text tile v-on="{ ...tooltip, ...menu }" v-bind="attrs">
                            <v-icon>mdi-filter</v-icon>
                          </v-btn>
                        </template>
                        Filter by Agreement Status
                      </v-tooltip>

                    </template>
                    <v-combobox style="padding: 1em; background-color: rgba(255, 255, 255, 1);" v-model="selectedAdobeSignAgreementStatusList" :items="adobeSignAgreementStatusList" multiple></v-combobox>
                  </v-menu>

                </v-toolbar>
                <v-card-title>{{ filterAdobeSignAgreementByStatusList(selectedAdobeSignAgreementStatusList).length }} {{ filterAdobeSignAgreementByStatusList(selectedAdobeSignAgreementStatusList).length > 1 ? 'Agreements' : 'Agreement' }} found by Filter</v-card-title>
                <v-card-subtitle>Filters: {{ selectedAdobeSignAgreementStatusList.map(status => status.split("_").map(stat => stat[0] + stat.substring(1).toLowerCase()).join(" ")).join(", ") }}</v-card-subtitle>
                <v-card-text>
                  <div v-for="(status, index) in selectedAdobeSignAgreementStatusList" :key="'virtual-scroll-status-'+index">
                    <span v-if="filterAdobeSignAgreementByStatus(status).length > 0">
                      <h3>{{ status.split("_").map(stat => stat[0] + stat.substring(1).toLowerCase()).join(" ") }} Agreements </h3>
                      <v-virtual-scroll :height="100 * filterAdobeSignAgreementByStatus(status).length" item-height="64" :items="filterAdobeSignAgreementByStatus(status)" >
                        <template v-slot:default="{ item }">
                          <v-list-item @mouseenter="selectedNonSignedAgreementIndex = filterAdobeSignAgreementByStatus(status).indexOf(item)" :key="'nonSignedAgreement-'+filterAdobeSignAgreementByStatus(status).indexOf(item)">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.status }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action v-if="selectedNonSignedAgreementIndex === filterAdobeSignAgreementByStatus(status).indexOf(item)">
                                <v-btn-toggle dense >
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                      <v-btn text @click="cancelAgreement(item)" v-on="on">
                                        <v-icon>mdi-cancel</v-icon>
                                      </v-btn>
                                    </template>
                                    Cancel Agreement
                                  </v-tooltip>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                      <v-btn text @click="deleteAgreement(item)" v-on="on">
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    Delete Agreement
                                  </v-tooltip>
                                  
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                      <v-btn text @click="showAgreementEvents(item)" v-on="on">
                                        <v-icon>mdi-history</v-icon>
                                      </v-btn>
                                    </template>
                                    Agreement Events
                                  </v-tooltip>
                                  
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                      <v-btn text @click="openPDF(item)" v-on="on">
                                        <v-icon>mdi-file-pdf-box</v-icon>
                                      </v-btn>
                                    </template>
                                    Open PDF
                                  </v-tooltip>

                                  <v-tooltip bottom>
                                    <template v-slot:activator="{on}">
                                      <v-btn text @click="openAuditTrail(item)" v-on="on">
                                        <v-icon>mdi-history</v-icon>
                                      </v-btn>
                                    </template>
                                    Open Audit Trail
                                  </v-tooltip>

                                  
                                </v-btn-toggle>
                              </v-list-item-action>
                            </v-list-item>
                            <v-divider
                              v-if="filterAdobeSignAgreementByStatus(status).indexOf(item) < filterAdobeSignAgreementByStatus(status).length - 1"
                              :key="filterAdobeSignAgreementByStatus(status).indexOf(item)"
                            ></v-divider>
                        </template>
                      </v-virtual-scroll>
                    </span>
                  </div>
                </v-card-text>
              </v-card>
              <v-skeleton-loader v-else-if="loadingAdobeAgreements"
                key="adobeAgreementsSkeletonLoader"
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
              <v-card key="noActiveAdobeAgreements" v-else>
                <v-card-title>
                  No Unsigned Agreements for {{ adobeSignTarget.firstName + " " + adobeSignTarget.lastName }}
                </v-card-title>
              </v-card>
            </v-fade-transition>
          </v-flex>
          <v-flex xs8 style="margin: 1em auto;" v-if="viewingPdf && !loadingPdf">
            <v-fade-transition group>
              <v-toolbar flat floating key='pdfToolbar'>
                <v-btn text tile @click="viewingPdf = false; adobeDCView = null;"><v-icon>mdi-close</v-icon></v-btn>
              </v-toolbar>
              <div key="pdfview" id="adobe-dc-view" style="width: 100%; height: 800px;"></div>
              <!-- <object key="pdf" :data="pdfToView" type="application/pdf" width="100%" height="800px">
                <p>It appears you don't have a PDF plugin for this browser.
                No biggie... you can <a :href="pdfToView">click here to
                  download the PDF file.</a></p>
              </object> -->
            </v-fade-transition>
          </v-flex>
          <v-flex xs8 style="margin: 1em auto;" v-else-if="viewingPdf && loadingPdf">
            <v-fade-transition>
              <v-skeleton-loader
                key="pdfSkeletonLoader"
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-fade-transition>
          </v-flex>
          <v-flex v-else-if="!viewingPdf" :xs7="!creatingNewAgreement" :xs8="creatingNewAgreement" style="margin: 1em auto;">
            <v-fade-transition group>
              <v-card key="newAgreement" v-if="!loadingLibraryDocuments && !creatingNewAgreement && libraryDocuments !== undefined && libraryDocuments !== null && libraryDocuments.length > 0" @click="newAgreement()">
                <v-card-title>New Agreement</v-card-title>
                <v-card-subtitle>Click to start a new agreement for <strong v-html="`${adobeSignTarget.firstName} ${adobeSignTarget.lastName} <${adobeSignTarget.cognitusEmailMailbox}>`"></strong></v-card-subtitle>
              </v-card>
              <v-card key="libraryDocSelection" v-else-if="!loadingLibraryDocuments && creatingNewAgreement && selectedLibraryDoc === null">
                <v-btn text tile style="position: absolute; left: 0px; top: 0px;" @click="creatingNewAgreement = false;"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="padding-top: 2em;"><span v-html="'New Agreement for&nbsp;'"></span><strong v-html="`${adobeSignTarget.firstName} ${adobeSignTarget.lastName} <${adobeSignTarget.cognitusEmailMailbox}>`"></strong></v-card-title>
                <v-card-text>
                  <h3>Select Agreement Template</h3>
                  <v-list>
                    <v-list-item :style="`${ libraryDocumentHoverIndex === index ? 'border: 1px solid #000;' : ''}`" @hover="libraryDocumentHoverIndex = index" @click="selectDoc(libraryDocument)" v-for="(libraryDocument,index) in libraryDocuments.filter(availableLibraryDocuments)" :key="'libraryDocument-'+index">
                      {{ libraryDocument.name }}
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <v-card key="libraryDocSignerSelection" v-else-if="!loadingLibraryDocuments && creatingNewAgreement && selectedLibraryDoc !== null">
                <v-btn text tile style="position: absolute; left: 0px; top: 0px;" @click="creatingNewAgreement = true; selectedLibraryDoc = null;"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <v-card-title style="padding-top: 2em;" v-html="`${selectedLibraryDoc.name} for ${adobeSignTarget.firstName} ${adobeSignTarget.lastName} <${adobeSignTarget.cognitusEmailMailbox}>`">
                </v-card-title>
                <v-card-text>
                  <div key="signersDiv" style="margin-top: 2em;">
                    <h2>Signers</h2>
                    <v-autocomplete label="Cognitus Signer (required)" v-model="cognitusSigner" :items="authorizedSignersComputed">
                    </v-autocomplete>
                    <v-autocomplete label="Second Party Signer (required)" v-model="secondPartySignerEmail" :items="secondPartySigners">
                    </v-autocomplete>
                  </div>
                  <div key="ccDiv" style="margin-top: 2em;">
                    <h2>Carbon Copy</h2>
                    <v-combobox
                      v-model="ccs"
                      :items="Array.prototype.concat.apply([], onboardings.map(onb => {
                        return {
                          text: `${onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>'}`,
                          value: onb.cognitusEmailMailbox + onb.cognitusEmailDomain
                        }
                      })).concat(onboardings.map(onb => {
                        return {
                          text: `${onb.firstName + ' ' + onb.lastName + ' <' + onb.personalEmailAddress + '>'}`,
                          value: onb.personalEmailAddress
                        }
                      }))"
                      label="Cc"
                      multiple
                      small-chips
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Press <kbd>enter</kbd> to add a new email address
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </div>
                  <div key="prefillDiv" style="margin-top: 2em;">
                    <span v-if="mergeInfo !== null">
                      <span v-if="mergeInfo.length > 0">
                        <h2>Prefill Form Fields</h2>
                        <span v-for="(field, index) in mergeInfo" :key="'mergeInfo-'+index">
                          <v-text-field v-if="!field.fieldName.includes(' or ')" v-model="field.defaultValue" :label="field.fieldName.includes('Vendor') && field.fieldName !== 'Vendor Name' ? field.fieldName + ' (optional)' : field.fieldName + ' (required)'"></v-text-field>
                          <v-autocomplete v-else :label="field.fieldName + ' (required)'" v-model="field.defaultValue" :items="field.fieldName.split(' or ').map(option => {
                              return {
                                text: option,
                                value: option
                              }
                            })"></v-autocomplete>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div key="createDiv" style="margin-top: 2em;">
                    <v-btn color="success" :disabled="!mergeInfoValid" @click="createAgreement">Create Agreement</v-btn>
                  </div>
                </v-card-text>
              </v-card>
              <v-skeleton-loader v-else-if="loadingLibraryDocuments"
                key="libraryDocumentSkeletonLoader"
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-fade-transition>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- TERMINATION DATE SELECTION DIALOG -->
    <v-dialog v-model="terminationDateDialog">
      <v-card>
        <v-card-title>Select Termination Date</v-card-title>
        <v-card-text>
          <v-layout justify-center align-center>
            <v-flex xs6>
              <v-date-picker v-model="terminationDate"></v-date-picker>
            </v-flex>
          </v-layout>
          
          <v-btn @click="terminateUser()">Terminate</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- REHIRE DATE SELECTION DIALOG -->
    <v-dialog v-model="rehireDateDialog">
      <v-card>
        <v-card-title>Select Rehire Date</v-card-title>
        <v-card-text>
          <v-layout justify-center align-center>
            <v-flex xs6>
              <v-date-picker v-model="rehireDate"></v-date-picker>
            </v-flex>
          </v-layout>
          <v-btn @click="revertTermination()">Rehire</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  Search Onboardings  -->
    
    <!-- <span v-if="e1LicenseAvailable">E1 License Available</span>
    <span v-if="e3LicenseAvailable">E3 License Available</span> -->
    <v-text-field
      clearable
      placeholder="Johnny Appleseed"
      label="Who are you looking for?"
      ref="search"
      autofocus
      :disabled="loading"
      :error="searchError"
      :error-messages="searchErrorMessages"
      :error-count="searchErrorMessages.length"
      :loading="searching || loading"
      :color="searchColor"
      :success="searchSuccess"
      :success-messages="searchSuccessMessages"
      :messages="searchMessages"
      filled
      hide-details="auto"
      :hint="!loading ? `${onboardings !== null ? onboardings.length : 0 } total onboardings` : 'loading onboardings'"
      v-model="search"
      flat
      @keyup.enter="searchDatabase()"
      @click:clear="clearSearch()"
      :persistent-hint="true"
    >
      <template v-slot:append>
        <v-switch v-model="advancedSearch" label="Advanced Search"></v-switch>
      </template>
    </v-text-field>
    <v-list style="border: 1px solid rgba(0, 0, 0, .05); background-color: rgba(0, 0, 0, .01); border-radius: .25em; margin-top: .5em;">
      <v-list-item
        @click="
          $router.push({
            path: `/company/${company._id}/onboarding-new/${UUID()}`,
          })
        "
      >
        <v-list-item-icon>
          <v-icon color="success">mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Create New Onboarding</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list v-if="searching">
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar-two-line@4"
      ></v-skeleton-loader>
    </v-list>
    <v-list
      v-if="searchRequest !== undefined && searchRequest !== null && searchRequest.length > 0"
      style="border: 1px solid rgba(0, 0, 0, .05); border-radius: .25em; margin-top: .5em;"
    >
      <v-list-item
        v-for="(searchedDataEntry, index) in searchRequest"
        :key="searchedDataEntry._id"
        :style="`background-color: ${index % 2 === 0 ? '#fcfcfc' : '#fff'}`"
        @click="
          $router.push({
            path: `/company/${company._id}/onboarding-profile/${searchedDataEntry._id}`,
          })
        "
      >
        <v-list-item-avatar>
          <v-img
            :src="searchedDataEntry.avatar || require('@/assets/3dlogo-cognitus-consulting.png')"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-text="
              searchedDataEntry.firstName + ' ' + searchedDataEntry.lastName
            "
          ></v-list-item-title>
          <v-list-item-subtitle
            v-text="
              searchedDataEntry.cognitusEmailMailbox +
                searchedDataEntry.cognitusEmailDomain
            "
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <!--  ONBOARDING PROFILE CARD  -->
    <v-card dark v-if="computedOnboarding !== undefined && computedOnboarding !== null" style="margin-top: .5em; margin-bottom: 2em;" elevation="7">
      <v-img :src="computedOnboarding.employee ? require('@/assets/ocean-employee.jpg') : require('@/assets/ocean-contractor.jpg')" :height="`${$vuetify.breakpoint.mdAndUp ? 'auto' : 'auto'}`">
        <v-avatar style="margin: 1em 0 0 1em;" size="72">
          <v-img :src="(computedOnboarding.avatar !== null ? computedOnboarding.avatar : require('@/assets/3dlogo-cognitus-consulting.png'))"></v-img>
        </v-avatar>
        <!-- SM AND DOWN CARD LAYOUT -->
        <v-layout v-if="$vuetify.breakpoint.smAndDown && !showOnboardingCode">
          <v-flex xs12 style="margin: 1em 0em 0em 1em;">
            <!-- NAME -->
            <span style="font-size: 1em;">
              {{computedOnboarding.firstName + " " + computedOnboarding.lastName}}
            </span>
            <br/>
            <!-- JOB TITLE -->
            <span style="color: hsla(0,0%,100%,.7); font-size: .8em;" v-if="computedOnboarding.jobTitle !== undefined &&  computedOnboarding.jobTitle !== null && computedOnboarding.jobTitle !== ''">
              {{ computedOnboarding.jobTitle }} &nbsp;-&nbsp;
            </span>
            <span style="color: hsla(0,0%,100%,.7); font-size: .8em;" v-else>
              No Job Title Found
            </span>
            <!-- CONTRACTOR/EMPLOYEE & REGION -->
            <span style="color: hsla(0,0%,100%,.7); font-size: .8em;">
              {{ computedOnboarding.employee ? "Employee" : computedOnboarding.contractor ? "Contractor" : "" }}
               &nbsp;-&nbsp;
              {{ computedOnboarding.region ? computedOnboarding.region : 'No region found'}}
            </span>
            <br/>
            <!-- EMAIL -->
            <v-tooltip right>
              <template v-slot:activator="{on}">
                <a  v-on="on"
                style="color: white;"
                :href="
                  `mailto:${computedOnboarding.cognitusEmailMailbox +
                    computedOnboarding.cognitusEmailDomain}`
                "
                >{{
                  computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain
                }}</a
              >
              </template>
              <h3>Office365 User Status</h3>
              {{ office365UserExistance[computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain] ? 'Exists' : 'Does Not Exist' }}<br/>
              {{ !office365LoginDisabled[computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain] ? 'Unlocked' : 'Locked' }}<br/>
              {{ office365UserHasMailingLicense[computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain] ? 'Email License' : 'No Email License'}}
            </v-tooltip>
            <br />
            <!-- PERSONAL EMAIL -->
            <v-tooltip right>
              <template v-slot:activator="{on}">
                <a v-on="on"
                  style="color: white;"
                  :href="`mailto:${computedOnboarding.personalEmailAddress}`"
                  >{{ computedOnboarding.personalEmailAddress }}</a
                >
              </template>
              Personal Email Address
            </v-tooltip>
            <br />
            <!-- PHONE -->
            <v-tooltip right>
              <template v-slot:activator="{on}">
                <a v-if="computedOnboarding.mobilePhone !== undefined" v-on="on"
                  style="color: white;"
                  :href="`tel:${computedOnboarding.mobilePhone.e164}`"
                  >{{ computedOnboarding.mobilePhone.e164 }}</a
                ><span v-else>No phone found</span>
              </template>
              Mobile Phone
            </v-tooltip>
            <br />
            
            <!-- FLOW APPROVAL STATUS -->
            <span v-if="computedOnboarding.flowApprovalStatus !== undefined && computedOnboarding.flowApprovalStatus !== null && computedOnboarding.flowApprovalStatus !== ''">
              <v-tooltip right color="black">
                <template v-slot:activator="{ on }">
                  <span v-on="on" style="color: white; font-size: .8em;">
                    Flow Approval Status
                    <v-icon
                      :class="
                        `history-item-avatar ${
                          computedOnboarding.flowApprovalStatus ===
                          'Approved'
                            ? 'approved'
                            : computedOnboarding.flowApprovalStatus ===
                              'Denied'
                            ? 'denied'
                            : computedOnboarding.flowApprovalStatus ===
                              'Requested'
                            ? 'requested'
                            : ''
                        }`
                      "
                    >
                      {{
                      computedOnboarding.flowApprovalStatus ===
                      "Approved"
                      ? "mdi-check"
                      : computedOnboarding.flowApprovalStatus ===
                      "Denied"
                      ? "mdi-cancel"
                      : computedOnboarding.flowApprovalStatus ===
                      "Requested"
                      ? "mdi-help-circle"
                      : ""
                      }}
                    </v-icon>
                  </span>
                </template>
                {{ computedOnboarding.flowApprovalStatus }}
              </v-tooltip>
            </span>
            <br v-if="computedOnboarding.flowApprovalStatus === 'Approved' || (computedOnboarding.jiraIssueStatus !== undefined && computedOnboarding.jiraIssueStatus !== null && computedOnboarding.jiraIssueStatus !== '')" />
            
            <!-- JIRA STATUS -->
            <span v-if="computedOnboarding.flowApprovalStatus === 'Approved' || (computedOnboarding.jiraIssueStatus !== undefined && computedOnboarding.jiraIssueStatus !== null && computedOnboarding.jiraIssueStatus !== '')">
              <v-tooltip right color="black">
                <template v-slot:activator="{ on }">
                  <a
                    style="color: white; text-decoration: underline; font-size: .8em;"
                    v-on="on"
                    @click="
                      $router.push({
                        path: `/redirect/${encodeURIComponent(
                          `https://cognitus.atlassian.net/browse/${computedOnboarding.jiraIssueKey}`
                        )}`
                      })
                    "
                  >Jira Issue Status</a>&nbsp;
                  <v-icon
                      v-on="on"
                      :class="
                        `history-item-avatar ${
                          computedOnboarding.jiraIssueStatus === 'Done'
                            ? 'done'
                            : computedOnboarding.jiraIssueStatus ===
                              'Created'
                            ? 'created'
                            : computedOnboarding.jiraIssueStatus ===
                              'Requested'
                            ? 'requested'
                            : ''
                        }`
                      "
                    >
                      {{
                      computedOnboarding.jiraIssueStatus === "Done"
                      ? "mdi-check"
                      : computedOnboarding.jiraIssueStatus ===
                      "Created"
                      ? "mdi-progress-clock"
                      : computedOnboarding.jiraIssueStatus ===
                      "Requested"
                      ? "mdi-help-circle"
                      : ""
                      }}
                    </v-icon>
                </template>
                {{ computedOnboarding.jiraIssueStatus }}
              </v-tooltip>
            </span>
            <br/>

            <!-- REQUESTER -->
            <span style="font-size: .9em;" v-if="computedOnboarding.requester || computedOnboarding.requestedBy || computedOnboarding.changeRequester">
              Requester: {{ computedOnboarding.requester || computedOnboarding.requestedBy || computedOnboarding.changeRequester }}
            </span>
            <br />
            
            <!-- SYSTEM ACCESS -->
            <span v-if="computedOnboarding.otherSystem.length > 0" style="font-size: .9em;">System Access:
              <br/>
              <ul v-for="(chunk, cIndex) in $chunk(computedOnboarding.otherSystem, 1)" :key="'chunk-'+cIndex" :style="`display: inline-block; font-size: .8em; margin-left: ${cIndex > 0 ? '0' : '0'}em`">
                <li v-for="(system, index) in chunk" :key="'system-'+index">
                  {{ system }}
                </li>
              </ul>
            </span>
            <span v-else style="color: hsla(0,0%,100%,.7); font-size: .9em;" >No System Access</span>
            <br/>
            <!-- EMAILS SENT -->
            <span v-if='computedOnboarding.sentEmails !== undefined && computedOnboarding.sentEmails.length > 0' style="font-size: .9em;">
              Emails Sent ({{computedOnboarding.sentEmails.length}}):
              <ul>
                <li v-for="(sentEmail, index) in computedOnboarding.sentEmails" :key="'sentEmail-'+index">
                  <v-tooltip right close-delay="500" color="black">
                    <template v-slot:activator="{ on }" >
                      <span v-on="on">
                        {{ sentEmail.subject }}<span v-if="sentEmail.times.length > 1"> ({{sentEmail.times.length}})</span><span v-if="index < computedOnboarding.sentEmails.length - 1"></span>
                      </span>
                    </template>
                    <span>
                      <p v-for="(sentEmailX, sentEmailXIndex) in sentEmail.times" :key="'emailSent-'+sentEmail.subject+'-'+sentEmailXIndex">
                        Date Sent: {{ sentEmailX.date }}
                        <br />
                        Sender: <a style="color: white;" :href="`mailto:${sentEmailX.sender}`">{{ sentEmailX.sender }}</a>
                        <br />
                        Recipient: <a style="color: white;" :href="`mailto:${sentEmailX.recipient}`">{{ sentEmailX.recipient }}</a>
                      </p>
                    </span>
                  </v-tooltip>
                </li>
              </ul>
            </span>
            <span v-else style="color:hsla(0,0%,100%,.7);">No Emails Sent</span>
            <br>
            <span>
              Projects:
            </span>
            <ul v-if="onboardingProjects.length > 0">
              <li v-for="(project, index) in onboardingProjects" :key="'project-'+index">
                <router-link style="color: white;" :to="{path: `/company/${company._id}/project/${project._id}?q=${computedOnboarding._id}`}">{{ project.title }}</router-link>
              </li>
            </ul>
            <span v-else>No Projects Found</span>
            <br>
            <br>
            <span>
              Adobe Sign Documents:
            </span>
            <span v-if="adobeSignOnboardingAgreements !== null && adobeSignOnboardingAgreements !== undefined && adobeSignOnboardingAgreements.length > 0">
              <v-virtual-scroll height="60" item-height="20" :items="adobeSignOnboardingAgreements">
                <template v-slot:default="{ item }">
                  <li :key="computedOnboarding._id.split('-')[0]+'-adobeAgreement-'+adobeSignOnboardingAgreements.indexOf(item)">
                    {{ item.name }} {{ item.status }}
                  </li>
                </template>
              </v-virtual-scroll>
            </span>
            <span v-else>No Documents Found</span>
            <br>
          </v-flex>
        </v-layout>
        <!-- MD AND UP CARD LAYOUT -->
        <v-layout v-else-if="$vuetify.breakpoint.mdAndUp && !showOnboardingCode">
          <v-flex xs4 style="margin: 1em 1em 1em 1em;">
            <span>{{
              computedOnboarding.firstName + " " + computedOnboarding.lastName
            }}</span>
            <p style="color: hsla(0,0%,100%,.7);">
              <span v-if="computedOnboarding.jobTitle !== undefined &&  computedOnboarding.jobTitle !== null && computedOnboarding.jobTitle !== ''">{{ computedOnboarding.jobTitle }}</span><span v-else>No Job Title Found</span>
            </p>
            <p style="font-size: .8em;">
              <!-- EMAIL -->
              <v-tooltip right>
                <template v-slot:activator="{on}">
                  <a  v-on="on"
                  style="color: white;"
                  :href="
                    `mailto:${computedOnboarding.cognitusEmailMailbox +
                      computedOnboarding.cognitusEmailDomain}`
                  "
                  >{{
                    computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain
                  }}</a
                >
                </template>
                <h3>Office365 User Status</h3>
                {{ office365UserExistance[computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain] ? 'Exists' : 'Does Not Exist' }}<br/>
                {{ !office365LoginDisabled[computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain] ? 'Unlocked' : 'Locked' }}<br/>
                {{ office365UserHasMailingLicense[computedOnboarding.cognitusEmailMailbox + computedOnboarding.cognitusEmailDomain] ? 'Email License' : 'No Email License'}}
              </v-tooltip>
                <br/>
                <!-- PERSONAL EMAIL -->
                <v-tooltip right>
                  <template v-slot:activator="{on}">
                    <a v-on="on"
                      style="color: white;"
                      :href="`mailto:${computedOnboarding.personalEmailAddress}`"
                      >{{ computedOnboarding.personalEmailAddress }}</a
                    >
                  </template>
                  Personal Email Address
                </v-tooltip>
                <br />
                <!-- PHONE -->
                <v-tooltip right>
                  <template v-slot:activator="{on}">
                    <a v-if="computedOnboarding.mobilePhone !== undefined" v-on="on"
                      style="color: white;"
                      :href="`tel:${computedOnboarding.mobilePhone.e164}`"
                      >{{ computedOnboarding.mobilePhone.e164 }}</a
                    ><span v-else>No phone found</span>
                  </template>
                  Mobile Phone
                </v-tooltip>
                
                
            </p>
            
          </v-flex>
          <v-flex xs4 style="margin: 1em 0em 1em 0em; font-size: .8em;">
            {{ computedOnboarding.employee ? "Employee" : computedOnboarding.contractor ? "Contractor" : "" }}
            <br />
            <span v-if="computedOnboarding.region">{{ computedOnboarding.region }}</span>
            <span style="color: hsla(0,0%,100%,.7);" v-else>No region found</span>
            <br v-if="computedOnboarding.flowApprovalStatus !== undefined && computedOnboarding.flowApprovalStatus !== null && computedOnboarding.flowApprovalStatus !== ''" />
            <br v-if="computedOnboarding.flowApprovalStatus !== undefined && computedOnboarding.flowApprovalStatus !== null && computedOnboarding.flowApprovalStatus !== ''" />
            <span v-if="computedOnboarding.flowApprovalStatus !== undefined && computedOnboarding.flowApprovalStatus !== null && computedOnboarding.flowApprovalStatus !== ''">
              <v-tooltip right color="black">
                <template v-slot:activator="{ on }">
                  <span v-on="on" style="color: white;">
                    Flow Approval Status
                    <v-icon
                      :class="
                        `history-item-avatar ${
                          computedOnboarding.flowApprovalStatus ===
                          'Approved'
                            ? 'approved'
                            : computedOnboarding.flowApprovalStatus ===
                              'Denied'
                            ? 'denied'
                            : computedOnboarding.flowApprovalStatus ===
                              'Requested'
                            ? 'requested'
                            : ''
                        }`
                      "
                    >
                      {{
                      computedOnboarding.flowApprovalStatus ===
                      "Approved"
                      ? "mdi-check"
                      : computedOnboarding.flowApprovalStatus ===
                      "Denied"
                      ? "mdi-cancel"
                      : computedOnboarding.flowApprovalStatus ===
                      "Requested"
                      ? "mdi-help-circle"
                      : ""
                      }}
                    </v-icon>
                  </span>
                </template>
                {{ computedOnboarding.flowApprovalStatus }}
              </v-tooltip>
            </span>
            <br v-if="computedOnboarding.flowApprovalStatus === 'Approved' || (computedOnboarding.jiraIssueStatus !== undefined && computedOnboarding.jiraIssueStatus !== null && computedOnboarding.jiraIssueStatus !== '')" />
            <span v-if="computedOnboarding.flowApprovalStatus === 'Approved' || (computedOnboarding.jiraIssueStatus !== undefined && computedOnboarding.jiraIssueStatus !== null && computedOnboarding.jiraIssueStatus !== '')">
              <v-tooltip right color="black">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <a
                    style="color: white; text-decoration: underline;"
                    
                    @click="
                      $router.push({
                        path: `/redirect/${encodeURIComponent(
                          `https://cognitus.atlassian.net/browse/${computedOnboarding.jiraIssueKey}`
                        )}`
                      })
                    "
                  >Jira Issue Status</a>&nbsp;
                  <v-icon
                      :class="
                        `history-item-avatar ${
                          computedOnboarding.jiraIssueStatus === 'Done'
                            ? 'done'
                            : computedOnboarding.jiraIssueStatus ===
                              'Created'
                            ? 'created'
                            : computedOnboarding.jiraIssueStatus ===
                              'Requested'
                            ? 'requested'
                            : ''
                        }`
                      "
                    >
                      {{
                      computedOnboarding.jiraIssueStatus === "Done"
                      ? "mdi-check"
                      : computedOnboarding.jiraIssueStatus ===
                      "Created"
                      ? "mdi-progress-clock"
                      : computedOnboarding.jiraIssueStatus ===
                      "Requested"
                      ? "mdi-help-circle"
                      : ""
                      }}
                    </v-icon>
                  </span>
                  
                </template>
                {{ computedOnboarding.jiraIssueStatus }}
              </v-tooltip>
            </span>
            <br />
            <br />
            <!-- REQUESTER -->
            <span v-if="computedOnboarding.requester || computedOnboarding.requestedBy || computedOnboarding.changeRequester">
              Requester: {{ computedOnboarding.requester || computedOnboarding.requestedBy || computedOnboarding.changeRequester }}
            </span>
          </v-flex>
          <v-flex xs4 style="margin: 1em 0em 1em 0em; font-size: .8em;">
            
            <span v-if="computedOnboarding.otherSystem.length > 0">System Access:<br/>
              <ul v-for="(chunk, cIndex) in $chunk(computedOnboarding.otherSystem, 3)" :key="'chunk-'+cIndex" style="display: inline-block;"> 
                <li v-for="(system, index) in chunk" :key="'system-'+index">
                  {{ system.replace("SAP-", "") }}
                </li>
              </ul>
            </span>
            
            <span v-else style="color: hsla(0,0%,100%,.7);">No System Access</span>
            <br />
            <br />
            <span v-if='computedOnboarding.sentEmails !== undefined && computedOnboarding.sentEmails.length > 0'>
              Emails Sent ({{computedOnboarding.sentEmails.length}}):
              <ul>
                <li v-for="(sentEmail, index) in computedOnboarding.sentEmails" :key="'sentEmail-'+index">
                  <v-tooltip right close-delay="500" color="black">
                    <template v-slot:activator="{ on }" >
                      <span v-on="on">
                        {{ sentEmail.subject }}<span v-if="sentEmail.times.length > 1"> ({{sentEmail.times.length}})</span><span v-if="index < computedOnboarding.sentEmails.length - 1"></span>
                      </span>
                    </template>
                    <span>
                      <p v-for="(sentEmailX, sentEmailXIndex) in sentEmail.times" :key="'emailSent-'+sentEmail.subject+'-'+sentEmailXIndex">
                        Date Sent: {{ sentEmailX.date }}
                        <br />
                        Sender: <a style="color: white;" :href="`mailto:${sentEmailX.sender}`">{{ sentEmailX.sender }}</a>
                        <br />
                        Recipient: <a style="color: white;" :href="`mailto:${sentEmailX.recipient}`">{{ sentEmailX.recipient }}</a>
                      </p>
                    </span>
                  </v-tooltip>
                </li>
              </ul>
            </span>
            <span v-else style="color:hsla(0,0%,100%,.7);">No Emails Sent</span>
          </v-flex>
          <v-flex xs4 style="margin: 1em 1em 1em 1em; font-size: .8em;">
            <span>
              Adobe Sign Documents:
            </span>
            <span v-if="adobeSignOnboardingAgreements !== null && adobeSignOnboardingAgreements !== undefined && adobeSignOnboardingAgreements.length > 0">
              <v-virtual-scroll height="250" item-height="20" :items="adobeSignOnboardingAgreements">
                <template v-slot:default="{ item }">
                  <li :key="computedOnboarding._id.split('-')[0]+'-adobeAgreement-'+adobeSignOnboardingAgreements.indexOf(item)">
                    {{ item.name }} {{ item.status }}
                  </li>
                </template>
              </v-virtual-scroll>
            </span>
            <span v-else>No Documents Found</span>
          </v-flex>
          <v-flex xs4 style="margin: 1em 1em 1em 1em; font-size: .8em;">
            <span>
              Projects:
            </span>
            <ul v-if="onboardingProjects.length > 0">
              <li v-for="(project, index) in onboardingProjects" :key="'project-'+index">
                <router-link style="color: white;" :to="{path: `/company/${company._id}/project/${project._id}?q=${computedOnboarding._id}`}">{{ project.title }}</router-link>
              </li>
            </ul>
            <span v-else>No Projects Found</span>
          </v-flex>
        </v-layout>
        <!-- CARD ACTIONS -->
        <p style="margin-left: .5em; margin-top: .5em;" v-for="(buttonGroup, index) in $chunk(buttons.filter(button => button.show), $vuetify.breakpoint.smAndDown ? 4 : buttons.length)" :key="'buttongroup-'+index">
          <!-- Button Group -->
          <v-btn-toggle v-model="toggleButton[index]"  style="margin-left: .5em; margin-bottom: .5em;">
            <!-- BUTTON -->
            <v-tooltip bottom v-for="(button, buttonIndex) in buttonGroup" :key="'button-'+buttonIndex+'-group-'+index">
              <template v-slot:activator="{ on }">
                <v-btn
                  style="opacity: 1 !important;"
                  :disabled="button.disabled"
                  :color="button.color"
                  fab
                  v-on="on"
                  @click="button.click()"
                >
                  <v-icon fab>{{ button.icon }}</v-icon>
                </v-btn>
              </template>
              {{ button.text }}
            </v-tooltip>
          </v-btn-toggle>
        </p>
        <v-layout v-if="showOnboardingCode">
          <span style="background-color: rgba(0, 0, 0, .8);" v-html="syntaxHighlight(JSON.stringify(computedOnboarding, null, 4))"></span>
        </v-layout>
      </v-img>
    </v-card>
  </span>
</template>

<script>
import  * as jd  from 'json-delta'
import EmailSend from "@/components/Company/Onboarding/Email/EmailSend.vue";
import OnboardingForm from "@/components/Company/Onboarding/OnboardingForm.vue";
export default {
  components: {
    EmailSend,
    OnboardingForm
  },
  data() {
    return {
      adobeDCView: null,
      authorizedSigners: [
        { 
          entity: '1000', 
          signers: [
            'rvanderzande', 'fverdesoto', 'nkhanna', 'psathi', 'awaals'
          ]
        },
        {
          entity: '1801', 
          signers: [
            'rvanderzande'
          ]
        },
        {
          entity: '1300', 
          signers: [
            'nkhanna'
          ]
        },
        {
          entity: '1400',
          signers: [
            'fverdesoto'
          ]
        },
        {
          entity: '6000',
          signers: [
            'magrawal'
          ]
        },
        {
          entity: '2000',
          signers: [
            'fverdesoto'
          ]
        },
        {
          entity: '3000',
          signers: [
            'evanderlaan'
          ]
        },
        {
          entity: '4000',
          signers: [
            'mreghioui'
          ]
        },
        {
          entity: '2100',
          signers: [
            'crodriguez', 'fverdesoto'
          ]
        }
      ],
      historyHoverIndex: -1,
      avatar: "",
      show: {
        filter: false,
        search: false,
      },
      expanded: [],
      datePickerValue: "",
      menu: false,
      showHistoryDetails: [],
      showHistoryCode: [],
      selectedAdobeSignAgreementStatusList: ["SIGNED", "OUT_FOR_SIGNATURE"],
      adobeSignAgreementStatusList:  ['WAITING_FOR_MY_SIGNATURE', 'WAITING_FOR_MY_APPROVAL', 'WAITING_FOR_MY_DELEGATION', 'WAITING_FOR_MY_ACKNOWLEDGEMENT', 'WAITING_FOR_MY_ACCEPTANCE', 'WAITING_FOR_MY_FORM_FILLING', 'OUT_FOR_SIGNATURE', 'OUT_FOR_APPROVAL', 'OUT_FOR_DELIVERY', 'OUT_FOR_ACCEPTANCE', 'OUT_FOR_FORM_FILLING', 'SIGNED', 'APPROVED', 'FORM_FILLED', 'DELIVERED', 'ACCEPTED', 'ARCHIVED', 'CANCELLED', 'EXPIRED', 'WAITING_FOR_AUTHORING', 'WAITING_FOR_PREFILL', 'DRAFT', 'DOCUMENTS_NOT_YET_PROCESSED', 'WAITING_FOR_MY_VERIFICATION', 'WAITING_FOR_VERIFICATION'],
      selectedFilters: [
        "firstName",
        "lastName",
        "personalEmailAddress",
        "cognitusEmailMailbox",
        "cognitusEmailDomain",
      ],
      dialog: {
        license: false,
        sap: false,
        change: false,
        email: false,
        history: false,
        new: false,
        adobe: false
      },
      additionalCCEmail: [],
      hardcodedCCEmail: [
        "carora@cognitusconsulting.com",
        "magrawal@cognitusconsulting.com"
      ],
      pdfToView: null,
      loadingPdf: false,
      viewingPdf: false,
      loadingAgreementEvents: false,
      agreementEvents: {},
      selectedNonSignedAgreementIndex: -1,
      agreementId: null,
      cognitusSigner: null,
      secondPartySignerEmail: null,
      loadingAdobeAgreements: false,
      loadingLibraryDocuments: false,
      adobeSignTarget: null,
      showActiveAgreements: false,
      creatingNewAgreement: false,
      mergeInfo: null,
      libraryDocumentFormFields: null,
      adobeAgreements: null,
      libraryDocuments: null,
      selectedLibraryDoc: null,
      s4Locations: [],
      libraryDocumentHoverIndex: -1,
      newOnboardingTemplate: null,
      emailOnboardingTarget: null,
      editedItem: null,
      selected: [],
      formTitle: "",
      availableLicenses: [],
      office365UserExistance: {},
      office365LoginDisabled: {},
      office365UserHasMailingLicense: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      historyItemView: -1,
      toggleButton: {},
      showOnboardingCode: false,
      userSAPItem: null,
      selectedSAPSystems: [],
      createdUserInSAPSystems: [],
      licenseRequestItem: null,
      selectedLicense: "",
      search: "",
      lastSearch: "",
      searchMessages: [],
      searchErrorMessages: [],
      searchError: false,
      searching: false,
      searchColor: "black",
      searchIcon: "mdi-magnify",
      searchRequest: [],
      searchTimeout: null,
      searchSuccess: false,
      searchSuccessMessages: [],
      hideCurrentHistory: true,
      histories: [],
      loading: false,
      onboarding: null,
      advancedSearch: false,
      terminationDateDialog: false,
      rehireDateDialog: false,
      terminationDate: Date.now(),
      rehireDate: Date.now()
    };
  },
  props: [
    "onboarding_id",
    "countries",
    "formTemplate",
    "onboardings",
    "company",
    "path",
    "projects",
    "projectRateCards"
  ],
  mounted() {
    if(this.path === 'new') {
      this.newItem();
    }
    this.getAvailableLicenses();
    if (this.adobeAgreements === null) {
      this.getAgreements(true);
    }
    if (this.$route.query.q) {
      this.search = this.$route.query.q;
      this.searchDatabase();
    }
    this.loading = true;
    let loadingCheckCount = 0;
    const checkLoading = () => {
      setTimeout(() => {
        if (this.computedOnboardings !== undefined && this.computedOnboardings !== null && Array.isArray(this.computedOnboardings) && this.computedOnboardings.length > 0) {
          this.loading = false;
          if (this.onboarding_id !== undefined && this.onboarding_id !== null) {
            this.$BackendService.onboarding.get({ _id: this.onboarding_id, company_id: this.company._id}).then(onb => {
              this.$store.dispatch("UPDATE_ONBOARDING", onb)
              this.onboarding = onb
              this.getAvatar(this.onboarding)
              this.office365UserLoginDisabled(this.onboarding)
              this.office365UserExists(this.onboarding)
              this.office365UserCanMail(this.onboarding)
            }).catch(error => {
              console.error(error);
            })
          }
        } else {
          if (loadingCheckCount <= 9) {
            checkLoading();
            loadingCheckCount++;
          } else {
            this.$AlertService.newAlert({
              message: 'Failed to retrieve onboaring data in a timely manner',
              timeout: 5,
              show: true,
              color: 'error'
            })
          }
        }
      }, 500)
    }
    checkLoading();
  },
  watch: {
    onboardings(val) {
      if (val !== undefined && val !== null && Array.isArray(val) && val.length > 0) {
        this.loading = false;
      }
    },
    // onboarding(val) {
    //   if (val) {
    //     console.log(val)
    //     this.getAgreements(true).then(() => {
    //       this.saveAdobeSignAgreementFieldData(val, true);
    //     })
    //   }
    // },
    lastSearch(val) {
      if (val) {
        let that = this;
        that.$router.replace({ path: that.$route.path, query: { q: val }})
      }
    },
    path(val) {
      if (val === 'new') {
        this.newItem();
      }
    },
    onboarding_id(val) {
      if (this.path === 'new') {
        this.newItem();
      } else {
        if (val !== undefined) {
          this.$BackendService.onboarding.get({ _id: val, company_id: this.company._id}).then(onb => {
            this.$store.dispatch("UPDATE_ONBOARDING", onb)
            this.onboarding = onb
            this.showOnboardingCode = false;
            this.getAvatar(this.onboarding)
            this.office365UserLoginDisabled(this.onboarding)
            this.office365UserExists(this.onboarding)
            this.office365UserCanMail(this.onboarding)
          }).catch(error => {
            console.error(error);
          })
        }
      }
    }
  },
  methods: {
    updateOnboardingFromStore() {
      let existIndex = this.$store.state.onboardings[this.onboarding.company_id].indexOf(this.$store.state.onboardings[this.onboarding.company_id].filter(o => o._id === this.onboarding._id)[0])
      if (existIndex !== -1)
        this.onboarding = this.$store.state.onboardings[this.onboarding.company_id][existIndex];
      $forceUpdate();
    },
    toggleShowOnboardingCode() {
      this.showOnboardingCode = !this.showOnboardingCode;
    },

    adobe() {
      let item = this.computedOnboarding;
      let that = this;
      
      this.close("ALL").then(function() {
        that.adobeSignTarget = item;
        that.getAgreements()
        that.getLibraryDocuments();
        if (that.adobeSignTarget.adobeAgreements === undefined) {
          that.adobeSignTarget.adobeAgreements = [];
        }
        that.$nextTick(() => {
          that.saveAdobeSignAgreementFieldData(that.adobeSignTarget);
        })
        that.dialog["adobe"] = true;
        that.$forceUpdate();
      });
    },
    setFocus(ref) {
      this.$refs[ref].focus();
    },
    UUID() {
      return this.$UUID();
    },
    searchDatabase(force = false) {
      let that = this;
      if(this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if ((that.search.length > 2 && that.search !== that.lastSearch) || force) {
        if (that.onboarding_id !== undefined) {
          this.$router.replace(`/company/${this.company._id}/onboarding`);
        }
        that.searching = true;
        that.searchColor = "warning";
        that.searchRequest = [];
        this.searchTimeout = setTimeout(function() {
          if (that.advancedSearch) {
            // console.log('advanced')
            if (!that.search.startsWith('${') && that.search.substr(that.search.length - 2) !== '}') {
              that.search = '${' + that.search + '}'
            }
          }
          // console.log(that.search)
          that.$BackendService.onboarding.search({ company_id: that.company._id, search: that.search }).then(result => {
            result.forEach(onb => that.$store.dispatch("UPDATE_ONBOARDING", onb))
            if ( result.length > 0 ) {
              that.searching = false;
              that.searchColor = "success";
              that.lastSearch = that.search;
              that.searchSuccess = true;
              that.searchSuccessMessages = [`Found ${result.length} onboardings.`];
              that.searchError = false;
              that.searchErrorMessages = [];
              that.searchRequest = result;
              that.searchRequest.forEach((onboarding) => {
                that.getAvatar(onboarding);
              });
            } else {
              that.searching = false;
              that.lastSearch = that.search;
              that.searchColor = "error";
              that.searchError = true;
              that.searchSuccess = false;
              that.searchMessages = [];
              that.searchSuccessMessages = [];
              that.searchErrorMessages = ["No results found."];
              that.searchRequest = [];
            }
          }).catch(error => {
            console.error(error)
          })

          // let searchSplit = that.search
          //   .split(" ")
          //   .concat(that.search)
          //   .concat(that.search.split(","))
          //   .flat().map(x => x.toLowerCase());
          // let filteredDatabase = that.onboardings.filter((entry) => {
          //   let firstName = entry.firstName.toLowerCase();
          //   let lastName = entry.lastName.toLowerCase();
          //   let cogEmail = entry.cognitusEmailMailbox + entry.cognitusEmailDomain.toLowerCase();
          //   let personalEmail = entry.personalEmailAddress.toLowerCase();
          //   return searchSplit.some(word => 
          //     (
          //       firstName.startsOrEndsWith(word) ||
          //       lastName.startsWith(word) || 
          //       cogEmail.startsOrEndsWith(word) || 
          //       personalEmail.startsOrEndsWith(word)
          //     )
          //   );
          // });
          // if (filteredDatabase.length > 0) {
          //   that.searching = false;
          //   that.searchColor = "success";
          //   that.lastSearch = that.search;
          //   that.searchSuccess = true;
          //   that.searchSuccessMessages = [`Found ${filteredDatabase.length} onboardings.`];
          //   that.searchError = false;
          //   that.searchErrorMessages = [];
          //   that.searchRequest = filteredDatabase;
          //   that.searchRequest.forEach((onboarding) => {
          //     that.getAvatar(onboarding);
          //   });
          // } else {
          //   that.searching = false;
          //   that.lastSearch = that.search;
          //   that.searchColor = "error";
          //   that.searchError = true;
          //   that.searchSuccess = false;
          //   that.searchMessages = [];
          //   that.searchSuccessMessages = [];
          //   that.searchErrorMessages = ["No results found."];
          //   that.searchRequest = [];
          // }
        }, 1500);
      } else {
        that.searching = false;
        that.lastSearch = "";
        that.searchColor = "black";
        that.searchRequest = [];
        that.searchSuccess = false;
        that.searchMessages = [];
        that.searchSuccessMessages = [];
        that.searchError = false;
        that.searchErrorMessages = [];
      }
    },
    clearSearch() {
      this.searchRequest = []; 
      this.search = '';
      this.lastSearch = null;
      this.searchColor="black";
      this.searchError = false;
      this.searchSuccess = false;
      this.searchMessages = [];
      this.searchSuccessMessages = [];
      this.searchMessages = [];
      this.searchErrorMessages = [];
      this.$router.replace(`/company/${this.company._id}/onboarding`);
    },
    getAvatar(onboarding) {
      let that = this;
      if (onboarding !== undefined) {
        if (
          onboarding.cognitusEmailMailbox !== undefined &&
          onboarding.cognitusEmailMailbox !== null &&
          onboarding.cognitusEmailMailbox !== ""
        ) {
          this.$GraphService
            .getSpecificUserAvatar(
              onboarding.cognitusEmailMailbox + onboarding.cognitusEmailDomain,
              that.$store.state.accessToken
            )
            .then(function(result) {
              axios.get(result).then(function(avatarBlob) {
                if (typeof avatarBlob.data === 'string') {
                  onboarding.avatar = result;
                } else if (typeof avatarBlob.data === 'object') {
                  onboarding.avatar = null;
                }
              }).catch(function(error) {
                console.error(error);
              })
            })
            .catch(function(error) {
              console.error(error);
            });
        }
      }
    },
    toggleHistory(index) {
      let that = this;
      this.$set(that.showHistoryDetails, index, !that.showHistoryDetails[index]);
    },
    toggleHistoryCode(index) {
      let that = this;
      this.$set(that.showHistoryCode, index, !that.showHistoryCode[index]);
    },
    emailFinished() {
      this.dialog['email'] = false;
      this.emailOnboardingTarget = null;
      // this.$eventBus.$emit('getOnboardings');
    },
    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return (
        '<pre style="outline: 1px solid #ccc; padding: 5px; margin: 5px;">' +
        json.replace(
          /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
          function(match) {
            var cls = "number";
            if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                cls = "key";
              } else {
                cls = "string";
              }
            } else if (/true|false/.test(match)) {
              cls = "boolean";
            } else if (/null/.test(match)) {
              cls = "null";
            }
            return '<span class="json-' + cls + '">' + match + "</span>";
          }
        ) +
        "</pre>"
      );
    },
    itemExpanded(event) {
      let that = this;
      if (event.value) {
        that.office365UserExists(event.item);
        that.office365UserLoginDisabled(event.item);
        that.office365UserCanMail(event.item);
        that.$forceUpdate();
      }
    },
    withoutHistory(historyItem) {
      delete historyItem.history;
      return historyItem;
    },
    startRevertTermination() {
      if (confirm("Are you sure you want to re-hire this user?")) {
        this.rehireDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.rehireDateDialog = true;
      }
    },
    revertTermination() {
      this.rehireDateDialog = false;
      let item = JSON.parse(JSON.stringify(this.computedOnboarding));
      item.rehireDate = this.rehireDate;
      let that = this;
      item.requester = that.$store.state.userADInfo.mail;
      this.$BackendService.flow.approval
        .revertTerminate(item)
        .then(function(response) {
          that.$AlertService.newAlert({
            message: `Flow Approval Sent - Onboarding Revert Termination Request - ${item.cognitusEmailMailbox +
              item.cognitusEmailDomain}`,
            color: "success",
            timeout: 5,
            show: true,
          });
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: `Flow Approval Failed - Onboarding Revert Termination Request - ${item.cognitusEmailMailbox +
              item.cognitusEmailDomain}`,
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        });
    },
    startTermination() {
      if (confirm("Are you sure you want to terminate this user?")) {
        this.terminationDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        this.terminationDateDialog = true;
      }
    },
    terminateUser() {
      this.terminationDateDialog = false;
      let item = JSON.parse(JSON.stringify(this.computedOnboarding));
      item.terminationDate = this.terminationDate;
      let that = this;
      item.requester = that.$store.state.userADInfo.mail;
      this.$BackendService.flow.approval
        .terminate(item)
        .then(function(response) {
          if (response !== "already requested") {
            that.$AlertService.newAlert({
              message: `Flow Approval Sent - Onboarding Termination Request - ${item.cognitusEmailMailbox +
                item.cognitusEmailDomain}`,
              color: "success",
              timeout: 5,
              show: true,
            });
          } else {
            that.$AlertService.newAlert({
              message: `Flow Approval Already Requested - ${item.cognitusEmailMailbox +
                item.cognitusEmailDomain}`,
              color: "error",
              timeout: 5,
              show: true,
            });
          }
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: `Flow Approval Failed - Onboarding Termination Request - ${item.cognitusEmailMailbox +
              item.cognitusEmailDomain}`,
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        });
    },
    office365UserLoginDisabled(item) {
      let that = this;
      this.$BackendService.o365.user
        .loginDisabled(item.cognitusEmailMailbox + item.cognitusEmailDomain)
        .then(function(disabled) {
          that.$set(
            that.office365LoginDisabled,
            item.cognitusEmailMailbox + item.cognitusEmailDomain,
            disabled
          );
        })
        .catch(function(error) {
          that.$set(
            that.office365LoginDisabled,
            item.cognitusEmailMailbox + item.cognitusEmailDomain,
            "ERROR"
          );
        });
    },
    office365UserExists(item) {
      let that = this;
      this.$BackendService.o365.user
        .exists(item.cognitusEmailMailbox + item.cognitusEmailDomain)
        .then(function(exists) {
          that.$set(
            that.office365UserExistance,
            item.cognitusEmailMailbox + item.cognitusEmailDomain,
            exists
          );
        })
        .catch(function(error) {
          that.$set(
            that.office365UserExistance,
            item.cognitusEmailMailbox + item.cognitusEmailDomain,
            "ERROR"
          );
        });
    },
    office365UserCanMail(item) {
      let that = this;
      this.$BackendService.o365.user
        .getLicenseDetails(item.cognitusEmailMailbox + item.cognitusEmailDomain)
        .then(function(licenses) {
          that.$set(
            that.office365UserHasMailingLicense,
            item.cognitusEmailMailbox + item.cognitusEmailDomain,
            licenses.value.some(l => l.skuId === '18181a46-0d4e-45cd-891e-60aabd171b4e' || l.skuId === '6fd2c87f-b296-42f0-b197-1e91e994b900')
          );
          
        })
        .catch(function(error) {
          that.$set(
            that.office365UserHasMailingLicense,
            item.cognitusEmailMailbox + item.cognitusEmailDomain,
            "ERROR"
          );
        });
    },
    createOffice365User() {
      let item = this.computedOnboarding;
      let that = this;
      if (
        !confirm(
          `Are you sure you want to create a new Office365 user for this onboarding?`
        )
      ) {
        return;
      }
      that.$BackendService.o365.user
        .exists(item.cognitusEmailMailbox + item.cognitusEmailDomain)
        .then(function(exists) {
          // console.log(exists);
          if (!exists) {
            item.temporaryOffice365Password = item.temporaryOffice365Password === '' ? that.$pass() : item.temporaryOffice365Password;
            that.$BackendService.onboarding
              .update(item)
              .then(function() {
                that.$AlertService.newAlert({
                  message: `Temporary password saved to ${item.cognitusEmailMailbox +
                    item.cognitusEmailDomain}'s onboarding data.`,
                  color: "success",
                  timeout: 5,
                  show: true,
                });
                let loginEnabled = confirm(`Allow user to Login to Office365?`);
                let userData = {
                  accountEnabled: loginEnabled,
                  displayName: item.firstName + " " + item.lastName,
                  mailNickname: item.cognitusEmailMailbox,
                  jobTitle: item.jobTitle,
                  mobilePhone: item.mobilePhone.e164,
                  givenName: item.firstName,
                  surname: item.lastName,
                  usageLocation: item.usageLocation === '' ? item.mobilePhone.countryCodeAndCountry.split('-')[0] : item.usageLocation,
                  country: item.region,
                  userPrincipalName:
                    item.cognitusEmailMailbox + item.cognitusEmailDomain,
                  passwordProfile: {
                    forceChangePasswordNextSignIn: true,
                    password: item.temporaryOffice365Password,
                  },
                };
                that.$BackendService.o365.user
                  .create(userData)
                  .then(function(response) {
                    that.$AlertService.newAlert({
                      show: true,
                      timeout: 5,
                      color: "success",
                      message: `New Office365 User created for ${item.cognitusEmailMailbox +
                        item.cognitusEmailDomain}!`,
                    });
                    let emailData = {
                      from: {
                        name: "Cognitus CRM",
                        email: "crm@cognitusconsulting.com",
                      },
                      plain: `New user created in Office365 through Surfboard.\n${item.cognitusEmailMailbox +
                        item.cognitusEmailDomain}\n${
                        item.temporaryOffice365Password
                      }\nLogin: ${loginEnabled ? "Enabled" : "Disabled"}`,
                      html: `<h2>New user created in Office365 through Surfboard</h2><p>${item.cognitusEmailMailbox +
                        item.cognitusEmailDomain}</p><p>${
                        item.temporaryOffice365Password
                      }</p><p>Login: ${
                        loginEnabled ? "Enabled" : "Disabled"
                      }</p>`,
                      to: that.$store.state.userADInfo.mail,
                      cc: [...new Set(([
                        "rvanderzande@cognitus.one",
                        "lvanderzande@cognitusconsulting.com",
                        "rreddy@cognitusconsulting.com",
                        "carora@cognitusconsulting.com",
                        "magrawal@cognitusconsulting.com",
                        "kojha@cognitusconsulting.com"
                      ].concat([item.requester])))],
                      subject: `${item.cognitusEmailMailbox +
                        item.cognitusEmailDomain} Office365 Temporary Password`,
                    };
                    that.$BackendService.company.email
                    .send({company: that.company._id, emailData})
                    .then(function(result) {
                      that.$AlertService.newAlert({
                        message: `Sent email with temporary password.`,
                        color: "success",
                        timeout: 5,
                        show: true,
                      });
                    })
                    .catch(function(error) {
                      that.$AlertService.newAlert({
                        message: `Failed to send email containing temporary password.`,
                        color: "error",
                        timeout: 5,
                        show: true,
                      });
                      console.error(error);
                    });
                    
                    that.office365UserExists(item);
                    that.office365UserLoginDisabled(item);
                    that.office365UserCanMail(item);
                  })
                  .catch(function(error) {
                    that.$AlertService.newAlert({
                      show: true,
                      timeout: 5,
                      color: "error",
                      message: `There was an issue creating an Office365 User for ${item.cognitusEmailMailbox +
                        item.cognitusEmailDomain}!`,
                    });
                    console.error(error);
                  });
              })
              .catch(function(error) {
                that.$AlertService.newAlert({
                  message: `Operation Aborted! Failed to save temporary password to ${item.cognitusEmailMailbox +
                    item.cognitusEmailDomain}'s onboarding data.`,
                  color: "error",
                  timeout: 5,
                  show: true,
                });
                console.error(error);
              });
          } else {
            that.$AlertService.newAlert({
              show: true,
              timeout: 5,
              color: "error",
              message:
                "Operation Aborted! User with that email address already exists in Office 365!",
            });
            that.office365UserLoginDisabled(item);
            that.office365UserCanMail(item);
          }
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            show: true,
            timeout: 5,
            color: "error",
            message:
              "Operation Aborted! Could not check if the user already exists in Office365.",
          });
          console.error(error);
        });
    },
    getAvailableLicenses() {
      let that = this;
      this.$BackendService.o365
        .getSubscribedSkus()
        .then(function(skus) {
          that.availableLicenses = skus;
          // console.log(skus);
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    assignOfficeLicense(item) {
      let that = this;
      this.$BackendService.o365.user
        .get(item.cognitusEmailMailbox + item.cognitusEmailDomain)
        .then(function(user) {
          if (
            !user.assignedLicenses.some((license) => {
              return item.o365license === "E1"
                ? license.skuId === "18181a46-0d4e-45cd-891e-60aabd171b4e"
                : item.o365license === "E3"
                ? license.skuId === "6fd2c87f-b296-42f0-b197-1e91e994b900"
                : false;
            })
          ) {
            let data = {
              user: item.cognitusEmailMailbox + item.cognitusEmailDomain,
              payload: {
                addLicenses: [],
                removeLicenses: [],
              },
            };
            if (item.o365license === "E1") {
              data.addLicenses.push({
                disabledPlans: [],
                skuId: "18181a46-0d4e-45cd-891e-60aabd171b4e",
              });
            } else if (item.o365license === "E3") {
              data.addLicenses.push({
                disabledPlans: [],
                skuId: "6fd2c87f-b296-42f0-b197-1e91e994b900",
              });
            }
            this.$BackendService.o365.user
              .assignLicense(data)
              .then(function(response) {
                that.$AlertService.newAlert({
                  message: `Assigned Licenses.`,
                  timeout: 5,
                  color: "success",
                  show: true,
                });
              })
              .catch(function(error) {
                consol.error(error);
                that.$AlertService.newAlert({
                  message: `${item.cognitusEmailMailbox}${item.cognitusEmailDomain} /assignLicense -- failed.`,
                  timeout: 5,
                  color: "error",
                  show: true,
                });
              });
          }
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    toggleOffice365Login() {
      let item = this.computedOnboarding;
      let bool = this.office365LoginDisabled[
                          this.computedOnboarding.cognitusEmailMailbox +
                            this.computedOnboarding.cognitusEmailDomain
                        ];
      if (
        !confirm(
          `Are you sure you want to ${
            bool ? "enable" : "disable"
          } ${item.cognitusEmailMailbox +
            item.cognitusEmailDomain}'s ability login to Office365?`
        )
      ) {
        return;
      }
      let userData = {
        user: item.cognitusEmailMailbox + item.cognitusEmailDomain,
        data: {
          accountEnabled: bool,
        },
      };
      let that = this;
      this.$BackendService.o365.user
        .exists(item.cognitusEmailMailbox + item.cognitusEmailDomain)
        .then(function(exists) {
          if (exists) {
            that.$BackendService.o365.user
              .update(userData)
              .then(function(response) {
                // console.log(response);
                that.$AlertService.newAlert({
                  show: true,
                  timeout: 5,
                  color: "success",
                  message: `Office365 User Login ${
                    bool ? "Enabled" : "Disabled"
                  } - ${item.cognitusEmailMailbox + item.cognitusEmailDomain}!`,
                });
                that.office365UserLoginDisabled(item);
                that.office365UserCanMail(item);
                that.$forceUpdate();
              })
              .catch(function(error) {
                that.$AlertService.newAlert({
                  show: true,
                  timeout: 5,
                  color: "error",
                  message: `Failed to update Office365 User - ${item.cognitusEmailMailbox +
                    item.cognitusEmailDomain}!\n${error.message}`,
                });
                console.error(error);
              });
          } else {
            that.$AlertService.newAlert({
              show: true,
              timeout: 5,
              color: "error",
              message: `User doesnt exist in Office365 - ${item.cognitusEmailMailbox +
                item.cognitusEmailDomain}!`,
            });
          }
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            show: true,
            timeout: 5,
            color: "error",
            message: `Error in checking if user exists - ${item.cognitusEmailMailbox +
              item.cognitusEmailDomain}!\n${error.message}`,
          });
          console.error(error);
        });
    },
    sendEmail() {
      let item = this.computedOnboarding;
      let that = this;
      this.close("ALL").then(function() {
        that.emailOnboardingTarget = item;
        that.dialog["email"] = true;
        that.$forceUpdate();
      });
    },
    close(d) {
      let that = this;
      return new Promise(function(resolve, reject) {
        if (d !== "ALL") that.dialog[d] = false;
        else
          Object.keys(that.dialog).forEach((k) => {
            that.dialog[k] = false;
          });
        switch (d) {
          case "ALL":
            that.historyItemView = null;
            that.emailOnboardingTarget = null;
            that.newOnboardingTemplate = null;
            that.editedItem = null;
            that.editedIndex = -1;
            that.pdfToView = null;
            that.loadingPdf = false;
            that.viewingPdf = false;
            that.loadingAgreementEvents = false;
            that.agreementEvents = {};
            that.selectedNonSignedAgreementIndex = 1;
            that.agreementId = null;
            that.cognitusSigner = null;
            that.secondPartySignerEmail = null;
            that.loadingAdobeAgreements = false;
            that.loadingLibraryDocuments = false;
            that.adobeSignTarget = null;
            that.showActiveAgreements = false;
            that.creatingNewAgreement = false;
            that.mergeInfo = null;
            that.libraryDocumentFormFields = null;
            that.selectedLibraryDoc = null;
            that.libraryDocumentHoverIndex = 1;
            resolve();
            break;
          case "email":
            that.emailOnboardingTarget = null;
            resolve();
            break;
          case "history":
            that.historyItemView = null;
            resolve();
            break;
          case "change":
            that.edited = null;
            that.editedItem = JSON.parse(JSON.stringify(that.formTemplate));
            resolve();
            break;
          case "new":
            that.newOnboardingTemplate = null;
            that.newOnboardingTemplate = JSON.parse(JSON.stringify(that.formTemplate));
            resolve();
            break;
          case "license":
            resolve();
            break;
          case "adobe":
            that.pdfToView = null;
            that.loadingPdf = false;
            that.viewingPdf = false;
            that.loadingAgreementEvents = false;
            that.agreementEvents = {};
            that.selectedNonSignedAgreementIndex = 1;
            that.agreementId = null;
            that.cognitusSigner = null;
            that.secondPartySignerEmail = null;
            that.loadingAdobeAgreements = false;
            that.loadingLibraryDocuments = false;
            that.adobeSignTarget = null;
            that.showActiveAgreements = false;
            that.creatingNewAgreement = false;
            that.mergeInfo = null;
            that.libraryDocumentFormFields = null;
            that.selectedLibraryDoc = null;
            that.libraryDocumentHoverIndex = 1;
            resolve();
            break;
        }
      });
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    editItem() {
      let item = this.computedOnboarding;
      let that = this;
      this.close("ALL").then(function() {
        that.editedIndex = that.computedOnboardings.indexOf(item);
        let editedItem = JSON.parse(JSON.stringify(item));
        // console.log(that.editedItem);
        editedItem.step = 1;
        editedItem.readyForOnboardedUserInput = false;
        if (
          editedItem.flowApprovalStatus === "Denied" && editedItem.jiraIssueStatus === '' && !editedItem.hasBeenOnboarded
        ) {
          editedItem.changeRequest = false;
        } else {
          editedItem.changeRequest = true;
        }
        editedItem.requester = that.$store.state.userADInfo.mail;
        editedItem.changeRequestUUID = that.$UUID();
        // only update flow approval status and jira issue status if a new approval is requested
        // editedItem.flowApprovalStatus = "";
        // editedItem.jiraIssueStatus = "";

        let x = JSON.parse(JSON.stringify(item));
        delete x.history;
        delete x.diff;
        delete x._rev;

        editedItem.history.push(x);

        Object.keys(that.formTemplate).forEach((key) => {
          if (editedItem[key] === undefined) {
            editedItem[key] = JSON.parse(
              JSON.stringify(that.formTemplate[key])
            );
          }
        });
        // console.log(that.editedItem);
        that.editedItem = editedItem;
        that.formTitle = `New Change Request - ${item.cognitusEmailMailbox + item.cognitusEmailDomain}`;
        that.dialog["change"] = true;
        that.$forceUpdate();
      });
    },
    newItem() {
      let that = this;
      this.close("ALL").then(function() {
        let newOnboardingTemplate = JSON.parse(JSON.stringify(that.formTemplate));
        newOnboardingTemplate.onboarding_id = that.$UUID();
        newOnboardingTemplate.requester = that.$store.state.userADInfo.mail;
        that.newOnboardingTemplate = newOnboardingTemplate;
        that.dialog["new"] = true;
      })
    },
    historyFilter(key, index) {
      var filter = false;

      if (this.histories[index][key] === undefined ||
          this.histories[index][key] === false ||
          this.histories[index][key] === null ||
          this.histories[index][key] === '') return filter;
      
      filter = [
        "step",
        "avatar",
        "onboarding_id",
        "company_id",
        "history",
        "vendorAddress",
        "flowApprovalStatus",
        "jiraIssueStatus",
        "jiraIssueID",
        "jiraIssueKey",
        "readyForOnboardedUserInput",
        "_id",
        "_rev",
        "changeRequest",
        "sentEmails",
        "forms",
        "jiraCheckboxDisabled",
        "hasBeenOnboarded",
        "sapUser",
        "temporaryOffice365Password",
        "conversationId",
        "activityId",
        "date_time_onboarded",
        "changeRequestUUID",
        "adobeAgreements",
        "projectRateCards",
        "adobeSignedAgreementsFieldData",
        "adobeSignedAgreementFieldData",
        "adobeFieldData"
      ].includes(key)

      if (filter) {
        return false;
      }

      if (key === "otherSystem") {
        if (index < this.histories.length - 1) {
          if (this.histories[index][key].some(x => !this.histories[index+1][key].some(y => x === y))) {
            return true;
          }
        }
      } else if (key === "mobilePhone") {
        if (index < this.histories.length - 1) {
          if (this.histories[index][key] !== undefined && this.histories[index+1][key] !== undefined) {
            if (this.histories[index][key].e164 !== undefined && this.histories[index+1][key].e164 !== undefined) {
              if (this.histories[index][key].e164 !== this.histories[index+1][key].e164) {
                return true;
              }
            }
          }
        }
      }

      if (index < this.histories.length - 1) {
        if (this.histories[index][key] !== this.histories[index+1][key]) {
          return true;
        }
      }

      return false;

    },
    historyItem() {
      let item = this.computedOnboarding;
      let that = this;
      this.close("ALL").then(function() {
        // this.$router.push({
        //   path: `/company/${this.company._id}/onboarding-history/${item.onboarding_id}`
        // });
        that.historyItemView = JSON.parse(JSON.stringify(item));
        // that.historyItemView.history = that.historyItemView.history.sort(
        //   that.sortDate
        // );
        that.showHistoryDetails = [];
        that.showHistoryCode = []
        that.historyItemView.history.forEach((x) => {
          that.showHistoryDetails.push(false);
          that.showHistoryCode.push(false);
        });
        that.histories = that.historyItemView.history.filter(history => Array.isArray(history)).map((diff, index) => {
          
          let i = that.historyItemView.history.length-1;
          let current = JSON.parse(JSON.stringify(that.historyItemView));
          
          delete current.history;
          while(i >= index) {
            if (Array.isArray(that.historyItemView.history[i])) {
              current = jd.applyDiff(current, that.historyItemView.history[i])
            }
            i--;
          }
          return current;
        });
        let current = JSON.parse(JSON.stringify(that.historyItemView));
        delete current.history;
        that.histories.push(current);
        that.histories = that.histories.sort(that.sortDate);
        that.dialog["history"] = true;
      });
    },
    deleteItem() {
      let item = this.computedOnboarding;
      var that = this;
      if (confirm("Are you sure you want to delete this item?")) {
        this.$BackendService.onboarding
          .delete(item)
          .then(function(response) {
            that.$AlertService.newAlert({
              message: "Removed onboarding item.",
              color: "success",
              timeout: 5,
              show: true,
            });
            that.$nextTick(() => {
              that.$store.dispatch("DELETE_ONBOARDING", item)
              that.$router.push({path: `/company/${that.company._id}/onboarding-profile?q=${that.search}`});
              that.searchDatabase(true);
            })
          })
          .catch(function(error) {
            that.$AlertService.newAlert({
              message: "Failed to remove onboarding item.",
              color: "error",
              timeout: 5,
              show: true,
            });
            console.error(error);
            that.$router.push({path: `/company/${that.company._id}/onboarding-profile`});
            that.$store.dispatch("DELETE_ONBOARDING", item)
          });
      }
    },
    sortDate(a, b) {
      return a.date_time_onboarded < b.date_time_onboarded ? 1 : -1;
    },
    // historyFilter(key, historyItem) {
    //   if (historyItem.employee) {
    //     if (
    //       key === "costRate" ||
    //       key === "billRate" ||
    //       key === "travelExpenses" ||
    //       key === "contingentWorker" ||
    //       key === "startDate" ||
    //       key === "currency"
    //     ) {
    //       return false;
    //     }
    //   }
    //   if (historyItem.contractor) {
    //     if (key === "laptop" || key === "employee") {
    //       return false;
    //     }
    //     if (key === "costRate" || key === "billRate" || key === "currency") {
    //       return historyItem["contingentWorker"];
    //     }
    //     if (key === "startDate") {
    //       return (
    //         historyItem["travelExpenses"] || historyItem["contingentWorker"]
    //       );
    //     }
    //   }
    //   return (
    //     key !== "step" &&
    //     key !== "onboarding_id" &&
    //     key !== "company_id" &&
    //     historyItem[key] !== false &&
    //     historyItem[key] !== null &&
    //     historyItem[key] !== "" &&
    //     key !== "history" &&
    //     key !== "vendorAddress" &&
    //     key !== "flowApprovalStatus" &&
    //     key !== "jiraIssueStatus" &&
    //     key !== "jiraIssueID" &&
    //     key !== "jiraIssueKey" &&
    //     key !== "readyForOnboardedUserInput" &&
    //     key !== "_id" &&
    //     key !== "_rev" &&
    //     key !== "changeRequest"
    //   );
    // },
    openDialogUserSAP() {
      let item = this.computedOnboarding;
      let that = this;
      this.close("ALL").then(function() {
        that.userSAPItem = JSON.parse(JSON.stringify(item));
        that.selectedSAPSystems = that.userSAPItem.otherSystem.filter(sys => (sys.includes("SAP-") || sys === 'FSP/SuccessFactors')).map(sys => sys.replace('SAP-', '').replace('FSP/SuccessFactors', 'FSP'));
        that.createdUserInSAPSystems = [];
        that.failedToCreateUserInSAPSystem = [];
        that.dialog["sap"] = true;
      });
    },
    createUserSAP() {
      let that = this;
      that.selectedSAPSystems.forEach(sys => {
        that.$BackendService.sap.user.create(
          {
            onboarding: that.onboarding, 
            sid: sys
          }
        ).then(function(result) {
          that.createdUserInSAPSystems.push({ system: sys, result: result});
        }).catch(function(error) {
          that.failedToCreateUserInSAPSystem.push({ system: sys, reason: error.message})
          console.error(error);
        });
      })
    },
    openDialogLicenseRequest() {
      let item = this.computedOnboarding;
      let that = this;
      this.close("ALL").then(function() {
        that.licenseRequestItem = JSON.parse(JSON.stringify(item));
        that.selectedLicense = that.licenseRequestItem.o365license || "";
        that.dialog["license"] = true;
      });
    },
    requestLicense() {
      let that = this;
      that.$BackendService.o365.user.license.request(
        {
          onboarding: that.onboarding,
          license: that.selectedLicense
        }
      ).then(function(result) {
        that.$AlertService.newAlert({
          message: "License Requested.",
          color: "success",
          timeout: 5,
          show: true,
        });
      }).catch(function(error) {
        that.$AlertService.newAlert({
          message: "Failed to request license.",
          color: "error",
          timeout: 5,
          show: true,
        });
        console.error(error);
      });
    },
    historyLineItems(historyIndex) {
      let that = this;
      return Object.keys(this.historyItemView).filter(function(key) {
        return historyFilter(key, historyIndex)
      })
      
    },
    
    /*
    
    ADOBE FUNCTIONS
    
    */
    cancelAgreement(agreement) {
      let that = this;
      if(confirm("Are you sure you want to CANCEL this agreement?")) {
        that.$BackendService.adobe.cancelAgreement({
          agreementId: agreement.id
        }).then(function(response) {
          that.$AlertService.newAlert({
            message: "Cancelled Adobe agreement.",
            color: "success",
            timeout: 5,
            show: true,
          });
          that.getAgreements(true).then(() => {
            that.$nextTick(() => {
              that.adobeSignTarget = that.computedOnboardings.filter(onb => onb._id === that.adobeSignTarget._id)[0];
              that.selectedNonSignedAgreementIndex = -1;
              that.$forceUpdate();
            })
          })
          
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to cancel Adobe agreement.",
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        })
      }
    },
    deleteAgreement(agreement) {
      let that = this;
      if(confirm("Are you sure you want to DELETE this agreement?")) {
        that.$BackendService.adobe.deleteAgreement({
          agreementId: agreement.id
        }).then(function(response) {
          that.$AlertService.newAlert({
            message: "Deleted Adobe agreement.",
            color: "success",
            timeout: 5,
            show: true,
          });
          that.getAgreements(true).then(() => {
            that.$nextTick(() => {
              that.adobeSignTarget = that.computedOnboardings.filter(onb => onb._id === that.adobeSignTarget._id)[0];
              that.selectedNonSignedAgreementIndex = -1;
              that.$forceUpdate();
            })
          });
          
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to delete Adobe agreement.",
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        })
      }
    },
    getAgreementEvents(agreement) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.adobe.getAgreementEvents({
          agreementId: agreement.id
        }).then(function(response) {
          that.$AlertService.newAlert({
            message: "Retrieved Adobe agreement events.",
            color: "success",
            timeout: 5,
            show: true,
          });
          resolve(response);
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to get Adobe agreement events.",
            color: "error",
            timeout: 5,
            show: true,
          });
          reject(error);
        })
      })
      
    },
    showAgreementEvents(agreement) {
      let that = this;
      that.loadingAgreementEvents = true;
      that.getAgreementEvents(agreement).then(function(response) {
        that.agreementEvents[agreement.id] = response.events;
        that.loadingAgreementEvents = false;
      }).catch(function(error) {
        console.error(error);
      })
    },
    openAuditTrail(agreement) {
      let that = this;
      that.loadingPdf = true;
      that.viewingPdf = true;
      that.$BackendService.adobe.getAgreementAuditTrail({
        agreementId: agreement.id
      }).then(function(doc) {
        // base64 string
        var base64str = doc;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        // that.pdfToView = URL.createObjectURL(new Blob([view], { type: 'application/pdf'}));
        that.loadingPdf = false;
        that.$nextTick(() => {
          if (that.adobeDCView === null)
            that.adobeDCView = new AdobeDC.View({clientId: 'bfbe3eab74f143e8a1cbd75cda264eeb', divId: "adobe-dc-view"});
          that.adobeDCView.previewFile(
          {
              content:   {location: {url: URL.createObjectURL(new Blob([view], { type: 'application/pdf'})) }},
              metaData: { fileName: 'Adobe Sign Document Audit Trial'}
          });
        })
      }).catch(function(error) {
        that.$AlertService.newAlert({
          message: "Failed to retrieved the Audit Trail PDF.",
          color: "error",
          timeout: 5,
          show: true,
        });
        console.error(error);
      })
    },
    openPDF(agreement) {
      let that = this;
      that.loadingPdf = true;
      that.viewingPdf = true;
      that.$BackendService.adobe.getAgreementCombinedDocument({
        agreementId: agreement.id
      }).then(function(doc) {
        // base64 string
        var base64str = doc;

        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        // that.pdfToView = URL.createObjectURL(new Blob([view], { type: 'application/pdf'}));
        that.loadingPdf = false;
        that.$nextTick(() => {
          if (that.adobeDCView === null)
            that.adobeDCView = new AdobeDC.View({clientId: 'bfbe3eab74f143e8a1cbd75cda264eeb', divId: "adobe-dc-view"});
          that.adobeDCView.previewFile(
          {
              content:   {location: {url: URL.createObjectURL(new Blob([view], { type: 'application/pdf'})) }},
              metaData: { fileName: 'Adobe Sign Document'}
          });
        })
      }).catch(function(error) {
        that.$AlertService.newAlert({
          message: "Failed to retrieved the PDF.",
          color: "error",
          timeout: 5,
          show: true,
        });
        console.error(error);
      })
    },
    newAgreement() {
      this.creatingNewAgreement = true;
      this.selectedLibraryDoc = null;
    },
    createAgreement() {
      let that = this;
      this.$BackendService.adobe.createAgreement({
        libraryDocumentId: that.selectedLibraryDoc.id,
        agreement_name: that.selectedLibraryDoc.name + ' for ' + that.adobeSignTarget.firstName + " " + that.adobeSignTarget.lastName,
        signers: [ that.secondPartySignerEmail, that.cognitusSigner.cognitusEmailMailbox+that.cognitusSigner.cognitusEmailDomain],
        ccs: that.ccs.map(cc => {
          if (cc.value !== undefined) {
            return cc.value
          }
          return cc;
        })
      }).then(function(agreement) {
        if (!that.adobeSignTarget.adobeAgreements.includes(agreement.id)) {
          that.$AlertService.newAlert({
            message: "Created Adobe agreement.",
            color: "success",
            timeout: 5,
            show: true,
          });
          that.adobeSignTarget.adobeAgreements.push(agreement.id);
          that.agreementId = agreement.id;
          that.$BackendService.onboarding.update(that.adobeSignTarget).then(function(response) {
            that.$store.dispatch("UPDATE_ONBOARDING", response)
            that.$AlertService.newAlert({
              message: "Linked agreement to onboarding.",
              color: "success",
              timeout: 5,
              show: true,
            });
            that.putAgreementFormFieldsMergeInfo().then(function(response) {
              setTimeout(() => {
                that.$nextTick(() => {
                  that.putAgreementState('AUTHORING').then(function(response) {
                    setTimeout(() => {
                      that.$nextTick(() => {
                        that.putAgreementState('IN_PROCESS').then(function(response) {
                          setTimeout(() => {
                            that.$nextTick(() => {
                              that.getAgreements(true).then(() => {
                                that.adobeSignTarget = that.computedOnboardings.filter(onb => onb._id === that.adobeSignTarget._id)[0];
                                that.agreementId = null;
                                that.cognitusSigner = null;
                                that.secondPartySignerEmail = null;
                                that.showActiveAgreements = false;
                                that.creatingNewAgreement = false;
                                that.mergeInfo = null;
                                that.libraryDocumentFormFields = null;
                                that.selectedLibraryDoc = null;
                                that.libraryDocumentHoverIndex = -1;
                                that.selectedNonSignedAgreementIndex = -1;
                              });
                            })
                          }, 2000)
                        }).catch(function(error) {
                          console.error(error);
                        })
                      })
                    }, 2000)
                  }).catch(function(error) {
                    console.error(error);
                  })
                })
              }, 2000)
            }).catch(function(error) {
              console.error(error);
            })
            
          }).catch(function(error) {
            that.$AlertService.newAlert({
              message: "Error linking agreement to onboarding.",
              color: "error",
              timeout: 5,
              show: true,
            });
            console.error(error);
          })
        }
      }).catch(function(error) {
        that.$AlertService.newAlert({
            message: "Failed to create Adobe agreement.",
            color: "error",
            timeout: 5,
            show: true,
          });
        console.error(error);
      })
    },
    putAgreementState(state) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.adobe.putAgreementState({
          state: state,
          agreement_id: that.agreementId
        }).then(function(response) {
          that.$AlertService.newAlert({
            message: "Updated agreement state to " + state + ".",
            color: "success",
            timeout: 5,
            show: true,
          });
          resolve(response)
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to update agreement state.",
            color: "error",
            timeout: 5,
            show: true,
          });
          reject(error);
        })
      })
      
    },
    putAgreementFormFieldsMergeInfo() {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.adobe.putAgreementFormFieldsMergeInfo({
          fieldMergeInfos: that.mergeInfo,
          agreement_id: that.agreementId
        }).then(function(response) {
          that.$AlertService.newAlert({
            message: "Updated agreement form fields.",
            color: "success",
            timeout: 5,
            show: true,
          });
          resolve(response);
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: "Failed to update agreement form fields.",
            color: "error",
            timeout: 5,
            show: true,
          });
          reject(error);
        })
      })  
      
    },
    getLibraryDocumentFormFields() {
      let that = this;
      this.$BackendService.adobe.getLibraryDocumentFormFields({
        libraryDocumentId: that.selectedLibraryDoc.id
      }).then(function(response) {
        // console.log(response.fields);
        that.libraryDocumentFormFields = response.fields;
        that.mergeInfo = [];
        that.libraryDocumentFormFields.forEach(field => {
          if (field.assignee === undefined) {
            that.mergeInfo.push({
              fieldName: field.name,
              defaultValue: field.defaultValue
            })
          }
        });
        if (that.selectedLibraryDoc.name === 'NDA' || that.selectedLibraryDoc.name === 'NDA_NEW') {
        
          that.secondPartySignerEmail = that.adobeSignTarget.personalEmailAddress;
          that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Second Party Name")[0])].defaultValue = that.adobeSignTarget.firstName + ' ' + that.adobeSignTarget.lastName;

        } else if (that.selectedLibraryDoc.name.startsWith("MSA")) {
          
          if (that.adobeSignTarget.contractor && that.adobeSignTarget.contingentWorker) {
            
            // MSA freelancer fields
            if (that.adobeSignTarget.vendorAddressSupplier === 'freelance') {
              
              that.secondPartySignerEmail = that.adobeSignTarget.personalEmailAddress;
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Vendor Name")[0])].defaultValue = that.adobeSignTarget.firstName + ' ' + that.adobeSignTarget.lastName;
            
            // MSA service provider fields
            } else if (that.adobeSignTarget.vendorAddressSupplier === 'serviceprovider') {
              
              that.secondPartySignerEmail = that.adobeSignTarget.serviceProviderEmailAddress;
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Vendor Name")[0])].defaultValue = that.adobeSignTarget.vendorAddress.serviceproviderName;
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Vendor Street Address")[0])].defaultValue = that.adobeSignTarget.vendorAddress.streetAddress + ' ' + that.adobeSignTarget.vendorAddress.streetAddress2;
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Vendor City")[0])].defaultValue = that.adobeSignTarget.vendorAddress.city;
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Vendor State")[0])].defaultValue = that.adobeSignTarget.vendorAddress.state;
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Vendor ZIP")[0])].defaultValue = that.adobeSignTarget.vendorAddress.zip;

            }

            if (that.selectedLibraryDoc.name.startsWith('MSA_')) {

              let splitDate = that.adobeSignTarget.startDate.split('-');
              let date = splitDate[1]+'/'+splitDate[2]+'/'+splitDate[0]
              that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Effective Date")[0])].defaultValue = date;

              that.getS4Locations().then((response) => {
                let location = response.find(location => parseInt(location.Bukrs) === parseInt(that.adobeSignTarget.sfPayingLegalEntity))
                that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Cognitus Company Name")[0])].defaultValue = location.Name1;
                that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Cognitus Company Legal Type")[0])].defaultValue = location.Name2;
                that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Cognitus Company Street Address")[0])].defaultValue = location.Street;
                that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Cognitus Company City")[0])].defaultValue = location.City1;
                that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Cognitus Company State")[0])].defaultValue = location.Region;
                that.mergeInfo[that.mergeInfo.indexOf(that.mergeInfo.filter(field => field.fieldName === "Cognitus Company ZIP")[0])].defaultValue = location.PostCode1;
              }).catch((error) => {
                console.error(error);
                that.$AlertService.newAlert({
                  message: `Failed to retrieve company locations from S4 HANA.`,
                  color: "error",
                  timeout: 5,
                  show: true,
                });
              })
            }
          
          }

        }
        that.$AlertService.newAlert({
          message: `Retrieved ${that.mergeInfo.length} PREFILL-able form fields.`,
          color: "success",
          timeout: 5,
          show: true,
        });
      }).catch(function(error) {
        that.$AlertService.newAlert({
          message: `Failed to retrieve form fields.`,
          color: "error",
          timeout: 5,
          show: true,
        });
        console.error(error);
      })
    },
    selectDoc(doc) {
      let that = this;
      this.selectedLibraryDoc = doc;
      this.getLibraryDocumentFormFields();
    },
    hasSignedMSA() {
      return this.signedAdobeSignAgreements.some(agreement => agreement.name.startsWith("MSA"))
    },
    availableLibraryDocuments(doc) {
      let that = this;
      // console.log(doc, that.adobeSignTarget.contractor, that.adobeSignTarget.contingentWorker);
      let showDoc = false;
      if (that.adobeSignTarget !== undefined && that.adobeSignTarget !== null) {
        if (that.adobeSignTarget.contractor && !that.adobeSignTarget.contingentWorker && (doc.name === "NDA" || doc.name === 'NDA_NEW')) {
          showDoc = true;
        } else if (that.adobeSignTarget.contractor && that.adobeSignTarget.contingentWorker && doc.name.startsWith("MSA")){
          let msaLegalEntityMap = that.company.settings[that.company.settings.indexOf(that.company.settings.filter(s => s.label === 'MSA Legal Entity Map')[0])].value;
          if (msaLegalEntityMap.length > 0) {
            // map is a key/value array
            // [ [key, value], [key, value], [key, value] ]
            if (msaLegalEntityMap.find(x => parseInt(x[0]) === parseInt(that.adobeSignTarget.sfPayingLegalEntity))[1].split(',').some(name => doc.name === name) ) {
              showDoc = true;
            }
          }
        }
      }
      return doc.creatorEmail === 'adobe@cognitusconsulting.com' && showDoc
    },
    getLibraryDocuments() {
      let that = this;
      that.loadingLibraryDocuments = true;
      if (that.adobeSignTarget !== undefined && that.adobeSignTarget !== null) {
        this.$BackendService.adobe.getLibraryDocuments({}).then(function(response) {
          that.libraryDocuments = response.libraryDocumentList;
          that.loadingLibraryDocuments = false;
          that.$AlertService.newAlert({
            message: `Retrieved ${that.libraryDocuments.length} library documents.`,
            color: "success",
            timeout: 5,
            show: true,
          });
        }).catch(function(error) {
          that.$AlertService.newAlert({
            message: `Failed to retrieve library documents.`,
            color: "error",
            timeout: 5,
            show: true,
          });
          console.error(error);
        })
      }
    },
    getAgreements(noNotify = false) {
      let that = this;
      return new Promise((resolve, reject) => {
        that.loadingAdobeAgreements = true;
        this.$BackendService.adobe.getAgreements({}).then(function(response) {
          that.adobeAgreements = response.userAgreementList;
          that.loadingAdobeAgreements = false;
          if (!noNotify)
            that.$AlertService.newAlert({
              message: `Retrieved ${that.adobeAgreements.length} agreements.`,
              color: "success",
              timeout: 5,
              show: true,
            });
            resolve();
        }).catch(function(error) {
          if (!noNotify)
            that.$AlertService.newAlert({
              message: `Failed to retrieve agreements.`,
              color: "error",
              timeout: 5,
              show: true,
            });
          reject(error);
        })
      })
      
    },


    getSFFoCompanies(companies = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/FOCompany?$select=externalCode,name_defaultValue"
        }).then(function(response) {
          companies = companies.concat(response.d.results.map(x => (
            { 
              payingLegalEntity: x.externalCode, 
              name: x.name_defaultValue
            }
          )))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFFoCompanies(companies, response.d['__next']));
          } else {
            resolve(companies)
          }
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFFoLocations() {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: "https://api4.successfactors.com/odata/v2/FOLocation?$select=addressNavDEFLT,companyFlxNav"
        }).then(function(response) {
          Promise.allSettled(response.d.results.map(async x => {
            try {
              let location = (await that.$BackendService.sap.sf({
                requestURL: x.addressNavDEFLT['__deferred'].uri
              })).d;
              let associatedCompanyCode = (await that.$BackendService.sap.sf({
                requestURL: x.companyFlxNav['__deferred'].uri
              })).d.results[0].externalCode
              return {
                location, associatedCompanyCode
              }
            } catch (error) {
              console.error(error);
              return null;
            }
          })).then((results) => {
            resolve(results.filter(result => result.status === 'fulfilled').map(result => result.value))
          }).catch(error => {
            reject(error);
          })
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getSFCostCenters(costCenters = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.sf({
          requestURL: requestURL !== undefined ? requestURL : "https://api4.successfactors.com/odata/v2/FOCostCenter?$select=externalCode,name_defaultValue,cust_company"
        }).then(function(response) {
          costCenters = costCenters.concat(response.d.results.map(x => (
            { 
              costCenter: x.externalCode, 
              payingLegalEntity: x.cust_company, 
              name: x.name_defaultValue, 
              text: x.externalCode + ' - ' + x.name_defaultValue, 
              value: x.externalCode + ' - ' + x.name_defaultValue,
              hidden: false
            }
          )))
          if (response.d['__next'] !== undefined) {
            resolve(that.getSFCostCenters(costCenters, response.d['__next']));
          } else {
            resolve(costCenters)
          }
          resolve();
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    getS4Locations(locations = [], requestURL = undefined) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$BackendService.sap.s4.location({
          environment: window.location.host === 'localhost:8080' ? 'test' : 'prod',
          requestURL
        }).then(function(response) {
          locations = locations.concat(response.d.results)
          if (response.d['__next'] !== undefined) {
            resolve(getS4Locations(locations, response.d['__next']))
          } else {
            resolve(locations);
          }
        }).catch(function(error) {
          reject(error);
        })
      })
    },
    filterAdobeSignAgreementByStatus(status) {
      return this.adobeSignAgreements.filter(agreement => status === agreement.status);
    },
    filterAdobeSignAgreementByStatusList(statusList) {
      return this.adobeSignAgreements.filter(agreement => statusList.includes(agreement.status));
    },
    saveAdobeSignAgreementFieldData(onb, noNotify = false) {
      let that = this;
      let promises = [];
      if (onb.adobeSignedAgreementsFieldData === undefined) {
        onb.adobeSignedAgreementsFieldData = [];
      } else if (onb.adobeSignedAgreementsFieldData !== undefined && onb.adobeSignedAgreementsFieldData.length > 0) {
        let sorted = onb.adobeSignedAgreementsFieldData.slice().sort((a, b) => new Date(b.data.filter(d => d.header === 'completed')[0].data[0]) - new Date(a.data.filter(d => d.header === 'completed')[0].data[0]))
        let latest = sorted[0];
        if (onb.msaDate !== latest.data.filter(d => d.header === 'completed')[0].data[1]) {
          onb.msaDate = latest.data.filter(d => d.header === 'completed')[0].data[1]
          onb.vendorAddress.streetAddress = latest.data.filter(d => d.header === 'Vendor Street Address')[0].data[0]
          onb.vendorAddress.state = latest.data.filter(d => d.header === 'Vendor State')[0].data[0]
          onb.vendorAddress.city = latest.data.filter(d => d.header === 'Vendor City')[0].data[0]
          onb.vendorAddress.zip = latest.data.filter(d => d.header === 'Vendor ZIP' || d.header === 'Custom Field 3')[0].data[0]
        }
      }
      this.adobeAgreements.filter(agreement => onb.adobeAgreements.some(agr => agr === agreement.id)).forEach(targetAgreement => {
        // check if there is a signed agreement which hasnt had it's field data retrieved and stored 
        if (targetAgreement.status === 'SIGNED' && !onb.adobeSignedAgreementsFieldData.some(signedAgreementFieldData => signedAgreementFieldData.id === targetAgreement.id )) {
          promises.push((new Promise((resolve, reject) => {
            that.$BackendService.adobe.getAgreementFieldData({agreement_id: targetAgreement.id}).then(fieldData => {
              resolve(fieldData)
            }).catch(error => {
              reject(error);
            })
          })).then(fieldData => {
            onb.adobeSignedAgreementsFieldData.push({
              id: targetAgreement.id,
              name: targetAgreement.name,
              data: fieldData
            })
            
          }).catch(error => {
            console.error(error);
          }))
        }
      })
      Promise.all(promises).then(() => {
        onb.adobeSignedAgreementsFieldData = onb.adobeSignedAgreementsFieldData.filter((signedFieldData, index) => {
          return !onb.adobeSignedAgreementsFieldData.some((fieldData, checkIndex) => {
            fieldData.id === signedFieldData.id && checkIndex !== index
          })
        })
        that.$BackendService.onboarding.update({
          _id: onb._id, 
          onboarding_id: onb._id, 
          company_id: onb.company_id,
          vendorAddress: onb.vendorAddress, 
          msaDate: onb.msaDate, 
          adobeSignedAgreementsFieldData: onb.adobeSignedAgreementsFieldData
        }).then((onb) => {
          that.$store.dispatch("UPDATE_ONBOARDING", onb)
          if (!noNotify)
          that.$AlertService.newAlert({
            message: `Saved Signed Adobe Agreement Field Data for ${onb.cognitusEmailMailbox}.`,
            color: "success",
            timeout: 5,
            show: true,
          })
        }).catch(error => {
          if (!noNotify)
          that.$AlertService.newAlert({
            message: `Failed to save Signed Adobe Agreement Field Data for ${onb.cognitusEmailMailbox}.`,
            color: "error",
            timeout: 5,
            show: true,
          })
          console.error(error);
        })
      })
    }
  },
  computed: {
    onboardingProjects() {
      let projects = []
      let that = this;
      if (
        this.computedOnboarding.projectRateCards !== undefined && 
        this.computedOnboarding.projectRateCards.length > 0 && 
        this.computedProjects !== undefined && 
        this.computedProjects !== null && 
        this.computedProjects.length > 0
      ) {
        if (that.projectRateCards !== null)
          projects = [
            ...new Set(
              this.computedOnboarding.projectRateCards.map(projectRateCardId => {
                let prjrc = that.projectRateCards.filter(projectRateCard => projectRateCardId === projectRateCard._id)[0]
                return prjrc
              }).filter(prc => {
                return prc !== undefined
              }).map(projectRateCard => {
                return that.computedProjects.filter(proj => proj._id === projectRateCard.project_id)[0]
              }).filter(project => project !== undefined)
            )
          ];
        else
          projects = []
      }
      return projects;
    },
    secondPartySigners() {
      let signers = [];
      if (this.adobeSignTarget !== null) {
        if (this.selectedLibraryDoc.name === 'NDA' || this.selectedLibraryDoc.name === 'NDA_NEW') {
          signers.push({
            text: 'Personal Email Address <'+this.adobeSignTarget.personalEmailAddress+'>',
            value: this.adobeSignTarget.personalEmailAddress
          })
        } else {
          if (this.adobeSignTarget.vendorAddressSupplier === 'serviceprovider') {
            signers.push({
              text: 'Service Provider Email Address <'+this.adobeSignTarget.serviceProviderEmailAddress+'>',
              value: this.adobeSignTarget.serviceProviderEmailAddress
            })
          } else if (this.adobeSignTarget.vendorAddressSupplier === 'freelance') {
            signers.push({
              text: 'Personal Email Address <'+this.adobeSignTarget.personalEmailAddress+'>',
              value: this.adobeSignTarget.personalEmailAddress
            });
          } else {
            signers.push({
              text: 'Cognitus Email Address <'+this.adobeSignTarget.cognitusEmailMailbox+this.adobeSignTarget.cognitusEmailDomain+'>',
              value: this.adobeSignTarget.cognitusEmailMailbox+this.adobeSignTarget.cognitusEmailDomain
            })
          }
        } 
      }
      return signers;
    },
    authorizedSignersComputed() {
      let signers;
      if (this.selectedLibraryDoc.name !== 'NDA' && this.selectedLibraryDoc.name !== 'NDA_NEW') {
        let signersFromSettings = this.company.settings[
          this.company.settings.indexOf(
            this.company.settings.filter(
              setting => setting.label === 'MSA Legal Entity Signer'
            )[0]
          )
        ];
        //signersFromSettings is of form: [[legalEntity], [signer1@cognitus.com, signer2@cognitus.com]]
        signers = signersFromSettings.value.filter(group => {
          return group[0].toString() === this.adobeSignTarget.sfPayingLegalEntity.toString()
        })[0][1];
      } else {
        return [ {
          text: 'Cognitus Partners <cognitus@cognitusconsulting.com>',
          value: {
            cognitusEmailDomain: '@cognitusconsulting.com',
            cognitusEmailMailbox: 'cognitus'
          }
        }]
      }
      if (!signers) return []
      return signers.map(signer => {
          // console.log(signer);
          let onb = this.computedOnboardings.filter(onb => onb !== undefined).filter(onb => onb.cognitusEmailMailbox + onb.cognitusEmailDomain === signer)[0];
          if (onb) {
            return {
              text: '' + onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
              value: {
                cognitusEmailDomain: onb.cognitusEmailDomain,
                cognitusEmailMailbox: onb.cognitusEmailMailbox
              }
            }
          } else {
            return undefined;
          }
      }).filter(signer => signer !== undefined);
    },
    mergeInfoValid() {
      // console.log('checking if merge info is valid');
      if (this.mergeInfo !== null && this.mergeInfo.length > 0) {
        // console.log(this.mergeInfo);
        this.mergeInfo.forEach(info => {
          if (info.defaultValue === undefined || info.defaultValue === null) {
            info.defaultValue = ""
          }
        });
        return !this.mergeInfo.some(info => (info.defaultValue === '' || info.defaultValue === null) && (info.fieldName !== 'Vendor Street Address' && info.fieldName !== 'Vendor City' && info.fieldName !== 'Vendor State' && info.fieldName !== 'Vendor ZIP')) && this.cognitusSigner !== null && this.cognitusSigner !== "" && this.secondPartySignerEmail !== null && this.secondPartySignerEmail !== ''
      }
      return this.cognitusSigner !== null && this.cognitusSigner !== "" && this.secondPartySignerEmail === null && this.secondPartySignerEmail === '';
    },
    partners() {
      return this.computedOnboardings.filter(onb => onb.jobTitle !== undefined && (onb.jobTitle.includes("Partner") || onb.jobTitle.includes("CEO") || onb.jobTitle.includes("CFO") || onb.jobTitle.includes("COO")) && onb.region === "North America")
    },
    signedAdobeSignAgreements() {
      return this.adobeSignAgreements.filter(agreement =>  agreement.status === 'SIGNED');
    },
    unsignedAdobeSignAgreements() {
      return this.adobeSignAgreements.filter(agreement =>  agreement.status !== 'SIGNED')
    },
    cancelledAdobeSignAgreements() {
      return this.adobeSignAgreements.filter(agreement => agreement.status === 'CANCELLED')
    },
    signedAdobeSignOnboardingAgreements() {
      return this.adobeSignOnboardingAgreements.filter(agreement =>  agreement.status === 'SIGNED')
    },
    unsignedAdobeSignOnboardingAgreements() {
      return this.adobeSignOnboardingAgreements.filter(agreement =>  agreement.status !== 'SIGNED')
    },
    cancelledAdobeSignOnboardingAgreements() {
      return this.adobeSignOnboardingAgreements.filter(agreement => agreement.status === 'CANCELLED')
    },
    adobeSignOnboardingAgreements() {
      let that = this;
      if (that.onboarding === undefined || that.onboarding === null || this.adobeAgreements === undefined || this.adobeAgreements === null) {
        return []
      }
      if (that.onboarding.adobeAgreements === undefined) {
        that.onboarding.adobeAgreements = [];
      }
      return this.adobeAgreements.filter(agreement => that.onboarding.adobeAgreements.some(agr => agr === agreement.id))
    },
    adobeSignAgreements() {
      let that = this;
      if (that.adobeSignTarget === undefined || that.adobeSignTarget === null || this.adobeAgreements === undefined || this.adobeAgreements === null) {
        return []
      }
      if (that.adobeSignTarget.adobeAgreements === undefined) {
        that.adobeSignTarget.adobeAgreements = [];
      }
      
      return this.adobeAgreements.filter(agreement => that.adobeSignTarget.adobeAgreements.some(agr => agr === agreement.id));
    },
    ccs: {
      get: function() {
        let that = this;
        let ccs = that.additionalCCEmail.concat(that.hardcodedCCEmail);
        if(!ccs.includes(that.adobeSignTarget.requester)) {
          ccs = ccs.concat([that.adobeSignTarget.requester]);
        }
        return ccs;
      },
      set: function(newValue) {
        let that = this;
        let hardCodedCCEmails = that.hardcodedCCEmail.concat([that.adobeSignTarget.requester]);
        that.additionalCCEmail = newValue.filter(function(email) {
          return hardCodedCCEmails.indexOf(email) === -1;
        });
      }
      
    },
    searchedData() {
      return this.searchRequest;
    },
    e1LicenseAvailable() {
      if (this.availableLicenses.value === undefined) {
        return false;
      }
      let e1 = this.availableLicenses.value.filter(license => {
        return license.skuId === '18181a46-0d4e-45cd-891e-60aabd171b4e';
      })[0];
      return (e1 !== undefined &&  e1.consumedUnits < (e1.prepaidUnits.enabled + e1.prepaidUnits.suspended + e1.prepaidUnits.warning))
    },
    e3LicenseAvailable() {
      if (this.availableLicenses.value === undefined) {
        return false;
      }
      let e3 = this.availableLicenses.value.filter(license => {
        return license.skuId === '6fd2c87f-b296-42f0-b197-1e91e994b900';
      })[0];
      return (e3 !== undefined &&  e3.consumedUnits < (e3.prepaidUnits.enabled + e3.prepaidUnits.suspended + e3.prepaidUnits.warning))
    },
    buttons() {
      return [
          {
              color: "cyan",
              disabled: this.computedOnboarding.terminated || this.computedOnboarding.flowApprovalStatus === 'Requested',
              click: this.editItem,
              text: "Onboarding Change Request",
              icon: "mdi-delta",
              show: true
          },
          {
              color: "yellow",
              disabled: false,
              click: this.historyItem,
              text: "View Onboarding History",
              icon: "mdi-history",
              show: true
          },
          {
              color: "light-blue",
              disabled: this.computedOnboarding.terminated,
              click: this.sendEmail,
              text: "Send Email",
              icon: "mdi-email",
              show: true
          },
          {
              color: "light-green",
              disabled: (
                !['rvanderzande@cognitus.com', 'lvanderzande@cognitusconsulting.com', 'carora@cognitus.com'].includes(this.$store.state.userADInfo.mail) ||
                this.computedOnboarding.terminated || 
                this.office365UserExistance[this.computedOnboarding.cognitusEmailMailbox + this.computedOnboarding.cognitusEmailDomain] ||
                this.office365UserExistance[this.computedOnboarding.cognitusEmailMailbox + this.computedOnboarding.cognitusEmailDomain] === 'ERROR'
              ),
              click: this.createOffice365User,
              text: "Create Office 365 User",
              icon: "mdi-microsoft-office",
              show: true
          },
          // {
          //   color: "rgb(0, 94, 100)",
          //   disabled: (
          //     ( !this.e1LicenseAvailable && 
          //       !this.e3LicenseAvailable && 
          //       !this.office365UserExistance[this.computedOnboarding.cognitusEmailMailbox + this.computedOnboarding.cognitusEmailDomain]
          //     ) || 
          //     this.computedOnboarding.terminated
          //   ),
          //   click: this.openDialogLicenseRequest,
          //   text: "Request o365 License Purchase",
          //   icon: "mdi-cart",
          //   show: false
          // },
          {
            color: 'rgb(96, 87, 230)',
            disabled: this.computedOnboarding.terminated || 
            this.office365UserExistance[
              this.computedOnboarding.cognitusEmailMailbox +
                this.computedOnboarding.cognitusEmailDomain
            ] === 'ERROR' ||
            !this.office365UserExistance[
              this.computedOnboarding.cognitusEmailMailbox +
                this.computedOnboarding.cognitusEmailDomain
            ],
            click: this.openDialogUserSAP,
            text: "Create user in SAP",
            icon: "mdi-account-plus-outline",
              show: true
          },
          // {
          //     color: "error",
          //     disabled: this.computedOnboarding.terminated,
          //     click: this.deleteItem,
          //     text: "Delete Onboarding",
          //     icon: "mdi-delete",
          //     show: true
          // },
          {
              color: this.office365LoginDisabled[
                            this.computedOnboarding.cognitusEmailMailbox +
                              this.computedOnboarding.cognitusEmailDomain
                          ] !== 'ERROR' &&
                          this.office365LoginDisabled[
                            this.computedOnboarding.cognitusEmailMailbox +
                              this.computedOnboarding.cognitusEmailDomain
                          ]
                            ? 'light-green'
                            : 'error',
              disabled: this.computedOnboarding.terminated ||
                            this.office365LoginDisabled[
                              this.computedOnboarding.cognitusEmailMailbox +
                                this.computedOnboarding.cognitusEmailDomain
                            ] === 'ERROR' ||
                            this.office365UserExistance[
                              this.computedOnboarding.cognitusEmailMailbox +
                                this.computedOnboarding.cognitusEmailDomain
                            ] === 'ERROR' ||
                            !this.office365UserExistance[
                              this.computedOnboarding.cognitusEmailMailbox +
                                this.computedOnboarding.cognitusEmailDomain
                            ],
              click: this.toggleOffice365Login,
              text: (this.office365LoginDisabled[
                        this.computedOnboarding.cognitusEmailMailbox + this.computedOnboarding.cognitusEmailDomain
                      ]
                        ? "Enable"
                        : "Disable") + " Office365 Login",
              icon: "mdi-key",
              show: true
          },
          {
              color: !this.computedOnboarding.terminated ? 'warning' : 'success',
              disabled: false,
              click: !this.computedOnboarding.terminated
                            ? this.startTermination
                            : this.startRevertTermination,
              text: !this.computedOnboarding.terminated
                        ? "Request Termination"
                        : "Request Rehire",
              icon: !this.computedOnboarding.terminated
                              ? "mdi-account-minus"
                              : "mdi-account-plus",
              show: true
          },
          {
            color: 'purple',
            disabled: false,
            click: this.adobe,
            text: 'AdobeSign',
            icon: 'mdi-signature',
            show: true
          },
          {
            color: 'rgba(0, 0, 0, .5)',
            disabled: false,
            click: this.toggleShowOnboardingCode,
            text: 'JSON',
            icon: 'mdi-code-json',
            show: true
          }
      ]
    },
    // changesInHistory() {
    //   let that = this;
    //   if (this.hist.length <= 1) {
    //     return false;
    //   } else {
    //     return JSON.parse(JSON.stringify(this.hist))
    //       .splice(that.hist.length - 1, 1)
    //       .some((h, i) => {
    //         return Object.keys(h)
    //           .filter((k) => that.historyFilter(k, h))
    //           .some((k) => {
    //             return (
    //               JSON.stringify(h[k]) !== JSON.stringify(that.hist[i + 1][k])
    //             );
    //           });
    //       });
    //   }
    // },
    // hist() {
    //   let that = this;
    //   return this.historyItemView !== null
    //     ? this.historyItemView.history
    //         .concat([that.withoutHistory(that.historyItemView)])
    //         .sort(that.sortDate)
    //     : [];
    // },
    editedItemComputed() {
      return this.editedItem;
    },
    filters() {
      return Object.keys(this.formTemplate);
    },
    computedProjects() {
      let projects = null
      if (this.$store.state.projects !== null) {
        projects = this.$store.state.projects;
      }
      return projects;
    },
    computedOnboardings() {
      // console.log('here')
      let onboardings = null
      if ((this.$store.state.onboardings !== null)) {
        onboardings = this.$store.state.onboardings[this.company._id];
      }
      return onboardings
    },
    computedOnboarding: {
      get() {
        // console.log(this.onboarding)
        return this.onboarding
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
