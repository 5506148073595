<template>
  <v-layout
    justify-center
    align-center
    row
    v-if="$store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && company !== undefined"
  >
    <v-flex
      xs12
      style="margin: 2em;"
      v-if="$store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.user.manage`)"
    >
      <v-flex xs12 v-if="computedUsers !== null">
        <h1 style="text-align: center;">Company User Manage</h1>
        <br />
      </v-flex>
      <v-data-table
        v-if="computedUsers !== undefined && computedUsers !== null"
        v-model="selected"
        :headers="headers"
        :items="computedUsers"
        item-key="cognitus_email_address"
        class="elevation-1"
        multi-sort
        calculate-widths
        :search="search"
        :loading="computedUsers === null"
        :custom-filter="(value, search, item) => {
          return search !== null && item !== null && ( (item.first_name !== null && (item.first_name.includes(search) || item.first_name.startsWith(search) || search.includes(item.first_name))) || (item.last_name !== null && (item.last_name.includes(search) || item.last_name.startsWith(search) || search.includes(item.last_name))) ||
          (item.cognitus_email_address !== null && (item.cognitus_email_address === search || item.cognitus_email_address.includes(search) || search.includes(item.cognitus_email_address) || item.cognitus_email_address.startsWith(search))));
        }"
      >
        <template v-slot:top>
          <v-btn icon style="margin: 0 auto;" @click="$eventBus.$emit('getUsers')">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-select
                      :items="companyNavPermissions.map(x => x.text)"
                      v-model="editedItem.navPermissions"
                      :label="`Navigation Permissions ${admin ? '(admin disabled)' : ''}`"
                      :disabled="admin"
                      multiple
                    ></v-select>
                  </v-row>
                  <v-row>
                    <v-switch v-model="admin" label="Admin" :success="admin"></v-switch>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-text-field style="margin: 0em 2em" type="text" v-model="search" label="Search" />
        </template>
        <template v-slot:item="{item}">
          <tr :class="`${item._id === user_id ? 'row-active' : ''}`">
            <td>{{item.first_name}}</td>
            <td>{{item.last_name}}</td>
            <td>{{item.cognitus_email_address}}</td>
            <td>{{item.mobile_phone}}</td>
            <td>{{item.job_title}}</td>
            <!-- <td>{{item._id}}</td> -->
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="mr-2"
                    @click="editItem(item); formTitle='Edit User'"
                  >mdi-pencil</v-icon>
                </template>Edit Permissions
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" @click="deleteItem(item)">mdi-delete</v-icon>
                </template>
                Remove User from {{company.name}}
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-text v-else>
        <v-btn style="margin: 0 auto;" @click="$eventBus.$emit('getUsers')">Refresh User List</v-btn>
      </v-card-text>
    </v-flex>
  </v-layout>
  <v-layout v-else>
    <v-flex xs12>
      <h1>You must be logged in to view this page!</h1>
      <v-btn @click="$eventBus.$emit('authDialog', true)">Login</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["users", "nav", "user_id", "companyNavPermissions", "company"],
  watch: {
    user_id: {
      handler(val) {
        var that = this;
        if (val !== null && val !== undefined) {
        }
      }
    }
  },
  data() {
    return {
      search: "",
      selected: null,
      headers: [
        { text: "First Name", value: "doc.first_name" },
        { text: "Last Name", value: "doc.last_name" },
        { text: "Cognitus Email", value: "doc.cognitus_email_address" },
        { text: "Mobile Phone #", value: "doc.mobile_phone" },
        { text: "Job Title", value: "doc.job_title" },
        { text: "Actions", value: "action", sortable: false }
      ],
      editedItem: {
        first_name: "",
        last_name: "",
        cognitus_email_address: "",
        mobile_phone: "",
        job_title: "",
        navPermissions: []
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        cognitus_email_address: "",
        mobile_phone: "",
        job_title: "",
        navPermissions: []
      },
      editedIndex: -1,
      dialog: false,
      formTitle: "",
      admin: false
    };
  },
  mounted() {
    if (this.user_id !== undefined && this.user_id !== null) {
      var that = this;
      let user_id_exists = this.computedUsers.filter(function(user) {
        return user._id === that.user_id;
      });
      if (user_id_exists.length > 0) {
        this.editItem(user_id_exists[0]);
      }
    }
  },
  computed: {
    computedUsers() {
      return this.users || null;
    }
  },
  methods: {
    log(...args) {
      console.log(args)
    },
    close() {
      // console.log('close');
      this.dialog = false;
      this.editedIndex = -1;
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.admin = false;
      this.$forceUpdate();
    },
    editItem(item) {
      let that = this;
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.admin = this.editedItem.navPermissions.includes(`${that.company._id}.admin`)
      this.dialog = true;
    },

    deleteItem(item) {
      var that = this;
      if (confirm("Are you sure you want to delete this item?")) {
        this.$BackendService.company
          .removeUser({ user_id: item._id, company_id: that.company._id })
          .then(function(response) {
            that.$AlertService.newAlert({
              message: `Removed ${item.cognitus_email_address} from company.`,
              show: true,
              timeout: 5,
              color: "success"
            });
            that.$eventBus.$emit("getUserInfo", {});
            that.$eventBus.$emit("getCompanies", {});
            that.$eventBus.$emit("getUsers", { data: {
                  company_id: this.company._id
                }});
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    },
    save() {
      var that = this;
      if (this.editedIndex > -1) {
        if (this.admin) {
          !that.editedItem.navPermissions.includes(`${that.company._id}.admin`) ? that.editedItem.navPermissions.push(`${that.company._id}.admin`) : '';
        } else {
          that.editedItem.navPermissions.includes(`${that.company._id}.admin`) ? that.editedItem.navPermissions.splice(that.editedItem.navPermissions.indexOf(`${that.company._id}.admin`), 1) : '';
          // that.editedItem.permissions.includes(`wavedash.${that.company._id}.admin`) ? that.editedItem.permissions.splice(that.editedItem.permissions.indexOf(`wavedash.${that.company._id}.admin`), 1) : '';
        }
        // let permissionMapToText  = that.navPermissions.map(x => x.text);
        // that.editedItem.navPermissions.forEach(function(permission) {
        //   let permissionIndex = permissionMapToText.indexOf(permission);
        //   that.navPermissions[permissionIndex].value.requiredPermissions.forEach(requiredPermission => {
        //     !that.editedItem.permissions.includes(`wavedash.${that.company._id}.${requiredPermission}`) ? that.editedItem.permissions.push(`wavedash.${that.company._id}.${requiredPermission}`) : ''
        //   })
        // })
        let messageName = `${that.editedItem.first_name +
                  " " +
                  that.editedItem.last_name}`
        this.$BackendService.user.update(
          that.editedItem).then(
          function(result) {
            that.$eventBus.$emit('getUsers', function() {
              that.$AlertService.newAlert({
                message: `User updated ${messageName}`,
                timeout: 5,
                show: true,
                color: "success"
              });
              that.close();
            });
          }).catch(
          function(error) {
            console.error(error);
            that.$AlertService.newAlert({
              message: `Error updating user ${messageName}. Check console.`,
              timeout: 5,
              show: true,
              color: "error"
            });
            that.close();
          }
        );
      }
    }
  }
};
</script>

<style scoped>
.row-active td {
  background-color: rgba(115, 223, 139, 0.507);
}
</style>