<template>
  <v-layout row wrap justify-center align-center style="background-color: rgba(0, 255, 0, .1);">
    <v-flex xs12>
      <h1>Form Item Selector</h1>
    </v-flex>
    <v-flex v-for="(formItem, index) in formItems" :key="'formItem-'+index">
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" @click="formItemSelect(formItem)" outlined>
            {{formItem.data.title}}
            <v-icon right>{{formItem.data.icon !== '' ? formItem.data.icon : 'mdi-plus'}}</v-icon>
          </v-btn>
        </template>
        <span>Add a {{formItem.data.title}} to the form.</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      formItems: [
        {
          uuid: null,
          data: {
            category: "select",
            id: "zcc-checkbox",
            title: "Checkbox",
            properties: [],
            type: "boolean",
            icon: "mdi-checkbox-marked-outline",
            settings: {
              value: false,
              label: ""
            }
          },
          settings: {
            tooltip: "bottom"
          }
        },
        {
          uuid: null,
          data: {
            category: "select",
            id: "zcc-choice",
            title: "Choice",
            properties: [],
            type: "string",
            icon: "mdi-arrow-decision",
            settings: {
              value: "",
              label: ""
            }
          },
          settings: {
            tooltip: "bottom"
          }
        },
        {
          uuid: null,
          data: {
            category: "select",
            id: "zcc-select",
            title: "Select",
            properties: [],
            type: "array",
            icon: "mdi-select",
            settings: {
              value: "",
              multiple: false,
              label: ""
            }
          },
          settings: {
            tooltip: "bottom"
          }
        },
        {
          uuid: null,
          data: {
            category: "text",
            id: "zcc-textfield",
            title: "Text Field",
            properties: [],
            type: "string",
            icon: "mdi-text-short",
            settings: {
              value: ""
            }
          },
          settings: {
            tooltip: "bottom"
          }
        },
        {
          uuid: null,
          data: {
            category: "text",
            id: "zcc-textarea",
            title: "Text Area",
            properties: [],
            type: "string",
            icon: "mdi-text",
            settings: {
              value: ""
            }
          },
          settings: {
            tooltip: "bottom"
          }
        },
        {
          uuid: null,
          data: {
            category: "time",
            id: "zcc-date",
            title: "Date",
            properties: [],
            type: "number",
            icon: "mdi-calendar-range",
            settings: {
              value: ""
            }
          },
          settings: {
            tooltip: "bottom"
          }
        }
      ]
    };
  },
  methods: {
    formItemSelect(formItem) {
      let formItemObj = JSON.parse(JSON.stringify(formItem));
      formItemObj.uuid = this.$UUID();
      this.$emit("formItemSelected", formItemObj);
    }
  }
};
</script>

<style scoped>
</style>