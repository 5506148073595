var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.userInfo !== undefined && _vm.$store.state.userInfo.navPermissions !== undefined && _vm.$store.state.userInfo.navPermissions !== null && _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".admin")) || _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".company.onboarding.form.builder")) && _vm.formLayout !== null)?_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{staticStyle:{"background-color":"rgba(0, 0, 0, .1)"},attrs:{"xs12":""}},[_c('h1',[_vm._v(" Form Layout ("+_vm._s(_vm.formLayout.pages.length)+" Step"+_vm._s(_vm.formLayout.pages.length > 1 ? "s" : "")+") ("+_vm._s(_vm.itemsInCurrentPage.length)+" Input"+_vm._s(_vm.itemsInCurrentPage.length > 1 ? "s" : "")+") - Current Step: "+_vm._s(_vm.currentPage)+" ")]),_c('v-layout',{staticStyle:{"margin":"10px 0"},attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('draggable',{staticClass:"flex xs10",attrs:{"list":_vm.formLayout.pages[_vm.currentPage - 1].items,"group":"items"}},_vm._l((_vm.itemsInCurrentPage),function(formItem,index){return _c('div',{key:'form-item-' + index + '-' + formItem.uuid,style:(("position: relative; border: " + (_vm.showFormItemActions[index] ? '1px dashed darkblue' : 'none') + "; margin: 5px 0; background-color: rgba(0, 0, 0, .05);")),on:{"mouseenter":function($event){_vm.showFormItemActions[index] = true;
                _vm.$forceUpdate();},"mouseleave":function($event){_vm.showFormItemActions[index] = false;
                _vm.$forceUpdate();}}},[_c('ZCC-FormItem',{key:'zcc-formItem-' + index + '-' + formItem.uuid,attrs:{"data":formItem.data,"settings":formItem.settings}}),_c('v-expand-transition',[(
                    _vm.showFormItemActions[index] &&
                      formItem.uuid !== _vm.formItemBeingModifiedUUID
                  )?_c('div',{key:'actionButtons-' + index + '-' + formItem.uuid,staticStyle:{"background-color":"rgba(0, 0, 0, .5)"}},[(_vm.showFormItemActions[index])?_c('h4',{key:'formItemTitle-' + index + '-' + formItem.uuid,staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(formItem.data.title)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":"","color":"white"},on:{"click":function($event){$event.stopPropagation();return _vm.modifyFormItem(formItem, index)}}},on),[_c('v-icon',[_vm._v("mdi-settings")])],1)]}}],null,true)},[_vm._v(" Modify the settings of this form item. ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFormItem(index)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" Remove this form item. ")])],1):_vm._e()]),(
                  _vm.modifyingFormItemSettings &&
                    _vm.formItemBeingModified !== null &&
                    formItem.uuid === _vm.formItemBeingModifiedUUID
                )?_c('FormItemSettings',{key:'formItemSettings-' + index + '-' + formItem.uuid,attrs:{"item":_vm.formItemBeingModified},on:{"saveModifiedChange":_vm.saveFormItemModifications,"cancelChange":_vm.cancelFormItemModifications}}):_vm._e()],1)}),0)],1)],1)],1)],1),(_vm.addingFormItem)?_c('FormItemSelector',{on:{"formItemSelected":_vm.confirmAddingFormItem}}):_vm._e(),_c('v-flex',{staticStyle:{"background-color":"rgba(222, 202, 27, 0.15)"},attrs:{"xs12":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.addFormItem()}}},on),[_vm._v(" Input "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus-box")])],1)]}}],null,false,952518748)},[_c('span',[_vm._v("Add a input field to the form.")])])],1),_c('v-flex',{staticStyle:{"background-color":"rgba(222, 202, 27, 0.15)"},attrs:{"xs12":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.addPage()}}},on),[_vm._v(" Step "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus-box")])],1)]}}],null,false,2648629704)},[_c('span',[_vm._v(" Add a step after this step. "),_c('br'),_vm._v(" "+_vm._s(_vm.formLayout.pages.length)+" "),_c('span',{staticStyle:{"color":"rgba(0, 255, 0, 1)"}},[_vm._v("+1")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.addPage(true)}}},on),[_vm._v(" Step (END) "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus-box")])],1)]}}],null,false,2738046640)},[_c('span',[_vm._v(" Add a step to the "),_c('span',[_vm._v("END")]),_vm._v(" of the form. "),_c('br'),_vm._v(" "+_vm._s(_vm.formLayout.pages.length)+" "),_c('span',{staticStyle:{"color":"rgba(0, 255, 0, 1)"}},[_vm._v("+1")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-2",attrs:{"color":"error","disabled":_vm.formLayout.pages.length <= 1},on:{"click":function($event){return _vm.removePage()}}},on),[_vm._v(" Step "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-minus-box")])],1)]}}],null,false,3260354948)},[_c('span',[_vm._v(" Remove this step. "),_c('br'),_vm._v(" "+_vm._s(_vm.formLayout.pages.length)+" "),_c('span',{staticStyle:{"color":"rgba(255, 0, 0, 1)"}},[_vm._v("-1")])])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-pagination',{attrs:{"length":_vm.formLayout.pages.length},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }