<template>
  <v-layout row wrap justify-center align-center>
    <v-flex
      xs12
      v-if="
        form !== undefined &&
          form !== null &&
          form.data !== undefined &&
          form.steps !== undefined
      "
    >
      <v-stepper v-model="form.data.step">
        <v-stepper-header>
          <v-stepper-step
            v-for="(step, index) in form.steps"
            :key="`formStepHeader-${index}`"
            :step="index + 1"
            :complete="form.data.step > index + 1"
            >{{ step.header }}</v-stepper-step
          >
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            v-for="(step, index) in form.steps"
            :step="index + 1"
            :key="`formStepItems-${index}`"
          >
            <ZCC-FormStep :items="step.items" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>

<script>
import ZCC_FormStep from "@/components/Company/Onboarding/Form/ZCC_FormStep.vue";
export default {
  props: ["form"],
  components: {
    "ZCC-FormStep": ZCC_FormStep
  },
  mounted() {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
