var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[(_vm.onboardingData !== undefined && _vm.onboardingData !== null)?_c('v-flex',{staticStyle:{"margin":"0 .8em 2em .8em"},attrs:{"xs11":""}},[_c('v-stepper',{model:{value:(_vm.onboardingData.step),callback:function ($$v) {_vm.$set(_vm.onboardingData, "step", $$v)},expression:"onboardingData.step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.onboardingData.step > 1,"step":"1"}},[_vm._v("Basic User Data")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.onboardingData.step > 2,"step":"2"}},[_vm._v(" "+_vm._s(_vm.onboardingData.employee ? "Employee" : _vm.onboardingData.contractor ? "Contractor" : "Employee or Contractor")+" ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.onboardingData.step > 3,"step":"3"}},[_vm._v("Configuration")]),_c('v-stepper-step',{attrs:{"complete":_vm.onboardingData.step > 4,"step":"4"}},[_vm._v(" "+_vm._s(_vm.onboardingData.contractor ? "Vendor Address" : "Address"))]),_c('v-stepper-step',{attrs:{"complete":_vm.onboardingData.step > 5,"step":"5"}},[_vm._v(" Confirmation ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('Step1',{attrs:{"step":1,"data":_vm.onboardingData,"company":_vm.company,"onboarding_id":_vm.onboarding_id,"countries":_vm.countries,"onboardings":_vm.onboardings},on:{"updateData":_vm.updateData}}),_c('span',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.stepsOkay[_vm.onboardingData.step - 1]},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep()}}},[_vm._v("Continue")])],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('Step2',{attrs:{"step":2,"data":_vm.onboardingData,"company":_vm.company,"onboarding_id":_vm.onboarding_id,"countries":_vm.countries,"onboardings":_vm.onboardings},on:{"updateData":_vm.updateData}}),(
              _vm.onboardingData.flowApprovalStatus === 'Approved' ||
                _vm.onboardingData.flowApprovalStatus === 'Requested'
            )?_c('span',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep()}}},[_vm._v("Continue")])],1):_c('span',[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.stepsOkay[_vm.onboardingData.step - 1]},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep()}}},[_vm._v("Continue")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.goBack()}}},[_vm._v("Go Back")])],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('Step3',{attrs:{"step":3,"data":_vm.onboardingData,"company":_vm.company,"onboarding_id":_vm.onboarding_id,"countries":_vm.countries,"onboardings":_vm.onboardings},on:{"updateData":_vm.updateData}}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.stepsOkay[_vm.onboardingData.step - 1]},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep()}}},[_vm._v("Continue")]),_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.goBack()}}},[_vm._v("Go Back")])],1),_c('v-stepper-content',{attrs:{"step":"5"}},[_c('Step5',{attrs:{"step":5,"data":_vm.onboardingData,"company":_vm.company,"onboarding_id":_vm.onboarding_id,"countries":_vm.countries,"onboardings":_vm.onboardings},on:{"updateData":_vm.updateData}}),(!_vm.$route.path.includes('onboarding-continue'))?_c('v-btn',{attrs:{"color":_vm.startFlowApproval
                ? 'warning'
                : _vm.sentForApproval
                ? 'success'
                : _vm.notSentForApproval
                ? 'error'
                : 'primary',"loading":_vm.startFlowApproval,"disabled":!_vm.stepsOkay[_vm.onboardingData.step - 1] ||
                _vm.sentForApproval ||
                (_vm.onboardingData.flowApprovalStatus === 'Approved' &&
                  !_vm.onboardingData.changeRequest)},on:{"click":function($event){$event.stopPropagation();return _vm.sendForApproval()}}},[_vm._v(" "+_vm._s(_vm.sentForApproval ? "Approval Started" : _vm.notSentForApproval ? "Failed to Start Approval" : _vm.onboardingData.flowApprovalStatus === "Approved" && !_vm.onboardingData.changeRequest ? "Flow Already Approved" : _vm.onboardingData.changeRequest ? "Save and Start Change Request" : "Save and Start Flow Approval")+" "),(_vm.sentForApproval)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [(!_vm.$route.path.includes('onboarding-continue'))?_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"loading":_vm.saving,"color":_vm.saving
                    ? 'warning'
                    : _vm.finishedSave && _vm.saved
                    ? 'success'
                    : _vm.finishedSave && !_vm.saved
                    ? 'error'
                    : 'light-primary',"disabled":_vm.startFlowApproval || _vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                  !_vm.$store.state.userInfo.navPermissions.includes(
                    ((_vm.company._id) + ".admin")
                  )},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Only save to DB "),(!_vm.finishedSave)?_c('v-icon',_vm._g({},on),[_vm._v(" "+_vm._s("mdi-lock" + _vm.$store.state.userInfo.navPermissions.includes( ((_vm.company._id) + ".admin") ) ? "-open" : "")+" ")]):_c('v-icon',[_vm._v("mdi-"+_vm._s(_vm.saved ? "check" : "cross"))])],1):_vm._e()]}}],null,false,3846049749)},[_vm._v(" Admin Permission Bypass ")]),_c('br'),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',{attrs:{"disabled":(_vm.onboardingData.changeRequest ||
                    _vm.onboardingData.flowApprovalStatus !== 'Approved' ||
                    _vm.onboardingData.jiraIssueStatus !== 'Done' ||
                    !_vm.onboardingData.readyForOnboardedUserInput ||
                    (_vm.onboardingData.cognitusEmailMailbox +
                      _vm.onboardingData.cognitusEmailDomain !==
                      _vm.$store.state.userInfo.cognitus_email_address &&
                      _vm.$store.state.userInfo.cognitus_email_address !==
                        _vm.onboardingData.requester)) &&
                    !_vm.$store.state.userInfo.navPermissions.includes(
                      ((_vm.company._id) + ".admin")
                    ),"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep()}}},[_vm._v(" Continue "),(!_vm.$route.path.includes('onboarding-continue'))?_c('v-icon',_vm._g({},on),[_vm._v(" mdi-lock"+_vm._s(_vm.$store.state.userInfo.navPermissions.includes( ((_vm.company._id) + ".admin") ) ? "-open" : "")+" ")]):_vm._e()],1)]}}],null,false,2110412670)},[_vm._v(" Admin Permission Bypass ")]),(!_vm.$route.path.includes('onboarding-continue'))?_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"color":_vm.sentForApproval ? 'warning' : 'error',"disabled":(_vm.onboardingData.flowApprovalStatus === 'Approved' ||
                _vm.onboardingData.flowApprovalStatus === 'Requested' ||
                !_vm.onboardingData.changeRequest) &&
                !_vm.$store.state.userInfo.navPermissions.includes(
                  ((_vm.company._id) + ".admin")
                )},on:{"click":function($event){$event.stopPropagation();return _vm.goBack()}}},[_vm._v("Go Back")]):_vm._e(),(_vm.sentForApproval && _vm.dialog)?_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"color":"error"},on:{"click":function($event){$event.stopPropagation();return (function () { _vm.$emit('closeDialog'); _vm.$emit('updateOnboarding'); }).apply(null, arguments)}}},[_vm._v("Close")]):_vm._e()],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('Step4',{attrs:{"step":4,"data":_vm.onboardingData,"company":_vm.company,"onboarding_id":_vm.onboarding_id,"countries":_vm.countries,"onboardings":_vm.onboardings},on:{"updateData":_vm.updateData}}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.stepsOkay[_vm.onboardingData.step - 1]},on:{"click":function($event){$event.stopPropagation();return _vm.nextStep()}}},[_vm._v("Continue")]),_c('v-btn',{staticStyle:{"margin-left":"1em"},attrs:{"color":"error","disabled":(_vm.onboardingData.flowApprovalStatus === 'Approved' ||
                _vm.onboardingData.flowApprovalStatus === 'Requested') &&
                !_vm.$store.state.userInfo.navPermissions.includes(
                  ((_vm.company._id) + ".admin")
                )},on:{"click":function($event){$event.stopPropagation();return _vm.goBack()}}},[_vm._v("Go Back")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }