<template>
  <v-layout row wrap justify-center align-center v-if="$store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && company !== undefined">
    <v-flex xs12 v-if="$store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.user.setting`)">
      <h1 style="text-align: center;">Company User Settings (needs implementation)</h1>
      <br />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ['company']
};
</script>

<style lang="scss" scoped>
</style>