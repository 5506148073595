<template>
  <v-flex xs11>
    <v-layout style="margin: 2em 0;" justify-center align-center row wrap>
      <v-flex xs11 style="margin-left: .67em;">
        <v-layout row wrap justify-center align-center style="margin: 0;">
          <user-manage
            v-if="path === 'manage'"
            :users="users"
            :user_id="user_id"
            :company="company"
            :companyNavPermissions="companyNavPermissions"
          />
          <user-invite v-else-if="path === 'invite'" :companyNavPermissions="companyNavPermissions" :company="company" />
          <user-history v-else-if="path === 'history'" :company="company" :history="userHistory" />
          <user-settings v-else-if="path === 'settings'" :company="company" />
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import UserManage from "@/components/Company/User/UserManage.vue";
import UserInvite from "@/components/Company/User/UserInvite.vue";
import UserHistory from "@/components/Company/User/UserHistory.vue";
import UserSettings from "@/components/Company/User/UserSettings.vue";
export default {
  components: {
    UserManage,
    UserInvite,
    UserHistory,
    UserSettings
  },
  props: ["path", "user_id", "users", "company", "companyNavPermissions"],
  data() {
    return {};
  },
  mounted() {
    var that = this;
    this.$eventBus.$emit("getUsers", { data: {
                  company_id: this.company._id
                }});
  },
  computed: {
    userHistory() {
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>