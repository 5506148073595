<template>
  <v-flex
    xs11
    v-if="$store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && company !== undefined && ($store.state.userInfo.navPermissions.includes(`${company._id}.company.history`) || $store.state.userInfo.navPermissions.includes(`${company._id}.admin`))"
  >
    <div v-if="company.history !== undefined && company.history !== null">
      <h1 style="text-align: center;">Company History</h1>
      <br />
    </div>
    <div v-else>
      <h1 style="text-align: center;">No Company History (needs implementation)</h1>
    </div>
  </v-flex>
</template>

<script>
export default {
  props: ["company"]
};
</script>

<style lang="scss" scoped>
</style>