<template>
  <v-select
    :items="settings.items"
    :label="settings.label"
    :multiple="settings.multiple"
    :value="settings.value"
  ></v-select>
</template>

<script>
export default {
  props: ["settings"],
  mounted() {}
};
</script>

<style scoped>
</style>