module.exports = function(pLength) {
    pLength = pLength ? pLength : 7
    var keyListAlpha="ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        keyListAlphaLower="abcdefghijklmnopqrstuvwxyz",
        keyListInt="123456789",
        password='';
    password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
    let lastIndex = -1;
    for (i = 1; i < 4; i++) {
        let index = lastIndex;
        while (index == lastIndex) {
            index = Math.floor(Math.random() * keyListAlphaLower.length);
        }
        lastIndex = index;
        password+=keyListAlphaLower.charAt(index);
    }
    lastIndex = -1;
    for (i = 4; i < 8; i++) {
        let index = lastIndex
        while (index == lastIndex) {
            index = Math.floor(Math.random() * keyListInt.length);
        }
        lastIndex = index;
        password += keyListInt.charAt(index);
    }

    return password;
}