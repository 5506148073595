<template>
  <v-layout justify-center align-center row wrap style="margin: 1em 0;">

    <v-flex xs10 v-if="onboardingFormDataCopy !== null">
      <v-text-field
        label="First Name"
        v-model="onboardingFormDataCopy.firstName"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        :success="!(onboardingFormDataCopy.firstName === '' || onboardingFormDataCopy.firstName === undefined || onboardingFormDataCopy.firstName === null)"
        :error="onboardingFormDataCopy.firstName === '' || onboardingFormDataCopy.firstName === undefined || onboardingFormDataCopy.firstName === null"
      ></v-text-field>
      <v-text-field
        label="Last Name"
        v-model="onboardingFormDataCopy.lastName"
        :disabled="
              !onboardingFormDataCopy.changeRequest &&
              (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                onboardingFormDataCopy.flowApprovalStatus === 'Approved')
            "
        :success="!(onboardingFormDataCopy.lastName === '' || onboardingFormDataCopy.lastName === undefined || onboardingFormDataCopy.lastName === null)"
        :error="onboardingFormDataCopy.lastName === '' || onboardingFormDataCopy.lastName === undefined || onboardingFormDataCopy.lastName === null"
      ></v-text-field>
      <v-text-field
        label="Job Title"
        v-model="onboardingFormDataCopy.jobTitle"
        :disabled="
              !onboardingFormDataCopy.changeRequest &&
              (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
                onboardingFormDataCopy.flowApprovalStatus === 'Approved')
            "
        :success="!(onboardingFormDataCopy.jobTitle === '' || onboardingFormDataCopy.jobTitle === undefined || onboardingFormDataCopy.jobTitle === null)"
        :error="onboardingFormDataCopy.jobTitle === '' || onboardingFormDataCopy.jobTitle === undefined || onboardingFormDataCopy.jobTitle === null"
      ></v-text-field>
      <v-autocomplete
        :items="company.settings[company.settings.indexOf(company.settings.filter(setting => setting.label === 'Region')[0])].items"
        v-model="onboardingFormDataCopy.region"
        label="Region"
        :success="!(onboardingFormDataCopy.region === undefined || onboardingFormDataCopy.region === null || onboardingFormDataCopy.region === '')"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        :error="onboardingFormDataCopy.region === undefined || onboardingFormDataCopy.region === null || onboardingFormDataCopy.region === ''"
      ></v-autocomplete>
      <!-- <v-autocomplete
        :items="company.settings[company.settings.indexOf(company.settings.filter(setting => setting.label === 'Country')[0])].items"
        v-model="onboardingFormDataCopy.country"
        label="Country"
        :success="!(onboardingFormDataCopy.country === undefined || onboardingFormDataCopy.country === null || onboardingFormDataCopy.country === '')"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        :error="onboardingFormDataCopy.country === undefined || onboardingFormDataCopy.country === null || onboardingFormDataCopy.country === ''"
      ></v-autocomplete>-->
      <v-text-field
        type="email"
        label="Personal Email Address"
        v-model="onboardingFormDataCopy.personalEmailAddress"
        :error-messages="
          `${personalEmailAddressUnavailable ? 'Already Exists' : !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(onboardingFormDataCopy.personalEmailAddress)) ? 'Please enter a valid email address' : ''}`
        "
        :error="personalEmailAddressUnavailable || onboardingFormDataCopy.personalEmailAddress === '' || onboardingFormDataCopy.personalEmailAddress === undefined || onboardingFormDataCopy.personalEmailAddress === null || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(onboardingFormDataCopy.personalEmailAddress))"
        :success="onboardingFormDataCopy.personalEmailAddress !== '' && onboardingFormDataCopy.personalEmailAddress !== null && !personalEmailAddressUnavailable && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(onboardingFormDataCopy.personalEmailAddress))"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      ></v-text-field>
      <v-text-field
        @focus="() => {
          cognitusEmailMailboxHasntBeenTouched = false
        }"
        style="width: 100%"
        label="Cognitus User Mailbox"
        v-model="onboardingFormDataCopy.cognitusEmailMailbox"
        :success="onboardingFormDataCopy.cognitusEmailMailbox !== '' && onboardingFormDataCopy.cognitusEmailMailbox !== null && !cognitusEmailMailboxUnavailable"
        :error-messages="
          `${cognitusEmailMailboxUnavailable ? 'Already Exists' : invalidEmailAddress ? 'Invalid Email Address (a-z A-Z 0-9)' : invalidEmailLength ? 'Length must be less than or equal to 12' : ''}`
        "
        @keydown="cognitusUserMailboxKeydown"
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
        :error="invalidEmailLength || invalidEmailAddress || cognitusEmailMailboxUnavailable || onboardingFormDataCopy.cognitusEmailMailbox === '' || onboardingFormDataCopy.cognitusEmailMailbox === undefined || onboardingFormDataCopy.cognitusEmailMailbox === null"
      >
        <template v-slot:append-outer>
          <v-autocomplete
            ref='cognitusUserMailDomain'
            style="position: relative; top: -1.25em;"
            :items="company.settings[company.settings.indexOf(company.settings.filter(setting => setting.label === 'Email Domain')[0])].items.map(item => {
              if (item.value !== '@cognitus.com') {
                item.disabled = true;
              }
              return item;
            })"
            label="Mailbox Domain"
            :messages="
              `${invalidEmailDomain ? 'Outdated email address, please use @cognitus.com' : ''}`
            "
            :success="!(onboardingFormDataCopy.cognitusEmailDomain === undefined || onboardingFormDataCopy.cognitusEmailDomain === null || onboardingFormDataCopy.cognitusEmailDomain === '')"
            v-model="onboardingFormDataCopy.cognitusEmailDomain"
            :error="onboardingFormDataCopy.cognitusEmailDomain === undefined || onboardingFormDataCopy.cognitusEmailDomain === null || onboardingFormDataCopy.cognitusEmailDomain === ''"
            :disabled="
              !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
            "
          ></v-autocomplete>
        </template>
      </v-text-field>
      <v-text-field
        v-if="onboardingFormDataCopy.mobilePhone !== undefined"
        label="Mobile Phone"
        @keyup="checkPhoneValidity"
        v-model="onboardingFormDataCopy.mobilePhone.number"
        :error="(!checkingPhoneValidity && onboardingFormDataCopy.mobilePhone.number !== '' && !isValidPhone) || onboardingFormDataCopy.mobilePhone.number === '' || onboardingFormDataCopy.mobilePhone.number === undefined || onboardingFormDataCopy.mobilePhone.number === ''"
        :success="!checkingPhoneValidity && isValidPhone"
      >
        <template v-slot:append>
          <v-progress-circular v-if="checkingPhoneValidity" color="warning" size="24" indeterminate></v-progress-circular>
          <v-icon
            v-else-if="onboardingFormDataCopy.mobilePhone.number !== '' && isValidPhone"
            color="success"
          >mdi-check</v-icon>
          <v-icon
            v-else-if="onboardingFormDataCopy.mobilePhone.number !== '' && !isValidPhone"
            color="error"
          >mdi-close</v-icon>
        </template>
        <template v-slot:prepend-inner>{{onboardingFormDataCopy.mobilePhone.countryCode}}</template>
        <template v-slot:prepend>
          <v-autocomplete
            :success="isValidPhone"
            label="Country Code"
            style="position: relative; top: -1.25em;"
            v-model="onboardingFormDataCopy.mobilePhone.countryCodeAndCountry"
            :items="countryCodes"
            :filter="countryCodeFilter"
            :error="onboardingFormDataCopy.mobilePhone.countryCodeAndCountry === undefined || onboardingFormDataCopy.mobilePhone.countryCodeAndCountry === null || onboardingFormDataCopy.mobilePhone.countryCodeAndCountry === ''"
          >
            <template
              v-slot:selection="{item}"
            >{{item.text + " (" + item.value.split('-')[1] + ")"}}</template>
            <template v-slot:item="{item}">
              <table :key="`item-${item.text}-${item.value}`">
                <tr>
                  <td>{{item.text + " (" + item.value.split('-')[1] + ")"}}</td>
                  <td>
                    <v-img
                      style="height: 24px; width:24px;"
                      contain
                      :src="require(`@/assets/flags/${item.code}.png`)"
                    ></v-img>
                  </td>
                </tr>
              </table>
            </template>
          </v-autocomplete>
        </template>
      </v-text-field>
      <!-- <vue-tel-input validCharactersOnly required v-model="onboardingFormDataCopy.mobilePhone.number" @country-changed="countrySelected">
        <template v-slot:arrow-icon>
          <strong>+{{onboardingFormDataCopy.mobilePhone.countryCode}}</strong>
        </template>
      </vue-tel-input>-->
      <br />
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: [
    "data",
    "onboardings",
    "company",
    "countries",
    "onboarding_id",
    "step"
  ],
  data() {
    return {
      checkPhoneValidityTimeout: null,
      isValid: false,
      checkingPhoneValidity: false,
      onboardingFormDataCopy: null,
      invalidEmailAddress: false,
      invalidEmailLength: false,
      invalidEmailDomain: false,
      cognitusEmailMailboxUnavailable: false,
      personalEmailAddressUnavailable: false,
      autoEmailMailboxTimeout: null,
      cognitusEmailMailboxHasntBeenTouched: true,
      isStepping: false
    };
  },
  computed: {
    isValidPhone() {
      return this.isValid;
    },

    countryCodes() {
      let codesMap = this.$countrycodes.countries.filter(c => c.callingCodes.length > 0).map(function(c) {
        return {
          text: c.name,
          code: c.alpha2Code,
          data: c,
          value: c.alpha2Code + "-+" + c.callingCodes[0]
        };
      });
      return codesMap;
    }
  },
  created() {
    var that = this;
    this.onboardingFormDataCopy = JSON.parse(JSON.stringify(that.data));
    this.onboardingFormDataCopy.date_time_onboarded = Date.now();
    this.$eventBus.$on("nextStep", function(currentStep) {
      if (currentStep === that.step && !that.isStepping) {
        that.isStepping = true;
        that.$emit(
          "updateData",
          that.getOnboardingFormDataCopy(),
          that.step,
          true,
          function() {
            that.isStepping = false;
          }
        );
      }
    });
    if (
      this.onboardingFormDataCopy.onboarding_id === "" ||
      this.onboardingFormDataCopy.onboarding_id === null
    ) {
      this.onboardingFormDataCopy.onboarding_id = this.$UUID();
    }
    if (this.onboardingFormDataCopy.mobilePhone === undefined) {
      this.onboardingFormDataCopy.mobilePhone = {
        number: "",
        countryCode: "",
        countryCodeAndCountry: "",
        e164: ""
      };
      that.$emit(
        "updateData",
        that.getOnboardingFormDataCopy(),
        that.step,
        false
      );
    }
  },
  mounted() {
    var that = this;
    this.checkStepOkay();
  },
  methods: {
    cognitusUserMailboxKeydown(e) {
      if (e.key === '@') {
        this.$refs.cognitusUserMailDomain.focus();
        this.onboardingFormDataCopy.cognitusEmailMailbox = this.onboardingFormDataCopy.cognitusEmailMailbox.replace('@', '');
      }
    },
    countryCodeFilter(item, queryText, itemText) {
      console.log(item.data)
      let possibleStrings = [item.data.alpha2Code, item.data.alpha3Code, ...item.data.altSpellings, item.data.name, ...[...item.data.callingCodes].map(code => "+"+code), ...item.data.callingCodes];
      if (possibleStrings.includes(queryText) || possibleStrings.some(str => str.startsOrEndsWith(queryText) || str.toLowerCase().startsOrEndsWith(queryText))) {
        return true;
      }
      return false;
    },
    mobilePhoneFormated(phone) {
      return phone.format("E.164");
    },
    checkPhoneValidity() {
      let that = this;
      let phone = null;
      if (that.checkPhoneValidityTimeout) {
        clearTimeout(that.checkPhoneValidityTimeout);
      }
      that.checkPhoneValidityTimeout = setTimeout(function() {
        that.checkingPhoneValidity = false;
        phone = that.$phonenumbers.parsePhoneNumberFromString(
          that.onboardingFormDataCopy.mobilePhone.countryCode +
            "" +
            that.onboardingFormDataCopy.mobilePhone.number
        );
        if (phone !== undefined && phone !== null) {
          if (phone.isValid()) {
            that.isValid = true;
            that.onboardingFormDataCopy.mobilePhone.e164 = that.mobilePhoneFormated(
              phone
            );
            that.onboardingFormDataCopy.usageLocation = that.onboardingFormDataCopy.mobilePhone.countryCodeAndCountry.split("-")[0]
            
          } else {
            that.isValid = false;
            that.onboardingFormDataCopy.mobilePhone.e164 = "";
            that.onboardingFormDataCopy.usageLocation = "";
          }
        }
      }, 1500);
      that.checkingPhoneValidity = true;
    },
    checkStepOkay() {
      var that = this;
      var okay =
        this.onboardingFormDataCopy.firstName !== "" &&
        this.onboardingFormDataCopy.firstName !== null &&
        this.onboardingFormDataCopy.lastName !== "" &&
        this.onboardingFormDataCopy.lastName !== null &&
        this.onboardingFormDataCopy.jobTitle !== undefined &&
        this.onboardingFormDataCopy.jobTitle !== null &&
        this.onboardingFormDataCopy.jobTitle !== "" &&
        this.onboardingFormDataCopy.personalEmailAddress !== "" &&
        this.onboardingFormDataCopy.personalEmailAddress !== null &&
        this.onboardingFormDataCopy.cognitusEmailMailbox !== "" &&
        this.onboardingFormDataCopy.cognitusEmailMailbox !== null &&
        this.onboardingFormDataCopy.cognitusEmailDomain !== "" &&
        this.onboardingFormDataCopy.cognitusEmailDomain !== null &&
        // this.onboardingFormDataCopy.country !== "" && this.onboardingFormDataCopy.country !== null &&
        this.onboardingFormDataCopy.region !== "" &&
        this.onboardingFormDataCopy.region !== null &&
        that.isValid &&
        !this.cognitusEmailMailboxUnavailable &&
        !this.personalEmailAddressUnavailable &&
        !this.invalidEmailAddress &&
        !this.invalidEmailLength
      that.$eventBus.$emit("stepOkay", {
        step: that.step,
        valid: okay
      });
    },
    getOnboardingFormDataCopy() {
      return this.onboardingFormDataCopy;
    },
    resetInitialFields() {
      this.onboardingFormDataCopy.firstName = "";
      this.onboardingFormDataCopy.lastName = "";
      this.onboardingFormDataCopy.personalEmailAddress = "";
      this.onboardingFormDataCopy.cognitusEmailMailbox = "";
      this.onboardingFormDataCopy.cognitusEmailDomain = "";
      this.onboardingFormDataCopy.region = "";
      // this.onboardingFormDataCopy.country = "";
      this.onboardingFormDataCopy.mobilePhone = {
        e164: "",
        number: "",
        countryCode: "",
        countryCodeAndCountry: ""
      };
      this.onboardingFormDataCopy.jobTitle = "";
    }
  },
  watch: {
    "onboardingFormDataCopy.mobilePhone.countryCodeAndCountry"(val) {
      if (
        val !== undefined &&
        val !== null &&
        this.onboardingFormDataCopy.mobilePhone !== undefined
      ) {
        this.onboardingFormDataCopy.mobilePhone.countryCode = val.split("-")[1];
        this.onboardingFormDataCopy.usageLocation = val.split("-")[0];
        this.checkPhoneValidity();
      }
    },
    isValid: {
      handler(val) {
        if (val !== undefined && val !== null) {
          this.checkStepOkay();
        }
      }
    },
    data: {
      deep: true,
      handler(val) {
        if (val) {
          this.onboardingFormDataCopy = JSON.parse(JSON.stringify(val));
          this.checkStepOkay();
          this.$forceUpdate();
        }
      }
    },
    "onboardingFormDataCopy.jobTitle"(val) {
      if (val !== undefined && val !== null) {
        this.onboardingFormDataCopy.jobTitle = val.trim();
        this.checkStepOkay();
      }
    },
    "onboardingFormDataCopy.firstName"(val) {
      if (val !== undefined && val !== null) {
        this.onboardingFormDataCopy.firstName = val.trim();
        this.checkStepOkay();
      }
    },
    "onboardingFormDataCopy.lastName"(val, oldVal) {
      if (val !== undefined && val !== null && val !== oldVal) {
        if (!this.onboardingFormDataCopy.changeRequest) {
          if (this.autoEmailMailboxTimeout !== null) {
            clearTimeout(this.autoEmailMailboxTimeout)
          }
          this.autoEmailMailboxTimeout = setTimeout(() => {
            this.onboardingFormDataCopy.cognitusEmailMailbox = (this.onboardingFormDataCopy.firstName[0].toLowerCase().trim() + val.toLowerCase().trim().split(" ").join("")).substring(0, 12)
            clearTimeout(this.autoEmailMailboxTimeout)
          }, 500)
          this.onboardingFormDataCopy.lastName = this.onboardingFormDataCopy.lastName.trim();
        }
        this.checkStepOkay();
      }
    },
    "onboardingFormDataCopy.region"(val) {
      if (val !== undefined && val !== null) {
        this.checkStepOkay();
      }
    },
    "onboardingFormDataCopy.cognitusEmailDomain"(val) {
      if (val !== undefined && val !== null) {
        if (val !== '@cognitus.com') {
          this.invalidEmailDomain = true;
        } else {
          this.invalidEmailDomain = false;
        }
        this.checkStepOkay();
      }
      
    },
    "onboardingFormDataCopy.cognitusEmailMailbox"(val) {
      var that = this;
      
      // check valid characters of email mailbox
      if (/^([a-zA-Z0-9]+)$/.test(val)) {
        this.invalidEmailAddress = false;
      } else {
        this.invalidEmailAddress = true;
      }

      // check mailbox for 12 characters max
      if (!that.onboardingFormDataCopy.changeRequest && val.length > 12) {
        that.invalidEmailLength = true;
      } else {
        that.invalidEmailLength = false;
      }

      // check if the mailbox is already used by another onboarding
      if (
        this.onboardings !== undefined &&
        this.onboardings.filter(row => {
          return (
            row.cognitusEmailMailbox.toLowerCase() === val.trim().toLowerCase() &&
            that.onboardingFormDataCopy.onboarding_id !== row._id
          );
        }).length > 0
      ) {
        this.cognitusEmailMailboxUnavailable = true;
      } else {
        this.cognitusEmailMailboxUnavailable = false;
      }

      this.checkStepOkay();
    },
    "onboardingFormDataCopy.personalEmailAddress"(val) {
      var that = this;
      if (
        this.onboardings !== undefined &&
        this.onboardings.filter(row => {
          return (
            row.personalEmailAddress.toLowerCase() === val.trim().toLowerCase() &&
            that.onboardingFormDataCopy.onboarding_id !== row._id
          );
        }).length > 0
      ) {
        this.personalEmailAddressUnavailable = true;
      } else {
        this.personalEmailAddressUnavailable = false;
      }
      this.onboardingFormDataCopy.personalEmailAddress = val.trim()
      this.checkStepOkay();
    }
  }
};
</script>

<style lang="scss" scoped></style>
