<template>
  <v-layout
    row
    wrap
    justify-center
    align-center
    v-if="company !== undefined && $store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null"
  >
    <v-flex xs10>
      <h1 style="text-align: center;">Company Onboarding Legacy (Sharepoint)</h1>
      <br />
    </v-flex>
    <v-flex
      xs10
      v-if="$store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.onboarding.legacy`)"
    >
      <!-- <span v-if="legacyOnboardings.length > 0">{{Object.keys(legacyOnboardings[209])}}</span> -->
      <v-data-table
        :headers="headers"
        :search="search"
        class="elevation-1"
        multi-sort
        :items="legacyOnboardings"
        :loading="loadingLegacyOnboardings"
        show-select
        v-model="selectedItems"
        item-key="ID"
      >
        <template v-slot:top>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="warning"
                v-on="on"
                icon
                style="margin: 0 auto;"
                @click="getLegacyOnboardings()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            Refresh Legacy Onboardings
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="warning"
                v-on="on"
                icon
                style="margin: 0 auto;"
                @click="convertMultiple(selectedItems)"
              >
                <v-icon>mdi-account-convert</v-icon>
              </v-btn>
            </template>
            Convert Selected ({{ selectedItems.length }})
          </v-tooltip>
          <span v-if="loadingLegacyOnboardingsError">
            <h4 style="color: red;">
              There was an error in retrieving legacy onboardings from
              sharepoint.
            </h4>
          </span>
          <v-text-field
            style="margin: 0em 2em"
            type="text"
            v-model="search"
            label="Search Legacy Onboardings"
          />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mr-1" @click="convert(item)">mdi-account-convert</v-icon>
            </template>
            Convert to Surfboard
          </v-tooltip>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["company", "formTemplate", "onboardings"],
  data() {
    return {
      selectedItems: [],
      loadingLegacyOnboardingsError: false,
      loadingLegacyOnboardings: false,
      legacyOnboardings: [],
      search: "",
      headers: [
        {
          text: "Date Requested",
          value: "Created"
        },
        {
          text: "Account Creation Status",
          value: "Account_x0020_Creation_x0020_Sta"
        },
        {
          text: "First Name",
          value: "First_x0020_Name"
        },
        {
          text: "Last Name",
          value: "Last_x0020_Name"
        },
        {
          text: "Personal Email Address",
          value: "Email_x0020_Address"
        },
        {
          text: "Cognitus Email",
          value: "E_x002d_mail_x0020_Address_x0020"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ]
    };
  },
  methods: {
    convertMultiple(items) {
      let that = this;
      let successCounter = 0;
      let errorCounter = 0;
      items.forEach((item, index) =>
        that.convert(
          item,
          index,
          items.length - 1,
          () => {
            successCounter++;
          },
          () => {
            errorCounter++;
          },
          () => {
            if (successCounter > 0) {
              that.$AlertService.newAlert({
                message: `Converted ${successCounter} Onboardings into Surfboard`,
                show: true,
                color: "success",
                timeout: 5
              });
            }
            if (errorCounter > 0) {
              that.$AlertService.newAlert({
                message: `Error in converting ${errorCounter} Onboardings into Surfboard`,
                show: true,
                color: "error",
                timeout: 5
              });
            }
          }
        )
      );
    },
    convert(
      item,
      index = -1,
      maxIndex = -1,
      convertCallback = undefined,
      convertErrorCallback = undefined,
      finishedCallback = undefined
    ) {
      let that = this;
      let itemTemplate = JSON.parse(JSON.stringify(this.formTemplate));
      itemTemplate.firstName = item.First_x0020_Name;
      itemTemplate.lastName = item.Last_x0020_Name;
      itemTemplate.personalEmailAddress = item.Email_x0020_Address;
      if (item.E_x002d_mail_x0020_Address_x0020 !== undefined) {
        itemTemplate.cognitusEmailMailbox = item.E_x002d_mail_x0020_Address_x0020.split(
          "@"
        )[0];
        itemTemplate.cognitusEmailDomain =
          "@" + item.E_x002d_mail_x0020_Address_x0020.split("@")[1];
      }
      itemTemplate.employee = item.Employee_x0020_or_x0020_Contract.includes(
        "Employee"
      )
        ? true
        : false;
      itemTemplate.contractor = item.Employee_x0020_or_x0020_Contract.includes(
        "Contractor"
      )
        ? true
        : false;
      // itemTemplate.jiraCheckbox = false;
      itemTemplate.travelExpenses = item.Travel_x0020_Expenses || false;
      itemTemplate.startDate = item.Start_x0020_Date;
      itemTemplate.contingentWorker = false;
      itemTemplate.costRate = item.Cost_x0020_Rate;
      itemTemplate.billRate = item.Bill_x0020_Rate;
      itemTemplate.costCenter = item.FSP_x0020__x002d__x0020_Cost_x00;
      itemTemplate.region = "";
      itemTemplate.employee &&
      item.System_x0020_Access.includes("FSS - Formosa S/4HANA Sandbox")
        ? itemTemplate.otherSystem.push("FSS")
        : "";
      itemTemplate.employee
        ? !itemTemplate.otherSystem.includes("FSP/SuccessFactors")
          ? itemTemplate.otherSystem.push("FSP/SuccessFactors")
          : ""
        : "";
      itemTemplate.laptop = item.Laptop_x0020_Needed || false;
      itemTemplate.otherSystem = item.System_x0020_Access || [];
      itemTemplate.history = [];
      itemTemplate.changeRequest = false;
      itemTemplate.onboarding_id = this.$UUID();
      itemTemplate.requester = item.Submitted_x0020_By || "";
      itemTemplate.company_id = this.company._id;
      itemTemplate.vendorAddress = {
        streetAddress: item.VendorAddress || "",
        streetAddress2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
      };
      itemTemplate.jobTitle = item.Job_x0020_Position;
      itemTemplate.date_time_onboarded = Date.parse(item.Created);
      itemTemplate.convertedFromSharepoint = true;
      that.$BackendService.onboarding
        .new(itemTemplate)
        .then(function(response) {
          that.legacyOnboardings.splice(
            that.legacyOnboardings.indexOf(
              that.legacyOnboardings.filter(function(l) {
                return l.ID === item.ID;
              })[0]
            ),
            1
          );
          that.$forceUpdate();
          if (index === -1) {
            that.$AlertService.newAlert({
              message:
                "Converted " +
                itemTemplate.firstName +
                " " +
                itemTemplate.lastName +
                " to Surfboard.",
              color: "success",
              timeout: 5,
              show: true
            });
          } else if (index === maxIndex) {
            finishedCallback();
          } else {
            if (convertCallback !== undefined) {
              convertCallback();
            }
          }
        })
        .catch(function(error) {
          if (convertErrorCallback !== undefined) {
            convertErrorCallback();
          }
          console.error(error);
        });
    },
    getLegacyOnboardings() {
      let that = this;
      this.loadingLegacyOnboardings = true;
      this.$BackendService.onboarding
        .legacy({ company: that.company._id })
        .then(function(response) {
          that.legacyOnboardings = response.filter(legacyOnboarding => {
            if (
              legacyOnboarding.E_x002d_mail_x0020_Address_x0020 !== undefined
            ) {
              if (
                that.onboardings !== undefined &&
                !that.onboardings.some(function(onboarding) {
                  return (
                    onboarding.cognitusEmailMailbox ===
                    legacyOnboarding.E_x002d_mail_x0020_Address_x0020.split(
                      "@"
                    )[0]
                  );
                })
              ) {
                return true;
              }
            }
            return false;
          });
          that.loadingLegacyOnboardings = false;
          that.loadingLegacyOnboardingsError = false;
        })
        .catch(function(error) {
          that.loadingLegacyOnboardingsError = true;
          console.error(error);
        });
    }
  },
  mounted() {
    this.getLegacyOnboardings();
  }
};
</script>

<style scoped></style>
