<template>
  <v-checkbox :value="settings.value" :label="settings.label"></v-checkbox>
</template>

<script>
export default {
  props: ["settings"]
};
</script>

<style lang="scss" scoped>
</style>