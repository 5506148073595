import store from '../store/index.js';
import BackendService from './backend.service.js';
let ProjectRateCardService = {
    updateProjectRateCard: function(data) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.projectRateCard.update(data).then(function (updatedProjectRateCard) {
                  resolve(updatedProjectRateCard);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    deleteProjectRateCard: function(data) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.projectRateCard.delete(data).then(function (deletedProjectRateCard) {
                  resolve(deletedProjectRateCard);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    createProjectRateCard: function(data) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.projectRateCard.create(data).then(function (createdProjectRateCard) {
                  resolve(createdProjectRateCard);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    getProjectRateCards: function ({company_id}) {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.projectRateCard.getAll({
                    company_id: company_id
                }).then(function (projectRateCards) {
                    store.dispatch("SET_PROJECT_RATE_CARDS", projectRateCards.docs);
                    resolve(projectRateCards);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }

        })
    },
    getProjectRateCard: function ({company_id, project_id, project_rate_card_id}) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.projectRateCard.get({
                  company_id: company_id,
                  project_id: project_id,
                  _id: project_rate_card_id
              }).then(function (projectRateCard) {
                  if (projectRateCard !== null) {
                      store.dispatch("SET_PROJECT_RATE_CARD", projectRateCard)
                  }
                  resolve(projectRateCard);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    },
    searchProjectRateCard: function ({company_id, project_id, searchQuery}) {
      return new Promise(function (resolve, reject) {
          if (store.state.userADInfo !== null) {
              BackendService.projectRateCard.search({
                  company_id: company_id,
                  project_id: project_id,
                  searchQuery: searchQuery
              }).then(function (projectRateCards) {
                  if (projectRateCards !== null) {
                      store.dispatch("UPDATE_PROJECT_RATE_CARDS", projectRateCards)
                  }
                  resolve(projectRateCards);
              }).catch(function (error) {
                  reject(error);
              })
          } else {
              reject(new Error("userADInfo null"));
          }
      })
    }
}
export default ProjectRateCardService;