<template>
  <v-layout
    style="margin: 1em 0;"
    justify-center
    align-center
    row
    wrap
    v-if="onboardingFormDataCopy !== null"
  >
    <v-flex xs10 v-if="onboardingFormDataCopy.forms !== undefined && onboardingFormDataCopy.forms.required !== undefined">
      <h3>PDF Signing</h3>
      <span v-if="onboardingFormDataCopy.forms.required.length > 0">
        <span v-if="onboardingFormDataCopy.forms.required.includes('w9')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-if="!signingW9" v-on="on" outlined @click="signW9()"
                >Sign W9</v-btn
              >
              <v-btn v-else v-on="on" color="success" outlined @click="saveW9()"
                >Save W9</v-btn
              >
            </template>
            {{
              signingW9
                ? "Save the changes you've made to the W9 form."
                : "You can use this link to sign your W9 form."
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.required.includes('ach')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-if="!signingACH"
                v-on="on"
                outlined
                @click="signACH()"
                >Sign ACH</v-btn
              >
              <v-btn
                style="margin-left: 1em;"
                v-else
                v-on="on"
                color="success"
                outlined
                @click="saveACH()"
                >Save ACH</v-btn
              >
            </template>
            {{
              signingACH
                ? "Save the changes you've made to the ACH form."
                : "You can use this link to sign your ACH form."
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.required.includes('nda')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-if="!signingNDA"
                v-on="on"
                outlined
                @click="signNDA()"
                >Sign NDA</v-btn
              >
              <v-btn
                style="margin-left: 1em;"
                v-else
                v-on="on"
                color="success"
                outlined
                @click="saveNDA()"
                >Save NDA</v-btn
              >
            </template>
            {{
              signingNDA
                ? "Save the changes you've made to the NDA form."
                : "You can use this link to sign your NDA form."
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.required.includes('msa')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-if="!signingMSA"
                v-on="on"
                outlined
                @click="signMSA()"
                >Sign MSA</v-btn
              >
              <v-btn
                style="margin-left: 1em;"
                v-else
                v-on="on"
                color="success"
                outlined
                @click="saveMSA()"
                >Save MSA</v-btn
              >
            </template>
            {{
              signingMSA
                ? "Save the changes you've made to the MSA form."
                : "You can use this link to sign your MSA form."
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.required.includes('sow')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-if="!signingSoW"
                v-on="on"
                outlined
                @click="signSoW()"
                >Sign SoW</v-btn
              >
              <v-btn
                style="margin-left: 1em;"
                v-else
                v-on="on"
                color="success"
                outlined
                @click="saveSoW()"
                >Save SoW</v-btn
              >
            </template>
            {{
              signingSoW
                ? "Save the changes you've made to the SoW form."
                : "You can use this link to sign your SoW form."
            }}
          </v-tooltip>
        </span>
      </span>
      <span v-if="onboardingFormDataCopy.forms.completed.length > 0">
        <span v-if="onboardingFormDataCopy.forms.completed.includes('w9')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-if="!signingW9" v-on="on" outlined @click="signW9()"
                >Sign W9</v-btn
              >
              <v-btn v-else v-on="on" color="success" outlined @click="saveW9()"
                >Save W9</v-btn
              >
            </template>
            {{
              signingW9
                ? "Save the changes you've made to the W9 form."
                : "You can use this link to sign your W9 form."
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.completed.includes('ach')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-if="!signingACH"
                v-on="on"
                outlined
                @click="signACH()"
                >Sign ACH</v-btn
              >
              <v-btn
                style="margin-left: 1em;"
                v-else
                v-on="on"
                color="success"
                outlined
                @click="saveACH()"
                >Save ACH</v-btn
              >
            </template>
            {{
              signingACH
                ? "Save the changes you've made to the ACH form."
                : "You can use this link to sign your ACH form."
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.completed.includes('nda')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-if="!signingNDA"
                v-on="on"
                outlined
                @click="signNDA()"
                >Download NDA (.pdf)</v-btn
              >
            </template>
            {{ "Download NDA (.pdf)" }}
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-on="on"
                color="success"
                outlined
                @click="emailNDA()"
                >Email NDA</v-btn
              >
            </template>
            {{
              "Send NDA to " +
                onboardingFormDataCopy.cognitusEmailMailbox +
                onboardingFormDataCopy.cognitusEmailDomain
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.completed.includes('msa')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-on="on"
                outlined
                @click="downloadMSA()"
                >Download MSA (.pdf)</v-btn
              >
            </template>
            {{ "Download MSA (.pdf)" }}
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-on="on"
                color="success"
                outlined
                @click="emailMSA()"
                >Email MSA</v-btn
              >
            </template>
            {{
              "Send MSA to " +
                onboardingFormDataCopy.cognitusEmailMailbox +
                onboardingFormDataCopy.cognitusEmailDomain
            }}
          </v-tooltip>
        </span>
        <span v-if="onboardingFormDataCopy.forms.completed.includes('sow')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-on="on"
                outlined
                @click="downloadSoW()"
                >Download SoW (.pdf)</v-btn
              >
            </template>
            {{
              "Send MSA to " +
                onboardingFormDataCopy.cognitusEmailMailbox +
                onboardingFormDataCopy.cognitusEmailDomain
            }}
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                style="margin-left: 1em;"
                v-on="on"
                color="success"
                outlined
                @click="emailSoW()"
                >Email SoW</v-btn
              >
            </template>
            {{
              "Send MSA to " +
                onboardingFormDataCopy.cognitusEmailMailbox +
                onboardingFormDataCopy.cognitusEmailDomain
            }}
          </v-tooltip>
        </span>
      </span>
      <span v-else>
        There are no PDF's that need signing.
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["data", "onboardings", "company", "countries", "onboarding_id", "step"],
  data() {
    return {
      onboardingFormDataCopy: null,
      signingW9: false,
      signingACH: false,
      signingNDA: false,
      signingMSA: false,
      signingSoW: false,
      isStepping: false
    };
  },
  mounted() {
    var that = this;
    that.onboardingFormDataCopy = JSON.parse(JSON.stringify(that.data));
    this.$eventBus.$on("nextStep", function(currentStep) {
      if (currentStep === that.step && !that.isStepping) {
        that.isStepping = true;
        that.$emit(
          "updateData",
          that.getOnboardingFormDataCopy(), that.step, true, function() {
            that.isStepping = false;
          }
        );
      }
    });
    that.$eventBus.$emit("stepOkay", {
      step: that.step,
      valid: that.stepOkay
    });
  },
  methods: {
    encodeURIComponent(x) {
      return window.encodeURIComponent(x);
    },
    getOnboardingFormDataCopy() {
      return this.onboardingFormDataCopy;
    }
  },
  watch: {
    "data": {
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          this.onboardingFormDataCopy = JSON.parse(JSON.stringify(val));
          this.$forceUpdate();
        }
      }
    },
  },
  computed: {
    stepOkay() {
      var that = this;
      var okay = true;
      return okay;
    }
  }
};
</script>

<style lang="scss" scoped></style>
