let template = {
    employee: false,
    contractor: false, 
    firstName: "",
    lastName: "", 
    jobTitle: "",
    personalEmailAddress: "",
    cognitusEmailMailbox: "",
    cognitusEmailDomain: "@cognitus.com",
    travelExpenses: false,
    contingentWorker: false,
    contingentWorkerFreelance: false,
    startDate: "",
    currency: "",
    costRate: 0,
    billRate: 0,
    costCenter: "",
    region: "",
    usageLocation: "",
    laptop: false,
    otherSystem: [],
    history: [],
    o365license: "",
    forms: {
        completed: [],
        required: []
    },
    adobeFieldData: [],
    adobeAgreements: [],
    mobilePhone: {
        e164: "",
        number: "",
        countryCode: "",
        countryCodeAndCountry: ""
    },
    requesterComment: "",
    requester: "",
    changeRequest: false,
    changeRequestUUID: "",
    jiraIssueStatus: "",
    flowApprovalStatus: "",
    jiraIssueKey: "",
    readyForOnboardedUserInput: false,
    onboardedUserInputComplete: false,
    jiraIssueID: "",
    date_time_onboarded: "",
    onboarding_id: "",
    company_id: "",
    sentEmails: [],
    sfReportsTo: '',
    sfPayingLegalEntity: '',
    temporaryOffice365Password: "",
    hasBeenOnboarded: false,
    sapUser: [],
    vendorAddressSupplier: '',
    msaDate: '',
    existingMSALink: '',
    serviceProviderEmailAddress: '',
    vendorAddress: {
        serviceproviderName: "",
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        zip: "",
        country: ""
    },
    step: 1,
    terminated: false,
    conversationId: null,
    activityId: null,
    projectRateCards: [],
    rehireDate: null,
    terminationDate: null
}
export default template;