var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.company !== undefined && _vm.$store.state.userInfo !== null && _vm.$store.state.userInfo.navPermissions !== undefined && _vm.$store.state.userInfo.navPermissions !== null)?_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',{attrs:{"xs10":""}},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Company Onboarding Legacy (Sharepoint)")]),_c('br')]),(_vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".admin")) || _vm.$store.state.userInfo.navPermissions.includes(((_vm.company._id) + ".company.onboarding.legacy")))?_c('v-flex',{attrs:{"xs10":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"multi-sort":"","items":_vm.legacyOnboardings,"loading":_vm.loadingLegacyOnboardings,"show-select":"","item-key":"ID"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin":"0 auto"},attrs:{"color":"warning","icon":""},on:{"click":function($event){return _vm.getLegacyOnboardings()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}],null,false,471068919)},[_vm._v(" Refresh Legacy Onboardings ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin":"0 auto"},attrs:{"color":"warning","icon":""},on:{"click":function($event){return _vm.convertMultiple(_vm.selectedItems)}}},on),[_c('v-icon',[_vm._v("mdi-account-convert")])],1)]}}],null,false,4111937970)},[_vm._v(" Convert Selected ("+_vm._s(_vm.selectedItems.length)+") ")]),(_vm.loadingLegacyOnboardingsError)?_c('span',[_c('h4',{staticStyle:{"color":"red"}},[_vm._v(" There was an error in retrieving legacy onboardings from sharepoint. ")])]):_vm._e(),_c('v-text-field',{staticStyle:{"margin":"0em 2em"},attrs:{"type":"text","label":"Search Legacy Onboardings"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.convert(item)}}},on),[_vm._v("mdi-account-convert")])]}}],null,true)},[_vm._v(" Convert to Surfboard ")])]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }