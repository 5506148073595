<template>
  <v-textarea :value="settings.value"></v-textarea>
</template>

<script>
export default {
  props: ["settings"]
};
</script>

<style scoped>
</style>