let GraphService = {}
let graphURL = 'https://graph.microsoft.com/v1.0';
GraphService.getUserInfo = function (token) {
    const headers = new Headers({
        Authorization: `Bearer ${token}`
    });
    const options = {
        headers
    };
    return fetch(`${graphURL}/me`, options)
        .then(response => response.json())
        .catch(response => {
            throw new Error(response.text());
        });
}
GraphService.getUserRole = function (token, userID) {
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
    });
    const options = {
        headers
    };
    return fetch(`${graphURL}/me?$select=userType`, options)
        .then(response => response.json())
        .catch(response => {
            throw new Error(response.text());
        })
}
GraphService.getSpecificUserAvatar = function (email, token) {
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": `image/jpg`
    });
    const options = {
        headers
    };
    return fetch(`${graphURL}/users/${email}/photo/$value`, options)
        .then(response => response.blob())
        .then(function (imageBlob) {
            return URL.createObjectURL(imageBlob);
        }).catch(response => {
            throw new Error(response);
        })
}
GraphService.getUserAvatar = function (token) {
    const headers = new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": `image/jpg`
    });
    const options = {
        headers
    };
    return fetch(`${graphURL}/me/photo/$value`, options)
        .then(response => response.blob())
        .then(function (imageBlob) {
            return URL.createObjectURL(imageBlob);
        }).catch(response => {
            throw new Error(response);
        })
}
export default GraphService;