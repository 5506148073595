<template>
  <v-layout row wrap justify-center align-center v-if="$store.state.userInfo !== undefined && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && $store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.onboarding.form.builder`) && formLayout !== null">
    <v-flex xs12>
      <v-layout row wrap justify-center align-center>
        <v-flex xs12 style="background-color: rgba(0, 0, 0, .1)">
          <h1>
            Form Layout ({{ formLayout.pages.length }} Step{{
              formLayout.pages.length > 1 ? "s" : ""
            }}) ({{ itemsInCurrentPage.length }} Input{{
              itemsInCurrentPage.length > 1 ? "s" : ""
            }}) - Current Step: {{ currentPage }}
          </h1>
          <v-layout
            row
            wrap
            justify-center
            align-center
            style="margin: 10px 0;"
          >
            <draggable
              class="flex xs10"
              :list="formLayout.pages[currentPage - 1].items"
              group="items"
            >
              <div
                v-for="(formItem, index) in itemsInCurrentPage"
                :style="
                  `position: relative; border: ${
                    showFormItemActions[index] ? '1px dashed darkblue' : 'none'
                  }; margin: 5px 0; background-color: rgba(0, 0, 0, .05);`
                "
                :key="'form-item-' + index + '-' + formItem.uuid"
                @mouseenter="
                  showFormItemActions[index] = true;
                  $forceUpdate();
                "
                @mouseleave="
                  showFormItemActions[index] = false;
                  $forceUpdate();
                "
              >
                <ZCC-FormItem
                  :data="formItem.data"
                  :settings="formItem.settings"
                  :key="'zcc-formItem-' + index + '-' + formItem.uuid"
                />
                <v-expand-transition>
                  <div
                    style="background-color: rgba(0, 0, 0, .5);"
                    :key="'actionButtons-' + index + '-' + formItem.uuid"
                    v-if="
                      showFormItemActions[index] &&
                        formItem.uuid !== formItemBeingModifiedUUID
                    "
                  >
                    <h4
                      :key="'formItemTitle-' + index + '-' + formItem.uuid"
                      v-if="showFormItemActions[index]"
                      style="color: white;"
                    >
                      {{ formItem.data.title }}
                    </h4>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          x-large
                          color="white"
                          @click.stop="modifyFormItem(formItem, index)"
                        >
                          <v-icon>mdi-settings</v-icon>
                        </v-btn>
                      </template>
                      Modify the settings of this form item.
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          x-large
                          color="error"
                          @click.stop="removeFormItem(index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      Remove this form item.
                    </v-tooltip>
                  </div>
                </v-expand-transition>
                <FormItemSettings
                  :key="'formItemSettings-' + index + '-' + formItem.uuid"
                  v-if="
                    modifyingFormItemSettings &&
                      formItemBeingModified !== null &&
                      formItem.uuid === formItemBeingModifiedUUID
                  "
                  :item="formItemBeingModified"
                  @saveModifiedChange="saveFormItemModifications"
                  @cancelChange="cancelFormItemModifications"
                />
              </div>
            </draggable>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <FormItemSelector
      v-if="addingFormItem"
      @formItemSelected="confirmAddingFormItem"
    />
    <v-flex xs12 style="background-color: rgba(222, 202, 27, 0.15)">
      <!-- ADD INPUT FIELD TO CURRENT -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="success" v-on="on" @click="addFormItem()" class="ma-2">
            Input
            <v-icon right>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Add a input field to the form.</span>
      </v-tooltip>
    </v-flex>
    <v-flex xs12 style="background-color: rgba(222, 202, 27, 0.15)">
      <!-- ADD STEP AFTER CURRENT -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="success" v-on="on" @click="addPage()" class="ma-2">
            Step
            <v-icon right>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>
          Add a step after this step.
          <br />
          {{ formLayout.pages.length }}
          <span style="color: rgba(0, 255, 0, 1);">+1</span>
        </span>
      </v-tooltip>
      <!-- ADD STEP TO END OF STEPS -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="success" v-on="on" @click="addPage(true)" class="ma-2">
            Step (END)
            <v-icon right>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>
          Add a step to the
          <span>END</span>
          of the form.
          <br />
          {{ formLayout.pages.length }}
          <span style="color: rgba(0, 255, 0, 1);">+1</span>
        </span>
      </v-tooltip>
      <!-- REMOVE CURRENT STEP -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            color="error"
            :disabled="formLayout.pages.length <= 1"
            v-on="on"
            @click="removePage()"
            class="ma-2"
          >
            Step
            <v-icon right>mdi-minus-box</v-icon>
          </v-btn>
        </template>
        <span>
          Remove this step.
          <br />
          {{ formLayout.pages.length }}
          <span style="color: rgba(255, 0, 0, 1);">-1</span>
        </span>
      </v-tooltip>
    </v-flex>
    <v-flex xs12>
      <v-pagination
        v-model="currentPage"
        :length="formLayout.pages.length"
      ></v-pagination>
    </v-flex>
  </v-layout>
</template>

<script>
import draggable from "vuedraggable";
import FormItemSelector from "@/components/Company/Onboarding/Form/Builder/FormItemSelector.vue";
import FormItemSettings from "@/components/Company/Onboarding/Form/Builder/FormItemSettings.vue";
import ZCC_FormItem from "@/components/Company/Onboarding/Form/ZCC_FormItem.vue";
export default {
  props: ["formLayoutData"],
  components: {
    draggable,
    FormItemSelector,
    FormItemSettings,
    "ZCC-FormItem": ZCC_FormItem
  },
  data() {
    return {
      showFormItemActions: [],
      modifyingFormItemSettings: false,
      formItemBeingModifiedUUID: -1,
      formItemBeingModified: null,
      currentPage: 1,
      formLayout: {
        pages: [{ header: "", items: [], id: null }]
      },
      defaultFormLayout: {
        pages: [
          {
            header: "",
            items: [],
            id: null
          }
        ]
      },
      defaultPage: {
        items: [],
        header: "",
        id: null
      },
      addingFormItem: false
    };
  },
  mounted() {
    let that = this;
    if (this.formLayoutData !== undefined && this.formLayoutData !== null) {
      this.formLayout = JSON.parse(JSON.stringify(that.formLayoutData));
    } else {
      this.formLayout = JSON.parse(JSON.stringify(that.defaultFormLayout));
      this.formLayout.pages[0].id = this.$UUID();
    }
  },
  computed: {
    itemsInCurrentPage() {
      let that = this;
      while (
        this.formLayout.pages[that.currentPage - 1] === undefined &&
        that.currentPage >= 1
      ) {
        that.currentPage--;
      }
      return this.formLayout.pages[that.currentPage - 1].items;
    }
  },
  methods: {
    log(evt) {
      // console.log(evt);
    },
    cancelFormItemModifications() {
      this.modifyingFormItemSettings = false;
      this.formItemBeingModifiedUUID = -1;
    },
    saveFormItemModifications(item, atIndex = -1, atPage = -1) {
      let that = this;
      let modifiedFormItem = JSON.parse(JSON.stringify(item));
      this.modifyingFormItemSettings = false;
      let page = atPage !== -1 ? atPage : that.currentPage - 1;
      let index =
        atIndex !== -1
          ? atIndex
          : this.formLayout.pages[page].items.indexOf(
              this.formLayout.pages[page].items.filter(
                formItem => formItem.uuid === that.formItemBeingModifiedUUID
              )[0]
            );
      this.formLayout.pages[page].items.splice(index, 1, modifiedFormItem);
      that.formItemBeingModifiedUUID = -1;
    },
    modifyFormItem(item, index) {
      this.modifyingFormItemSettings = true;
      this.formItemBeingModifiedUUID = item.uuid;
      this.formItemBeingModified = JSON.parse(JSON.stringify(item));
    },
    removePage() {
      let that = this;
      if (this.formLayout.pages.length > 0) {
        this.formLayout.pages.splice(that.currentPage - 1, 1);
      }
    },
    addPage(toEnd = false) {
      let that = this;
      let newPage = JSON.parse(JSON.stringify(that.defaultPage));
      newPage.index = that.currentPage;
      newPage.id = that.$UUID();
      if (toEnd) this.formLayout.pages.push(newPage);
      else this.formLayout.pages.splice(that.currentPage, 0, newPage);
    },
    confirmAddingFormItem(item) {
      let that = this;
      item.id = that.$UUID();
      this.formLayout.pages[that.currentPage - 1].items.push(item);
      this.showFormItemActions.push(false);
      this.addingFormItem = false;
    },
    cancelAddingFormItem() {
      this.addingFormItem = false;
    },
    removeFormItem(page, index) {
      this.formLayout.pages[page].items.splice(index, 1);
      this.showFormItemActions.splice(index, 1);
    },
    addFormItem() {
      this.addingFormItem = true;
    },
    saveFormLayout() {
      let that = this;
      this.$BackendService.company
        .saveFormLayout(that.formLayout)
        .then(function(response) {})
        .catch(function(error) {});
    }
  }
};
</script>

<style lang="scss" scoped></style>
