import store from '../store/index.js';
import BackendService from './backend.service.js';
let UserService = {
    getUsers: function () {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.user.getAll({
                    _id: store.state.userADInfo.id
                }).then(function (users) {
                    store.dispatch("SET_USERS", users);
                    resolve(users);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }

        })
    },
    getUserInfo: function () {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.user.getInfo({
                    _id: store.state.userADInfo.id
                }).then(function (user) {
                    store.dispatch("SET_USER_INFO", user);
                    resolve(user);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }

        })
    }
}
export default UserService;