var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('h3',[_vm._v("Join existing company")])]),_c('v-card-subtitle',[_vm._v("Use an invite token to join an existing company.")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Company ID"},model:{value:(_vm.inviteCompanyID),callback:function ($$v) {_vm.inviteCompanyID=$$v},expression:"inviteCompanyID"}}),_c('v-text-field',{attrs:{"label":"Invite Token"},model:{value:(_vm.inviteToken),callback:function ($$v) {_vm.inviteToken=$$v},expression:"inviteToken"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.inviteToken === '' ||
                  _vm.inviteCompanyID === '' ||
                  _vm.errorJoiningCompany ||
                  _vm.finishedJoiningCompany,"color":("" + (_vm.joiningCompany
                    ? 'teal'
                    : _vm.finishedJoiningCompany
                    ? 'success'
                    : _vm.errorJoiningCompany
                    ? 'error'
                    : 'primary')),"loading":_vm.joiningCompany},on:{"click":function($event){return _vm.joinCompany()}}},[_vm._v(" "+_vm._s(_vm.joiningCompany ? "Joining Company" : _vm.finishedJoiningCompany ? "Joined Company" : _vm.errorJoiningCompany ? "Error Joining Company" : "Join")+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }