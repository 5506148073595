var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.onboardingFormDataCopy !== null)?_c('v-layout',{staticStyle:{"margin":"1em 0"},attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[(_vm.onboardingFormDataCopy.forms !== undefined && _vm.onboardingFormDataCopy.forms.required !== undefined)?_c('v-flex',{attrs:{"xs10":""}},[_c('h3',[_vm._v("PDF Signing")]),(_vm.onboardingFormDataCopy.forms.required.length > 0)?_c('span',[(_vm.onboardingFormDataCopy.forms.required.includes('w9'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingW9)?_c('v-btn',_vm._g({attrs:{"outlined":""},on:{"click":function($event){return _vm.signW9()}}},on),[_vm._v("Sign W9")]):_c('v-btn',_vm._g({attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveW9()}}},on),[_vm._v("Save W9")])]}}],null,false,2294411151)},[_vm._v(" "+_vm._s(_vm.signingW9 ? "Save the changes you've made to the W9 form." : "You can use this link to sign your W9 form.")+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.required.includes('ach'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingACH)?_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.signACH()}}},on),[_vm._v("Sign ACH")]):_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveACH()}}},on),[_vm._v("Save ACH")])]}}],null,false,529107179)},[_vm._v(" "+_vm._s(_vm.signingACH ? "Save the changes you've made to the ACH form." : "You can use this link to sign your ACH form.")+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.required.includes('nda'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingNDA)?_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.signNDA()}}},on),[_vm._v("Sign NDA")]):_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveNDA()}}},on),[_vm._v("Save NDA")])]}}],null,false,418275722)},[_vm._v(" "+_vm._s(_vm.signingNDA ? "Save the changes you've made to the NDA form." : "You can use this link to sign your NDA form.")+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.required.includes('msa'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingMSA)?_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.signMSA()}}},on),[_vm._v("Sign MSA")]):_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveMSA()}}},on),[_vm._v("Save MSA")])]}}],null,false,1268592382)},[_vm._v(" "+_vm._s(_vm.signingMSA ? "Save the changes you've made to the MSA form." : "You can use this link to sign your MSA form.")+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.required.includes('sow'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingSoW)?_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.signSoW()}}},on),[_vm._v("Sign SoW")]):_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveSoW()}}},on),[_vm._v("Save SoW")])]}}],null,false,481409354)},[_vm._v(" "+_vm._s(_vm.signingSoW ? "Save the changes you've made to the SoW form." : "You can use this link to sign your SoW form.")+" ")])],1):_vm._e()]):_vm._e(),(_vm.onboardingFormDataCopy.forms.completed.length > 0)?_c('span',[(_vm.onboardingFormDataCopy.forms.completed.includes('w9'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingW9)?_c('v-btn',_vm._g({attrs:{"outlined":""},on:{"click":function($event){return _vm.signW9()}}},on),[_vm._v("Sign W9")]):_c('v-btn',_vm._g({attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveW9()}}},on),[_vm._v("Save W9")])]}}],null,false,2294411151)},[_vm._v(" "+_vm._s(_vm.signingW9 ? "Save the changes you've made to the W9 form." : "You can use this link to sign your W9 form.")+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.completed.includes('ach'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingACH)?_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.signACH()}}},on),[_vm._v("Sign ACH")]):_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.saveACH()}}},on),[_vm._v("Save ACH")])]}}],null,false,529107179)},[_vm._v(" "+_vm._s(_vm.signingACH ? "Save the changes you've made to the ACH form." : "You can use this link to sign your ACH form.")+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.completed.includes('nda'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.signingNDA)?_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.signNDA()}}},on),[_vm._v("Download NDA (.pdf)")]):_vm._e()]}}],null,false,3238858868)},[_vm._v(" "+_vm._s("Download NDA (.pdf)")+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.emailNDA()}}},on),[_vm._v("Email NDA")])]}}],null,false,404238400)},[_vm._v(" "+_vm._s("Send NDA to " + _vm.onboardingFormDataCopy.cognitusEmailMailbox + _vm.onboardingFormDataCopy.cognitusEmailDomain)+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.completed.includes('msa'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.downloadMSA()}}},on),[_vm._v("Download MSA (.pdf)")])]}}],null,false,231067157)},[_vm._v(" "+_vm._s("Download MSA (.pdf)")+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.emailMSA()}}},on),[_vm._v("Email MSA")])]}}],null,false,4070748608)},[_vm._v(" "+_vm._s("Send MSA to " + _vm.onboardingFormDataCopy.cognitusEmailMailbox + _vm.onboardingFormDataCopy.cognitusEmailDomain)+" ")])],1):_vm._e(),(_vm.onboardingFormDataCopy.forms.completed.includes('sow'))?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"outlined":""},on:{"click":function($event){return _vm.downloadSoW()}}},on),[_vm._v("Download SoW (.pdf)")])]}}],null,false,1258459925)},[_vm._v(" "+_vm._s("Send MSA to " + _vm.onboardingFormDataCopy.cognitusEmailMailbox + _vm.onboardingFormDataCopy.cognitusEmailDomain)+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-left":"1em"},attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.emailSoW()}}},on),[_vm._v("Email SoW")])]}}],null,false,3554372224)},[_vm._v(" "+_vm._s("Send MSA to " + _vm.onboardingFormDataCopy.cognitusEmailMailbox + _vm.onboardingFormDataCopy.cognitusEmailDomain)+" ")])],1):_vm._e()]):_c('span',[_vm._v(" There are no PDF's that need signing. ")])]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }