var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.onboardingFormDataCopy !== null)?_c('v-layout',{staticStyle:{"margin":"1em 0"},attrs:{"justify-center":"","align-center":"","row":"","wrap":""}},[_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.onboardingFormDataCopy.employee),expression:"onboardingFormDataCopy.employee"}],attrs:{"xs10":""}},[_c('v-autocomplete',{key:"currency",attrs:{"items":Object.keys(_vm.$store.state.currencies),"label":"Currency"},model:{value:(_vm.onboardingFormDataCopy.currency),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "currency", $$v)},expression:"onboardingFormDataCopy.currency"}}),_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
        currency: _vm.onboardingFormDataCopy.currency || 'USD',
        locale: 'en',
        autoDecimalMode: false,
        decimalLength: undefined,
        'allow-negative': false,
        distractionFree: true,
        min: null,
        max: null
      }),expression:"{\n        currency: onboardingFormDataCopy.currency || 'USD',\n        locale: 'en',\n        autoDecimalMode: false,\n        decimalLength: undefined,\n        'allow-negative': false,\n        distractionFree: true,\n        min: null,\n        max: null\n      }"}],attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Bill Rate (required)"},model:{value:(_vm.onboardingFormDataCopy.billRate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "billRate", $$v)},expression:"onboardingFormDataCopy.billRate"}}),_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
        currency: _vm.onboardingFormDataCopy.currency || 'USD',
        locale: 'en',
        autoDecimalMode: false,
        decimalLength: undefined,
        'allow-negative': false,
        distractionFree: true,
        min: null,
        max: null
      }),expression:"{\n        currency: onboardingFormDataCopy.currency || 'USD',\n        locale: 'en',\n        autoDecimalMode: false,\n        decimalLength: undefined,\n        'allow-negative': false,\n        distractionFree: true,\n        min: null,\n        max: null\n      }"}],attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Cost Rate"},model:{value:(_vm.onboardingFormDataCopy.costRate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "costRate", $$v)},expression:"onboardingFormDataCopy.costRate"}}),_c('v-switch',{attrs:{"success":_vm.onboardingFormDataCopy.laptop === true,"label":"Laptop","disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},model:{value:(_vm.onboardingFormDataCopy.laptop),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "laptop", $$v)},expression:"onboardingFormDataCopy.laptop"}}),_c('v-autocomplete',{key:"otherSystems",attrs:{"cache-items":"","chips":"","multiple":"","items":_vm.otherSystemItems,"label":"Other Systems","disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? _vm.onboardingFormDataCopy.travelExpenses ? false : _vm.onboardingFormDataCopy.contingentWorker ? false : true : true,"color":(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? _vm.onboardingFormDataCopy.travelExpenses ? 'success' : _vm.onboardingFormDataCopy.contingentWorker ? 'success' : 'primary' : 'primary'},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s((data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? _vm.onboardingFormDataCopy.travelExpenses ? data.item.text + ' (required)' : _vm.onboardingFormDataCopy.contingentWorker ? data.item.text + ' (required)' : data.item.text : data.item.text)+" ")])]}}],null,false,2568048899),model:{value:(_vm.onboardingFormDataCopy.otherSystem),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "otherSystem", $$v)},expression:"onboardingFormDataCopy.otherSystem"}}),_c('v-menu',{key:"contingent-startDate",ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px","disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":'Start Date (required)',"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.onboardingFormDataCopy.startDate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "startDate", $$v)},expression:"onboardingFormDataCopy.startDate"}},on))]}}],null,false,2882162324),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
            (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.onboardingFormDataCopy.startDate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "startDate", $$v)},expression:"onboardingFormDataCopy.startDate"}})],1),(_vm.onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors'))?_c('v-autocomplete',{attrs:{"color":_vm.retrievingSFData ? 'warning' : 'primary',"loading":_vm.retrievingSFData,"label":("Reports To (required) " + (_vm.retrievingSFData ? '(loading data)' : '')),"items":_vm.sfPositions.filter(function (position) { return !position.hidden; })},model:{value:(_vm.onboardingFormDataCopy.sfReportsTo),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "sfReportsTo", $$v)},expression:"onboardingFormDataCopy.sfReportsTo"}}):_vm._e(),(_vm.onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors'))?_c('v-autocomplete',{attrs:{"color":_vm.retrievingSFData ? 'warning' : 'primary',"loading":_vm.retrievingSFData,"label":("Paying Legal Entity (required) " + (_vm.retrievingSFData ? '(loading data)' : '')),"items":_vm.sfPayingLegalEntities,"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},model:{value:(_vm.onboardingFormDataCopy.sfPayingLegalEntity),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "sfPayingLegalEntity", $$v)},expression:"onboardingFormDataCopy.sfPayingLegalEntity"}}):_vm._e(),_c('v-autocomplete',{attrs:{"items":_vm.sfCostCenters.filter(function (costCenter) { return !costCenter.hidden; }),"label":"Cost Center (required)","disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},model:{value:(_vm.onboardingFormDataCopy.costCenter),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "costCenter", $$v)},expression:"onboardingFormDataCopy.costCenter"}}),_c('v-autocomplete',{attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Office 365 License","items":_vm.company.settings[
        _vm.company.settings.indexOf(
          _vm.company.settings.filter(
            function (setting) { return setting.label === 'Office 365 License'; }
          )[0]
        )
      ].items},model:{value:(_vm.onboardingFormDataCopy.o365license),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "o365license", $$v)},expression:"onboardingFormDataCopy.o365license"}})],1),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.onboardingFormDataCopy.contractor),expression:"onboardingFormDataCopy.contractor"}],attrs:{"xs10":""}},[_c('v-switch',{key:'contractor-travel',attrs:{"success":_vm.onboardingFormDataCopy.travelExpenses === true,"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Travel Expenses (optional)"},model:{value:(_vm.onboardingFormDataCopy.travelExpenses),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "travelExpenses", $$v)},expression:"onboardingFormDataCopy.travelExpenses"}}),_c('v-switch',{key:'contractor-contingent',attrs:{"success":_vm.onboardingFormDataCopy.contingentWorker === true,"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Contingent Worker (optional)"},model:{value:(_vm.onboardingFormDataCopy.contingentWorker),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "contingentWorker", $$v)},expression:"onboardingFormDataCopy.contingentWorker"}}),(_vm.onboardingFormDataCopy.contingentWorker)?_c('v-layout',{staticStyle:{"margin-bottom":"1em"}},[_c('v-flex',{attrs:{"xs5":"","mr-4":""}},[_c('v-card',{style:(_vm.onboardingFormDataCopy.vendorAddressSupplier === 'freelance' ? 'color: rgba(0, 100, 0, 1);' : 'color: black'),attrs:{"hover":"","outlined":_vm.onboardingFormDataCopy.vendorAddressSupplier === 'freelance',"color":_vm.onboardingFormDataCopy.vendorAddressSupplier === 'freelance' ? 'rgba(200, 200, 200, .1)' : '',"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},on:{"click":function($event){_vm.onboardingFormDataCopy.vendorAddressSupplier = 'freelance'; _vm.$forceUpdate();}}},[_c('v-card-title',[_vm._v("Freelance")]),_c('v-card-subtitle',[_vm._v(" Vendor Address is provided by the "),_c('strong',[_vm._v("freelance contingent worker")]),_vm._v(". ")]),_c('v-card-text')],1)],1),_c('v-flex',{attrs:{"xs5":"","mr-4":""}},[_c('v-card',{style:(_vm.onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider' ? 'color: rgba(0, 100, 0, 1);' : 'color: black'),attrs:{"hover":"","outlined":_vm.onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider',"color":_vm.onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider' ? 'rgba(200, 200, 200, .1)' : '',"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},on:{"click":function($event){_vm.onboardingFormDataCopy.vendorAddressSupplier = 'serviceprovider'; _vm.$forceUpdate();}}},[_c('v-card-title',[_vm._v("Service Provider")]),_c('v-card-subtitle',[_vm._v(" Vendor Address is provided by a "),_c('strong',[_vm._v("service provider")]),_vm._v(". ")]),_c('v-card-text')],1)],1)],1):_vm._e(),(_vm.onboardingFormDataCopy.contingentWorker && _vm.onboardingFormDataCopy.vendorAddressSupplier === 'serviceprovider')?_c('v-text-field',{attrs:{"error":!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_vm.onboardingFormDataCopy.serviceProviderEmailAddress)),"success":(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_vm.onboardingFormDataCopy.serviceProviderEmailAddress)),"erorr-messages":!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_vm.onboardingFormDataCopy.serviceProviderEmailAddress)) ? ['Please enter a valid email address'] : [],"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Service Provider Email Address (required)"},model:{value:(_vm.onboardingFormDataCopy.serviceProviderEmailAddress),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "serviceProviderEmailAddress", $$v)},expression:"onboardingFormDataCopy.serviceProviderEmailAddress"}}):_vm._e(),(_vm.onboardingFormDataCopy.contingentWorker)?_c('v-autocomplete',{key:"currency",attrs:{"items":Object.keys(_vm.$store.state.currencies),"label":"Currency"},model:{value:(_vm.onboardingFormDataCopy.currency),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "currency", $$v)},expression:"onboardingFormDataCopy.currency"}}):_vm._e(),(_vm.onboardingFormDataCopy.contingentWorker)?_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
        currency: _vm.onboardingFormDataCopy.currency || 'USD',
        locale: 'en',
        autoDecimalMode: false,
        decimalLength: undefined,
        'allow-negative': false,
        distractionFree: true,
        min: null,
        max: null
      }),expression:"{\n        currency: onboardingFormDataCopy.currency || 'USD',\n        locale: 'en',\n        autoDecimalMode: false,\n        decimalLength: undefined,\n        'allow-negative': false,\n        distractionFree: true,\n        min: null,\n        max: null\n      }"}],attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Bill Rate"},model:{value:(_vm.onboardingFormDataCopy.billRate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "billRate", $$v)},expression:"onboardingFormDataCopy.billRate"}}):_vm._e(),(_vm.onboardingFormDataCopy.contingentWorker)?_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
        currency: _vm.onboardingFormDataCopy.currency || 'USD',
        locale: 'en',
        autoDecimalMode: false,
        decimalLength: undefined,
        'allow-negative': false,
        distractionFree: true,
        min: null,
        max: null
      }),expression:"{\n        currency: onboardingFormDataCopy.currency || 'USD',\n        locale: 'en',\n        autoDecimalMode: false,\n        decimalLength: undefined,\n        'allow-negative': false,\n        distractionFree: true,\n        min: null,\n        max: null\n      }"}],attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Cost Rate"},model:{value:(_vm.onboardingFormDataCopy.costRate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "costRate", $$v)},expression:"onboardingFormDataCopy.costRate"}}):_vm._e(),(
        _vm.onboardingFormDataCopy.travelExpenses || _vm.onboardingFormDataCopy.contingentWorker
      )?_c('v-menu',{key:"startDate",ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px","disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.onboardingFormDataCopy.travelExpenses &&
          !_vm.onboardingFormDataCopy.contingentWorker ? "Travel Start Date (required)" : !_vm.onboardingFormDataCopy.travelExpenses &&
          _vm.onboardingFormDataCopy.contingentWorker ? "Working Start Date (required)" : _vm.onboardingFormDataCopy.travelExpenses &&
          _vm.onboardingFormDataCopy.contingentWorker ? "Work and Travel Start Date (required)" : "","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.onboardingFormDataCopy.startDate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "startDate", $$v)},expression:"onboardingFormDataCopy.startDate"}},on))]}}],null,false,145684340),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
            (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
              _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.onboardingFormDataCopy.startDate),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "startDate", $$v)},expression:"onboardingFormDataCopy.startDate"}})],1):_vm._e(),_c('v-autocomplete',{key:"otherSystems",attrs:{"cache-items":"","chips":"","multiple":"","items":_vm.otherSystemItems,"label":"Other Systems","disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? _vm.onboardingFormDataCopy.travelExpenses ? false : _vm.onboardingFormDataCopy.contingentWorker ? false : true : true,"color":(data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? _vm.onboardingFormDataCopy.travelExpenses ? 'success' : _vm.onboardingFormDataCopy.contingentWorker ? 'success' : 'primary' : 'primary'},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s((data.item.text === 'JIRA' || data.item.text === 'FSP/SuccessFactors') ? _vm.onboardingFormDataCopy.travelExpenses ? data.item.text + ' (required)' : _vm.onboardingFormDataCopy.contingentWorker ? data.item.text + ' (required)' : data.item.text : data.item.text)+" ")])]}}],null,false,2568048899),model:{value:(_vm.onboardingFormDataCopy.otherSystem),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "otherSystem", $$v)},expression:"onboardingFormDataCopy.otherSystem"}}),(_vm.onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors'))?_c('v-autocomplete',{key:"reportsTo",attrs:{"color":_vm.retrievingSFData ? 'warning' : 'primary',"loading":_vm.retrievingSFData,"label":("Reports To (required) " + (_vm.retrievingSFData ? '(loading data)' : '')),"items":_vm.sfPositions.filter(function (position) { return !position.hidden; }),"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},model:{value:(_vm.onboardingFormDataCopy.sfReportsTo),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "sfReportsTo", $$v)},expression:"onboardingFormDataCopy.sfReportsTo"}}):_vm._e(),(_vm.onboardingFormDataCopy.otherSystem.includes('FSP/SuccessFactors'))?_c('v-autocomplete',{key:"payingLegalEntity",attrs:{"color":_vm.retrievingSFData ? 'warning' : 'primary',"loading":_vm.retrievingSFData,"label":("Paying Legal Entity (required) " + (_vm.retrievingSFData ? '(loading data)' : '')),"items":_vm.sfPayingLegalEntities,"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved')},model:{value:(_vm.onboardingFormDataCopy.sfPayingLegalEntity),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "sfPayingLegalEntity", $$v)},expression:"onboardingFormDataCopy.sfPayingLegalEntity"}}):_vm._e(),(_vm.onboardingFormDataCopy.contingentWorker)?_c('v-autocomplete',{key:"costCenter",attrs:{"items":_vm.sfCostCenters.filter(function (costCenter) { return !costCenter.hidden; }),"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Cost Center (required)"},model:{value:(_vm.onboardingFormDataCopy.costCenter),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "costCenter", $$v)},expression:"onboardingFormDataCopy.costCenter"}}):_vm._e(),_c('v-autocomplete',{key:"o365License",attrs:{"disabled":!_vm.onboardingFormDataCopy.changeRequest &&
          (_vm.onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            _vm.onboardingFormDataCopy.flowApprovalStatus === 'Approved'),"label":"Office 365 License","items":_vm.company.settings[
        _vm.company.settings.indexOf(
          _vm.company.settings.filter(
            function (setting) { return setting.label === 'Office 365 License'; }
          )[0]
        )
      ].items},model:{value:(_vm.onboardingFormDataCopy.o365license),callback:function ($$v) {_vm.$set(_vm.onboardingFormDataCopy, "o365license", $$v)},expression:"onboardingFormDataCopy.o365license"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }