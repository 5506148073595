import BackendService from './backend.service.js';
let RunnService = {
    getProjectId: function(data) {
      return new Promise(function (resolve, reject) {
        BackendService.runn.project.get(data).then(function (project) {
            resolve(project);
        }).catch(function (error) {
            reject(error);
        })
      })
    },

    getRunnProjects: function(data) {
      return new Promise(function (resolve, reject) {
        BackendService.runn.project.getAll(data).then(function (projects) {
            resolve(projects);
        }).catch(function (error) {
            reject(error);
        })
      })
    },

    getRunnAssignments: function(data) {
      return new Promise(function (resolve, reject) {
        BackendService.runn.assignments.getAll(data).then(function (assignments) {
            resolve(assignments);
        }).catch(function (error) {
            reject(error);
        })
      })
    },

    getRunnPeople: function (data) {
        return new Promise(function (resolve, reject) {
            BackendService.runn.people.getAll(data).then(function (people) {
                resolve(people);
            }).catch(function (error) {
                reject(error);
            })

        })
    },
    
    getPerson: function (data) {
      return new Promise(function (resolve, reject) {
        BackendService.runn.people.get(data).then(function (person) {
            resolve(person);
        }).catch(function (error) {
            reject(error);
        })
      })
    }
}
export default RunnService;