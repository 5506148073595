import store from '../store/index.js';
import BackendService from './backend.service.js';
let OnboardingService = {
    getOnboardings: function ({company_id}) {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.onboarding.getAll({
                    _id: store.state.userADInfo.id,
                    company_id: company_id
                }).then(function (onboardings) {
                    store.dispatch("SET_ONBOARDINGS", onboardings);
                    resolve(onboardings);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }

        })
    },
    getOnboarding: function ({ company_id, onboarding_id }) {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.onboarding.get({
                    _id: store.state.userADInfo.id,
                    company_id: company_id,
                    onboarding_id: onboarding_id
                }).then(function (onboarding) {
                    if (onboarding !== null) {
                        store.dispatch("SET_ONBOARDING", onboarding);
                    }
                    resolve(onboarding);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }
        })
    },
    search: function ({ company_id, searchQuery }) {
        return new Promise(function (resolve, reject) {
            if (store.state.userADInfo !== null) {
                BackendService.onboarding.search({
                    _id: store.state.userADInfo.id,
                    company_id: company_id,
                    searchQuery: searchQuery
                }).then(function (onboardings) {
                    if (onboardings !== null) {
                        store.dispatch("UPDATE_ONBOARDINGS", onboardings);
                    }
                    resolve(onboardings);
                }).catch(function (error) {
                    reject(error);
                })
            } else {
                reject(new Error("userADInfo null"));
            }
        })
    }
}
export default OnboardingService;