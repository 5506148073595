<template>
  <v-text-field :value="settings.value"></v-text-field>
</template>

<script>
export default {
  props: ["settings"]
};
</script>

<style scoped>
</style>