<template>
  <v-layout row wrap justify-center align-center v-if="
    company !== undefined &&
    $store.state.userInfo !== null &&
    $store.state.userInfo.navPermissions !== undefined &&
    $store.state.userInfo.navPermissions !== null &&
    companyDataCopy !== undefined &&
    companyDataCopy !== null
  ">
    <v-flex xs10 v-if="
      ($store.state.userInfo.navPermissions.includes(`${company._id}.admin`) ||
        $store.state.userInfo.navPermissions.includes(
          `${company._id}.company.setting`
        )) && ['lvanderzande', 'rvanderzande', 'anadigatla'].includes($store.state.userADInfo.mail.split('@')[0])
    " style="position: relative;">
      <div style="width: 100%; position: fixed; z-index: 5;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-1" color="primary" v-on="on" @click="reloadCompanySettings()" icon>
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          Reload Settings from DB
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="settingsEqual" class="ma-1" color="success" @click="save()" v-on="on" icon>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          Save All Changes
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :disabled="settingsEqual" class="ma-1" color="error" @click="revertAll()" v-on="on" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          Revert All Changes
        </v-tooltip>
      </div>
      <h1 style="text-align: center;">Company Settings</h1>
      <br />
      <v-dialog v-model="companySettingsInconsistency">
        <v-card>
          <v-card-title>Company Settings Inconsistency</v-card-title>
          <v-card-text>To prevent errors.</v-card-text>
        </v-card>
      </v-dialog>
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab @click="() => {
          if (setting.type === 'autocomplete') {
            setting.edit = setting.value
          }
        }" v-for="(setting, index) in settings" :key="'tab-setting-' + index + '-' + setting.render"
          :href="`#tab-${index}`">{{ setting.label }}</v-tab>
        <v-tab-item v-for="(setting, index) in settings" :key="'tab-setting-item' + index + '-' + setting.render"
          :value="'tab-' + index">
          <v-card flat tile>
            <v-card-text>
              <v-layout row wrap justify-center align-center style="margin: 1em; padding: 1em;">
                <v-flex xs11>
                  <div v-if="setting.type === 'map'">
                  
                    <div v-for="(mapEntry, mapEntryIndex) in companyDataCopy.settings[index].value" :key="index+'mapValueEntry-'+mapEntryIndex">
                      <div style="width: 100%;">
                        <v-text-field v-model="mapEntry[0]" label="Key" @keyup="$forceUpdate();"></v-text-field>
                        <v-text-field v-model="mapEntry[1]" label="Value" @keyup="$forceUpdate();"></v-text-field>
                        <v-btn @click="companyDataCopy.settings[index].value.splice(mapEntryIndex, 1)">Remove</v-btn>
                      </div>
                      <br>
                    </div>
                    <v-btn @click="companyDataCopy.settings[index].value.push(['', ''])">Add</v-btn>

                  </div>

                  <div v-if="setting.type === 'autocomplete-map'">
                    <div v-for="(mapEntry, mapEntryIndex) in companyDataCopy.settings[index].value" :key="index+'mapValueEntry-'+mapEntryIndex">
                      <div style="width: 100%;">
                        <v-text-field :key="index+'mapValueEntry-Key-'+mapEntryIndex" v-model="mapEntry[0]" label="Key" @keyup="$forceUpdate();"></v-text-field>
                        <v-autocomplete :key="index+'mapValueEntry-Value-'+mapEntryIndex" :search-input.sync="setting.search" :chips="setting.chips" :multiple="setting.multiple" :items="setting.items" v-model="mapEntry[1]" label="Value"></v-autocomplete>
                        <v-btn @click="companyDataCopy.settings[index].value.splice(mapEntryIndex, 1)">Remove</v-btn>
                      </div>
                      <br>
                    </div>
                    <v-btn @click="setting.multiple ? companyDataCopy.settings[index].value.push(['', []]) : companyDataCopy.settings[index].value.push(['', ''])">Add</v-btn>

                  </div>

                  <div v-if="setting.type === 'textfield'">
                    <v-text-field v-model="companyDataCopy.settings[index].value" :label="setting.label"
                      @keyup="$forceUpdate()"></v-text-field>
                    <v-text-field v-model="companyDataCopy.settings[index].label" :label="`Change Label`"
                      @keyup="$forceUpdate()"></v-text-field>
                  </div>

                  <div v-if="setting.type === 'textarea'">
                    <v-text-area v-model="companyDataCopy.settings[index].value" :label="setting.label"
                      @keyup="$forceUpdate()"></v-text-area>
                    <v-text-field v-model="companyDataCopy.settings[index].label" :label="`Change Label`"
                      @keyup="$forceUpdate()"></v-text-field>
                  </div>

                  <div v-if="setting.type === 'select'">
                    <v-select :multiple="setting.multiple" :items="setting.items"
                      v-model="companyDataCopy.settings[index].value" :label="setting.label" />
                    <v-text-field v-model="companyDataCopy.settings[index].edit" :label="`Add/Remove Item`"
                      @keyup="$forceUpdate()"></v-text-field>
                    <v-btn @click="addEditToItems(index)" :disabled="setting.edit === ''" color="success"
                      style="margin-right: 1em;">Add</v-btn>
                    <v-btn @click="removeEditFromItems(index)" :disabled="setting.edit === ''"
                      color="error">Remove</v-btn>
                  </div>

                  <div v-if="setting.type === 'autocomplete'">
                    <v-autocomplete :search-input.sync="setting.search" :chips="setting.chips"
                      :multiple="setting.multiple" :items="setting.items" :label="setting.label"
                      v-model="setting.value">
                    </v-autocomplete>
                  </div>
                  <div class="mt-3">
                    <v-btn
                      :disabled="companyDataCopy.settings.type === 'select' ? companyDataCopy.settings[index].items.length === company.settings[index].items.length : JSON.stringify(companyDataCopy.settings[index].value) === JSON.stringify(company.settings[index].value)"
                      color="warning" @click="revertChange(index)">Revert Changes</v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-flex>
    <v-flex xs10 v-else style="position: relative;">
      Nothing to see here.
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["company"],
  data() {
    return {
      tab: 0,
      deepEqual: true,
      companyDataCopy: null,
      companySettingsInconsistency: false,
      settingTypes: [
        {
          description: "Vuetify Autocomplete",
          type: "autocomplete",
          label: "",
          multiple: true,
          edit: "",
          render: "",
          value: "",
          chips: false
        },
        {
          description: "Choose from a list of items",
          type: "select",
          label: "",
          multiple: false,
          edit: "",
          render: "",
          value: "",
          items: []
        },
        {
          description: "A single line string of text",
          type: "textfield",
          label: "",
          value: "",
          edit: "",
          render: ""
        },
        {
          description: "A multi line string of text",
          type: "textarea",
          label: "",
          value: "",
          edit: "",
          render: ""
        },
        {
          description: "A simple key value map",
          type: "map",
          label: "",
          value: [],
          edit: [],
          render: ""
        },
        {
          description: "Key Value Multi Autocomplete map",
          type: "autocomplete-map",
          label: "",
          value: [],
          items: [],
          multiple: true,
          edit: [],
          render: "",
          chips: true,
          search: ''
        }
      ]
    };
  },
  methods: {
    revertChange(index) {
      let that = this;
      this.companyDataCopy.settings[index].items = JSON.parse(
        JSON.stringify(this.company.settings[index].items)
      );
      this.$forceUpdate();
    },
    revertAll() {
      this.companyDataCopy = JSON.parse(JSON.stringify(this.company));
      this.$forceUpdate();
    },
    deleteSetting(setting, index) {
      this.companyDataCopy.settings.splice(index, 1);
      this.$forceUpdate();
    },
    addSetting(settingType, index) {
      this.companyDataCopy.settings.push(
        JSON.parse(JSON.stringify(settingType))
      );
      this.$forceUpdate();
    },
    addEditToItems(index) {
      let that = this;
      let setting = that.companyDataCopy.settings[index];
      let textvalue = JSON.parse(JSON.stringify(setting.edit));
      setting.items.push({ text: textvalue, value: textvalue });
      setting.edit = "";
      setting.render = that.$UUID().split("-")[0];
      that.companyDataCopy.settings[index] = setting;
      that.$forceUpdate();
    },
    removeEditFromItems(index) {
      let that = this;
      let setting = that.companyDataCopy.settings[index];
      setting.items.splice(
        setting.items.indexOf(
          setting.items.filter(item => item.text === setting.edit)[0]
        ),
        1
      );
      setting.edit = "";
      setting.render = that.$UUID().split("-")[0];
      that.companyDataCopy.settings[index] = setting;
      that.$forceUpdate();
    },
    save() {
      let that = this;
      that.companyDataCopy.settings = that.companyDataCopy.settings.map(setting => {
        if (setting.type === 'map') {
          setting.edit = setting.value
        }
        return setting;
      })
      this.$BackendService.company
        .setting(that.companyDataCopy)
        .then(function (response) {
          that.$AlertService.newAlert({
            message: "Saved settings",
            timeout: 5,
            show: true,
            color: "success"
          });
          that
            .reloadCompanySettings()
            .then(function () {
              that.$forceUpdate();
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    reloadCompanySettings() {
      let that = this;
      return new Promise(function (resolve, reject) {
        that.$eventBus.$emit("getCompanies", {
          data: null,
          callback: function (response, error) {
            if (error !== null) {
              reject(error);
              that.$AlertService.newAlert({
                message: "Error: Reloading Settings from DB",
                timeout: 5,
                show: true,
                color: "error"
              });
            } else {
              resolve();
              that.$AlertService.newAlert({
                message: "Reloaded Settings from DB",
                timeout: 5,
                show: true,
                color: "success"
              });
            }
          }
        });
      });
    }
  },
  computed: {
    settingsEqual() {
      let that = this;
      let equal = true;
      this.settings.forEach((setting, index) => {
        // console.log(setting.items[setting.items.length-1].text, that.company.settings[index].items[that.company.settings[index].items.length-1].text);
        if (that.company.settings[index].type === 'select') {
          if (
            that.company.settings[index].items.length !==
            setting.items.length
          ) {
            // console.log(index, setting, that.company.settings[index])
            equal = false;
          }
        } else {
          if (that.companyDataCopy.settings[index].edit !== that.companyDataCopy.settings[index].value) {
            equal = false;
          }
        }
      });
      return equal;
    },
    onboardings() {
      if (this.$store.state.onboardings !== null) 
      return this.$store.state.onboardings[this.company._id]
      return []
    },
    settings() {
      let that = this;
      return this.companyDataCopy.settings.map(x => {
        if (x.itemsGetter) {
          const fn = (new Function('return ' + x.itemsGetter)()).bind(that);
          x.items = fn()
        }
        return x;
      })
    }
  },
  watch: {
    company: {
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null) {
          if (val._rev !== this.companyDataCopy._rev) {
            this.companyDataCopy = JSON.parse(JSON.stringify(val));
            this.deepEqual = true;
            this.$forceUpdate();
          }
        }
      }
    }
  },
  mounted() {
    if (this.company !== undefined) {
      this.companyDataCopy = JSON.parse(JSON.stringify(this.company));
      // console.log(this.companyDataCopy)
      // if (
      //   this.companyDataCopy.settings === undefined &&
      //   this.company.name === "Cognitus Consulting LLC" &&
      //   this.company._id === "2face694-fc04-41c6-9263-da21ba614f4c"
      // ) {
      //   delete this.companyDataCopy.onboardingSettings;
      //   this.companyDataCopy.settings = [
      //     {
      //       type: "select",
      //       label: "Office 365 License",
      //       items: [
      //         { text: "E1 - Contractor", value: "E1" },
      //         { text: "E3 - Employee", value: "E3" }
      //       ],
      //       edit: "",
      //       multiple: false,
      //       value: "",
      //       render: ""
      //     },
      //     {
      //       type: "select",
      //       label: "Other Systems",
      //       items: [
      //         { text: "Hubspot", value: "Hubspot" },
      //         { text: "JIRA", value: "JIRA" },
      //         { text: "FSP/SuccessFactors", value: "FSP/SuccessFactors" },
      //         { text: "FAS", value: "SAP-FAS" },
      //         { text: "FAD", value: "SAP-FAD" },
      //         { text: "FSQ", value: "SAP-FSQ" },
      //         { text: "ECC", value: "SAP-ECC" },
      //         { text: "HSS", value: "SAP-HSS" },
      //         { text: "IAS", value: "SAP-IAS" },
      //         { text: "Office365 Phone", value: "Office365 Phone" },
      //         {
      //           text: "Office365 Conference Bridge",
      //           value: "Office365 Conference Bridge"
      //         },
      //         {
      //           text: "Office365 Project Plan",
      //           value: "Office365 Project Plan"
      //         },
      //         { text: "LucidChart", value: "LucidChart" }
      //       ],
      //       multiple: true,
      //       edit: "",
      //       render: "",
      //       value: ""
      //     },
      //     {
      //       type: "select",
      //       label: "Email Domain",
      //       items: [
      //         {
      //           text: '@cognitus.com',
      //           value: '@cognitus.com'
      //         },
      //         {
      //           text: "@cognitusconsulting.com",
      //           value: "@cognitusconsulting.com"
      //         },
      //         { text: "@cognitus.com.my", value: "@cognitus.com.my" },
      //         { text: "@cognitus.us", value: "@cognitus.us" },
      //         { text: "@cognitus.one", value: "@cognitus.one" },
      //         { text: "@galloptos4hana.com", value: "@galloptos4hana.com" },
      //         { text: "@gallop.one", value: "@gallop.one" },
      //         { text: "@hamilton.one", value: "@hamilton.one" }
      //       ],
      //       edit: "",
      //       multiple: false,
      //       value: "",
      //       render: ""
      //     },
      //     {
      //       type: "select",
      //       label: "Region",
      //       items: [
      //         { text: "APAC", value: "APAC" },
      //         { text: "Africa", value: "Africa" },
      //         { text: "Europe", value: "Europe" },
      //         { text: "India", value: "India" },
      //         { text: "Latin America", value: "Latin America" },
      //         { text: "Middle East", value: "Middle East" },
      //         { text: "North America", value: "North America" }
      //       ],
      //       edit: "",
      //       multiple: false,
      //       value: "",
      //       render: ""
      //     },
      //     {
      //       type: "autocomplete",
      //       label: "Onboarding Email CC Recipient",
      //       itemsGetter: `(onboardings) => {
      //           if (onboardings === undefined) {
      //               onboardings = this.onboardings;
      //           }
      //           return onboardings.map((onb) => ({
      //               text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
      //               value:  onb.cognitusEmailMailbox + onb.cognitusEmailDomain
      //           }))
      //       }`,
      //       items: [],
      //       input: 'onboardings',
      //       chips: true,
      //       edit: "",
      //       multiple: true,
      //       value: "",
      //       render: "",
      //       search: ""
      //     },
      //     {
      //       type: "autocomplete",
      //       label: "Project Email CC Recipient",
      //       itemsGetter: `(onboardings) => {
      //           if (onboardings === undefined) {
      //               onboardings = this.onboardings;
      //           }
      //           return onboardings.map((onb) => ({
      //               text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
      //               value:  onb.cognitusEmailMailbox + onb.cognitusEmailDomain
      //           }))
      //       }`,
      //       items: [],
      //       input: 'onboardings',
      //       chips: true,
      //       edit: "",
      //       multiple: true,
      //       value: "",
      //       render: "",
      //       search: ""
      //     },
      //     {
      //       // old hardcoded values
      //       // 'ksimms', 'jmaldonado', 'skrishnappa', 'sbandaru', 'ajames', 'jolsen', 'lmunnungi', 'mghisaidoobe', 'kojha', 'mreghioui', 'hsharma', 'pgulabrao', 'lvanderzande', 'rvanderzande', 'fverdesoto', 'awaals', 'nkhanna', 'dgustin', 'rahlawat', 'lmunnungi', 'glauria', 'jromijn', 'evanderlaan', 'mbehrend', 'magrawal', 'emaio', 'acipriani', 'carora', 'psathi', 'swendzel'
      //       type: "autocomplete",
      //       label: "Project Access",
      //       // this.$store.state.onboardings[this.company._id]
      //       itemsGetter: `(onboardings) => {
      //           if (onboardings === undefined) {
      //               onboardings = this.onboardings;
      //           }
      //           return onboardings.map((onb) => ({
      //               text: onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>',
      //               value:  onb.cognitusEmailMailbox + onb.cognitusEmailDomain
      //           }))
      //       }`,
      //       items: [],
      //       input: 'onboardings',
      //       chips: true,
      //       edit: "",
      //       multiple: true,
      //       value: "",
      //       render: "",
      //       search: ""
      //     }
      //   ];
      //   this.companyDataCopy.settings.forEach(setting => {
      //     if (setting.itemsGetter) {
      //       setting.items = eval(setting.itemsGetter)()
      //     }
      //   })
      //   this.save();
      // }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
