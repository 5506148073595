<template>
  <v-layout
    justify-center
    align-center
    row
    wrap
    style="margin: 1em 0;"
    v-if="onboardingFormDataCopy !== null"
  >
    <v-flex xs5 mr-4>
      <v-card
        hover
        @click="
          active = 'employee'; $forceUpdate();
        "
        :outlined="active === 'employee'"
        :color="active === 'employee' ? 'rgba(200, 200, 200, .1)' : ''"
        :style="
          active === 'employee' ? 'color: rgba(0, 100, 0, 1);' : 'color: black'
        "
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      >
        <v-card-title>Employee</v-card-title>
        <v-card-subtitle>
          The onboarded user will be an
          <strong>employee</strong>.
        </v-card-subtitle>
        <v-card-text></v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs5 ml-4>
      <v-card
        hover
        @click="
          active = 'contractor'; $forceUpdate();
        "
        :outlined="active === 'contractor'"
        :color="active === 'contractor' ? 'rgba(200, 200, 200, .1)' : ''"
        :style="
          active === 'contractor'
            ? 'color: rgba(0, 100, 0, 1);'
            : 'color: black'
        "
        :disabled="
          !onboardingFormDataCopy.changeRequest &&
          (onboardingFormDataCopy.flowApprovalStatus === 'Requested' ||
            onboardingFormDataCopy.flowApprovalStatus === 'Approved')
        "
      >
        <v-card-title>Contractor</v-card-title>
        <v-card-subtitle>
          The onboarded user will be an
          <strong>contractor</strong>.
        </v-card-subtitle>
        <v-card-text></v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: [
    "data",
    "onboardings",
    "company",
    "countries",
    "onboarding_id",
    "step"
  ],
  data() {
    return {
      onboardingFormDataCopy: null,
      active: "",
      isStepping: false
    };
  },
  created() {
    var that = this;
    this.onboardingFormDataCopy = JSON.parse(JSON.stringify(that.data));
    this.active = null;
    if (this.onboardingFormDataCopy.employee) {
      this.active = "employee";
    } else if (this.onboardingFormDataCopy.contractor) {
      this.active = "contractor";
    }
    this.$eventBus.$on("nextStep", function(currentStep) {
      if (currentStep === that.step && !that.isStepping) {
        that.isStepping = true;
        let updateData = that.getOnboardingFormDataCopy();
        // console.log(updateData.o365license);
        that.$emit("updateData", updateData, that.step, true, function() {
          that.isStepping = false;
        });
      }
    });
  },
  mounted() {},
  watch: {
    active(val) {
      let that = this;
      if (val === "employee" || val === "contractor") {
        if (val === "employee") {
          this.onboardingFormDataCopy.employee = true;
          this.onboardingFormDataCopy.contractor = false;
          // that.onboardingFormDataCopy.fspSuccessFactorsCheckbox = true;
          if (
            this.onboardingFormDataCopy.o365license === undefined ||
            that.onboardingFormDataCopy.o365license === null ||
            that.onboardingFormDataCopy.o365license === ""
          ) {
            this.onboardingFormDataCopy.o365license = "E3";
          }
          if (
            !this.onboardingFormDataCopy.otherSystem.some(x => x === "JIRA")
          ) {
            this.onboardingFormDataCopy.otherSystem.push("JIRA");
          }
          if (
            !this.onboardingFormDataCopy.otherSystem.some(
              x => x === "FSP/SuccessFactors"
            )
          ) {
            this.onboardingFormDataCopy.otherSystem.push("FSP/SuccessFactors");
          }
        } else if (val === "contractor") {
          this.onboardingFormDataCopy.contractor = true;
          this.onboardingFormDataCopy.employee = false;
          // that.onboardingFormDataCopy.fspSuccessFactorsCheckbox = true;
          if (
            this.onboardingFormDataCopy.o365license === undefined ||
            that.onboardingFormDataCopy.o365license === null ||
            that.onboardingFormDataCopy.o365license === ""
          ) {
            this.onboardingFormDataCopy.o365license = "E1";
          }
          if (this.onboardingFormDataCopy.otherSystem.some(x => x === "JIRA")) {
            this.onboardingFormDataCopy.otherSystem.splice(
              this.onboardingFormDataCopy.otherSystem.indexOf("JIRA"),
              1
            );
          }
          if (
            this.onboardingFormDataCopy.otherSystem.some(
              x => x === "FSP/SuccessFactors"
            )
          ) {
            this.onboardingFormDataCopy.otherSystem.splice(
              this.onboardingFormDataCopy.otherSystem.indexOf(
                "FSP/SuccessFactors"
              ),
              1
            );
          }
        } else {
          this.onboardingFormDataCopy.contractor = false;
          this.onboardingFormDataCopy.employee = false;
          if (
            this.onboardingFormDataCopy.o365license === undefined ||
            that.onboardingFormDataCopy.o365license === null ||
            that.onboardingFormDataCopy.o365license === "" ||
            that.onboardingFormDataCopy.history[
              that.onboardingFormDataCopy.history.length - 1
            ].o365license === undefined ||
            that.onboardingFormDataCopy.history[
              that.onboardingFormDataCopy.history.length - 1
            ].o365license === null ||
            that.onboardingFormDataCopy.history[
              that.onboardingFormDataCopy.history.length - 1
            ].o365license === ""
          ) {
            this.onboardingFormDataCopy.o365license = "";
          }
        }
        this.$eventBus.$emit("stepOkay", {
          step: that.step,
          valid: true
        });
        that.$emit(
          "updateData",
          that.getOnboardingFormDataCopy(),
          that.step,
          false
        );
      } else {
        // that.onboardingFormDataCopy.fspSuccessFactorsCheckbox = false;
        this.$eventBus.$emit("stepOkay", {
          step: that.step,
          valid: false
        });
      }
    },
    data: {
      deep: true,
      handler(val) {
        if (val) {
          this.onboardingFormDataCopy = JSON.parse(JSON.stringify(val));
          this.active = JSON.parse(JSON.stringify(this.active));
          this.$forceUpdate();
        }
      }
    },
    "onboardingFormDataCopy.contractor"(val) {
      var that = this;
      if (val !== undefined && val !== null && val) {
        // this.onboardingFormDataCopy.fspSuccessFactorsCheckbox = true;
        that.$eventBus.$emit("stepOkay", {
          step: that.step,
          valid: true
        });
      } else if (!val && !this.onboardingFormDataCopy.employee) {
        // that.onboardingFormDataCopy.fspSuccessFactorsCheckbox = false;
        that.onboardingFormDataCopy.o365license = "";
        that.$eventBus.$emit("stepOkay", {
          step: that.step,
          valid: false
        });
      }
    },
    "onboardingFormDataCopy.employee"(val) {
      var that = this;
      if (val !== undefined && val !== null && val) {
        that.$eventBus.$emit("stepOkay", {
          step: that.step,
          valid: true
        });
      } else if (!val && !this.onboardingFormDataCopy.contractor) {
        // that.onboardingFormDataCopy.fspSuccessFactorsCheckbox = false;
        that.onboardingFormDataCopy.o365license = "";
        that.$eventBus.$emit("stepOkay", {
          step: that.step,
          valid: false
        });
      }
    }
  },
  methods: {
    getOnboardingFormDataCopy() {
      return this.onboardingFormDataCopy;
    }
  }
};
</script>

<style lang="scss" scoped></style>
