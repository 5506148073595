<template>
  <v-layout
    row
    wrap
    justify-center
    align-center
    v-if="$store.state.userADInfo !== null && $store.state.userInfo !== null"
  >
    <v-flex :xs12="$vuetify.breakpoint.mdAndUp" :xs10="$vuetify.breakpoint.smAndDown" v-if="company !== undefined && company !== null">
      <v-layout
        justify-center
        align-center
        row
        wrap
        v-if="path !== undefined && path !== null && path !== ''"
      >
        <CompanyHome
          v-if="path === 'home'"
          :company="company"
          :users="users"
          :onboardings="onboardings"
        />

        <User
          v-else-if="path.includes('user')"
          :users="users"
          :path="path.includes('user-') ? path.split('user-')[1] : null"
          :user_id="uuid"
          :company="company"
          :companyNavPermissions="companyNavPermissions"
        />

        <Onboarding
          v-else-if="path.includes('onboarding')"
          :company="company"
          :onboardings="onboardings"
          :path="path.includes('onboarding-') ? path.split('onboarding-')[1] : null"
          :onboarding_id="uuid"
          :projects="projects"
          :projectRateCards="projectRateCards"
        />

        <Project
          v-else-if="path.includes('project')"
          :company="company"
          :projects="projects"
          :path="path.includes('project-') ? path.split('project-')[1] : null"
          :project_id="uuid"
          :onboardings="onboardings"
          :projectRateCards="projectRateCards"
        />

        <CompanySettings v-else-if="path === 'settings'" :company="company" />
        <CompanyHistory v-else-if="path === 'history'" :company="company" />
        <EmailComposer v-else-if="path === 'email-composer'" :company="company"/>
        <EmailSend v-else-if="path === 'email-send'" :company="company" :onboardings="onboardings"/>
      </v-layout>
    </v-flex>
    <v-flex xs12 v-else-if="id !== undefined && id !== null">
      <v-layout
        justify-center
        align-center
        row
        wrap
        style="margin: 2em 0;"
        v-if="path !== undefined && path !== null && path !== ''"
      >
        <v-flex xs10 v-if="path === 'join'">
          <company-join :company_id="id" :token="uuid" />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
Date.prototype.monthDays = function() {
  var d = new Date(this.getFullYear(), this.getMonth() + 1, 0);
  return d.getDate();
};

import Onboarding from "@/components/Company/Onboarding/Onboarding.vue";
import User from "@/components/Company/User/User.vue";
import CompanyJoin from "@/components/Company/CompanyJoin.vue";
import CompanySettings from "@/components/Company/CompanySettings.vue";
import CompanyHistory from "@/components/Company/CompanyHistory.vue";
import CompanyHome from "@/components/Company/CompanyHome.vue";
import EmailComposer from "@/components/Company/Onboarding/Email/EmailComposer.vue";
import EmailSend from "@/components/Company/Onboarding/Email/EmailSend.vue";
import Project from "@/components/Company/Project/Project.vue";
export default {
  props: ["id", "path", "uuid"],
  components: {
    User,
    Project,
    Onboarding,
    CompanyJoin,
    CompanySettings,
    CompanyHome,
    CompanyHistory,
    EmailComposer,
    EmailSend
  },
  mounted() {
    if (this.company !== undefined && this.company !== null) {
      this.$eventBus.$emit("getUserInfo", {});
      this.$eventBus.$emit("getOnboardings", { data: {
                  company_id: this.company._id
                }, callback: null});
      this.$eventBus.$emit("getProjects", { data: {
                  company_id: this.company._id
                }, callback: null });
      this.$eventBus.$emit("getProjectRateCards", { data: {
                  company_id: this.company._id
                }, callback: null});
    } else if (
      this.id !== undefined &&
      this.id !== null &&
      this.path !== undefined &&
      (this.path !== null) & (this.path !== "join")
    ) {
      this.$router.push("/");
    }
  },
  computed: {
    companyNavPermissions() {
      let that = this;
      let navPermissions = this.$store.getters.navPermissions
        .map(function(navItemTextValue) {
          let companyReplacedNavItemTextValue = {text: `${that.company._id}.${navItemTextValue.text}`, value: navItemTextValue.value};
          companyReplacedNavItemTextValue.value.navPermission = companyReplacedNavItemTextValue.text;
          return companyReplacedNavItemTextValue;
        })
        .reverse();
      return navPermissions;
    },
    projects() {
      return this.$store.state.projects;
    },
    projectRateCards() {
      return this.$store.state.projectRateCards;
    },
    users() {
      let that = this;
      return this.$store.state.users === null
        ? null
        : this.$store.state.users[
            Object.keys(this.$store.state.users).filter(
              companyID => companyID === that.id
            )[0]
          ];
    },
    company() {
      let that = this;
      return this.$store.state.companies === null
        ? null
        : this.$store.state.companies.filter(
            company => company._id === that.id
          )[0];
    },
    onboardings() {
      let that = this;
      return this.$store.state.onboardings === null
        ? null
        : this.$store.state.onboardings[
            Object.keys(this.$store.state.onboardings).filter(
              companyID => companyID === that.id
            )[0]
          ];
    }
  },
};
</script>

<style scoped>
</style>