<template>
  <v-card>
    <v-card-title>
      <h3>Join existing company</h3>
    </v-card-title>
    <v-card-subtitle>Use an invite token to join an existing company.</v-card-subtitle>
    <v-card-text>
      <v-text-field v-model="inviteCompanyID" label="Company ID"></v-text-field>
      <v-text-field v-model="inviteToken" label="Invite Token"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="
                  inviteToken === '' ||
                    inviteCompanyID === '' ||
                    errorJoiningCompany ||
                    finishedJoiningCompany
                "
        :color="
                  `${
                    joiningCompany
                      ? 'teal'
                      : finishedJoiningCompany
                      ? 'success'
                      : errorJoiningCompany
                      ? 'error'
                      : 'primary'
                  }`
                "
        :loading="joiningCompany"
        @click="joinCompany()"
      >
        {{
        joiningCompany
        ? "Joining Company"
        : finishedJoiningCompany
        ? "Joined Company"
        : errorJoiningCompany
        ? "Error Joining Company"
        : "Join"
        }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["company_id", "token"],
  data() {
    return {
      inviteToken: "",
      inviteCompanyID: "",
      joiningCompany: false,
      errorJoiningCompany: false,
      finishedJoiningCompany: false
    };
  },
  mounted() {
    if (this.company_id !== undefined && this.company_id !== null) {
      this.inviteCompanyID = this.company_id;
    }
    if (this.token !== undefined && this.token !== null) {
      this.inviteToken = this.token;
    }
  },
  methods: {
    joinCompany() {
      var that = this;
      that.joiningCompany = true;
      that.errorJoiningCompany = false;
      that.finishedJoiningCompany = false;
      that.$BackendService.company
        .join({
          user_id: that.$store.state.userADInfo.id,
          user_email: that.$store.state.userADInfo.mail,
          company: that.inviteCompanyID,
          inviteToken: that.inviteToken
        })
        .then(function(company) {
          that.$AlertService.newAlert({
            message: `You have joined: ${company.name}`,
            timeout: 5,
            show: true,
            color: "success"
          });
          that.joiningCompany = false;
          that.finishedJoiningCompany = true;
          that.$eventBus.$emit("getCompanies", {});
          that.$eventBus.$emit("getUserInfo", {});
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: `Failed to join company with invite token.`,
            timeout: 5,
            show: true,
            color: "error"
          });
          that.errorJoiningCompany = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>