
const secret = "09b21bfb-5ca1-42b5-962d-994544e43180";
const backendAPI = (window.location.hostname === 'surfboard.cognitusconsulting.com' ? "https://wavedash.cognitusconsulting.com/" : "https://wavedashqa.cognitusconsulting.com/");
import axios from "axios";
import store from "../store";
import { default as CryptService } from "./crypt.service.js";
// console.log(backendAPI, process.env.NODE_ENV, CryptService)
export default {
  adobe: {
    getAgreementAuditTrail(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getAgreementAuditTrail", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    cancelAgreement: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/cancelAgreement", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    deleteAgreement: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/deleteAgreement", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAgreementEvents: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getAgreementEvents", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAgreementCombinedDocument: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getAgreementCombinedDocument", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    putAgreementState: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/putAgreementState", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAgreementFieldData: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getAgreementFieldData", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    putAgreementFormFieldsMergeInfo: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/putAgreementFormFieldsMergeInfo", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getLibraryDocumentFormFields: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getLibraryDocumentFormFields", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAgreements: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getAgreements", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    createAgreement: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/createAgreement", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getLibraryDocuments: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getLibraryDocuments", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAccessToken: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "adobe/getAccessToken", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  socket: {
    ping: function() {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(secure)
          .then(function(security) {
            let d = {
              security: security,
              socketID: store.state.socketID,
            };
            axios
              .post(backendAPI + "socket/ping", d)
              .then(function(response) {
                CryptService.decryptAES(response.data)
                  .then(function(result) {
                    resolve(result);
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  wavedash: {
    index: function() {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(secure)
          .then(function(security) {
            let d = {
              security: security,
              socketID: store.state.socketID,
            };
            axios
              .post(backendAPI, d)
              .then(function(response) {
                // console.log(response);
                CryptService.decryptAES(response.data)
                  .then(function(result) {
                    
                    resolve(result);
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    permissions: function() {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(secure).then(function(security) {
            let d = {
              security: security,
              socketID: store.state.socketID,
            };
            axios
              .post(backendAPI + "permissions", d)
              .then(function(response) {
                CryptService.decryptAES(response.data)
                  .then(function(result) {
                    resolve(result);
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  mjml: {
    toHtml: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  data: encryptedData,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                };
                axios
                  .post(backendAPI + "mjml2html", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  pdf: {
    fill: function(sourcePDF, uuid, data) {
      return new Promise(function(resolve, reject) {
        axios
          .post("https://pdftk.cognitusconsulting.com/fillForm", {
            sourcePDF: sourcePDF,
            uuid: uuid,
            data: data,
            socketID: store.state.socketID,
          })
          .then(function(response) {
            resolve(response.data);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    fields: function(sourcePDF, uuid) {
      return new Promise(function(resolve, reject) {
        axios
          .post("https://pdftk.cognitusconsulting.com/generateFDFTemplate", {
            sourcePDF: sourcePDF,
            uuid: uuid,
            socketID: store.state.socketID,
          })
          .then(function(response) {
            resolve(response.data);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  db: {
    dump: function() {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(secure)
          .then(function(security) {
            let d = {
              security: security,
              socketID: store.state.socketID,
            };
            axios
              .post(backendAPI + "db/dump", d)
              .then(function(response) {
                CryptService.decryptAES(response.data)
                  .then(function(result) {
                    resolve(result);
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  flow: {
    approval: {
      new: function(data) {
        return new Promise(function(resolve, reject) {
          // console.log("post to " + backendAPI + "flow/approval/new")
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    token: store.state.accessToken,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "flow/approval/new", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      revertTerminate: function(data) {
        return new Promise(function(resolve, reject) {
          // console.log("post to " + backendAPI + "flow/approval/revertTerminate")
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "flow/approval/revertTerminate", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      terminate: function(data) {
        return new Promise(function(resolve, reject) {
          // console.log("post to " + backendAPI + "flow/approval/terminate")
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "flow/approval/terminate", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
    },
    sharepoint: {
      getProjects: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "flow/getProjects", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      getProjectRateCards: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "flow/getRateCards", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      uploadPDFToCognitusVendors: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "flow/uploadPDFToCognitusVendors", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
    },
  },
  company: {
    setting: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/setting")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data._id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/setting", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    updateSoWNumber: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/user/remove")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/updateSoWNumber", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    email: {
      template: {
        save: function(data) {
          return new Promise(function(resolve, reject) {
            let secure = {
              secret: secret,
              user: store.state.userADInfo.id,
              token: store.state.accessToken,
              company: data.company,
            };
            CryptService.encryptAES(data)
              .then(function(encryptedData) {
                CryptService.encryptAES(secure)
                  .then(function(security) {
                    let d = {
                      security: security,
                      socketID: store.state.socketID,
                      userMail: store.state.userADInfo.mail,
                      data: encryptedData,
                    };
                    axios
                      .post(backendAPI + "company/email/template/save", d)
                      .then(function(response) {
                        CryptService.decryptAES(response.data)
                          .then(function(result) {
                            resolve(result);
                          })
                          .catch(function(error) {
                            reject(error);
                          });
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          });
        },
        getAll: function(data) {
          return new Promise(function(resolve, reject) {
            let secure = {
              secret: secret,
              user: store.state.userADInfo.id,
              token: store.state.accessToken,
              company: data._id,
            };

            CryptService.encryptAES(data)
              .then(function(encryptedData) {
                CryptService.encryptAES(secure)
                  .then(function(security) {
                    let d = {
                      security: security,
                      socketID: store.state.socketID,
                      userMail: store.state.userADInfo.mail,
                      data: encryptedData,
                    };
                    axios
                      .post(backendAPI + "company/email/template/get", d)
                      .then(function(response) {
                        CryptService.decryptAES(response.data)
                          .then(function(result) {
                            resolve(result);
                          })
                          .catch(function(error) {
                            reject(error);
                          });
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          });
        },
      },
      send: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company,
          };
          CryptService.encryptAES(data.emailData)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "company/email/send", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
    },
    removeUser: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/user/remove")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/user/remove", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getFromUser: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/getFromUser")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/user/get", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAll: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/getAll")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/get", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    delete: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/delete")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/delete", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    new: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/new")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/new", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    info: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/info")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/info", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    join: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/join")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/join", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    invite: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/invite")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/invite", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    revokeInvite: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/invite/revoke")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/invite/revoke", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    addToHistory: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/addToHistory")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/history/add", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  onboarding: {
    legacy: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/legacy")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/legacy", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    history: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/history")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/history", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    search: function (data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/new")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/search", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    new: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/new")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/new", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    change: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/change")
        // console.log(data);
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/change", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAll: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/onboarding/get")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/getAll", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    get: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/onboarding/get")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/get", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    delete: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "company/onboarding/delete")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/delete", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    update: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/update")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/update", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    finish: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "onboarding/finish")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/onboarding/finish", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  o365: {
    getSubscribedSkus: function() {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(secure)
          .then(function(security) {
            let d = {
              security: security,
              socketID: store.state.socketID,
              userMail: store.state.userADInfo.mail,
              token: store.state.accessToken,
            };
            axios
              .post(backendAPI + "o365/subscribedSkus", d)
              .then(function(response) {
                CryptService.decryptAES(response.data)
                  .then(function(result) {
                    resolve(result);
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    user: {
      getLicenseDetails: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                    token: store.state.accessToken,
                  };
                  axios
                    .post(backendAPI + "o365/user/licenseDetails", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      assignLicense: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                    token: store.state.accessToken,
                  };
                  axios
                    .post(backendAPI + "o365/user/assignLicense", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      get: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                    token: store.state.accessToken,
                  };
                  axios
                    .post(backendAPI + "o365/user/get", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      create: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                    token: store.state.accessToken,
                  };
                  axios
                    .post(backendAPI + "o365/user/create", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      exists: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    token: store.state.accessToken,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "o365/user/exists", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      loginDisabled: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    token: store.state.accessToken,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "o365/user/loginDisabled", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      update: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    token: store.state.accessToken,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "o365/user/update", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      getPhoto: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    token: store.state.accessToken,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "o365/user/photo", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
    },
  },
  user: {
    delete: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "user/delete")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "user/delete", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    login: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "user/login")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "user/login", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    register: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "user/register")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "user/register", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getAll: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "users")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "user/get", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getInfoOf: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "user/infoOf")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/user/infoOf", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    getInfo: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "user/info")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "user/info", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    update: function(data) {
      return new Promise(function(resolve, reject) {
        // console.log("post to " + backendAPI + "user/update")
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "user/update", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  sap: {
    s4: {
      location: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "sap/s4/location", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      }
    },
    sf: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "sap/sf", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    user: {
      create: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "sap/user/create", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
    },
  },
  project: {
    getAll: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/project/getAll", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    update: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/project/update", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    create: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/project/create", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    delete: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/project/delete", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    get: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/project/get", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  projectRateCard: {
    getAll: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/projectRateCard/getAll", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    update: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/projectRateCard/update", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    create: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/projectRateCard/create", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    delete: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/projectRateCard/delete", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
    get: function(data) {
      return new Promise(function(resolve, reject) {
        let secure = {
          secret: secret,
          user: store.state.userADInfo.id,
          token: store.state.accessToken,
          company: data.company_id,
        };
        CryptService.encryptAES(data)
          .then(function(encryptedData) {
            CryptService.encryptAES(secure)
              .then(function(security) {
                let d = {
                  security: security,
                  socketID: store.state.socketID,
                  userMail: store.state.userADInfo.mail,
                  data: encryptedData,
                };
                axios
                  .post(backendAPI + "company/projectRateCard/get", d)
                  .then(function(response) {
                    CryptService.decryptAES(response.data)
                      .then(function(result) {
                        resolve(result);
                      })
                      .catch(function(error) {
                        reject(error);
                      });
                  })
                  .catch(function(error) {
                    reject(error);
                  });
              })
              .catch(function(error) {
                reject(error);
              });
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },
  },
  runn: {
    project: {
      get: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "runn/project", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      getAll: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "runn/projects", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      }
    },
    assignments: {
      getAll: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "runn/assignments", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      }
    },
    people: {
      get: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "runn/person", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      },
      getAll: function(data) {
        return new Promise(function(resolve, reject) {
          let secure = {
            secret: secret,
            user: store.state.userADInfo.id,
            token: store.state.accessToken,
            company: data.company_id,
          };
          CryptService.encryptAES(data)
            .then(function(encryptedData) {
              CryptService.encryptAES(secure)
                .then(function(security) {
                  let d = {
                    security: security,
                    socketID: store.state.socketID,
                    userMail: store.state.userADInfo.mail,
                    data: encryptedData,
                  };
                  axios
                    .post(backendAPI + "runn/people", d)
                    .then(function(response) {
                      CryptService.decryptAES(response.data)
                        .then(function(result) {
                          resolve(result);
                        })
                        .catch(function(error) {
                          reject(error);
                        });
                    })
                    .catch(function(error) {
                      reject(error);
                    });
                })
                .catch(function(error) {
                  reject(error);
                });
            })
            .catch(function(error) {
              reject(error);
            });
        });
      }
    }
  }
};
