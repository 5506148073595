<template>
  <v-app>
    <!-- NEW UPDATE BUTTON -->
    <v-dialog v-model="updateExists" persistent>
      <v-btn @click="refreshApp" elevation="2" x-large tile color="success">
         New version available! Click to update
      </v-btn>
      </v-dialog>
    <!-- ALERTS -->
    <v-alert
      :key="'alert-' + index"
      v-for="(alert, index) in alerts.filter(function(alert) {
        return alert.show;
      })"
      :style="
        `position: fixed; top: calc(3px + ${4 *
          index}em); right: 1em; z-index: 303;`
      "
      :value="alert.show"
      :color="alert.color"
      elevation="2"
      style="height:auto;"
      transistion="fade-transition"
      dismissible
      dense
      tile
      >{{ alert.message }}</v-alert
    >

    <!-- AUTHENTICATION DIALOG -->
    <v-dialog v-model="authDialog" fullscreen hide-overlay>
      <authentication />
    </v-dialog>

    <!-- Socket Connection Lost Dialog / Inactivity Timeout -->
    <v-dialog
      max-width="400"
      v-model="socketConnectionDialog"
      persistent
      no-click-animation
    >
      <v-card height="300">
        <v-card-title style="color: red;" class="headline">Inactivity</v-card-title>
        <v-card-text style="color: black;"
          >Hi you've been inactive for too long. Please reload the page.</v-card-text
        >
        <v-card-actions>
          <v-btn
            style="margin: auto;"
            fab
            x-large
            color="success"
            @click="reload"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- REFRESH FOR NEW VERSION -->
    <v-snackbar
      v-model="snackWithButtons"
      :timeout="timeout"
      bottom
      left
      class="snack"
    >
      {{ snackWithBtnText }}
      <v-spacer />
      <v-btn dark text color="#00f500" @click.native="refreshApp">{{
        snackBtnText
      }}</v-btn>
      <v-btn icon @click="snackWithButtons = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- SMALL SCREEN NAV DRAWER -->
    <v-bottom-navigation
      v-if="$vuetify.breakpoint.smAndDown && $route.params.id !== undefined"
      app
      v-model="bottomNav"
      dark
    >
      <v-btn icon @click="$router.push('/')">
        <v-icon color="error">mdi-domain</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn @click="$router.push(`/company/${$route.params.id}/home`)">
        <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn @click="$router.push(`/company/${$route.params.id}/onboarding`)">
        <v-icon>mdi-briefcase-account</v-icon>
      </v-btn>

      <v-btn @click="$router.push(`/company/${$route.params.id}/project`)">
        <v-icon>mdi-group</v-icon>
      </v-btn>

    </v-bottom-navigation>
    
    <!-- MEDIUM SCREEN -->
    <v-navigation-drawer
      app
      :style="
        `box-shadow: 1px 0px 0px -1px rgba(255,0,0,${0.2}),0 1px 1px 0 rgba(255,0,0,${0.14}),0 1px 3px 0 rgba(255,0,0,${0.12}) !important; max-height: 100% !important;`
      "
      permanent
      width="20em"
      mini-varient
      :mini-variant.sync="miniNav"
      v-if="$store.state.userInfo !== null && $vuetify.breakpoint.mdAndUp"
    >
      <template
        v-slot:prepend
        v-if="
          $store.state.userInfo === undefined || $store.state.userInfo === null
        "
      >
        <v-list-item link :to="{ path: '/authentication' }">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            color="error"
            @click="logout()"
            :block="!miniNav"
            :icon="miniNav"
          >
            <v-icon v-if="miniNav">mdi-logout</v-icon>
            <span v-else>Logout</span>
          </v-btn>
        </div>
      </template>
      <span
        v-if="
          $store.state.userInfo !== undefined && $store.state.userInfo !== null
        "
        :style="`pointer-events: ${miniNav ? 'none' : 'all'} !important;`"
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              :src="
                $store.state.userADPhotoURL !== null
                  ? $store.state.userADPhotoURL
                  : require('./assets/surfing_astronaut.png')
              "
            />
          </v-list-item-avatar>
          <v-list-item-title>
            {{ $store.state.userInfo.first_name }}
            {{ $store.state.userInfo.last_name }}
          </v-list-item-title>
          <v-btn icon @click.stop="miniNav = !miniNav">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <!-- SURFBOARD NAV LIST -->
        <v-list nav dense>
          <v-list-item
            :class="
              `${
                $route.path === '/'
                  ? 'v-list-item v-list-item--link theme--light v-list-item--active primary--text'
                  : ''
              }`
            "
            @click="$route.path === `/` ? '' : $router.push({ path: '/' })"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item
            :class="
              `${
                $route.path === '/settings'
                  ? 'v-list-item v-list-item--link theme--light v-list-item--active primary--text'
                  : ''
              }`
            "
            @click="
              $route.path === `/settings`
                ? ''
                : $router.push({ path: '/settings' })
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <!-- COMPANY NAV LIST -->
        <v-list
          nav
          dense
          v-if="
            companies !== undefined &&
              companies !== null &&
              companies.length > 0
          "
        >
          <v-list-group
            v-for="(company, index) in companies"
            :key="'company' + index"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ company.name }}</v-list-item-title>
            </template>
            <v-list
              nav
              v-for="(item, index) in companyNavListItems.filter(function(
                navItem
              ) {
                let permissionExist =
                  $store.state.userInfo.navPermissions !== undefined
                    ? $store.state.userInfo.navPermissions.some(function(
                        permission
                      ) {
                        return (
                          permission ===
                          `${company._id}.${navItem.navPermission}`
                        );
                      })
                    : false;
                return (
                  $store.state.userInfo.navPermissions.some(
                    (permission) => permission === `${company._id}.admin`
                  ) || permissionExist
                );
              })"
              :key="'companyNavListItem-' + index"
            >
              <v-list-item
                @click.stop="companyListItemClick(company, item, null)"
                v-if="item.items === undefined || item.items === null"
                :class="
                  `${
                    $route.path.includes(
                      'company' +
                        '/' +
                        company._id +
                        '/' +
                        item.name.toLowerCase()
                    )
                      ? 'v-list-item v-list-item--link theme--light v-list-item--active primary--text'
                      : ''
                  }`
                "
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
              <v-list-group
                v-else
                no-action
                :prepend-icon="item.icon"
                :key="'groupList-' + index"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </template>
                <v-list
                  nav
                  v-for="(subItem, index) in item.items.filter(function(
                    navItem
                  ) {
                    let permissionExist =
                      $store.state.userInfo.navPermissions !== undefined
                        ? $store.state.userInfo.navPermissions.some(function(
                            permission
                          ) {
                            return (
                              permission ===
                              `${company._id}.${navItem.navPermission}`
                            );
                          })
                        : false;
                    return (
                      $store.state.userInfo.navPermissions.some(
                        (permission) => permission === `${company._id}.admin`
                      ) || permissionExist
                    );
                  })"
                  :key="'subItem-' + index"
                >
                  <v-list-item
                    v-if="subItem.items === undefined || subItem.items === null"
                    @click.stop="companyListItemClick(company, item, subItem)"
                    :class="
                      `${
                        $route.path.includes(
                          'company' +
                            '/' +
                            company._id +
                            '/' +
                            item.name.toLowerCase() +
                            '-' +
                            subItem.name.toLowerCase()
                        )
                          ? 'v-list-item--active'
                          : ''
                      }`
                    "
                  >
                    <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-group
                    v-else
                    sub-group
                    no-action
                    :prepend-icon="subItem.icon"
                  >
                    <template v-slot:activator>
                      <v-list-item-title>{{ subItem.name }}</v-list-item-title>
                    </template>
                    <v-list
                      nav
                      v-for="(subSubItem, index) in subItem.items.filter(
                        function(navItem) {
                          let permissionExist =
                            $store.state.userInfo.navPermissions !== undefined
                              ? $store.state.userInfo.navPermissions.some(
                                  function(permission) {
                                    return (
                                      permission ===
                                      `${company._id}.${navItem.navPermission}`
                                    );
                                  }
                                )
                              : false;
                          return (
                            $store.state.userInfo.navPermissions.some(
                              (permission) =>
                                permission === `${company._id}.admin`
                            ) || permissionExist
                          );
                        }
                      )"
                      :key="`subSubItem-${index}`"
                    >
                      <v-list-item
                        v-if="
                          subSubItem.items === undefined ||
                            subSubItem.items === null
                        "
                        @click.stop="
                          companyListItemClick(
                            company,
                            item,
                            subItem,
                            subSubItem
                          )
                        "
                        :class="
                          `${
                            $route.path.includes(
                              'company' +
                                '/' +
                                company._id +
                                '/' +
                                item.name.toLowerCase() +
                                '-' +
                                subItem.name.toLowerCase() +
                                '-' +
                                subSubItem.name.toLowerCase()
                            )
                              ? 'v-list-item--active'
                              : ''
                          }`
                        "
                      >
                        <v-list-item-title>
                          {{ subSubItem.name }}
                        </v-list-item-title>
                        <v-list-item-icon>
                          <v-icon>{{ subSubItem.icon }}</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list>
                  </v-list-group>
                </v-list>
              </v-list-group>
            </v-list>
          </v-list-group>
        </v-list>
      </span>
    </v-navigation-drawer>
   
    <!-- MAIN CONTENT -->
    <v-main
      :style="
        `padding: ${$vuetify.breakpoint.mdAndUp ? '56px 0px 32px 56px' : '56px 0px 100px'}; height: calc( ${ $vuetify.breakpoint.mdAndUp ? '100%' : ($vuetify.breakpoint.smAndDown ? '100%' : '100%') } ); width: calc( ${
          $vuetify.breakpoint.mdAndUp ? (miniNav ? '100%' : '100% - 264px') : $vuetify.breakpoint.smAndDown ? '100%' : '100%'
        } ); position: relative; left: ${
          $vuetify.breakpoint.mdAndUp ? (miniNav ? '0em' : '264px') : $vuetify.breakpoint.smAndDown ? '0%' : '0%'
        }; background: url('${require('./assets/surfing_astronaut.png')}') no-repeat calc (${'50% + 0px'});`
      "
    >
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- MEDIUM SCREEN FOOTER -->
    <v-footer app :elevation="0" v-if="$vuetify.breakpoint.mdAndUp">
      <v-tooltip
        top
        :open-on-hover="true"
        style="pointer-events: all !important;"
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <h3
              :style="
                `position: relative; left: ${
                  $store.state.userInfo !== null
                    ? !miniNav
                      ? '16.5em'
                      : '3.5em'
                    : '0em'
                }; text-align: center;`
              "
            >
              Cognitus Consulting LLC
              <sup>&reg;</sup>
            </h3>
          </span>
        </template>
        <span :style="`font-size: large;`"
          >v{{ $store.state.version || "0.0.1-alpha" }}</span
        >
      </v-tooltip>
      <v-spacer></v-spacer>
      <div>
        <v-tooltip
          top
          :close-delay="!socketConnectionStatus ? 5000 : 0"
          :open-on-hover="true"
          style="pointer-events: all !important;"
        >
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-icon
                v-if="!testingSocketConnectionStatus"
                :color="socketConnectionStatus ? 'success' : 'error'"
                >mdi-{{ socketConnectionStatus ? "check" : "close" }}</v-icon
              >
              <v-progress-circular
                v-on="on"
                v-else
                color="warning"
              ></v-progress-circular>
              {{ $store.state.socketID }}
            </span>
          </template>
          <span
            :style="
              `font-size: large; color: ${
                socketConnectionStatus ? 'cyan' : 'red'
              };`
            "
            >{{
              socketConnectionStatus
                ? "Connected to the backend."
                : "Not connected to the backend."
            }}</span
          >
        </v-tooltip>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Authentication from "./components/Authentication.vue";
export default {
  name: "App",
  components: { Authentication },
  data: () => ({
    alerts: [],
    miniNav: false,
    authDialog: false,
    socketConnectionDialog: false,
    socketConnectionStatus: false,
    socketConnectionStatusTimeout: null,
    testingSocketConnectionStatus: false,
    refreshing: false,
    registration: null,
    updateExists: false,
    snackBtnText: "",
    snackWithBtnText: "",
    snackWithButtons: false,
    timeout: -1,
    selectedCompany: null,
    selectingCompany: false,
    selectingCompanyHoverIndex: -1, 
    bottomNav: 0
  }),
  mounted: function() {
    // console.log('hello')
    var that = this;
    if (this.$route.hash === "") {
      this.$socket.on("connect", function() {
        // console.log(that.$socket)
        // console.log("connected to backend websocket." + that.$socket.id);
        that.$store.dispatch("SET_SOCKET_ID", that.$socket.id);
        that.socketConnectionStatus = false;
        that.socketConnectionStatusTimeout = setTimeout(function() {
          that.testSocketConnection();
        }, 10000);
      });
      this.$socket.on("aesKey", function(aesKey) {
        // console.log("connected and stored aesKey", aesKey);
        that.$store.dispatch("SET_AES_KEY", aesKey);
      });
      this.$socket.on("disconnect", function() {
        // console.log("disconnected from backend websocket.");
        that.socketConnectionStatus = false;
      });
      [
        "updated_user",
        "ready_for_onboarded_user_input",
        "ready_for_onboarded_user_input_failed",
        "jira_issue_resolved",
        "jira_issue_resolved_failed",
        "jira_new",
        "jira_new_failed",
        "jira_new_issue",
        "jira_new_issue_failed",
        "flow_approval_new",
        "flow_approval_new_failed",
        "flow_approval_denied",
        "flow_approval_denied_failed",
        "flow_approval_approved",
        "flow_approval_approved_failed",
        "onboarding_flow_approval_requested",
        "onboarding_flow_approval_requested_failed",
        "onboarding_update",
        "onboarding_update_failed",
        "onboarding_delete",
        "onboarding_delete_failed",
        "onboarding_new",
        "onboarding_new_failed",
        "onboarding_new_update",
        "onboarding_new_update_failed",
        "project_new",
        "project_update",
        "project_delete",
        "projectRateCard_new",
        "projectRateCard_delete",
        "projectRateCard_update"
      ].forEach(function(socketListenerKey) {
        that.$socket.on(socketListenerKey, function(data) {
          that.$eventBus.$emit(socketListenerKey, data);
        });
      });
      if (
        this.$store.state.userADInfo === null ||
        this.$store.state.userInfo === null
      ) {
        that.authDialog = true;
      }
    }
    [
      { type: "Users", service: that.$UserService },
      { type: "UserInfo", service: that.$UserService },
      { type: "Companies", service: that.$CompanyService },
      { type: "Onboardings", service: that.$OnboardingService },
      { type: "Projects", service: that.$ProjectService },
      { type: "ProjectRateCards", service: that.$ProjectRateCardService },
    ].forEach(function(getType) {
      that.$eventBus.$on(`get${getType.type}`, function({ data, callback }) {
        getType.service[`get${getType.type}`](data)
          .then(function(d) {
            // console.log(d, getType.type);
            if (callback) callback(d, null);
            // that.$AlertService.newAlert({
            //   message: `Retrieved latest ${getType.type} data.`,
            //   show: true,
            //   color: "success",
            //   timeout: 5
            // });
          })
          .catch(function(error) {
            if (callback) callback(null, error);
            else console.error(error);
            that.$eventBus.$emit('reauth', (error) => {
              console.error(error);
            })
            
          });
      });
    });
    this.$eventBus.$on("authDialog", function(boolean, callback) {
      that.authDialog = boolean;
      if (
        !that.authDialog &&
        that.$store.state.userADInfo !== null &&
        that.$store.state.userInfo !== null
      ) {
        // console.log('authDialog')
        that.$eventBus.$emit("getCompanies", { data: null, callback: function(resp, error) {
          that.$eventBus.$emit("getUsers", { data: null, callback: function(resp, error) {
            callback();
            // that.$eventBus.$emit("getOnboardings", { data: { company_id: that.company._id}, callback: function(resp, error) {
            //   that.$eventBus.$emit("getProjects", { data: null, callback: function(resp, error) {
            //     that.$eventBus.$emit("getProjectRateCards", { data: null, callback: function(resp, error) {
            //       callback();
            //     } });
            //   } });
            // } });
          } });
        } });
      }
    });
    if (this.$vuetify.breakpoint.smAndDown && this.$store.state.userADInfo !== null && this.companies !== null && this.companies.length > 0) {
      this.selectingCompany = true;
    }

  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
    this.miniNav = true;
  },
  watch: {
    selectedCompany(val) {
      if (this.selectingCompany && val !== null) {
        this.selectingCompany = false;
        this.selectingCompanyHoverIndex = -1;
        this.$forceUpdate();
      } else if (val === null) {
        this.selectingCompany = true;
      }
    },
    "$store.state.alerts": {
      deep: true,
      handler(val) {
        if (val !== undefined && val !== null && val.length > 0) {
          this.alerts = val;
        }
      },
    },
  },
  computed: {
    companyNavListItems: function() {
      return this.$store.state.navigation.items;
    },
    companies: function() {
      return this.$store.state.companies;
    },
  },
  methods: {
    showRefreshUI: function(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.snackBtnText = "Refresh";
      this.snackWithBtnText = "New version available!";
      this.snackWithButtons = true;
    },
    reload: function() {
      return window.location.reload(true);
    },
    refreshApp: function() {
      this.snackWithButtons = false;
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    testSocketConnection: function() {
      let that = this;
      this.testingSocketConnectionStatus = true;
      if (that.socketConnectionStatusTimeout) {
        clearTimeout(that.socketConnectionStatusTimeout);
      }
      this.$BackendService.socket
        .ping()
        .then(function() {
          that.socketConnectionStatus = true;
          that.socketConnectionDialog = false;
          that.testingSocketConnectionStatus = false;
          that.socketConnectionStatusTimeout = setTimeout(function() {
            that.testSocketConnection();
          }, (10000 * 60 * 30));
        })
        .catch(function(error) {
          // console.log(error.name)
          console.error(error)
          that.socketConnectionStatus = false;
          // that.socketConnectionDialog = true;
          that.testingSocketConnectionStatus = false;
          that.socketConnectionStatusTimeout = setTimeout(function() {
            that.testSocketConnection();
          }, (10000 * 60 * 30));
        });
    },
    companyListItemClick: function(
      company,
      item,
      subItem = null,
      subSubItem = null
    ) {
      this.$router.push({
        path: `/company/${company._id}/${item.name.toLowerCase()}${
          subItem !== null
            ? "-" +
              subItem.name.toLowerCase() +
              (subSubItem !== null ? "-" + subSubItem.name.toLowerCase() : "")
            : ""
        }`,
      });
    },
    selectCompany: function(company) {
      this.selectedCompany = company;
      this.selectingCompany = false;
      this.selectingCompanyHoverIndex = -1;
    },
    logout: function() {
      this.$eventBus.$emit("logout");
    },
  },
};
</script>
<style>
* {
  font-family: "Poppins";
}
</style>
