module.exports = {
  _id: "",
  company_id: "",
  owners: [],
  sharepointInternalId: "",
  title: "",
  resources: [],
  missingResources: [],
  project_definition_existing_project: "",
  end_client_name: "",
  project_location: "",
  client_project_manager: {
      name: "",
      email: "",
  },
  partner_or_practice: "",
  bill_to_customer: {
      name: "",
      address: {
          street: "",
          city: "",
          state: "",
          zip: "",
      },
      phone: "",
      email_address: "",
  },
  payment_terms: "",
  purchase_order_number: "",
  billing_cycle: "",
  standard_work_week: "",
  expenses: "",
  salesCommission: [],
  history: [],
  lastChange: '',
  changedBy: ''
}