<template>
  <v-flex
    xs12
    v-if="$store.state.userInfo !== null && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && company !== undefined && company !== null && ($store.state.userInfo.navPermissions.includes(`${company._id}.company.home`) || $store.state.userInfo.navPermissions.includes(`${company._id}.admin`))"
  >
    <v-layout justify-center align-center row wrap mb-2>
      <v-flex xs10>
        <v-card>
          <v-btn
            icon
            style="position: absolute; top: 0px; right: 0px;"
            color="warning"
            @click="companyEditMode = !companyEditMode"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-img
            width="320px"
            style="margin: 0 auto;"
            contain
            :src="
                    company.logo !== undefined
                      ? company.logo.url
                      : require('../../assets/3dlogo-cognitus-consulting.png')
                  "
          ></v-img>
          <v-card-title>
            <strong>{{ company.name }}</strong>
          </v-card-title>
          <v-card-text>
            <div>
              <strong>Users</strong>
              :
              <strong v-if="users !== null">
                {{
                users.length
                }}
              </strong>
              <strong v-else>0</strong>
            </div>
            <div>
              <strong>Onboardings</strong>
              :
              <strong v-if="onboardings !== undefined && onboardings !== null">
                {{
                onboardings.length
                }}
              </strong>
              <strong v-else>0</strong>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout justify-center align-center row wrap>
      <v-flex xs10>
        <OnboardingActionRequired :onboardings="onboardings" />
      </v-flex>
    </v-layout>
    <v-layout justify-center align-center row wrap mb-2>
      <v-flex xs10>
        <v-card>
          <v-card-title>Onboarding Status History</v-card-title>
          <v-card-text>
            <column-chart
              :data="onboardingFlowColumnChart.data"
              :xmin="onboardingFlowColumnChart.xmin"
              :xmax="onboardingFlowColumnChart.xmax"
              :xtitle="onboardingFlowColumnChart.xtitle"
              :ytitle="onboardingFlowColumnChart.ytitle"
              title="Flow Status History"
            />
            <column-chart
              :data="onboardingJIRAColumnChart.data"
              :xmin="onboardingJIRAColumnChart.xmin"
              :xmax="onboardingJIRAColumnChart.xmax"
              :xtitle="onboardingJIRAColumnChart.xtitle"
              :ytitle="onboardingJIRAColumnChart.ytitle"
              title="JIRA Status History"
            />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <v-layout justify-center align-center row wrap mb-2>
      <v-flex xs10>
        <v-card>
          <v-card-title>User Activity Per Month</v-card-title>
          <v-card-text>
            <line-chart :data="userLineChartData" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout> -->
  </v-flex>
</template>

<script>
import OnboardingActionRequired from "@/components/Company/Onboarding/OnboardingActionRequired.vue";
export default {
  props: ["company", "onboardings", "users"],
  components: {
    OnboardingActionRequired
  },
  methods: {
    filterOnboardingForChart(statusType) {
      var that = this;
      if (
        that.onboardings !== undefined &&
        that.onboardings !== null &&
        this.onboardings.length > 0
      ) {
        let result = {};
        var currentDate = new Date(Date.now());
        var currentMonth = currentDate.getMonth();
        var numberOfDaysInCurrentMonth = currentDate.monthDays();
        let onboardingsStatusType = that.onboardings.filter(function(onb) {
          return (
            onb[statusType] !== undefined && onb[statusType] !== null && onb[statusType] !== ''
          );
        });
        let chartData = Array.from(
          new Set(
            onboardingsStatusType.map(
              function(onb) {
                return onb[statusType]
              }
            )
          )
        ).map(function(name) {
          let data = [];
          for (let x = 1; x <= numberOfDaysInCurrentMonth; x++) {
            let value = onboardingsStatusType.filter(function(onboarding) {
              let dateTimeOnboarded = new Date(onboarding.date_time_onboarded);
              return (
                dateTimeOnboarded.getMonth() === currentMonth &&
                onboarding[statusType] === name &&
                dateTimeOnboarded.getDate() === x
              );
            }).length;
            if (value > 0) data.push([x, value]);
          }
          if (data.length > 0) {
            return {
              name: name,
              data: data
            }
          } else {
            return null;
          }
        }).filter(dataChunk => dataChunk !== null);
        result.data = chartData;
        result.xmin = 1;
        result.xmax = numberOfDaysInCurrentMonth;
        result.xtitle = "Date";
        result.ytitle = "# of Onboardings";
        return result;
      } else {
        return [[]];
      }
    }
  },
  computed: {
    onboardingJIRAColumnChart() {
      return this.filterOnboardingForChart("jiraIssueStatus");
    },
    onboardingFlowColumnChart() {
      return this.filterOnboardingForChart("flowApprovalStatus");
    },
    userLineChartData() {
      return [[]];
    }
  }
};
</script>

<style lang="scss" scoped>
</style>