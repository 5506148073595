<template>
  <v-layout
    row
    wrap
    justify-center
    v-if="$store.state.userInfo !== undefined && $store.state.userInfo.navPermissions !== undefined && $store.state.userInfo.navPermissions !== null && $store.state.userInfo.navPermissions.includes(`${company._id}.admin`) || $store.state.userInfo.navPermissions.includes(`${company._id}.company.onboarding.email.send`)"
  >
    <v-flex xs10 style="margin-top: 1em;">
      <h1 style="text-align: center;">Email Send</h1>
    </v-flex>
    <v-flex :xs10="populatedTemplate === ''" :xs5="populatedTemplate !== ''">
      <div style="margin: 0em;">
        <v-select label="Template Selection" v-model="template" :items="templates"></v-select>
        <v-autocomplete
          label="Recipient"
          v-if="template"
          :search-input.sync="recipientSearch"
          v-model="onboardingData"
          :items="onboardingsMapped"
        ></v-autocomplete>
        <v-select
          label="Recipient Email Address"
          v-if="onboardingData && template"
          v-model="recipientEmail"
          :items="onboardingDataEmails"
        ></v-select>
        <br />
        <v-text-field v-if="recipientEmail" label="To" disabled :value="recipientEmail"></v-text-field>
        <v-combobox
          v-if="recipientEmail"
          v-model="ccEmail"
          :items="Array.prototype.concat.apply([], onboardings.map(onb => {
            return {
              text: `${onb.firstName + ' ' + onb.lastName + ' <' + onb.cognitusEmailMailbox + onb.cognitusEmailDomain + '>'}`,
              value: onb.cognitusEmailMailbox + onb.cognitusEmailDomain
            }
          })).concat(onboardings.map(onb => {
            return {
              text: `${onb.firstName + ' ' + onb.lastName + ' <' + onb.personalEmailAddress + '>'}`,
              value: onb.personalEmailAddress
            }
          }))"
          label="Cc"
          multiple
          small-chips
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Press <kbd>enter</kbd> to add a new email address
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
        <v-text-field v-if="recipientEmail" label="Subject" v-model="emailSubject"></v-text-field>
        <br />
        <v-btn
          :loading="sendingEmail === true"
          outlined
          v-if="template"
          @click="sendEmail()"
          :color="sendingEmail === true ? 'warning' : sendingEmail === 'success' ? 'success' : sendingEmail === 'error' ? 'error' : 'primary'"
          :disabled="populatedTemplate === '' || !recipientEmail"
        >{{sendingEmail ==='success' ? 'Email Sent' : sendingEmail === 'error' ? 'Error Sending Email' : 'Send Email'}}</v-btn>
      </div>
    </v-flex>
    <v-flex v-if="populatedTemplate !== ''" style="margin-top: 1em;" xs5>
      <h1 style="text-align: center">Email Preview</h1>
      <span v-html="populatedTemplate" style="background-color: #ffffff;"></span>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["company", "onboardings", "onboarding", "dialog"],
  data() {
    return {
      sendingEmail: "",
      onboardingData: null,
      template: null,
      templates: [],
      loadingTemplates: false,
      recipientEmail: "",
      additionalCCEmail: [],
      hardcodedCCEmail: [],
      emailSubject: "",
      recipientSearch: ""
    };
  },
  mounted() {
    let that = this;
    this.loadTemplates();
    if (this.onboarding !== undefined) {
      this.onboardingData = JSON.parse(JSON.stringify(that.onboarding));
    }
  },
  watch: {
    onboarding(val) {
      if (val) this.template = null;
      this.onboardingData = JSON.parse(JSON.stringify(val));
      this.recipientEmail = "";
      this.emailSubject = "";
      this.recipientSearch = "";
    },
    template(val) {
      if (val) {
        this.emailSubject = val.subject;
        if (val.title === 'Time Tracking') {
          this.hardcodedCCEmail = this.company.settings[this.company.settings.indexOf(this.company.settings.filter(s => s.label === 'Time Tracking Email CC Recipient')[0])].value
        } else {
          this.hardcodedCCEmail = this.company.settings[this.company.settings.indexOf(this.company.settings.filter(s => s.label === 'Onboarding Email CC Recipient')[0])].value
        }
      }
    }
  },
  methods: {
    sendEmail() {
      let that = this;
      that.sendingEmail = true;
      //  send email
      that.$BackendService.company.email
        .send({company: that.company._id, emailData: {
          from: { name: "Cognitus CRM", email: "crm@cognitusconsulting.com" },
          plain: "Please use an HTML email viewer.",
          html: that.populatedTemplate,
          to: that.recipientEmail,
          cc: that.ccEmail,
          subject: that.emailSubject
        }})
        .then(function(result) {
          //  alert email sent confirmation
          that.$AlertService.newAlert({
            message: `Sent Email:  ${that.template.title} to ${that.recipientEmail}`,
            color: "success",
            timeout: 5,
            show: true
          });
          that.sendingEmail = "success";
          setTimeout(function() {
            that.sendingEmail = "";
          }, 3000);
          let dateSent = (new Date(Date.now())).toDateString();
          if (that.onboardingData.sentEmails === undefined) {
            that.onboardingData.sentEmails = [];
          }
          that.onboardingData.sentEmails = that.onboardingData.sentEmails.filter(sentEmail => {
            return sentEmail.times !== undefined;
          });
          if (that.onboardingData.sentEmails.some(email => {
            return email.subject === that.emailSubject
          })) {
            let alreadySentEmail = that.onboardingData.sentEmails[that.onboardingData.sentEmails.findIndex(x => {
              return x.subject === that.emailSubject;
            })];
            if (alreadySentEmail.times === undefined) {
              alreadySentEmail.times = [];
            }
            alreadySentEmail.times.push({
              date: dateSent,
              sender: that.$store.state.userADInfo.mail,
              recipient: that.recipientEmail
            });
          } else {
            that.onboardingData.sentEmails.push({
              subject: that.emailSubject,
              times: [{
                date: dateSent,
                sender: that.$store.state.userADInfo.mail,
                recipient: that.recipientEmail
              }]
            });
          }
          
          //  update onboarding sentEmails
          that.$BackendService.onboarding
            .update(that.onboardingData)
            .then(function(updatedOnb) {
              that.$store.dispatch("UPDATE_ONBOARDING", updatedOnb)
              that.$emit("updateOnboarding");
              //  alert updated onboarding email sent status
              that.$AlertService.newAlert({
                message: `Updated onboarding with email sent status!`,
                color: "success",
                timeout: 5,
                show: true
              });
              that.$emit("finished");
            }).catch(function(error) {
              that.$AlertService.newAlert({
                message: `Failed to update onboarding with email sent status!`,
                color: "error",
                timeout: 5,
                show: true
              });
              console.error(error);
            });
        })
        .catch(function(error) {
          that.$AlertService.newAlert({
            message: `Failed to send email!`,
            color: "error",
            timeout: 5,
            show: true
          });
          that.$emit("finished");
          setTimeout(function() {
            that.sendingEmail = "";
          }, 3000);
          that.sendingEmail = "error";
          console.error(error);
        });
    },
    loadTemplates() {
      let that = this;
      if (!this.loadingTemplates) {
        that.loadingTemplates = true;
        this.$BackendService.company.email.template
          .getAll(that.company)
          .then(function(templates) {
            that.templates = templates.docs.map(doc => {
              // console.log(doc);
              return {
                text: doc.title,
                value: doc
              };
            }).filter(template => template.text === "Time Tracking" || template.text === "Welcome to Cognitus" || template.text === "SAP System Access");
            that.loadingTemplates = false;
          })
          .catch(function(error) {
            console.error(error);
          });
      }
    }
  },
  computed: {
    ccEmail: {
      get: function() {
        let that = this;
        let ccEmail = that.additionalCCEmail.concat(that.hardcodedCCEmail);
        if(!ccEmail.includes(that.onboarding.requester)) {
          ccEmail = ccEmail.concat([that.onboarding.requester]);
        }
        return ccEmail;
      },
      set: function(newValue) {
        let that = this;
        let hardCodedCCEmails = that.hardcodedCCEmail.concat([that.onboarding.requester]);
        that.additionalCCEmail = newValue.filter(function(email) {
          return hardCodedCCEmails.indexOf(email) === -1;
        });
      }
      
    },
    onboardingDataEmails() {
      let that = this;
      return this.onboardingData !== null
        ? [
            {
              text: "Cognitus Email Address",
              value:
                that.onboardingData.cognitusEmailMailbox +
                that.onboardingData.cognitusEmailDomain
            },
            {
              text: "Personal Email Address",
              value: that.onboardingData.personalEmailAddress
            }
          ]
        : [];
    },
    onboardingsMapped() {
      let that = this;
      return that.onboardings.map(function(onboarding) {
        return {
          text: onboarding["firstName"] + " " + onboarding["lastName"],
          value: onboarding
        };
      });
    },
    populatedTemplate() {
      let that = this;
      if (this.template !== null && that.onboardingData !== null) {
        let regexMatch = /\$\{([a-zA-Z0-9\-\_\|]+)\}/gm;
        let template = JSON.parse(JSON.stringify(this.template.html));
        let matches = template.match(regexMatch);
        if (matches !== null) {
          matches.forEach(function(match) {
            // console.log(match)
            let value = "";
            if (match === "${otherSystem|SAP}") {
              let otherSystems = that.onboardingData["otherSystem"];
              if (
                !Array.isArray(otherSystems) &&
                typeof otherSystems === "string"
              ) {
                otherSystems = JSON.parse(otherSystems);
              }
              otherSystems = otherSystems
                .filter(function(system) {
                  return (
                    system.includes("SAP-") ||
                    system.includes("FSD") ||
                    system.includes("FAD") ||
                    system.includes("HSS") ||
                    system.includes("FSQ") ||
                    system.includes("ECC") ||
                    system.includes("FSS") ||
                    system.includes("FAS") ||
                    system.includes("ISS") ||
                    system.includes("IAS")
                  );
                })
                .map(function(system) {
                  return system.includes("FSD")
                    ? "FSD"
                    : system.includes("FAD")
                    ? "FSD"
                    : system.includes("HSS")
                    ? "HSS"
                    : system.includes("FSQ")
                    ? "FSQ"
                    : system.includes("FSP")
                    ? "FSP"
                    : system.includes("FSS") || system.includes("FAS")
                    ? "FAS"
                    : system.includes("ECC")
                    ? "ECC"
                    : system.includes("ISS") || system.includes("IAS")
                    ? "ISS"
                    : "";
                });
              if (otherSystems.length > 0) {
                value = `<table cellspacing="2" cellpadding="2" border='2' align="center" style="width: 100%">
                                            <thead>
                                                <tr>
                                                    <th style="background-color: #202020; color: white;" scope="col">
                                                        Description
                                                    </th>
                                                    <th style="background-color: #202020; color: white;" scope="col">
                                                        Application Server
                                                    </th>
                                                    <th style="background-color: #202020; color: white;" scope="col">
                                                        Instance Number
                                                    </th>
                                                    <th style="background-color: #202020; color: white;" scope="col">
                                                        System ID
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>`;
                let tableRowTemplate = "<tr><td>description</td><td>applicationServer</td><td>instanceNumber</td><td>systemID</td></tr>";
                otherSystems.forEach(function(system) {
                  if (system !== "") {
                    let systemID = system.replace("SAP-", "");
                    let description =
                      systemID[0] === "F"
                        ? "Formosa"
                        : systemID[0] === "H"
                        ? "Huxelrube Sandbox"
                        : systemID[0] === "E"
                        ? "ECC IDES EHP7"
                        : systemID[0] === "I"
                        ? "Ives Product Development and Demo"
                        : "";
                    let instanceNumber =
                      description === "Formosa"
                        ? "01"
                        : description === "Huxelrube Sandbox"
                        ? "00"
                        : description === "ECC IDES EHP7"
                        ? "00"
                        : description === "Ives Product Development and Demo"
                        ? "01"
                        : "";
                    if (description === "Formosa") {
                      description +=
                        " " +
                        (systemID[2] === "S"
                          ? "Sandbox"
                          : systemID[2] === "D"
                          ? "Development"
                          : systemID[2] === "P"
                          ? "Production"
                          : systemID[2] === "Q"
                          ? "Quality Assurance"
                          : "");
                    }
                    let applicationServer =
                      systemID.toLowerCase() + ".cognitusconsulting.com";
                    value += JSON.parse(
                      JSON.stringify(
                        tableRowTemplate
                          .replace("description", description)
                          .replace("systemID", systemID)
                          .replace("applicationServer", applicationServer)
                          .replace("instanceNumber", instanceNumber)
                      )
                    );
                  }
                });
                value += "</tbody></table>";
                value = value.replace(
                  "fss.cognitusconsulting.com",
                  "fas.cognitusconsulting.com"
                );
                value = value.replace(
                  'iss.cognitusconsulting.com', 
                  'ias.cognitusconsulting.com'
                )
                value = value.replace(
                  'fsd.cognitusconsulting.com', 
                  'fad.cognitusconsulting.com'
                )
              } else {
                value = "<strong>None.</strong>";
              }
            } else {
              value = that.onboardingData[match.substr(2, match.length - 3)];
              if (value === undefined || value === "" || value === null) {
                value = prompt(
                  "Please enter a valid value for: " +
                    match.substr(2, match.length - 3)
                );
              }
            }
            template = template.replace(match, value);
          });
        }
        return template;
      } else {
        return "";
      }
    }
  }
};
</script>

<style >
</style>